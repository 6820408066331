/* tslint:disable */
/* eslint-disable */
/**
 * Stocky API
 * Playground For Stocky API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { DTDCTracker } from '../model';
// @ts-ignore
import type { LogisticsTrackerDetailsDtdcList200Response } from '../model';
// @ts-ignore
import type { LogisticsTrackerDtdcList200Response } from '../model';
// @ts-ignore
import type { PublicLogisticsTrack } from '../model';
// @ts-ignore
import type { TrackingDetail } from '../model';
/**
 * LogisticsApi - axios parameter creator
 * @export
 */
export const LogisticsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logisticsTestRead: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/logistics/test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logisticsTrackRead: async (slug: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('logisticsTrackRead', 'slug', slug)
            const localVarPath = `/logistics/track/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [manifest_no] manifest_no
         * @param {string} [header] header
         * @param {string} [action] action
         * @param {string} [code] code
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logisticsTrackerDetailsDtdcList: async (manifest_no?: string, header?: string, action?: string, code?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/logistics/tracker_details_dtdc/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (manifest_no !== undefined) {
                localVarQueryParameter['manifest_no'] = manifest_no;
            }

            if (header !== undefined) {
                localVarQueryParameter['header'] = header;
            }

            if (action !== undefined) {
                localVarQueryParameter['action'] = action;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this tracking detail.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logisticsTrackerDetailsDtdcRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('logisticsTrackerDetailsDtdcRead', 'id', id)
            const localVarPath = `/logistics/tracker_details_dtdc/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [status_trans_on] status_trans_on
         * @param {string} [status_trans_time] status_trans_time
         * @param {string} [booked_time] booked_time
         * @param {string} [logistic] logistic
         * @param {string} [shipment_no] shipment_no
         * @param {string} [booked_date] booked_date
         * @param {string} [status] status
         * @param {string} [booking_type] booking_type
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logisticsTrackerDtdcList: async (status_trans_on?: string, status_trans_time?: string, booked_time?: string, logistic?: string, shipment_no?: string, booked_date?: string, status?: string, booking_type?: string, start_date?: string, end_date?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/logistics/tracker_dtdc/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (status_trans_on !== undefined) {
                localVarQueryParameter['status_trans_on'] = status_trans_on;
            }

            if (status_trans_time !== undefined) {
                localVarQueryParameter['status_trans_time'] = status_trans_time;
            }

            if (booked_time !== undefined) {
                localVarQueryParameter['booked_time'] = booked_time;
            }

            if (logistic !== undefined) {
                localVarQueryParameter['logistic'] = logistic;
            }

            if (shipment_no !== undefined) {
                localVarQueryParameter['shipment_no'] = shipment_no;
            }

            if (booked_date !== undefined) {
                localVarQueryParameter['booked_date'] = booked_date;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (booking_type !== undefined) {
                localVarQueryParameter['booking_type'] = booking_type;
            }

            if (start_date !== undefined) {
                localVarQueryParameter['start_date'] = start_date;
            }

            if (end_date !== undefined) {
                localVarQueryParameter['end_date'] = end_date;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this dtdc tracker.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logisticsTrackerDtdcRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('logisticsTrackerDtdcRead', 'id', id)
            const localVarPath = `/logistics/tracker_dtdc/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LogisticsApi - functional programming interface
 * @export
 */
export const LogisticsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LogisticsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logisticsTestRead(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logisticsTestRead(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LogisticsApi.logisticsTestRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logisticsTrackRead(slug: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicLogisticsTrack>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logisticsTrackRead(slug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LogisticsApi.logisticsTrackRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [manifest_no] manifest_no
         * @param {string} [header] header
         * @param {string} [action] action
         * @param {string} [code] code
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logisticsTrackerDetailsDtdcList(manifest_no?: string, header?: string, action?: string, code?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogisticsTrackerDetailsDtdcList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logisticsTrackerDetailsDtdcList(manifest_no, header, action, code, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LogisticsApi.logisticsTrackerDetailsDtdcList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this tracking detail.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logisticsTrackerDetailsDtdcRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TrackingDetail>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logisticsTrackerDetailsDtdcRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LogisticsApi.logisticsTrackerDetailsDtdcRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [status_trans_on] status_trans_on
         * @param {string} [status_trans_time] status_trans_time
         * @param {string} [booked_time] booked_time
         * @param {string} [logistic] logistic
         * @param {string} [shipment_no] shipment_no
         * @param {string} [booked_date] booked_date
         * @param {string} [status] status
         * @param {string} [booking_type] booking_type
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logisticsTrackerDtdcList(status_trans_on?: string, status_trans_time?: string, booked_time?: string, logistic?: string, shipment_no?: string, booked_date?: string, status?: string, booking_type?: string, start_date?: string, end_date?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogisticsTrackerDtdcList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logisticsTrackerDtdcList(status_trans_on, status_trans_time, booked_time, logistic, shipment_no, booked_date, status, booking_type, start_date, end_date, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LogisticsApi.logisticsTrackerDtdcList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this dtdc tracker.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logisticsTrackerDtdcRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DTDCTracker>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logisticsTrackerDtdcRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LogisticsApi.logisticsTrackerDtdcRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LogisticsApi - factory interface
 * @export
 */
export const LogisticsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LogisticsApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logisticsTestRead(options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.logisticsTestRead(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LogisticsApiLogisticsTrackReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logisticsTrackRead(requestParameters: LogisticsApiLogisticsTrackReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<PublicLogisticsTrack> {
            return localVarFp.logisticsTrackRead(requestParameters.slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LogisticsApiLogisticsTrackerDetailsDtdcListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logisticsTrackerDetailsDtdcList(requestParameters: LogisticsApiLogisticsTrackerDetailsDtdcListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<LogisticsTrackerDetailsDtdcList200Response> {
            return localVarFp.logisticsTrackerDetailsDtdcList(requestParameters.manifest_no, requestParameters.header, requestParameters.action, requestParameters.code, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LogisticsApiLogisticsTrackerDetailsDtdcReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logisticsTrackerDetailsDtdcRead(requestParameters: LogisticsApiLogisticsTrackerDetailsDtdcReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<TrackingDetail> {
            return localVarFp.logisticsTrackerDetailsDtdcRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LogisticsApiLogisticsTrackerDtdcListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logisticsTrackerDtdcList(requestParameters: LogisticsApiLogisticsTrackerDtdcListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<LogisticsTrackerDtdcList200Response> {
            return localVarFp.logisticsTrackerDtdcList(requestParameters.status_trans_on, requestParameters.status_trans_time, requestParameters.booked_time, requestParameters.logistic, requestParameters.shipment_no, requestParameters.booked_date, requestParameters.status, requestParameters.booking_type, requestParameters.start_date, requestParameters.end_date, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {LogisticsApiLogisticsTrackerDtdcReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logisticsTrackerDtdcRead(requestParameters: LogisticsApiLogisticsTrackerDtdcReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<DTDCTracker> {
            return localVarFp.logisticsTrackerDtdcRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for logisticsTrackRead operation in LogisticsApi.
 * @export
 * @interface LogisticsApiLogisticsTrackReadRequest
 */
export interface LogisticsApiLogisticsTrackReadRequest {
    /**
     * 
     * @type {string}
     * @memberof LogisticsApiLogisticsTrackRead
     */
    readonly slug: string
}

/**
 * Request parameters for logisticsTrackerDetailsDtdcList operation in LogisticsApi.
 * @export
 * @interface LogisticsApiLogisticsTrackerDetailsDtdcListRequest
 */
export interface LogisticsApiLogisticsTrackerDetailsDtdcListRequest {
    /**
     * manifest_no
     * @type {string}
     * @memberof LogisticsApiLogisticsTrackerDetailsDtdcList
     */
    readonly manifest_no?: string

    /**
     * header
     * @type {string}
     * @memberof LogisticsApiLogisticsTrackerDetailsDtdcList
     */
    readonly header?: string

    /**
     * action
     * @type {string}
     * @memberof LogisticsApiLogisticsTrackerDetailsDtdcList
     */
    readonly action?: string

    /**
     * code
     * @type {string}
     * @memberof LogisticsApiLogisticsTrackerDetailsDtdcList
     */
    readonly code?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof LogisticsApiLogisticsTrackerDetailsDtdcList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof LogisticsApiLogisticsTrackerDetailsDtdcList
     */
    readonly offset?: number
}

/**
 * Request parameters for logisticsTrackerDetailsDtdcRead operation in LogisticsApi.
 * @export
 * @interface LogisticsApiLogisticsTrackerDetailsDtdcReadRequest
 */
export interface LogisticsApiLogisticsTrackerDetailsDtdcReadRequest {
    /**
     * A unique integer value identifying this tracking detail.
     * @type {number}
     * @memberof LogisticsApiLogisticsTrackerDetailsDtdcRead
     */
    readonly id: number
}

/**
 * Request parameters for logisticsTrackerDtdcList operation in LogisticsApi.
 * @export
 * @interface LogisticsApiLogisticsTrackerDtdcListRequest
 */
export interface LogisticsApiLogisticsTrackerDtdcListRequest {
    /**
     * status_trans_on
     * @type {string}
     * @memberof LogisticsApiLogisticsTrackerDtdcList
     */
    readonly status_trans_on?: string

    /**
     * status_trans_time
     * @type {string}
     * @memberof LogisticsApiLogisticsTrackerDtdcList
     */
    readonly status_trans_time?: string

    /**
     * booked_time
     * @type {string}
     * @memberof LogisticsApiLogisticsTrackerDtdcList
     */
    readonly booked_time?: string

    /**
     * logistic
     * @type {string}
     * @memberof LogisticsApiLogisticsTrackerDtdcList
     */
    readonly logistic?: string

    /**
     * shipment_no
     * @type {string}
     * @memberof LogisticsApiLogisticsTrackerDtdcList
     */
    readonly shipment_no?: string

    /**
     * booked_date
     * @type {string}
     * @memberof LogisticsApiLogisticsTrackerDtdcList
     */
    readonly booked_date?: string

    /**
     * status
     * @type {string}
     * @memberof LogisticsApiLogisticsTrackerDtdcList
     */
    readonly status?: string

    /**
     * booking_type
     * @type {string}
     * @memberof LogisticsApiLogisticsTrackerDtdcList
     */
    readonly booking_type?: string

    /**
     * start_date
     * @type {string}
     * @memberof LogisticsApiLogisticsTrackerDtdcList
     */
    readonly start_date?: string

    /**
     * end_date
     * @type {string}
     * @memberof LogisticsApiLogisticsTrackerDtdcList
     */
    readonly end_date?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof LogisticsApiLogisticsTrackerDtdcList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof LogisticsApiLogisticsTrackerDtdcList
     */
    readonly offset?: number
}

/**
 * Request parameters for logisticsTrackerDtdcRead operation in LogisticsApi.
 * @export
 * @interface LogisticsApiLogisticsTrackerDtdcReadRequest
 */
export interface LogisticsApiLogisticsTrackerDtdcReadRequest {
    /**
     * A unique integer value identifying this dtdc tracker.
     * @type {number}
     * @memberof LogisticsApiLogisticsTrackerDtdcRead
     */
    readonly id: number
}

/**
 * LogisticsApi - object-oriented interface
 * @export
 * @class LogisticsApi
 * @extends {BaseAPI}
 */
export class LogisticsApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogisticsApi
     */
    public logisticsTestRead(options?: RawAxiosRequestConfig) {
        return LogisticsApiFp(this.configuration).logisticsTestRead(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LogisticsApiLogisticsTrackReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogisticsApi
     */
    public logisticsTrackRead(requestParameters: LogisticsApiLogisticsTrackReadRequest, options?: RawAxiosRequestConfig) {
        return LogisticsApiFp(this.configuration).logisticsTrackRead(requestParameters.slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LogisticsApiLogisticsTrackerDetailsDtdcListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogisticsApi
     */
    public logisticsTrackerDetailsDtdcList(requestParameters: LogisticsApiLogisticsTrackerDetailsDtdcListRequest = {}, options?: RawAxiosRequestConfig) {
        return LogisticsApiFp(this.configuration).logisticsTrackerDetailsDtdcList(requestParameters.manifest_no, requestParameters.header, requestParameters.action, requestParameters.code, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LogisticsApiLogisticsTrackerDetailsDtdcReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogisticsApi
     */
    public logisticsTrackerDetailsDtdcRead(requestParameters: LogisticsApiLogisticsTrackerDetailsDtdcReadRequest, options?: RawAxiosRequestConfig) {
        return LogisticsApiFp(this.configuration).logisticsTrackerDetailsDtdcRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LogisticsApiLogisticsTrackerDtdcListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogisticsApi
     */
    public logisticsTrackerDtdcList(requestParameters: LogisticsApiLogisticsTrackerDtdcListRequest = {}, options?: RawAxiosRequestConfig) {
        return LogisticsApiFp(this.configuration).logisticsTrackerDtdcList(requestParameters.status_trans_on, requestParameters.status_trans_time, requestParameters.booked_time, requestParameters.logistic, requestParameters.shipment_no, requestParameters.booked_date, requestParameters.status, requestParameters.booking_type, requestParameters.start_date, requestParameters.end_date, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LogisticsApiLogisticsTrackerDtdcReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogisticsApi
     */
    public logisticsTrackerDtdcRead(requestParameters: LogisticsApiLogisticsTrackerDtdcReadRequest, options?: RawAxiosRequestConfig) {
        return LogisticsApiFp(this.configuration).logisticsTrackerDtdcRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}

