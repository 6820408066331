/* tslint:disable */
/* eslint-disable */
/**
 * Stocky API
 * Playground For Stocky API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { HistoryGroupedDiff } from '../model';
// @ts-ignore
import type { RentalAssetCount } from '../model';
// @ts-ignore
import type { RentalAssetRequestPublic } from '../model';
// @ts-ignore
import type { RentalAssetRequestSerializers } from '../model';
// @ts-ignore
import type { RentalAssetRequestStage } from '../model';
// @ts-ignore
import type { RentalHistory } from '../model';
// @ts-ignore
import type { RentalMonthSummary } from '../model';
// @ts-ignore
import type { RentalProductConfigMaster } from '../model';
// @ts-ignore
import type { RentalProductMaster } from '../model';
// @ts-ignore
import type { RentalSerialAssigned } from '../model';
// @ts-ignore
import type { Rentals } from '../model';
// @ts-ignore
import type { RentalsAssignedExportList200Response } from '../model';
// @ts-ignore
import type { RentalsAssignedList200Response } from '../model';
// @ts-ignore
import type { RentalsExportList200Response } from '../model';
// @ts-ignore
import type { RentalsList200Response } from '../model';
// @ts-ignore
import type { RentalsProductConfigMasterList200Response } from '../model';
// @ts-ignore
import type { RentalsProductMasterList200Response } from '../model';
// @ts-ignore
import type { RentalsRateList200Response } from '../model';
// @ts-ignore
import type { RentalsReportsActiveList200Response } from '../model';
// @ts-ignore
import type { RentalsRequestList200Response } from '../model';
// @ts-ignore
import type { RentalsRequestStageList200Response } from '../model';
// @ts-ignore
import type { SerialAssigned } from '../model';
/**
 * RentalsApi - axios parameter creator
 * @export
 */
export const RentalsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [account] account
         * @param {string} [connect] connect
         * @param {string} [serial] serial
         * @param {string} [rent_year] rent_year
         * @param {string} [rent_month] rent_month
         * @param {string} [status] status
         * @param {string} [replacement_serial] replacement_serial
         * @param {string} [pagination] Pagination
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsAssignedExportList: async (account?: string, connect?: string, serial?: string, rent_year?: string, rent_month?: string, status?: string, replacement_serial?: string, pagination?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rentals/assigned/export/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (account !== undefined) {
                localVarQueryParameter['account'] = account;
            }

            if (connect !== undefined) {
                localVarQueryParameter['connect'] = connect;
            }

            if (serial !== undefined) {
                localVarQueryParameter['serial'] = serial;
            }

            if (rent_year !== undefined) {
                localVarQueryParameter['rent_year'] = rent_year;
            }

            if (rent_month !== undefined) {
                localVarQueryParameter['rent_month'] = rent_month;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (replacement_serial !== undefined) {
                localVarQueryParameter['replacement_serial'] = replacement_serial;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [account] account
         * @param {string} [connect] connect
         * @param {string} [serial] serial
         * @param {string} [rent_year] rent_year
         * @param {string} [rent_month] rent_month
         * @param {string} [status] status
         * @param {string} [replacement_serial] replacement_serial
         * @param {string} [pagination] Pagination
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsAssignedList: async (account?: string, connect?: string, serial?: string, rent_year?: string, rent_month?: string, status?: string, replacement_serial?: string, pagination?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rentals/assigned/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (account !== undefined) {
                localVarQueryParameter['account'] = account;
            }

            if (connect !== undefined) {
                localVarQueryParameter['connect'] = connect;
            }

            if (serial !== undefined) {
                localVarQueryParameter['serial'] = serial;
            }

            if (rent_year !== undefined) {
                localVarQueryParameter['rent_year'] = rent_year;
            }

            if (rent_month !== undefined) {
                localVarQueryParameter['rent_month'] = rent_month;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (replacement_serial !== undefined) {
                localVarQueryParameter['replacement_serial'] = replacement_serial;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} slug 
         * @param {string} serial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsAssignedRentalSerialRead: async (slug: string, serial: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('rentalsAssignedRentalSerialRead', 'slug', slug)
            // verify required parameter 'serial' is not null or undefined
            assertParamExists('rentalsAssignedRentalSerialRead', 'serial', serial)
            const localVarPath = `/rentals/assigned/rental/serial/{slug}/{serial}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)))
                .replace(`{${"serial"}}`, encodeURIComponent(String(serial)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} slug 
         * @param {string} serial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsAssignedSerialRead: async (slug: string, serial: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('rentalsAssignedSerialRead', 'slug', slug)
            // verify required parameter 'serial' is not null or undefined
            assertParamExists('rentalsAssignedSerialRead', 'serial', serial)
            const localVarPath = `/rentals/assigned/serial/{slug}/{serial}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)))
                .replace(`{${"serial"}}`, encodeURIComponent(String(serial)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsCountRead: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rentals/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsDailyMonthStatsRead: async (account_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'account_id' is not null or undefined
            assertParamExists('rentalsDailyMonthStatsRead', 'account_id', account_id)
            const localVarPath = `/rentals/daily/month/stats/{account_id}`
                .replace(`{${"account_id"}}`, encodeURIComponent(String(account_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rentals.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rentalsDelete', 'id', id)
            const localVarPath = `/rentals/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {string} [account] account
         * @param {string} [connect] connect
         * @param {string} [serial] serial
         * @param {string} [rent_year] rent_year
         * @param {string} [rent_month] rent_month
         * @param {string} [status] status
         * @param {string} [rental_rate] rental_rate
         * @param {string} [logistics_cost] logistics_cost
         * @param {string} [deposit] deposit
         * @param {string} [replacement_serial] replacement_serial
         * @param {string} [logistics_cost__isnull] logistics_cost__isnull
         * @param {string} [deposit__isnull] deposit__isnull
         * @param {string} [dispatch_date] dispatch_date
         * @param {string} [product__item__id] product__item__id
         * @param {string} [pagination] Pagination
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsExportList: async (search?: string, account?: string, connect?: string, serial?: string, rent_year?: string, rent_month?: string, status?: string, rental_rate?: string, logistics_cost?: string, deposit?: string, replacement_serial?: string, logistics_cost__isnull?: string, deposit__isnull?: string, dispatch_date?: string, product__item__id?: string, pagination?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rentals/export/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (account !== undefined) {
                localVarQueryParameter['account'] = account;
            }

            if (connect !== undefined) {
                localVarQueryParameter['connect'] = connect;
            }

            if (serial !== undefined) {
                localVarQueryParameter['serial'] = serial;
            }

            if (rent_year !== undefined) {
                localVarQueryParameter['rent_year'] = rent_year;
            }

            if (rent_month !== undefined) {
                localVarQueryParameter['rent_month'] = rent_month;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (rental_rate !== undefined) {
                localVarQueryParameter['rental_rate'] = rental_rate;
            }

            if (logistics_cost !== undefined) {
                localVarQueryParameter['logistics_cost'] = logistics_cost;
            }

            if (deposit !== undefined) {
                localVarQueryParameter['deposit'] = deposit;
            }

            if (replacement_serial !== undefined) {
                localVarQueryParameter['replacement_serial'] = replacement_serial;
            }

            if (logistics_cost__isnull !== undefined) {
                localVarQueryParameter['logistics_cost__isnull'] = logistics_cost__isnull;
            }

            if (deposit__isnull !== undefined) {
                localVarQueryParameter['deposit__isnull'] = deposit__isnull;
            }

            if (dispatch_date !== undefined) {
                localVarQueryParameter['dispatch_date'] = dispatch_date;
            }

            if (product__item__id !== undefined) {
                localVarQueryParameter['product__item__id'] = product__item__id;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rentals.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsHistoryDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rentalsHistoryDelete', 'id', id)
            const localVarPath = `/rentals/history/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rentals.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsHistoryDiffDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rentalsHistoryDiffDelete', 'id', id)
            const localVarPath = `/rentals/history/diff/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rentals.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsHistoryDiffRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rentalsHistoryDiffRead', 'id', id)
            const localVarPath = `/rentals/history/diff/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rentals.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsHistoryRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rentalsHistoryRead', 'id', id)
            const localVarPath = `/rentals/history/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {string} [account] account
         * @param {string} [connect] connect
         * @param {string} [serial] serial
         * @param {string} [rent_year] rent_year
         * @param {string} [rent_month] rent_month
         * @param {string} [status] status
         * @param {string} [rental_rate] rental_rate
         * @param {string} [logistics_cost] logistics_cost
         * @param {string} [deposit] deposit
         * @param {string} [replacement_serial] replacement_serial
         * @param {string} [logistics_cost__isnull] logistics_cost__isnull
         * @param {string} [deposit__isnull] deposit__isnull
         * @param {string} [dispatch_date] dispatch_date
         * @param {string} [product__item__id] product__item__id
         * @param {string} [pagination] Pagination
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsList: async (search?: string, account?: string, connect?: string, serial?: string, rent_year?: string, rent_month?: string, status?: string, rental_rate?: string, logistics_cost?: string, deposit?: string, replacement_serial?: string, logistics_cost__isnull?: string, deposit__isnull?: string, dispatch_date?: string, product__item__id?: string, pagination?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rentals/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (account !== undefined) {
                localVarQueryParameter['account'] = account;
            }

            if (connect !== undefined) {
                localVarQueryParameter['connect'] = connect;
            }

            if (serial !== undefined) {
                localVarQueryParameter['serial'] = serial;
            }

            if (rent_year !== undefined) {
                localVarQueryParameter['rent_year'] = rent_year;
            }

            if (rent_month !== undefined) {
                localVarQueryParameter['rent_month'] = rent_month;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (rental_rate !== undefined) {
                localVarQueryParameter['rental_rate'] = rental_rate;
            }

            if (logistics_cost !== undefined) {
                localVarQueryParameter['logistics_cost'] = logistics_cost;
            }

            if (deposit !== undefined) {
                localVarQueryParameter['deposit'] = deposit;
            }

            if (replacement_serial !== undefined) {
                localVarQueryParameter['replacement_serial'] = replacement_serial;
            }

            if (logistics_cost__isnull !== undefined) {
                localVarQueryParameter['logistics_cost__isnull'] = logistics_cost__isnull;
            }

            if (deposit__isnull !== undefined) {
                localVarQueryParameter['deposit__isnull'] = deposit__isnull;
            }

            if (dispatch_date !== undefined) {
                localVarQueryParameter['dispatch_date'] = dispatch_date;
            }

            if (product__item__id !== undefined) {
                localVarQueryParameter['product__item__id'] = product__item__id;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsMonthSummaryRead: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rentals/month_summary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} account_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsMonthlyStatsRead: async (account_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'account_id' is not null or undefined
            assertParamExists('rentalsMonthlyStatsRead', 'account_id', account_id)
            const localVarPath = `/rentals/monthly/stats/{account_id}`
                .replace(`{${"account_id"}}`, encodeURIComponent(String(account_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rentals.
         * @param {Rentals} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsPartialUpdate: async (id: number, data: Rentals, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rentalsPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('rentalsPartialUpdate', 'data', data)
            const localVarPath = `/rentals/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RentalProductConfigMaster} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsProductConfigMasterCreate: async (data: RentalProductConfigMaster, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('rentalsProductConfigMasterCreate', 'data', data)
            const localVarPath = `/rentals/product/config/master/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rental product config master.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsProductConfigMasterDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rentalsProductConfigMasterDelete', 'id', id)
            const localVarPath = `/rentals/product/config/master/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {string} [id] id
         * @param {string} [product] product
         * @param {string} [account] account
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsProductConfigMasterList: async (search?: string, id?: string, product?: string, account?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rentals/product/config/master/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (product !== undefined) {
                localVarQueryParameter['product'] = product;
            }

            if (account !== undefined) {
                localVarQueryParameter['account'] = account;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rental product config master.
         * @param {RentalProductConfigMaster} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsProductConfigMasterPartialUpdate: async (id: number, data: RentalProductConfigMaster, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rentalsProductConfigMasterPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('rentalsProductConfigMasterPartialUpdate', 'data', data)
            const localVarPath = `/rentals/product/config/master/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rental product config master.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsProductConfigMasterRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rentalsProductConfigMasterRead', 'id', id)
            const localVarPath = `/rentals/product/config/master/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rental product config master.
         * @param {RentalProductConfigMaster} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsProductConfigMasterUpdate: async (id: number, data: RentalProductConfigMaster, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rentalsProductConfigMasterUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('rentalsProductConfigMasterUpdate', 'data', data)
            const localVarPath = `/rentals/product/config/master/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RentalProductMaster} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsProductMasterCreate: async (data: RentalProductMaster, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('rentalsProductMasterCreate', 'data', data)
            const localVarPath = `/rentals/product/master/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rental product master.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsProductMasterDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rentalsProductMasterDelete', 'id', id)
            const localVarPath = `/rentals/product/master/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {string} [id] id
         * @param {string} [account] account
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsProductMasterList: async (search?: string, id?: string, account?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rentals/product/master/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (account !== undefined) {
                localVarQueryParameter['account'] = account;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rental product master.
         * @param {RentalProductMaster} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsProductMasterPartialUpdate: async (id: number, data: RentalProductMaster, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rentalsProductMasterPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('rentalsProductMasterPartialUpdate', 'data', data)
            const localVarPath = `/rentals/product/master/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rental product master.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsProductMasterRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rentalsProductMasterRead', 'id', id)
            const localVarPath = `/rentals/product/master/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rental product master.
         * @param {RentalProductMaster} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsProductMasterUpdate: async (id: number, data: RentalProductMaster, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rentalsProductMasterUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('rentalsProductMasterUpdate', 'data', data)
            const localVarPath = `/rentals/product/master/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsPublicRequestRead: async (slug: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('rentalsPublicRequestRead', 'slug', slug)
            const localVarPath = `/rentals/public/request/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [rent_month] rent_month
         * @param {string} [rent_year] rent_year
         * @param {string} [connect_id] connect_id
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsRateExportList: async (rent_month?: string, rent_year?: string, connect_id?: string, search?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rentals/rate/export`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (rent_month !== undefined) {
                localVarQueryParameter['rent_month'] = rent_month;
            }

            if (rent_year !== undefined) {
                localVarQueryParameter['rent_year'] = rent_year;
            }

            if (connect_id !== undefined) {
                localVarQueryParameter['connect_id'] = connect_id;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [rent_month] rent_month
         * @param {string} [rent_year] rent_year
         * @param {string} [connect_id] connect_id
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsRateList: async (rent_month?: string, rent_year?: string, connect_id?: string, search?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rentals/rate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (rent_month !== undefined) {
                localVarQueryParameter['rent_month'] = rent_month;
            }

            if (rent_year !== undefined) {
                localVarQueryParameter['rent_year'] = rent_year;
            }

            if (connect_id !== undefined) {
                localVarQueryParameter['connect_id'] = connect_id;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rentals.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rentalsRead', 'id', id)
            const localVarPath = `/rentals/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [account] account
         * @param {string} [rent_month] rent_month
         * @param {string} [rent_year] rent_year
         * @param {RentalsReportsActiveListOrderBy} [order_by] Ordering
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsReportsActiveList: async (account?: string, rent_month?: string, rent_year?: string, order_by?: RentalsReportsActiveListOrderBy, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rentals/reports/active/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (account !== undefined) {
                localVarQueryParameter['account'] = account;
            }

            if (rent_month !== undefined) {
                localVarQueryParameter['rent_month'] = rent_month;
            }

            if (rent_year !== undefined) {
                localVarQueryParameter['rent_year'] = rent_year;
            }

            if (order_by !== undefined) {
                localVarQueryParameter['order_by'] = order_by;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} serial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsReportsTrailRead: async (serial: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serial' is not null or undefined
            assertParamExists('rentalsReportsTrailRead', 'serial', serial)
            const localVarPath = `/rentals/reports/trail/{serial}`
                .replace(`{${"serial"}}`, encodeURIComponent(String(serial)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RentalAssetRequestSerializers} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsRequestCreate: async (data: RentalAssetRequestSerializers, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('rentalsRequestCreate', 'data', data)
            const localVarPath = `/rentals/request/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [slug] slug
         * @param {RentalsRequestListRequestType} [request_type] request_type
         * @param {string} [connect] connect
         * @param {string} [serial] serial
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [stages] stages
         * @param {string} [exclude_order_stage] exclude_order_stage
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsRequestList: async (slug?: string, request_type?: RentalsRequestListRequestType, connect?: string, serial?: string, start_date?: string, end_date?: string, stages?: string, exclude_order_stage?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rentals/request/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (slug !== undefined) {
                localVarQueryParameter['slug'] = slug;
            }

            if (request_type !== undefined) {
                localVarQueryParameter['request_type'] = request_type;
            }

            if (connect !== undefined) {
                localVarQueryParameter['connect'] = connect;
            }

            if (serial !== undefined) {
                localVarQueryParameter['serial'] = serial;
            }

            if (start_date !== undefined) {
                localVarQueryParameter['start_date'] = start_date;
            }

            if (end_date !== undefined) {
                localVarQueryParameter['end_date'] = end_date;
            }

            if (stages !== undefined) {
                localVarQueryParameter['stages'] = stages;
            }

            if (exclude_order_stage !== undefined) {
                localVarQueryParameter['exclude_order_stage'] = exclude_order_stage;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rental asset request.
         * @param {RentalAssetRequestSerializers} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsRequestPartialUpdate: async (id: number, data: RentalAssetRequestSerializers, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rentalsRequestPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('rentalsRequestPartialUpdate', 'data', data)
            const localVarPath = `/rentals/request/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rental asset request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsRequestRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rentalsRequestRead', 'id', id)
            const localVarPath = `/rentals/request/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RentalAssetRequestStage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsRequestStageCreate: async (data: RentalAssetRequestStage, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('rentalsRequestStageCreate', 'data', data)
            const localVarPath = `/rentals/request/stage/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [stage] stage
         * @param {string} [request] request
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsRequestStageList: async (stage?: string, request?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rentals/request/stage/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (stage !== undefined) {
                localVarQueryParameter['stage'] = stage;
            }

            if (request !== undefined) {
                localVarQueryParameter['request'] = request;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rental asset request stage.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsRequestStageRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rentalsRequestStageRead', 'id', id)
            const localVarPath = `/rentals/request/stage/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rental asset request.
         * @param {RentalAssetRequestSerializers} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsRequestUpdate: async (id: number, data: RentalAssetRequestSerializers, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rentalsRequestUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('rentalsRequestUpdate', 'data', data)
            const localVarPath = `/rentals/request/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsTestRead: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/rentals/test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rentals.
         * @param {Rentals} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsUpdate: async (id: number, data: Rentals, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('rentalsUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('rentalsUpdate', 'data', data)
            const localVarPath = `/rentals/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RentalsApi - functional programming interface
 * @export
 */
export const RentalsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RentalsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [account] account
         * @param {string} [connect] connect
         * @param {string} [serial] serial
         * @param {string} [rent_year] rent_year
         * @param {string} [rent_month] rent_month
         * @param {string} [status] status
         * @param {string} [replacement_serial] replacement_serial
         * @param {string} [pagination] Pagination
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsAssignedExportList(account?: string, connect?: string, serial?: string, rent_year?: string, rent_month?: string, status?: string, replacement_serial?: string, pagination?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalsAssignedExportList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsAssignedExportList(account, connect, serial, rent_year, rent_month, status, replacement_serial, pagination, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsAssignedExportList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [account] account
         * @param {string} [connect] connect
         * @param {string} [serial] serial
         * @param {string} [rent_year] rent_year
         * @param {string} [rent_month] rent_month
         * @param {string} [status] status
         * @param {string} [replacement_serial] replacement_serial
         * @param {string} [pagination] Pagination
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsAssignedList(account?: string, connect?: string, serial?: string, rent_year?: string, rent_month?: string, status?: string, replacement_serial?: string, pagination?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalsAssignedList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsAssignedList(account, connect, serial, rent_year, rent_month, status, replacement_serial, pagination, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsAssignedList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} slug 
         * @param {string} serial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsAssignedRentalSerialRead(slug: string, serial: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalSerialAssigned>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsAssignedRentalSerialRead(slug, serial, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsAssignedRentalSerialRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} slug 
         * @param {string} serial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsAssignedSerialRead(slug: string, serial: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SerialAssigned>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsAssignedSerialRead(slug, serial, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsAssignedSerialRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsCountRead(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalAssetCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsCountRead(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsCountRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} account_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsDailyMonthStatsRead(account_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsDailyMonthStatsRead(account_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsDailyMonthStatsRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rentals.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {string} [account] account
         * @param {string} [connect] connect
         * @param {string} [serial] serial
         * @param {string} [rent_year] rent_year
         * @param {string} [rent_month] rent_month
         * @param {string} [status] status
         * @param {string} [rental_rate] rental_rate
         * @param {string} [logistics_cost] logistics_cost
         * @param {string} [deposit] deposit
         * @param {string} [replacement_serial] replacement_serial
         * @param {string} [logistics_cost__isnull] logistics_cost__isnull
         * @param {string} [deposit__isnull] deposit__isnull
         * @param {string} [dispatch_date] dispatch_date
         * @param {string} [product__item__id] product__item__id
         * @param {string} [pagination] Pagination
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsExportList(search?: string, account?: string, connect?: string, serial?: string, rent_year?: string, rent_month?: string, status?: string, rental_rate?: string, logistics_cost?: string, deposit?: string, replacement_serial?: string, logistics_cost__isnull?: string, deposit__isnull?: string, dispatch_date?: string, product__item__id?: string, pagination?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalsExportList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsExportList(search, account, connect, serial, rent_year, rent_month, status, rental_rate, logistics_cost, deposit, replacement_serial, logistics_cost__isnull, deposit__isnull, dispatch_date, product__item__id, pagination, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsExportList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rentals.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsHistoryDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsHistoryDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsHistoryDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rentals.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsHistoryDiffDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsHistoryDiffDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsHistoryDiffDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rentals.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsHistoryDiffRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HistoryGroupedDiff>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsHistoryDiffRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsHistoryDiffRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rentals.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsHistoryRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalHistory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsHistoryRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsHistoryRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {string} [account] account
         * @param {string} [connect] connect
         * @param {string} [serial] serial
         * @param {string} [rent_year] rent_year
         * @param {string} [rent_month] rent_month
         * @param {string} [status] status
         * @param {string} [rental_rate] rental_rate
         * @param {string} [logistics_cost] logistics_cost
         * @param {string} [deposit] deposit
         * @param {string} [replacement_serial] replacement_serial
         * @param {string} [logistics_cost__isnull] logistics_cost__isnull
         * @param {string} [deposit__isnull] deposit__isnull
         * @param {string} [dispatch_date] dispatch_date
         * @param {string} [product__item__id] product__item__id
         * @param {string} [pagination] Pagination
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsList(search?: string, account?: string, connect?: string, serial?: string, rent_year?: string, rent_month?: string, status?: string, rental_rate?: string, logistics_cost?: string, deposit?: string, replacement_serial?: string, logistics_cost__isnull?: string, deposit__isnull?: string, dispatch_date?: string, product__item__id?: string, pagination?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsList(search, account, connect, serial, rent_year, rent_month, status, rental_rate, logistics_cost, deposit, replacement_serial, logistics_cost__isnull, deposit__isnull, dispatch_date, product__item__id, pagination, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsMonthSummaryRead(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalMonthSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsMonthSummaryRead(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsMonthSummaryRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} account_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsMonthlyStatsRead(account_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsMonthlyStatsRead(account_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsMonthlyStatsRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rentals.
         * @param {Rentals} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsPartialUpdate(id: number, data: Rentals, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Rentals>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsPartialUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RentalProductConfigMaster} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsProductConfigMasterCreate(data: RentalProductConfigMaster, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalProductConfigMaster>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsProductConfigMasterCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsProductConfigMasterCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rental product config master.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsProductConfigMasterDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsProductConfigMasterDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsProductConfigMasterDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {string} [id] id
         * @param {string} [product] product
         * @param {string} [account] account
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsProductConfigMasterList(search?: string, id?: string, product?: string, account?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalsProductConfigMasterList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsProductConfigMasterList(search, id, product, account, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsProductConfigMasterList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rental product config master.
         * @param {RentalProductConfigMaster} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsProductConfigMasterPartialUpdate(id: number, data: RentalProductConfigMaster, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalProductConfigMaster>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsProductConfigMasterPartialUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsProductConfigMasterPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rental product config master.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsProductConfigMasterRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalProductConfigMaster>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsProductConfigMasterRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsProductConfigMasterRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rental product config master.
         * @param {RentalProductConfigMaster} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsProductConfigMasterUpdate(id: number, data: RentalProductConfigMaster, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalProductConfigMaster>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsProductConfigMasterUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsProductConfigMasterUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RentalProductMaster} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsProductMasterCreate(data: RentalProductMaster, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalProductMaster>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsProductMasterCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsProductMasterCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rental product master.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsProductMasterDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsProductMasterDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsProductMasterDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {string} [id] id
         * @param {string} [account] account
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsProductMasterList(search?: string, id?: string, account?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalsProductMasterList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsProductMasterList(search, id, account, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsProductMasterList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rental product master.
         * @param {RentalProductMaster} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsProductMasterPartialUpdate(id: number, data: RentalProductMaster, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalProductMaster>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsProductMasterPartialUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsProductMasterPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rental product master.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsProductMasterRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalProductMaster>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsProductMasterRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsProductMasterRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rental product master.
         * @param {RentalProductMaster} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsProductMasterUpdate(id: number, data: RentalProductMaster, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalProductMaster>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsProductMasterUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsProductMasterUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsPublicRequestRead(slug: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalAssetRequestPublic>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsPublicRequestRead(slug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsPublicRequestRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [rent_month] rent_month
         * @param {string} [rent_year] rent_year
         * @param {string} [connect_id] connect_id
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsRateExportList(rent_month?: string, rent_year?: string, connect_id?: string, search?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalsRateList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsRateExportList(rent_month, rent_year, connect_id, search, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsRateExportList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [rent_month] rent_month
         * @param {string} [rent_year] rent_year
         * @param {string} [connect_id] connect_id
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsRateList(rent_month?: string, rent_year?: string, connect_id?: string, search?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalsRateList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsRateList(rent_month, rent_year, connect_id, search, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsRateList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rentals.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Rentals>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [account] account
         * @param {string} [rent_month] rent_month
         * @param {string} [rent_year] rent_year
         * @param {RentalsReportsActiveListOrderBy} [order_by] Ordering
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsReportsActiveList(account?: string, rent_month?: string, rent_year?: string, order_by?: RentalsReportsActiveListOrderBy, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalsReportsActiveList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsReportsActiveList(account, rent_month, rent_year, order_by, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsReportsActiveList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} serial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsReportsTrailRead(serial: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsReportsTrailRead(serial, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsReportsTrailRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RentalAssetRequestSerializers} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsRequestCreate(data: RentalAssetRequestSerializers, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalAssetRequestSerializers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsRequestCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsRequestCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [slug] slug
         * @param {RentalsRequestListRequestType} [request_type] request_type
         * @param {string} [connect] connect
         * @param {string} [serial] serial
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [stages] stages
         * @param {string} [exclude_order_stage] exclude_order_stage
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsRequestList(slug?: string, request_type?: RentalsRequestListRequestType, connect?: string, serial?: string, start_date?: string, end_date?: string, stages?: string, exclude_order_stage?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalsRequestList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsRequestList(slug, request_type, connect, serial, start_date, end_date, stages, exclude_order_stage, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsRequestList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rental asset request.
         * @param {RentalAssetRequestSerializers} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsRequestPartialUpdate(id: number, data: RentalAssetRequestSerializers, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalAssetRequestSerializers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsRequestPartialUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsRequestPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rental asset request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsRequestRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalAssetRequestSerializers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsRequestRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsRequestRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RentalAssetRequestStage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsRequestStageCreate(data: RentalAssetRequestStage, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalAssetRequestStage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsRequestStageCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsRequestStageCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [stage] stage
         * @param {string} [request] request
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsRequestStageList(stage?: string, request?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalsRequestStageList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsRequestStageList(stage, request, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsRequestStageList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rental asset request stage.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsRequestStageRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalAssetRequestStage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsRequestStageRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsRequestStageRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rental asset request.
         * @param {RentalAssetRequestSerializers} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsRequestUpdate(id: number, data: RentalAssetRequestSerializers, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalAssetRequestSerializers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsRequestUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsRequestUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsTestRead(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsTestRead(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsTestRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rentals.
         * @param {Rentals} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rentalsUpdate(id: number, data: Rentals, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Rentals>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rentalsUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RentalsApi.rentalsUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RentalsApi - factory interface
 * @export
 */
export const RentalsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RentalsApiFp(configuration)
    return {
        /**
         * 
         * @param {RentalsApiRentalsAssignedExportListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsAssignedExportList(requestParameters: RentalsApiRentalsAssignedExportListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<RentalsAssignedExportList200Response> {
            return localVarFp.rentalsAssignedExportList(requestParameters.account, requestParameters.connect, requestParameters.serial, requestParameters.rent_year, requestParameters.rent_month, requestParameters.status, requestParameters.replacement_serial, requestParameters.pagination, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RentalsApiRentalsAssignedListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsAssignedList(requestParameters: RentalsApiRentalsAssignedListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<RentalsAssignedList200Response> {
            return localVarFp.rentalsAssignedList(requestParameters.account, requestParameters.connect, requestParameters.serial, requestParameters.rent_year, requestParameters.rent_month, requestParameters.status, requestParameters.replacement_serial, requestParameters.pagination, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RentalsApiRentalsAssignedRentalSerialReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsAssignedRentalSerialRead(requestParameters: RentalsApiRentalsAssignedRentalSerialReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<RentalSerialAssigned> {
            return localVarFp.rentalsAssignedRentalSerialRead(requestParameters.slug, requestParameters.serial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RentalsApiRentalsAssignedSerialReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsAssignedSerialRead(requestParameters: RentalsApiRentalsAssignedSerialReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<SerialAssigned> {
            return localVarFp.rentalsAssignedSerialRead(requestParameters.slug, requestParameters.serial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsCountRead(options?: RawAxiosRequestConfig): AxiosPromise<RentalAssetCount> {
            return localVarFp.rentalsCountRead(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RentalsApiRentalsDailyMonthStatsReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsDailyMonthStatsRead(requestParameters: RentalsApiRentalsDailyMonthStatsReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.rentalsDailyMonthStatsRead(requestParameters.account_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RentalsApiRentalsDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsDelete(requestParameters: RentalsApiRentalsDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.rentalsDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RentalsApiRentalsExportListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsExportList(requestParameters: RentalsApiRentalsExportListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<RentalsExportList200Response> {
            return localVarFp.rentalsExportList(requestParameters.search, requestParameters.account, requestParameters.connect, requestParameters.serial, requestParameters.rent_year, requestParameters.rent_month, requestParameters.status, requestParameters.rental_rate, requestParameters.logistics_cost, requestParameters.deposit, requestParameters.replacement_serial, requestParameters.logistics_cost__isnull, requestParameters.deposit__isnull, requestParameters.dispatch_date, requestParameters.product__item__id, requestParameters.pagination, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RentalsApiRentalsHistoryDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsHistoryDelete(requestParameters: RentalsApiRentalsHistoryDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.rentalsHistoryDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RentalsApiRentalsHistoryDiffDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsHistoryDiffDelete(requestParameters: RentalsApiRentalsHistoryDiffDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.rentalsHistoryDiffDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RentalsApiRentalsHistoryDiffReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsHistoryDiffRead(requestParameters: RentalsApiRentalsHistoryDiffReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<HistoryGroupedDiff> {
            return localVarFp.rentalsHistoryDiffRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RentalsApiRentalsHistoryReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsHistoryRead(requestParameters: RentalsApiRentalsHistoryReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<RentalHistory> {
            return localVarFp.rentalsHistoryRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RentalsApiRentalsListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsList(requestParameters: RentalsApiRentalsListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<RentalsList200Response> {
            return localVarFp.rentalsList(requestParameters.search, requestParameters.account, requestParameters.connect, requestParameters.serial, requestParameters.rent_year, requestParameters.rent_month, requestParameters.status, requestParameters.rental_rate, requestParameters.logistics_cost, requestParameters.deposit, requestParameters.replacement_serial, requestParameters.logistics_cost__isnull, requestParameters.deposit__isnull, requestParameters.dispatch_date, requestParameters.product__item__id, requestParameters.pagination, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsMonthSummaryRead(options?: RawAxiosRequestConfig): AxiosPromise<RentalMonthSummary> {
            return localVarFp.rentalsMonthSummaryRead(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RentalsApiRentalsMonthlyStatsReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsMonthlyStatsRead(requestParameters: RentalsApiRentalsMonthlyStatsReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.rentalsMonthlyStatsRead(requestParameters.account_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RentalsApiRentalsPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsPartialUpdate(requestParameters: RentalsApiRentalsPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Rentals> {
            return localVarFp.rentalsPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RentalsApiRentalsProductConfigMasterCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsProductConfigMasterCreate(requestParameters: RentalsApiRentalsProductConfigMasterCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<RentalProductConfigMaster> {
            return localVarFp.rentalsProductConfigMasterCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RentalsApiRentalsProductConfigMasterDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsProductConfigMasterDelete(requestParameters: RentalsApiRentalsProductConfigMasterDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.rentalsProductConfigMasterDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RentalsApiRentalsProductConfigMasterListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsProductConfigMasterList(requestParameters: RentalsApiRentalsProductConfigMasterListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<RentalsProductConfigMasterList200Response> {
            return localVarFp.rentalsProductConfigMasterList(requestParameters.search, requestParameters.id, requestParameters.product, requestParameters.account, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RentalsApiRentalsProductConfigMasterPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsProductConfigMasterPartialUpdate(requestParameters: RentalsApiRentalsProductConfigMasterPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<RentalProductConfigMaster> {
            return localVarFp.rentalsProductConfigMasterPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RentalsApiRentalsProductConfigMasterReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsProductConfigMasterRead(requestParameters: RentalsApiRentalsProductConfigMasterReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<RentalProductConfigMaster> {
            return localVarFp.rentalsProductConfigMasterRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RentalsApiRentalsProductConfigMasterUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsProductConfigMasterUpdate(requestParameters: RentalsApiRentalsProductConfigMasterUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<RentalProductConfigMaster> {
            return localVarFp.rentalsProductConfigMasterUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RentalsApiRentalsProductMasterCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsProductMasterCreate(requestParameters: RentalsApiRentalsProductMasterCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<RentalProductMaster> {
            return localVarFp.rentalsProductMasterCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RentalsApiRentalsProductMasterDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsProductMasterDelete(requestParameters: RentalsApiRentalsProductMasterDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.rentalsProductMasterDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RentalsApiRentalsProductMasterListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsProductMasterList(requestParameters: RentalsApiRentalsProductMasterListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<RentalsProductMasterList200Response> {
            return localVarFp.rentalsProductMasterList(requestParameters.search, requestParameters.id, requestParameters.account, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RentalsApiRentalsProductMasterPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsProductMasterPartialUpdate(requestParameters: RentalsApiRentalsProductMasterPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<RentalProductMaster> {
            return localVarFp.rentalsProductMasterPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RentalsApiRentalsProductMasterReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsProductMasterRead(requestParameters: RentalsApiRentalsProductMasterReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<RentalProductMaster> {
            return localVarFp.rentalsProductMasterRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RentalsApiRentalsProductMasterUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsProductMasterUpdate(requestParameters: RentalsApiRentalsProductMasterUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<RentalProductMaster> {
            return localVarFp.rentalsProductMasterUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RentalsApiRentalsPublicRequestReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsPublicRequestRead(requestParameters: RentalsApiRentalsPublicRequestReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<RentalAssetRequestPublic> {
            return localVarFp.rentalsPublicRequestRead(requestParameters.slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RentalsApiRentalsRateExportListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsRateExportList(requestParameters: RentalsApiRentalsRateExportListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<RentalsRateList200Response> {
            return localVarFp.rentalsRateExportList(requestParameters.rent_month, requestParameters.rent_year, requestParameters.connect_id, requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RentalsApiRentalsRateListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsRateList(requestParameters: RentalsApiRentalsRateListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<RentalsRateList200Response> {
            return localVarFp.rentalsRateList(requestParameters.rent_month, requestParameters.rent_year, requestParameters.connect_id, requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RentalsApiRentalsReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsRead(requestParameters: RentalsApiRentalsReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<Rentals> {
            return localVarFp.rentalsRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RentalsApiRentalsReportsActiveListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsReportsActiveList(requestParameters: RentalsApiRentalsReportsActiveListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<RentalsReportsActiveList200Response> {
            return localVarFp.rentalsReportsActiveList(requestParameters.account, requestParameters.rent_month, requestParameters.rent_year, requestParameters.order_by, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RentalsApiRentalsReportsTrailReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsReportsTrailRead(requestParameters: RentalsApiRentalsReportsTrailReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.rentalsReportsTrailRead(requestParameters.serial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RentalsApiRentalsRequestCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsRequestCreate(requestParameters: RentalsApiRentalsRequestCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<RentalAssetRequestSerializers> {
            return localVarFp.rentalsRequestCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RentalsApiRentalsRequestListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsRequestList(requestParameters: RentalsApiRentalsRequestListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<RentalsRequestList200Response> {
            return localVarFp.rentalsRequestList(requestParameters.slug, requestParameters.request_type, requestParameters.connect, requestParameters.serial, requestParameters.start_date, requestParameters.end_date, requestParameters.stages, requestParameters.exclude_order_stage, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RentalsApiRentalsRequestPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsRequestPartialUpdate(requestParameters: RentalsApiRentalsRequestPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<RentalAssetRequestSerializers> {
            return localVarFp.rentalsRequestPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RentalsApiRentalsRequestReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsRequestRead(requestParameters: RentalsApiRentalsRequestReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<RentalAssetRequestSerializers> {
            return localVarFp.rentalsRequestRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RentalsApiRentalsRequestStageCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsRequestStageCreate(requestParameters: RentalsApiRentalsRequestStageCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<RentalAssetRequestStage> {
            return localVarFp.rentalsRequestStageCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RentalsApiRentalsRequestStageListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsRequestStageList(requestParameters: RentalsApiRentalsRequestStageListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<RentalsRequestStageList200Response> {
            return localVarFp.rentalsRequestStageList(requestParameters.stage, requestParameters.request, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RentalsApiRentalsRequestStageReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsRequestStageRead(requestParameters: RentalsApiRentalsRequestStageReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<RentalAssetRequestStage> {
            return localVarFp.rentalsRequestStageRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RentalsApiRentalsRequestUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsRequestUpdate(requestParameters: RentalsApiRentalsRequestUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<RentalAssetRequestSerializers> {
            return localVarFp.rentalsRequestUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsTestRead(options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.rentalsTestRead(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {RentalsApiRentalsUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rentalsUpdate(requestParameters: RentalsApiRentalsUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Rentals> {
            return localVarFp.rentalsUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for rentalsAssignedExportList operation in RentalsApi.
 * @export
 * @interface RentalsApiRentalsAssignedExportListRequest
 */
export interface RentalsApiRentalsAssignedExportListRequest {
    /**
     * account
     * @type {string}
     * @memberof RentalsApiRentalsAssignedExportList
     */
    readonly account?: string

    /**
     * connect
     * @type {string}
     * @memberof RentalsApiRentalsAssignedExportList
     */
    readonly connect?: string

    /**
     * serial
     * @type {string}
     * @memberof RentalsApiRentalsAssignedExportList
     */
    readonly serial?: string

    /**
     * rent_year
     * @type {string}
     * @memberof RentalsApiRentalsAssignedExportList
     */
    readonly rent_year?: string

    /**
     * rent_month
     * @type {string}
     * @memberof RentalsApiRentalsAssignedExportList
     */
    readonly rent_month?: string

    /**
     * status
     * @type {string}
     * @memberof RentalsApiRentalsAssignedExportList
     */
    readonly status?: string

    /**
     * replacement_serial
     * @type {string}
     * @memberof RentalsApiRentalsAssignedExportList
     */
    readonly replacement_serial?: string

    /**
     * Pagination
     * @type {string}
     * @memberof RentalsApiRentalsAssignedExportList
     */
    readonly pagination?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof RentalsApiRentalsAssignedExportList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof RentalsApiRentalsAssignedExportList
     */
    readonly offset?: number
}

/**
 * Request parameters for rentalsAssignedList operation in RentalsApi.
 * @export
 * @interface RentalsApiRentalsAssignedListRequest
 */
export interface RentalsApiRentalsAssignedListRequest {
    /**
     * account
     * @type {string}
     * @memberof RentalsApiRentalsAssignedList
     */
    readonly account?: string

    /**
     * connect
     * @type {string}
     * @memberof RentalsApiRentalsAssignedList
     */
    readonly connect?: string

    /**
     * serial
     * @type {string}
     * @memberof RentalsApiRentalsAssignedList
     */
    readonly serial?: string

    /**
     * rent_year
     * @type {string}
     * @memberof RentalsApiRentalsAssignedList
     */
    readonly rent_year?: string

    /**
     * rent_month
     * @type {string}
     * @memberof RentalsApiRentalsAssignedList
     */
    readonly rent_month?: string

    /**
     * status
     * @type {string}
     * @memberof RentalsApiRentalsAssignedList
     */
    readonly status?: string

    /**
     * replacement_serial
     * @type {string}
     * @memberof RentalsApiRentalsAssignedList
     */
    readonly replacement_serial?: string

    /**
     * Pagination
     * @type {string}
     * @memberof RentalsApiRentalsAssignedList
     */
    readonly pagination?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof RentalsApiRentalsAssignedList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof RentalsApiRentalsAssignedList
     */
    readonly offset?: number
}

/**
 * Request parameters for rentalsAssignedRentalSerialRead operation in RentalsApi.
 * @export
 * @interface RentalsApiRentalsAssignedRentalSerialReadRequest
 */
export interface RentalsApiRentalsAssignedRentalSerialReadRequest {
    /**
     * 
     * @type {string}
     * @memberof RentalsApiRentalsAssignedRentalSerialRead
     */
    readonly slug: string

    /**
     * 
     * @type {string}
     * @memberof RentalsApiRentalsAssignedRentalSerialRead
     */
    readonly serial: string
}

/**
 * Request parameters for rentalsAssignedSerialRead operation in RentalsApi.
 * @export
 * @interface RentalsApiRentalsAssignedSerialReadRequest
 */
export interface RentalsApiRentalsAssignedSerialReadRequest {
    /**
     * 
     * @type {string}
     * @memberof RentalsApiRentalsAssignedSerialRead
     */
    readonly slug: string

    /**
     * 
     * @type {string}
     * @memberof RentalsApiRentalsAssignedSerialRead
     */
    readonly serial: string
}

/**
 * Request parameters for rentalsDailyMonthStatsRead operation in RentalsApi.
 * @export
 * @interface RentalsApiRentalsDailyMonthStatsReadRequest
 */
export interface RentalsApiRentalsDailyMonthStatsReadRequest {
    /**
     * 
     * @type {string}
     * @memberof RentalsApiRentalsDailyMonthStatsRead
     */
    readonly account_id: string
}

/**
 * Request parameters for rentalsDelete operation in RentalsApi.
 * @export
 * @interface RentalsApiRentalsDeleteRequest
 */
export interface RentalsApiRentalsDeleteRequest {
    /**
     * A unique integer value identifying this rentals.
     * @type {number}
     * @memberof RentalsApiRentalsDelete
     */
    readonly id: number
}

/**
 * Request parameters for rentalsExportList operation in RentalsApi.
 * @export
 * @interface RentalsApiRentalsExportListRequest
 */
export interface RentalsApiRentalsExportListRequest {
    /**
     * A search term.
     * @type {string}
     * @memberof RentalsApiRentalsExportList
     */
    readonly search?: string

    /**
     * account
     * @type {string}
     * @memberof RentalsApiRentalsExportList
     */
    readonly account?: string

    /**
     * connect
     * @type {string}
     * @memberof RentalsApiRentalsExportList
     */
    readonly connect?: string

    /**
     * serial
     * @type {string}
     * @memberof RentalsApiRentalsExportList
     */
    readonly serial?: string

    /**
     * rent_year
     * @type {string}
     * @memberof RentalsApiRentalsExportList
     */
    readonly rent_year?: string

    /**
     * rent_month
     * @type {string}
     * @memberof RentalsApiRentalsExportList
     */
    readonly rent_month?: string

    /**
     * status
     * @type {string}
     * @memberof RentalsApiRentalsExportList
     */
    readonly status?: string

    /**
     * rental_rate
     * @type {string}
     * @memberof RentalsApiRentalsExportList
     */
    readonly rental_rate?: string

    /**
     * logistics_cost
     * @type {string}
     * @memberof RentalsApiRentalsExportList
     */
    readonly logistics_cost?: string

    /**
     * deposit
     * @type {string}
     * @memberof RentalsApiRentalsExportList
     */
    readonly deposit?: string

    /**
     * replacement_serial
     * @type {string}
     * @memberof RentalsApiRentalsExportList
     */
    readonly replacement_serial?: string

    /**
     * logistics_cost__isnull
     * @type {string}
     * @memberof RentalsApiRentalsExportList
     */
    readonly logistics_cost__isnull?: string

    /**
     * deposit__isnull
     * @type {string}
     * @memberof RentalsApiRentalsExportList
     */
    readonly deposit__isnull?: string

    /**
     * dispatch_date
     * @type {string}
     * @memberof RentalsApiRentalsExportList
     */
    readonly dispatch_date?: string

    /**
     * product__item__id
     * @type {string}
     * @memberof RentalsApiRentalsExportList
     */
    readonly product__item__id?: string

    /**
     * Pagination
     * @type {string}
     * @memberof RentalsApiRentalsExportList
     */
    readonly pagination?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof RentalsApiRentalsExportList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof RentalsApiRentalsExportList
     */
    readonly offset?: number
}

/**
 * Request parameters for rentalsHistoryDelete operation in RentalsApi.
 * @export
 * @interface RentalsApiRentalsHistoryDeleteRequest
 */
export interface RentalsApiRentalsHistoryDeleteRequest {
    /**
     * A unique integer value identifying this rentals.
     * @type {number}
     * @memberof RentalsApiRentalsHistoryDelete
     */
    readonly id: number
}

/**
 * Request parameters for rentalsHistoryDiffDelete operation in RentalsApi.
 * @export
 * @interface RentalsApiRentalsHistoryDiffDeleteRequest
 */
export interface RentalsApiRentalsHistoryDiffDeleteRequest {
    /**
     * A unique integer value identifying this rentals.
     * @type {number}
     * @memberof RentalsApiRentalsHistoryDiffDelete
     */
    readonly id: number
}

/**
 * Request parameters for rentalsHistoryDiffRead operation in RentalsApi.
 * @export
 * @interface RentalsApiRentalsHistoryDiffReadRequest
 */
export interface RentalsApiRentalsHistoryDiffReadRequest {
    /**
     * A unique integer value identifying this rentals.
     * @type {number}
     * @memberof RentalsApiRentalsHistoryDiffRead
     */
    readonly id: number
}

/**
 * Request parameters for rentalsHistoryRead operation in RentalsApi.
 * @export
 * @interface RentalsApiRentalsHistoryReadRequest
 */
export interface RentalsApiRentalsHistoryReadRequest {
    /**
     * A unique integer value identifying this rentals.
     * @type {number}
     * @memberof RentalsApiRentalsHistoryRead
     */
    readonly id: number
}

/**
 * Request parameters for rentalsList operation in RentalsApi.
 * @export
 * @interface RentalsApiRentalsListRequest
 */
export interface RentalsApiRentalsListRequest {
    /**
     * A search term.
     * @type {string}
     * @memberof RentalsApiRentalsList
     */
    readonly search?: string

    /**
     * account
     * @type {string}
     * @memberof RentalsApiRentalsList
     */
    readonly account?: string

    /**
     * connect
     * @type {string}
     * @memberof RentalsApiRentalsList
     */
    readonly connect?: string

    /**
     * serial
     * @type {string}
     * @memberof RentalsApiRentalsList
     */
    readonly serial?: string

    /**
     * rent_year
     * @type {string}
     * @memberof RentalsApiRentalsList
     */
    readonly rent_year?: string

    /**
     * rent_month
     * @type {string}
     * @memberof RentalsApiRentalsList
     */
    readonly rent_month?: string

    /**
     * status
     * @type {string}
     * @memberof RentalsApiRentalsList
     */
    readonly status?: string

    /**
     * rental_rate
     * @type {string}
     * @memberof RentalsApiRentalsList
     */
    readonly rental_rate?: string

    /**
     * logistics_cost
     * @type {string}
     * @memberof RentalsApiRentalsList
     */
    readonly logistics_cost?: string

    /**
     * deposit
     * @type {string}
     * @memberof RentalsApiRentalsList
     */
    readonly deposit?: string

    /**
     * replacement_serial
     * @type {string}
     * @memberof RentalsApiRentalsList
     */
    readonly replacement_serial?: string

    /**
     * logistics_cost__isnull
     * @type {string}
     * @memberof RentalsApiRentalsList
     */
    readonly logistics_cost__isnull?: string

    /**
     * deposit__isnull
     * @type {string}
     * @memberof RentalsApiRentalsList
     */
    readonly deposit__isnull?: string

    /**
     * dispatch_date
     * @type {string}
     * @memberof RentalsApiRentalsList
     */
    readonly dispatch_date?: string

    /**
     * product__item__id
     * @type {string}
     * @memberof RentalsApiRentalsList
     */
    readonly product__item__id?: string

    /**
     * Pagination
     * @type {string}
     * @memberof RentalsApiRentalsList
     */
    readonly pagination?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof RentalsApiRentalsList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof RentalsApiRentalsList
     */
    readonly offset?: number
}

/**
 * Request parameters for rentalsMonthlyStatsRead operation in RentalsApi.
 * @export
 * @interface RentalsApiRentalsMonthlyStatsReadRequest
 */
export interface RentalsApiRentalsMonthlyStatsReadRequest {
    /**
     * 
     * @type {string}
     * @memberof RentalsApiRentalsMonthlyStatsRead
     */
    readonly account_id: string
}

/**
 * Request parameters for rentalsPartialUpdate operation in RentalsApi.
 * @export
 * @interface RentalsApiRentalsPartialUpdateRequest
 */
export interface RentalsApiRentalsPartialUpdateRequest {
    /**
     * A unique integer value identifying this rentals.
     * @type {number}
     * @memberof RentalsApiRentalsPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Rentals}
     * @memberof RentalsApiRentalsPartialUpdate
     */
    readonly data: Rentals
}

/**
 * Request parameters for rentalsProductConfigMasterCreate operation in RentalsApi.
 * @export
 * @interface RentalsApiRentalsProductConfigMasterCreateRequest
 */
export interface RentalsApiRentalsProductConfigMasterCreateRequest {
    /**
     * 
     * @type {RentalProductConfigMaster}
     * @memberof RentalsApiRentalsProductConfigMasterCreate
     */
    readonly data: RentalProductConfigMaster
}

/**
 * Request parameters for rentalsProductConfigMasterDelete operation in RentalsApi.
 * @export
 * @interface RentalsApiRentalsProductConfigMasterDeleteRequest
 */
export interface RentalsApiRentalsProductConfigMasterDeleteRequest {
    /**
     * A unique integer value identifying this rental product config master.
     * @type {number}
     * @memberof RentalsApiRentalsProductConfigMasterDelete
     */
    readonly id: number
}

/**
 * Request parameters for rentalsProductConfigMasterList operation in RentalsApi.
 * @export
 * @interface RentalsApiRentalsProductConfigMasterListRequest
 */
export interface RentalsApiRentalsProductConfigMasterListRequest {
    /**
     * A search term.
     * @type {string}
     * @memberof RentalsApiRentalsProductConfigMasterList
     */
    readonly search?: string

    /**
     * id
     * @type {string}
     * @memberof RentalsApiRentalsProductConfigMasterList
     */
    readonly id?: string

    /**
     * product
     * @type {string}
     * @memberof RentalsApiRentalsProductConfigMasterList
     */
    readonly product?: string

    /**
     * account
     * @type {string}
     * @memberof RentalsApiRentalsProductConfigMasterList
     */
    readonly account?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof RentalsApiRentalsProductConfigMasterList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof RentalsApiRentalsProductConfigMasterList
     */
    readonly offset?: number
}

/**
 * Request parameters for rentalsProductConfigMasterPartialUpdate operation in RentalsApi.
 * @export
 * @interface RentalsApiRentalsProductConfigMasterPartialUpdateRequest
 */
export interface RentalsApiRentalsProductConfigMasterPartialUpdateRequest {
    /**
     * A unique integer value identifying this rental product config master.
     * @type {number}
     * @memberof RentalsApiRentalsProductConfigMasterPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {RentalProductConfigMaster}
     * @memberof RentalsApiRentalsProductConfigMasterPartialUpdate
     */
    readonly data: RentalProductConfigMaster
}

/**
 * Request parameters for rentalsProductConfigMasterRead operation in RentalsApi.
 * @export
 * @interface RentalsApiRentalsProductConfigMasterReadRequest
 */
export interface RentalsApiRentalsProductConfigMasterReadRequest {
    /**
     * A unique integer value identifying this rental product config master.
     * @type {number}
     * @memberof RentalsApiRentalsProductConfigMasterRead
     */
    readonly id: number
}

/**
 * Request parameters for rentalsProductConfigMasterUpdate operation in RentalsApi.
 * @export
 * @interface RentalsApiRentalsProductConfigMasterUpdateRequest
 */
export interface RentalsApiRentalsProductConfigMasterUpdateRequest {
    /**
     * A unique integer value identifying this rental product config master.
     * @type {number}
     * @memberof RentalsApiRentalsProductConfigMasterUpdate
     */
    readonly id: number

    /**
     * 
     * @type {RentalProductConfigMaster}
     * @memberof RentalsApiRentalsProductConfigMasterUpdate
     */
    readonly data: RentalProductConfigMaster
}

/**
 * Request parameters for rentalsProductMasterCreate operation in RentalsApi.
 * @export
 * @interface RentalsApiRentalsProductMasterCreateRequest
 */
export interface RentalsApiRentalsProductMasterCreateRequest {
    /**
     * 
     * @type {RentalProductMaster}
     * @memberof RentalsApiRentalsProductMasterCreate
     */
    readonly data: RentalProductMaster
}

/**
 * Request parameters for rentalsProductMasterDelete operation in RentalsApi.
 * @export
 * @interface RentalsApiRentalsProductMasterDeleteRequest
 */
export interface RentalsApiRentalsProductMasterDeleteRequest {
    /**
     * A unique integer value identifying this rental product master.
     * @type {number}
     * @memberof RentalsApiRentalsProductMasterDelete
     */
    readonly id: number
}

/**
 * Request parameters for rentalsProductMasterList operation in RentalsApi.
 * @export
 * @interface RentalsApiRentalsProductMasterListRequest
 */
export interface RentalsApiRentalsProductMasterListRequest {
    /**
     * A search term.
     * @type {string}
     * @memberof RentalsApiRentalsProductMasterList
     */
    readonly search?: string

    /**
     * id
     * @type {string}
     * @memberof RentalsApiRentalsProductMasterList
     */
    readonly id?: string

    /**
     * account
     * @type {string}
     * @memberof RentalsApiRentalsProductMasterList
     */
    readonly account?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof RentalsApiRentalsProductMasterList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof RentalsApiRentalsProductMasterList
     */
    readonly offset?: number
}

/**
 * Request parameters for rentalsProductMasterPartialUpdate operation in RentalsApi.
 * @export
 * @interface RentalsApiRentalsProductMasterPartialUpdateRequest
 */
export interface RentalsApiRentalsProductMasterPartialUpdateRequest {
    /**
     * A unique integer value identifying this rental product master.
     * @type {number}
     * @memberof RentalsApiRentalsProductMasterPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {RentalProductMaster}
     * @memberof RentalsApiRentalsProductMasterPartialUpdate
     */
    readonly data: RentalProductMaster
}

/**
 * Request parameters for rentalsProductMasterRead operation in RentalsApi.
 * @export
 * @interface RentalsApiRentalsProductMasterReadRequest
 */
export interface RentalsApiRentalsProductMasterReadRequest {
    /**
     * A unique integer value identifying this rental product master.
     * @type {number}
     * @memberof RentalsApiRentalsProductMasterRead
     */
    readonly id: number
}

/**
 * Request parameters for rentalsProductMasterUpdate operation in RentalsApi.
 * @export
 * @interface RentalsApiRentalsProductMasterUpdateRequest
 */
export interface RentalsApiRentalsProductMasterUpdateRequest {
    /**
     * A unique integer value identifying this rental product master.
     * @type {number}
     * @memberof RentalsApiRentalsProductMasterUpdate
     */
    readonly id: number

    /**
     * 
     * @type {RentalProductMaster}
     * @memberof RentalsApiRentalsProductMasterUpdate
     */
    readonly data: RentalProductMaster
}

/**
 * Request parameters for rentalsPublicRequestRead operation in RentalsApi.
 * @export
 * @interface RentalsApiRentalsPublicRequestReadRequest
 */
export interface RentalsApiRentalsPublicRequestReadRequest {
    /**
     * 
     * @type {number}
     * @memberof RentalsApiRentalsPublicRequestRead
     */
    readonly slug: number
}

/**
 * Request parameters for rentalsRateExportList operation in RentalsApi.
 * @export
 * @interface RentalsApiRentalsRateExportListRequest
 */
export interface RentalsApiRentalsRateExportListRequest {
    /**
     * rent_month
     * @type {string}
     * @memberof RentalsApiRentalsRateExportList
     */
    readonly rent_month?: string

    /**
     * rent_year
     * @type {string}
     * @memberof RentalsApiRentalsRateExportList
     */
    readonly rent_year?: string

    /**
     * connect_id
     * @type {string}
     * @memberof RentalsApiRentalsRateExportList
     */
    readonly connect_id?: string

    /**
     * A search term.
     * @type {string}
     * @memberof RentalsApiRentalsRateExportList
     */
    readonly search?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof RentalsApiRentalsRateExportList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof RentalsApiRentalsRateExportList
     */
    readonly offset?: number
}

/**
 * Request parameters for rentalsRateList operation in RentalsApi.
 * @export
 * @interface RentalsApiRentalsRateListRequest
 */
export interface RentalsApiRentalsRateListRequest {
    /**
     * rent_month
     * @type {string}
     * @memberof RentalsApiRentalsRateList
     */
    readonly rent_month?: string

    /**
     * rent_year
     * @type {string}
     * @memberof RentalsApiRentalsRateList
     */
    readonly rent_year?: string

    /**
     * connect_id
     * @type {string}
     * @memberof RentalsApiRentalsRateList
     */
    readonly connect_id?: string

    /**
     * A search term.
     * @type {string}
     * @memberof RentalsApiRentalsRateList
     */
    readonly search?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof RentalsApiRentalsRateList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof RentalsApiRentalsRateList
     */
    readonly offset?: number
}

/**
 * Request parameters for rentalsRead operation in RentalsApi.
 * @export
 * @interface RentalsApiRentalsReadRequest
 */
export interface RentalsApiRentalsReadRequest {
    /**
     * A unique integer value identifying this rentals.
     * @type {number}
     * @memberof RentalsApiRentalsRead
     */
    readonly id: number
}

/**
 * Request parameters for rentalsReportsActiveList operation in RentalsApi.
 * @export
 * @interface RentalsApiRentalsReportsActiveListRequest
 */
export interface RentalsApiRentalsReportsActiveListRequest {
    /**
     * account
     * @type {string}
     * @memberof RentalsApiRentalsReportsActiveList
     */
    readonly account?: string

    /**
     * rent_month
     * @type {string}
     * @memberof RentalsApiRentalsReportsActiveList
     */
    readonly rent_month?: string

    /**
     * rent_year
     * @type {string}
     * @memberof RentalsApiRentalsReportsActiveList
     */
    readonly rent_year?: string

    /**
     * Ordering
     * @type {'total_effective_rate' | '-total_effective_rate'}
     * @memberof RentalsApiRentalsReportsActiveList
     */
    readonly order_by?: RentalsReportsActiveListOrderBy

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof RentalsApiRentalsReportsActiveList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof RentalsApiRentalsReportsActiveList
     */
    readonly offset?: number
}

/**
 * Request parameters for rentalsReportsTrailRead operation in RentalsApi.
 * @export
 * @interface RentalsApiRentalsReportsTrailReadRequest
 */
export interface RentalsApiRentalsReportsTrailReadRequest {
    /**
     * 
     * @type {string}
     * @memberof RentalsApiRentalsReportsTrailRead
     */
    readonly serial: string
}

/**
 * Request parameters for rentalsRequestCreate operation in RentalsApi.
 * @export
 * @interface RentalsApiRentalsRequestCreateRequest
 */
export interface RentalsApiRentalsRequestCreateRequest {
    /**
     * 
     * @type {RentalAssetRequestSerializers}
     * @memberof RentalsApiRentalsRequestCreate
     */
    readonly data: RentalAssetRequestSerializers
}

/**
 * Request parameters for rentalsRequestList operation in RentalsApi.
 * @export
 * @interface RentalsApiRentalsRequestListRequest
 */
export interface RentalsApiRentalsRequestListRequest {
    /**
     * slug
     * @type {string}
     * @memberof RentalsApiRentalsRequestList
     */
    readonly slug?: string

    /**
     * request_type
     * @type {'NEW ORDER' | 'REPLACEMENT' | 'RETURN'}
     * @memberof RentalsApiRentalsRequestList
     */
    readonly request_type?: RentalsRequestListRequestType

    /**
     * connect
     * @type {string}
     * @memberof RentalsApiRentalsRequestList
     */
    readonly connect?: string

    /**
     * serial
     * @type {string}
     * @memberof RentalsApiRentalsRequestList
     */
    readonly serial?: string

    /**
     * start_date
     * @type {string}
     * @memberof RentalsApiRentalsRequestList
     */
    readonly start_date?: string

    /**
     * end_date
     * @type {string}
     * @memberof RentalsApiRentalsRequestList
     */
    readonly end_date?: string

    /**
     * stages
     * @type {string}
     * @memberof RentalsApiRentalsRequestList
     */
    readonly stages?: string

    /**
     * exclude_order_stage
     * @type {string}
     * @memberof RentalsApiRentalsRequestList
     */
    readonly exclude_order_stage?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof RentalsApiRentalsRequestList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof RentalsApiRentalsRequestList
     */
    readonly offset?: number
}

/**
 * Request parameters for rentalsRequestPartialUpdate operation in RentalsApi.
 * @export
 * @interface RentalsApiRentalsRequestPartialUpdateRequest
 */
export interface RentalsApiRentalsRequestPartialUpdateRequest {
    /**
     * A unique integer value identifying this rental asset request.
     * @type {number}
     * @memberof RentalsApiRentalsRequestPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {RentalAssetRequestSerializers}
     * @memberof RentalsApiRentalsRequestPartialUpdate
     */
    readonly data: RentalAssetRequestSerializers
}

/**
 * Request parameters for rentalsRequestRead operation in RentalsApi.
 * @export
 * @interface RentalsApiRentalsRequestReadRequest
 */
export interface RentalsApiRentalsRequestReadRequest {
    /**
     * A unique integer value identifying this rental asset request.
     * @type {number}
     * @memberof RentalsApiRentalsRequestRead
     */
    readonly id: number
}

/**
 * Request parameters for rentalsRequestStageCreate operation in RentalsApi.
 * @export
 * @interface RentalsApiRentalsRequestStageCreateRequest
 */
export interface RentalsApiRentalsRequestStageCreateRequest {
    /**
     * 
     * @type {RentalAssetRequestStage}
     * @memberof RentalsApiRentalsRequestStageCreate
     */
    readonly data: RentalAssetRequestStage
}

/**
 * Request parameters for rentalsRequestStageList operation in RentalsApi.
 * @export
 * @interface RentalsApiRentalsRequestStageListRequest
 */
export interface RentalsApiRentalsRequestStageListRequest {
    /**
     * stage
     * @type {string}
     * @memberof RentalsApiRentalsRequestStageList
     */
    readonly stage?: string

    /**
     * request
     * @type {string}
     * @memberof RentalsApiRentalsRequestStageList
     */
    readonly request?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof RentalsApiRentalsRequestStageList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof RentalsApiRentalsRequestStageList
     */
    readonly offset?: number
}

/**
 * Request parameters for rentalsRequestStageRead operation in RentalsApi.
 * @export
 * @interface RentalsApiRentalsRequestStageReadRequest
 */
export interface RentalsApiRentalsRequestStageReadRequest {
    /**
     * A unique integer value identifying this rental asset request stage.
     * @type {number}
     * @memberof RentalsApiRentalsRequestStageRead
     */
    readonly id: number
}

/**
 * Request parameters for rentalsRequestUpdate operation in RentalsApi.
 * @export
 * @interface RentalsApiRentalsRequestUpdateRequest
 */
export interface RentalsApiRentalsRequestUpdateRequest {
    /**
     * A unique integer value identifying this rental asset request.
     * @type {number}
     * @memberof RentalsApiRentalsRequestUpdate
     */
    readonly id: number

    /**
     * 
     * @type {RentalAssetRequestSerializers}
     * @memberof RentalsApiRentalsRequestUpdate
     */
    readonly data: RentalAssetRequestSerializers
}

/**
 * Request parameters for rentalsUpdate operation in RentalsApi.
 * @export
 * @interface RentalsApiRentalsUpdateRequest
 */
export interface RentalsApiRentalsUpdateRequest {
    /**
     * A unique integer value identifying this rentals.
     * @type {number}
     * @memberof RentalsApiRentalsUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Rentals}
     * @memberof RentalsApiRentalsUpdate
     */
    readonly data: Rentals
}

/**
 * RentalsApi - object-oriented interface
 * @export
 * @class RentalsApi
 * @extends {BaseAPI}
 */
export class RentalsApi extends BaseAPI {
    /**
     * 
     * @param {RentalsApiRentalsAssignedExportListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsAssignedExportList(requestParameters: RentalsApiRentalsAssignedExportListRequest = {}, options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsAssignedExportList(requestParameters.account, requestParameters.connect, requestParameters.serial, requestParameters.rent_year, requestParameters.rent_month, requestParameters.status, requestParameters.replacement_serial, requestParameters.pagination, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RentalsApiRentalsAssignedListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsAssignedList(requestParameters: RentalsApiRentalsAssignedListRequest = {}, options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsAssignedList(requestParameters.account, requestParameters.connect, requestParameters.serial, requestParameters.rent_year, requestParameters.rent_month, requestParameters.status, requestParameters.replacement_serial, requestParameters.pagination, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RentalsApiRentalsAssignedRentalSerialReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsAssignedRentalSerialRead(requestParameters: RentalsApiRentalsAssignedRentalSerialReadRequest, options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsAssignedRentalSerialRead(requestParameters.slug, requestParameters.serial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RentalsApiRentalsAssignedSerialReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsAssignedSerialRead(requestParameters: RentalsApiRentalsAssignedSerialReadRequest, options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsAssignedSerialRead(requestParameters.slug, requestParameters.serial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsCountRead(options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsCountRead(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RentalsApiRentalsDailyMonthStatsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsDailyMonthStatsRead(requestParameters: RentalsApiRentalsDailyMonthStatsReadRequest, options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsDailyMonthStatsRead(requestParameters.account_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RentalsApiRentalsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsDelete(requestParameters: RentalsApiRentalsDeleteRequest, options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RentalsApiRentalsExportListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsExportList(requestParameters: RentalsApiRentalsExportListRequest = {}, options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsExportList(requestParameters.search, requestParameters.account, requestParameters.connect, requestParameters.serial, requestParameters.rent_year, requestParameters.rent_month, requestParameters.status, requestParameters.rental_rate, requestParameters.logistics_cost, requestParameters.deposit, requestParameters.replacement_serial, requestParameters.logistics_cost__isnull, requestParameters.deposit__isnull, requestParameters.dispatch_date, requestParameters.product__item__id, requestParameters.pagination, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RentalsApiRentalsHistoryDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsHistoryDelete(requestParameters: RentalsApiRentalsHistoryDeleteRequest, options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsHistoryDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RentalsApiRentalsHistoryDiffDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsHistoryDiffDelete(requestParameters: RentalsApiRentalsHistoryDiffDeleteRequest, options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsHistoryDiffDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RentalsApiRentalsHistoryDiffReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsHistoryDiffRead(requestParameters: RentalsApiRentalsHistoryDiffReadRequest, options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsHistoryDiffRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RentalsApiRentalsHistoryReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsHistoryRead(requestParameters: RentalsApiRentalsHistoryReadRequest, options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsHistoryRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RentalsApiRentalsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsList(requestParameters: RentalsApiRentalsListRequest = {}, options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsList(requestParameters.search, requestParameters.account, requestParameters.connect, requestParameters.serial, requestParameters.rent_year, requestParameters.rent_month, requestParameters.status, requestParameters.rental_rate, requestParameters.logistics_cost, requestParameters.deposit, requestParameters.replacement_serial, requestParameters.logistics_cost__isnull, requestParameters.deposit__isnull, requestParameters.dispatch_date, requestParameters.product__item__id, requestParameters.pagination, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsMonthSummaryRead(options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsMonthSummaryRead(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RentalsApiRentalsMonthlyStatsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsMonthlyStatsRead(requestParameters: RentalsApiRentalsMonthlyStatsReadRequest, options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsMonthlyStatsRead(requestParameters.account_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RentalsApiRentalsPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsPartialUpdate(requestParameters: RentalsApiRentalsPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RentalsApiRentalsProductConfigMasterCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsProductConfigMasterCreate(requestParameters: RentalsApiRentalsProductConfigMasterCreateRequest, options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsProductConfigMasterCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RentalsApiRentalsProductConfigMasterDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsProductConfigMasterDelete(requestParameters: RentalsApiRentalsProductConfigMasterDeleteRequest, options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsProductConfigMasterDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RentalsApiRentalsProductConfigMasterListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsProductConfigMasterList(requestParameters: RentalsApiRentalsProductConfigMasterListRequest = {}, options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsProductConfigMasterList(requestParameters.search, requestParameters.id, requestParameters.product, requestParameters.account, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RentalsApiRentalsProductConfigMasterPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsProductConfigMasterPartialUpdate(requestParameters: RentalsApiRentalsProductConfigMasterPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsProductConfigMasterPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RentalsApiRentalsProductConfigMasterReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsProductConfigMasterRead(requestParameters: RentalsApiRentalsProductConfigMasterReadRequest, options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsProductConfigMasterRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RentalsApiRentalsProductConfigMasterUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsProductConfigMasterUpdate(requestParameters: RentalsApiRentalsProductConfigMasterUpdateRequest, options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsProductConfigMasterUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RentalsApiRentalsProductMasterCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsProductMasterCreate(requestParameters: RentalsApiRentalsProductMasterCreateRequest, options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsProductMasterCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RentalsApiRentalsProductMasterDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsProductMasterDelete(requestParameters: RentalsApiRentalsProductMasterDeleteRequest, options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsProductMasterDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RentalsApiRentalsProductMasterListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsProductMasterList(requestParameters: RentalsApiRentalsProductMasterListRequest = {}, options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsProductMasterList(requestParameters.search, requestParameters.id, requestParameters.account, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RentalsApiRentalsProductMasterPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsProductMasterPartialUpdate(requestParameters: RentalsApiRentalsProductMasterPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsProductMasterPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RentalsApiRentalsProductMasterReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsProductMasterRead(requestParameters: RentalsApiRentalsProductMasterReadRequest, options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsProductMasterRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RentalsApiRentalsProductMasterUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsProductMasterUpdate(requestParameters: RentalsApiRentalsProductMasterUpdateRequest, options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsProductMasterUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RentalsApiRentalsPublicRequestReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsPublicRequestRead(requestParameters: RentalsApiRentalsPublicRequestReadRequest, options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsPublicRequestRead(requestParameters.slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RentalsApiRentalsRateExportListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsRateExportList(requestParameters: RentalsApiRentalsRateExportListRequest = {}, options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsRateExportList(requestParameters.rent_month, requestParameters.rent_year, requestParameters.connect_id, requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RentalsApiRentalsRateListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsRateList(requestParameters: RentalsApiRentalsRateListRequest = {}, options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsRateList(requestParameters.rent_month, requestParameters.rent_year, requestParameters.connect_id, requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RentalsApiRentalsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsRead(requestParameters: RentalsApiRentalsReadRequest, options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RentalsApiRentalsReportsActiveListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsReportsActiveList(requestParameters: RentalsApiRentalsReportsActiveListRequest = {}, options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsReportsActiveList(requestParameters.account, requestParameters.rent_month, requestParameters.rent_year, requestParameters.order_by, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RentalsApiRentalsReportsTrailReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsReportsTrailRead(requestParameters: RentalsApiRentalsReportsTrailReadRequest, options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsReportsTrailRead(requestParameters.serial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RentalsApiRentalsRequestCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsRequestCreate(requestParameters: RentalsApiRentalsRequestCreateRequest, options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsRequestCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RentalsApiRentalsRequestListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsRequestList(requestParameters: RentalsApiRentalsRequestListRequest = {}, options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsRequestList(requestParameters.slug, requestParameters.request_type, requestParameters.connect, requestParameters.serial, requestParameters.start_date, requestParameters.end_date, requestParameters.stages, requestParameters.exclude_order_stage, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RentalsApiRentalsRequestPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsRequestPartialUpdate(requestParameters: RentalsApiRentalsRequestPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsRequestPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RentalsApiRentalsRequestReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsRequestRead(requestParameters: RentalsApiRentalsRequestReadRequest, options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsRequestRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RentalsApiRentalsRequestStageCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsRequestStageCreate(requestParameters: RentalsApiRentalsRequestStageCreateRequest, options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsRequestStageCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RentalsApiRentalsRequestStageListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsRequestStageList(requestParameters: RentalsApiRentalsRequestStageListRequest = {}, options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsRequestStageList(requestParameters.stage, requestParameters.request, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RentalsApiRentalsRequestStageReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsRequestStageRead(requestParameters: RentalsApiRentalsRequestStageReadRequest, options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsRequestStageRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RentalsApiRentalsRequestUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsRequestUpdate(requestParameters: RentalsApiRentalsRequestUpdateRequest, options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsRequestUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsTestRead(options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsTestRead(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RentalsApiRentalsUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RentalsApi
     */
    public rentalsUpdate(requestParameters: RentalsApiRentalsUpdateRequest, options?: RawAxiosRequestConfig) {
        return RentalsApiFp(this.configuration).rentalsUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const RentalsReportsActiveListOrderBy = {
    total_effective_rate: 'total_effective_rate',
    total_effective_rate2: '-total_effective_rate'
} as const;
export type RentalsReportsActiveListOrderBy = typeof RentalsReportsActiveListOrderBy[keyof typeof RentalsReportsActiveListOrderBy];
/**
 * @export
 */
export const RentalsRequestListRequestType = {
    NEW_ORDER: 'NEW ORDER',
    REPLACEMENT: 'REPLACEMENT',
    RETURN: 'RETURN'
} as const;
export type RentalsRequestListRequestType = typeof RentalsRequestListRequestType[keyof typeof RentalsRequestListRequestType];
