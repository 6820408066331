/* tslint:disable */
/* eslint-disable */
/**
 * Stocky API
 * Playground For Stocky API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { AssetCurrentCategory } from '../model';
// @ts-ignore
import type { Attendance } from '../model';
// @ts-ignore
import type { AttendanceCheckIn } from '../model';
// @ts-ignore
import type { BiosPassword } from '../model';
// @ts-ignore
import type { Brand } from '../model';
// @ts-ignore
import type { Category } from '../model';
// @ts-ignore
import type { ClientThread } from '../model';
// @ts-ignore
import type { ComputerExport } from '../model';
// @ts-ignore
import type { Connect } from '../model';
// @ts-ignore
import type { ConnectPOCEmails } from '../model';
// @ts-ignore
import type { DCItems } from '../model';
// @ts-ignore
import type { DeliveryChalan } from '../model';
// @ts-ignore
import type { GenerateUUID } from '../model';
// @ts-ignore
import type { GenericProduct } from '../model';
// @ts-ignore
import type { GroupFileUpload } from '../model';
// @ts-ignore
import type { GroupItemSave } from '../model';
// @ts-ignore
import type { GroupProduct } from '../model';
// @ts-ignore
import type { HSNCode } from '../model';
// @ts-ignore
import type { Inventory } from '../model';
// @ts-ignore
import type { ItemModel } from '../model';
// @ts-ignore
import type { LogisticDelivered } from '../model';
// @ts-ignore
import type { LogisticsDC } from '../model';
// @ts-ignore
import type { LogisticsMedia } from '../model';
// @ts-ignore
import type { MigrateFromOperations } from '../model';
// @ts-ignore
import type { MigrateFromQualityCheck } from '../model';
// @ts-ignore
import type { MigrateReturnFromService } from '../model';
// @ts-ignore
import type { NewLogisticsSerializer } from '../model';
// @ts-ignore
import type { Operations } from '../model';
// @ts-ignore
import type { PickupAttempt } from '../model';
// @ts-ignore
import type { ProductExport } from '../model';
// @ts-ignore
import type { PublicProduct } from '../model';
// @ts-ignore
import type { QualityCheck } from '../model';
// @ts-ignore
import type { Quote } from '../model';
// @ts-ignore
import type { QuoteRequest } from '../model';
// @ts-ignore
import type { QuoteRequestStage } from '../model';
// @ts-ignore
import type { ReplacementAsset } from '../model';
// @ts-ignore
import type { ReturnFromService } from '../model';
// @ts-ignore
import type { SavedFilter } from '../model';
// @ts-ignore
import type { SentService } from '../model';
// @ts-ignore
import type { ServiceReAssign } from '../model';
// @ts-ignore
import type { SlimConnect } from '../model';
// @ts-ignore
import type { SlimProduct } from '../model';
// @ts-ignore
import type { StockyAttendanceList200Response } from '../model';
// @ts-ignore
import type { StockyAttendanceReportUserMonthList200Response } from '../model';
// @ts-ignore
import type { StockyBrandList200Response } from '../model';
// @ts-ignore
import type { StockyClientRequestCommentsList200Response } from '../model';
// @ts-ignore
import type { StockyClientRequestsList200Response } from '../model';
// @ts-ignore
import type { StockyClientRequestsStageList200Response } from '../model';
// @ts-ignore
import type { StockyConnectExportList200Response } from '../model';
// @ts-ignore
import type { StockyConnectList200Response } from '../model';
// @ts-ignore
import type { StockyDeliveryChalanItemsList200Response } from '../model';
// @ts-ignore
import type { StockyDeliveryChalanList200Response } from '../model';
// @ts-ignore
import type { StockyExportDcList200Response } from '../model';
// @ts-ignore
import type { StockyExportProductsList200Response } from '../model';
// @ts-ignore
import type { StockyFilterProductSerialList200Response } from '../model';
// @ts-ignore
import type { StockyGroupItemsList200Response } from '../model';
// @ts-ignore
import type { StockyGroupsList200Response } from '../model';
// @ts-ignore
import type { StockyHsnCodesList200Response } from '../model';
// @ts-ignore
import type { StockyLogisticsDcList200Response } from '../model';
// @ts-ignore
import type { StockyLogsAssetTrailList200Response } from '../model';
// @ts-ignore
import type { StockyLogsExportLogisticsList200Response } from '../model';
// @ts-ignore
import type { StockyLogsExportOperationsList200Response } from '../model';
// @ts-ignore
import type { StockyLogsExportQualityCheckList200Response } from '../model';
// @ts-ignore
import type { StockyLogsExportServiceReturnList200Response } from '../model';
// @ts-ignore
import type { StockyLogsExportServiceSentList200Response } from '../model';
// @ts-ignore
import type { StockyLogsLogisticsList200Response } from '../model';
// @ts-ignore
import type { StockyLogsLogisticsPickupAttemptList200Response } from '../model';
// @ts-ignore
import type { StockyLogsOperationsList200Response } from '../model';
// @ts-ignore
import type { StockyLogsQualityCheckList200Response } from '../model';
// @ts-ignore
import type { StockyLogsSentServiceList200Response } from '../model';
// @ts-ignore
import type { StockyLogsServiceReturnList200Response } from '../model';
// @ts-ignore
import type { StockyPublicProductList200Response } from '../model';
// @ts-ignore
import type { StockyQuoteList200Response } from '../model';
// @ts-ignore
import type { StockyQuoteRequestList200Response } from '../model';
// @ts-ignore
import type { StockyQuoteRequestStageList200Response } from '../model';
// @ts-ignore
import type { StockyReportsMasterList200Response } from '../model';
// @ts-ignore
import type { StockyReportsOperationsModelWiseQtyList200Response } from '../model';
// @ts-ignore
import type { StockyReportsServiceModelWiseQtyList200Response } from '../model';
// @ts-ignore
import type { StockySavedFilterList200Response } from '../model';
// @ts-ignore
import type { StockySearchCategoryList200Response } from '../model';
// @ts-ignore
import type { StockySearchItemList200Response } from '../model';
// @ts-ignore
import type { StockySearchTagList200Response } from '../model';
// @ts-ignore
import type { StockySearchUserList200Response } from '../model';
// @ts-ignore
import type { StockySubtasksListList200Response } from '../model';
// @ts-ignore
import type { StockyTaskCommentsList200Response } from '../model';
// @ts-ignore
import type { StockyTemplatesList200Response } from '../model';
// @ts-ignore
import type { SupportRequest } from '../model';
// @ts-ignore
import type { SupportRequestStage } from '../model';
// @ts-ignore
import type { Tag } from '../model';
// @ts-ignore
import type { Task } from '../model';
// @ts-ignore
import type { TaskThread } from '../model';
// @ts-ignore
import type { Template } from '../model';
/**
 * StockyApi - axios parameter creator
 * @export
 */
export const StockyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyAttendanceCheckInDayReportRead: async (date: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'date' is not null or undefined
            assertParamExists('stockyAttendanceCheckInDayReportRead', 'date', date)
            const localVarPath = `/stocky/attendance/check_in_day_report/{date}`
                .replace(`{${"date"}}`, encodeURIComponent(String(date)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Attendance} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyAttendanceCreate: async (data: Attendance, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyAttendanceCreate', 'data', data)
            const localVarPath = `/stocky/attendance/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [user] user
         * @param {string} [created_at__month] created_at__month
         * @param {string} [created_at__year] created_at__year
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyAttendanceList: async (user?: string, created_at__month?: string, created_at__year?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/attendance/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (user !== undefined) {
                localVarQueryParameter['user'] = user;
            }

            if (created_at__month !== undefined) {
                localVarQueryParameter['created_at__month'] = created_at__month;
            }

            if (created_at__year !== undefined) {
                localVarQueryParameter['created_at__year'] = created_at__year;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this attendance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyAttendanceRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyAttendanceRead', 'id', id)
            const localVarPath = `/stocky/attendance/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [user] user
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyAttendanceReportUserMonthList: async (user?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/attendance/report/user/month/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (user !== undefined) {
                localVarQueryParameter['user'] = user;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyAttendanceStatusRead: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/attendance/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Brand | null} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyBrandCreate: async (data: Brand | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyBrandCreate', 'data', data)
            const localVarPath = `/stocky/brand/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyBrandList: async (search?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/brand/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this brand.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyBrandRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyBrandRead', 'id', id)
            const localVarPath = `/stocky/brand/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Category | null} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyCategoryCreate: async (data: Category | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyCategoryCreate', 'data', data)
            const localVarPath = `/stocky/category`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ClientThread} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyClientRequestCommentsCreate: async (data: ClientThread, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyClientRequestCommentsCreate', 'data', data)
            const localVarPath = `/stocky/client_request/comments/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this client thread.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyClientRequestCommentsDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyClientRequestCommentsDelete', 'id', id)
            const localVarPath = `/stocky/client_request/comments/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyClientRequestCommentsList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/client_request/comments/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this client thread.
         * @param {ClientThread} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyClientRequestCommentsPartialUpdate: async (id: number, data: ClientThread, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyClientRequestCommentsPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyClientRequestCommentsPartialUpdate', 'data', data)
            const localVarPath = `/stocky/client_request/comments/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this client thread.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyClientRequestCommentsRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyClientRequestCommentsRead', 'id', id)
            const localVarPath = `/stocky/client_request/comments/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this client thread.
         * @param {ClientThread} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyClientRequestCommentsUpdate: async (id: number, data: ClientThread, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyClientRequestCommentsUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyClientRequestCommentsUpdate', 'data', data)
            const localVarPath = `/stocky/client_request/comments/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SupportRequest} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyClientRequestsCreate: async (data: SupportRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyClientRequestsCreate', 'data', data)
            const localVarPath = `/stocky/client_requests/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this support request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyClientRequestsDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyClientRequestsDelete', 'id', id)
            const localVarPath = `/stocky/client_requests/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [serial_number] serial_number
         * @param {string} [connect] connect
         * @param {string} [category] category
         * @param {string} [slug] slug
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [stages] stages
         * @param {string} [exclude_stages] exclude_stages
         * @param {string} [consignee__auto_id] consignee__auto_id
         * @param {string} [consignee__city] consignee__city
         * @param {string} [consignee__state] consignee__state
         * @param {string} [consignee__pincode] consignee__pincode
         * @param {string} [task_id] task_id
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyClientRequestsExportList: async (serial_number?: string, connect?: string, category?: string, slug?: string, start_date?: string, end_date?: string, stages?: string, exclude_stages?: string, consignee__auto_id?: string, consignee__city?: string, consignee__state?: string, consignee__pincode?: string, task_id?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/client_requests/export/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (serial_number !== undefined) {
                localVarQueryParameter['serial_number'] = serial_number;
            }

            if (connect !== undefined) {
                localVarQueryParameter['connect'] = connect;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (slug !== undefined) {
                localVarQueryParameter['slug'] = slug;
            }

            if (start_date !== undefined) {
                localVarQueryParameter['start_date'] = start_date;
            }

            if (end_date !== undefined) {
                localVarQueryParameter['end_date'] = end_date;
            }

            if (stages !== undefined) {
                localVarQueryParameter['stages'] = stages;
            }

            if (exclude_stages !== undefined) {
                localVarQueryParameter['exclude_stages'] = exclude_stages;
            }

            if (consignee__auto_id !== undefined) {
                localVarQueryParameter['consignee__auto_id'] = consignee__auto_id;
            }

            if (consignee__city !== undefined) {
                localVarQueryParameter['consignee__city'] = consignee__city;
            }

            if (consignee__state !== undefined) {
                localVarQueryParameter['consignee__state'] = consignee__state;
            }

            if (consignee__pincode !== undefined) {
                localVarQueryParameter['consignee__pincode'] = consignee__pincode;
            }

            if (task_id !== undefined) {
                localVarQueryParameter['task_id'] = task_id;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {string} [serial_number] serial_number
         * @param {string} [connect] connect
         * @param {string} [category] category
         * @param {string} [slug] slug
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [stages] stages
         * @param {string} [exclude_stages] exclude_stages
         * @param {string} [consignee__auto_id] consignee__auto_id
         * @param {string} [consignee__city] consignee__city
         * @param {string} [consignee__state] consignee__state
         * @param {string} [consignee__pincode] consignee__pincode
         * @param {string} [task_id] task_id
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyClientRequestsList: async (search?: string, serial_number?: string, connect?: string, category?: string, slug?: string, start_date?: string, end_date?: string, stages?: string, exclude_stages?: string, consignee__auto_id?: string, consignee__city?: string, consignee__state?: string, consignee__pincode?: string, task_id?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/client_requests/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (serial_number !== undefined) {
                localVarQueryParameter['serial_number'] = serial_number;
            }

            if (connect !== undefined) {
                localVarQueryParameter['connect'] = connect;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (slug !== undefined) {
                localVarQueryParameter['slug'] = slug;
            }

            if (start_date !== undefined) {
                localVarQueryParameter['start_date'] = start_date;
            }

            if (end_date !== undefined) {
                localVarQueryParameter['end_date'] = end_date;
            }

            if (stages !== undefined) {
                localVarQueryParameter['stages'] = stages;
            }

            if (exclude_stages !== undefined) {
                localVarQueryParameter['exclude_stages'] = exclude_stages;
            }

            if (consignee__auto_id !== undefined) {
                localVarQueryParameter['consignee__auto_id'] = consignee__auto_id;
            }

            if (consignee__city !== undefined) {
                localVarQueryParameter['consignee__city'] = consignee__city;
            }

            if (consignee__state !== undefined) {
                localVarQueryParameter['consignee__state'] = consignee__state;
            }

            if (consignee__pincode !== undefined) {
                localVarQueryParameter['consignee__pincode'] = consignee__pincode;
            }

            if (task_id !== undefined) {
                localVarQueryParameter['task_id'] = task_id;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this support request.
         * @param {SupportRequest} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyClientRequestsPartialUpdate: async (id: number, data: SupportRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyClientRequestsPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyClientRequestsPartialUpdate', 'data', data)
            const localVarPath = `/stocky/client_requests/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this support request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyClientRequestsRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyClientRequestsRead', 'id', id)
            const localVarPath = `/stocky/client_requests/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SupportRequestStage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyClientRequestsStageCreate: async (data: SupportRequestStage, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyClientRequestsStageCreate', 'data', data)
            const localVarPath = `/stocky/client_requests/stage/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [stage] stage
         * @param {string} [client_request] client_request
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyClientRequestsStageList: async (stage?: string, client_request?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/client_requests/stage/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (stage !== undefined) {
                localVarQueryParameter['stage'] = stage;
            }

            if (client_request !== undefined) {
                localVarQueryParameter['client_request'] = client_request;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this cr stage.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyClientRequestsStageRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyClientRequestsStageRead', 'id', id)
            const localVarPath = `/stocky/client_requests/stage/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this support request.
         * @param {SupportRequest} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyClientRequestsUpdate: async (id: number, data: SupportRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyClientRequestsUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyClientRequestsUpdate', 'data', data)
            const localVarPath = `/stocky/client_requests/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Connect} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyConnectCreate: async (data: Connect, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyConnectCreate', 'data', data)
            const localVarPath = `/stocky/connect/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [is_rental_client] is_rental_client
         * @param {string} [is_individual] is_individual
         * @param {string} [is_supplier] is_supplier
         * @param {string} [company] company
         * @param {string} [gst_number] gst_number
         * @param {string} [is_sales_client] is_sales_client
         * @param {string} [connect] connect
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyConnectExportList: async (is_rental_client?: string, is_individual?: string, is_supplier?: string, company?: string, gst_number?: string, is_sales_client?: string, connect?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/connect/export/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (is_rental_client !== undefined) {
                localVarQueryParameter['is_rental_client'] = is_rental_client;
            }

            if (is_individual !== undefined) {
                localVarQueryParameter['is_individual'] = is_individual;
            }

            if (is_supplier !== undefined) {
                localVarQueryParameter['is_supplier'] = is_supplier;
            }

            if (company !== undefined) {
                localVarQueryParameter['company'] = company;
            }

            if (gst_number !== undefined) {
                localVarQueryParameter['gst_number'] = gst_number;
            }

            if (is_sales_client !== undefined) {
                localVarQueryParameter['is_sales_client'] = is_sales_client;
            }

            if (connect !== undefined) {
                localVarQueryParameter['connect'] = connect;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {string} [is_rental_client] is_rental_client
         * @param {string} [is_individual] is_individual
         * @param {string} [is_supplier] is_supplier
         * @param {string} [company] company
         * @param {string} [gst_number] gst_number
         * @param {string} [is_sales_client] is_sales_client
         * @param {string} [connect] connect
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyConnectList: async (search?: string, is_rental_client?: string, is_individual?: string, is_supplier?: string, company?: string, gst_number?: string, is_sales_client?: string, connect?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/connect/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (is_rental_client !== undefined) {
                localVarQueryParameter['is_rental_client'] = is_rental_client;
            }

            if (is_individual !== undefined) {
                localVarQueryParameter['is_individual'] = is_individual;
            }

            if (is_supplier !== undefined) {
                localVarQueryParameter['is_supplier'] = is_supplier;
            }

            if (company !== undefined) {
                localVarQueryParameter['company'] = company;
            }

            if (gst_number !== undefined) {
                localVarQueryParameter['gst_number'] = gst_number;
            }

            if (is_sales_client !== undefined) {
                localVarQueryParameter['is_sales_client'] = is_sales_client;
            }

            if (connect !== undefined) {
                localVarQueryParameter['connect'] = connect;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this connect.
         * @param {Connect} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyConnectPartialUpdate: async (id: number, data: Connect, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyConnectPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyConnectPartialUpdate', 'data', data)
            const localVarPath = `/stocky/connect/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this connect.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyConnectPocEmailsRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyConnectPocEmailsRead', 'id', id)
            const localVarPath = `/stocky/connect/poc/emails/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this connect.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyConnectRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyConnectRead', 'id', id)
            const localVarPath = `/stocky/connect/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this connect.
         * @param {Connect} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyConnectUpdate: async (id: number, data: Connect, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyConnectUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyConnectUpdate', 'data', data)
            const localVarPath = `/stocky/connect/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeliveryChalan} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyDeliveryChalanCreate: async (data: DeliveryChalan, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyDeliveryChalanCreate', 'data', data)
            const localVarPath = `/stocky/delivery_chalan/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this delivery chalan.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyDeliveryChalanDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyDeliveryChalanDelete', 'id', id)
            const localVarPath = `/stocky/delivery_chalan/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DCItems} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyDeliveryChalanItemsCreate: async (data: DCItems, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyDeliveryChalanItemsCreate', 'data', data)
            const localVarPath = `/stocky/delivery_chalan/items/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this dc items.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyDeliveryChalanItemsDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyDeliveryChalanItemsDelete', 'id', id)
            const localVarPath = `/stocky/delivery_chalan/items/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [dc] dc
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyDeliveryChalanItemsList: async (dc?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/delivery_chalan/items/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (dc !== undefined) {
                localVarQueryParameter['dc'] = dc;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this dc items.
         * @param {DCItems} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyDeliveryChalanItemsPartialUpdate: async (id: number, data: DCItems, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyDeliveryChalanItemsPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyDeliveryChalanItemsPartialUpdate', 'data', data)
            const localVarPath = `/stocky/delivery_chalan/items/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this dc items.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyDeliveryChalanItemsRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyDeliveryChalanItemsRead', 'id', id)
            const localVarPath = `/stocky/delivery_chalan/items/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this dc items.
         * @param {DCItems} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyDeliveryChalanItemsUpdate: async (id: number, data: DCItems, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyDeliveryChalanItemsUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyDeliveryChalanItemsUpdate', 'data', data)
            const localVarPath = `/stocky/delivery_chalan/items/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StockyDeliveryChalanListStatus} [status] status
         * @param {string} [ids] ids
         * @param {string} [eway_bill_required] eway_bill_required
         * @param {string} [connect] connect
         * @param {string} [company] company
         * @param {string} [order] order
         * @param {string} [serial] serial
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyDeliveryChalanList: async (status?: StockyDeliveryChalanListStatus, ids?: string, eway_bill_required?: string, connect?: string, company?: string, order?: string, serial?: string, start_date?: string, end_date?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/delivery_chalan/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (ids !== undefined) {
                localVarQueryParameter['ids'] = ids;
            }

            if (eway_bill_required !== undefined) {
                localVarQueryParameter['eway_bill_required'] = eway_bill_required;
            }

            if (connect !== undefined) {
                localVarQueryParameter['connect'] = connect;
            }

            if (company !== undefined) {
                localVarQueryParameter['company'] = company;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (serial !== undefined) {
                localVarQueryParameter['serial'] = serial;
            }

            if (start_date !== undefined) {
                localVarQueryParameter['start_date'] = start_date;
            }

            if (end_date !== undefined) {
                localVarQueryParameter['end_date'] = end_date;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this delivery chalan.
         * @param {DeliveryChalan} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyDeliveryChalanPartialUpdate: async (id: number, data: DeliveryChalan, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyDeliveryChalanPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyDeliveryChalanPartialUpdate', 'data', data)
            const localVarPath = `/stocky/delivery_chalan/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this delivery chalan.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyDeliveryChalanRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyDeliveryChalanRead', 'id', id)
            const localVarPath = `/stocky/delivery_chalan/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this delivery chalan.
         * @param {DeliveryChalan} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyDeliveryChalanUpdate: async (id: number, data: DeliveryChalan, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyDeliveryChalanUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyDeliveryChalanUpdate', 'data', data)
            const localVarPath = `/stocky/delivery_chalan/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ComputerExport} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyExportComputerCreate: async (data: ComputerExport, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyExportComputerCreate', 'data', data)
            const localVarPath = `/stocky/export/computer/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StockyExportDcListStatus} [status] status
         * @param {string} [ids] ids
         * @param {string} [eway_bill_required] eway_bill_required
         * @param {string} [connect] connect
         * @param {string} [company] company
         * @param {string} [order] order
         * @param {string} [serial] serial
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyExportDcList: async (status?: StockyExportDcListStatus, ids?: string, eway_bill_required?: string, connect?: string, company?: string, order?: string, serial?: string, start_date?: string, end_date?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/export/dc/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (ids !== undefined) {
                localVarQueryParameter['ids'] = ids;
            }

            if (eway_bill_required !== undefined) {
                localVarQueryParameter['eway_bill_required'] = eway_bill_required;
            }

            if (connect !== undefined) {
                localVarQueryParameter['connect'] = connect;
            }

            if (company !== undefined) {
                localVarQueryParameter['company'] = company;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (serial !== undefined) {
                localVarQueryParameter['serial'] = serial;
            }

            if (start_date !== undefined) {
                localVarQueryParameter['start_date'] = start_date;
            }

            if (end_date !== undefined) {
                localVarQueryParameter['end_date'] = end_date;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ProductExport} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyExportProductsCreate: async (data: ProductExport, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyExportProductsCreate', 'data', data)
            const localVarPath = `/stocky/export/products/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [brand__id] brand__id
         * @param {string} [item__id] item__id
         * @param {string} [serial] serial
         * @param {string} [tag__id] tag__id
         * @param {string} [ids] ids
         * @param {string} [processor] processor
         * @param {string} [generation] generation
         * @param {string} [stock_filter] stock_filter
         * @param {string} [current_category] current_category
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [asset_status] asset_status
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyExportProductsList: async (brand__id?: string, item__id?: string, serial?: string, tag__id?: string, ids?: string, processor?: string, generation?: string, stock_filter?: string, current_category?: string, start_date?: string, end_date?: string, asset_status?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/export/products/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (brand__id !== undefined) {
                localVarQueryParameter['brand__id'] = brand__id;
            }

            if (item__id !== undefined) {
                localVarQueryParameter['item__id'] = item__id;
            }

            if (serial !== undefined) {
                localVarQueryParameter['serial'] = serial;
            }

            if (tag__id !== undefined) {
                localVarQueryParameter['tag__id'] = tag__id;
            }

            if (ids !== undefined) {
                localVarQueryParameter['ids'] = ids;
            }

            if (processor !== undefined) {
                localVarQueryParameter['processor'] = processor;
            }

            if (generation !== undefined) {
                localVarQueryParameter['generation'] = generation;
            }

            if (stock_filter !== undefined) {
                localVarQueryParameter['stock_filter'] = stock_filter;
            }

            if (current_category !== undefined) {
                localVarQueryParameter['current_category'] = current_category;
            }

            if (start_date !== undefined) {
                localVarQueryParameter['start_date'] = start_date;
            }

            if (end_date !== undefined) {
                localVarQueryParameter['end_date'] = end_date;
            }

            if (asset_status !== undefined) {
                localVarQueryParameter['asset_status'] = asset_status;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [serial] serial
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyFilterProductSerialList: async (serial?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/filter/product/serial/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (serial !== undefined) {
                localVarQueryParameter['serial'] = serial;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyGreetingsRead: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/greetings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this group product.
         * @param {GroupItemSave} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyGroupItemsAddCreate: async (id: number, data: GroupItemSave, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyGroupItemsAddCreate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyGroupItemsAddCreate', 'data', data)
            const localVarPath = `/stocky/group/{id}/items/add`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this group product.
         * @param {string} item_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyGroupItemsDelete: async (id: number, item_id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyGroupItemsDelete', 'id', id)
            // verify required parameter 'item_id' is not null or undefined
            assertParamExists('stockyGroupItemsDelete', 'item_id', item_id)
            const localVarPath = `/stocky/group/{id}/items/{item_id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"item_id"}}`, encodeURIComponent(String(item_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this group product.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyGroupItemsList: async (id: number, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyGroupItemsList', 'id', id)
            const localVarPath = `/stocky/group/{id}/items`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GroupProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyGroupsCreate: async (data: GroupProduct, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyGroupsCreate', 'data', data)
            const localVarPath = `/stocky/groups/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this group product.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyGroupsDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyGroupsDelete', 'id', id)
            const localVarPath = `/stocky/groups/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [connect] connect
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyGroupsList: async (connect?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/groups/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (connect !== undefined) {
                localVarQueryParameter['connect'] = connect;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this group product.
         * @param {GroupProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyGroupsPartialUpdate: async (id: number, data: GroupProduct, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyGroupsPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyGroupsPartialUpdate', 'data', data)
            const localVarPath = `/stocky/groups/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this group product.
         * @param {GroupProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyGroupsUpdate: async (id: number, data: GroupProduct, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyGroupsUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyGroupsUpdate', 'data', data)
            const localVarPath = `/stocky/groups/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyHsnCodesList: async (search?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/hsn_codes/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this hsn code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyHsnCodesRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyHsnCodesRead', 'id', id)
            const localVarPath = `/stocky/hsn_codes/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Inventory} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyInventoryComputerCreate: async (data: Inventory, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyInventoryComputerCreate', 'data', data)
            const localVarPath = `/stocky/inventory/computer/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ItemModel | null} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyItemCreate: async (data: ItemModel | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyItemCreate', 'data', data)
            const localVarPath = `/stocky/item`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LogisticsDC} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogisticsDcCreate: async (data: LogisticsDC, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyLogisticsDcCreate', 'data', data)
            const localVarPath = `/stocky/logistics/dc/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogisticsDcList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/logistics/dc/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} serial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogisticsRelatedRead: async (serial: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serial' is not null or undefined
            assertParamExists('stockyLogisticsRelatedRead', 'serial', serial)
            const localVarPath = `/stocky/logistics/related/{serial}`
                .replace(`{${"serial"}}`, encodeURIComponent(String(serial)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AssetCurrentCategory} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsAssetCurrentCategoryCreate: async (data: AssetCurrentCategory, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyLogsAssetCurrentCategoryCreate', 'data', data)
            const localVarPath = `/stocky/logs/asset/current_category/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this asset trail.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsAssetTrailDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyLogsAssetTrailDelete', 'id', id)
            const localVarPath = `/stocky/logs/asset_trail/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [serial] serial
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsAssetTrailList: async (serial?: string, start_date?: string, end_date?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/logs/asset_trail/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (serial !== undefined) {
                localVarQueryParameter['serial'] = serial;
            }

            if (start_date !== undefined) {
                localVarQueryParameter['start_date'] = start_date;
            }

            if (end_date !== undefined) {
                localVarQueryParameter['end_date'] = end_date;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [ops_type] ops_type
         * @param {string} [serial] serial
         * @param {string} [product__item__id] product__item__id
         * @param {string} [order_type] order_type
         * @param {string} [connect] connect
         * @param {string} [tracking_no] tracking_no
         * @param {string} [replacement_asset__status] replacement_asset__status
         * @param {string} [replacement_asset__serial] replacement_asset__serial
         * @param {string} [order] order
         * @param {string} [cost_to] cost_to
         * @param {string} [logistics_partner] logistics_partner
         * @param {string} [pagination] Pagination
         * @param {string} [meta__CONDITION_OF_ASSET] meta__CONDITION_OF_ASSET
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [asset_status] asset_status
         * @param {string} [delivery] delivery
         * @param {string} [exclude_id] exclude_id
         * @param {string} [tracking_number_isnull] tracking_number_isnull
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsExportLogisticsList: async (ops_type?: string, serial?: string, product__item__id?: string, order_type?: string, connect?: string, tracking_no?: string, replacement_asset__status?: string, replacement_asset__serial?: string, order?: string, cost_to?: string, logistics_partner?: string, pagination?: string, meta__CONDITION_OF_ASSET?: string, start_date?: string, end_date?: string, asset_status?: string, delivery?: string, exclude_id?: string, tracking_number_isnull?: string, search?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/logs/export/logistics/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (ops_type !== undefined) {
                localVarQueryParameter['ops_type'] = ops_type;
            }

            if (serial !== undefined) {
                localVarQueryParameter['serial'] = serial;
            }

            if (product__item__id !== undefined) {
                localVarQueryParameter['product__item__id'] = product__item__id;
            }

            if (order_type !== undefined) {
                localVarQueryParameter['order_type'] = order_type;
            }

            if (connect !== undefined) {
                localVarQueryParameter['connect'] = connect;
            }

            if (tracking_no !== undefined) {
                localVarQueryParameter['tracking_no'] = tracking_no;
            }

            if (replacement_asset__status !== undefined) {
                localVarQueryParameter['replacement_asset__status'] = replacement_asset__status;
            }

            if (replacement_asset__serial !== undefined) {
                localVarQueryParameter['replacement_asset__serial'] = replacement_asset__serial;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (cost_to !== undefined) {
                localVarQueryParameter['cost_to'] = cost_to;
            }

            if (logistics_partner !== undefined) {
                localVarQueryParameter['logistics_partner'] = logistics_partner;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }

            if (meta__CONDITION_OF_ASSET !== undefined) {
                localVarQueryParameter['meta__CONDITION_OF_ASSET'] = meta__CONDITION_OF_ASSET;
            }

            if (start_date !== undefined) {
                localVarQueryParameter['start_date'] = start_date;
            }

            if (end_date !== undefined) {
                localVarQueryParameter['end_date'] = end_date;
            }

            if (asset_status !== undefined) {
                localVarQueryParameter['asset_status'] = asset_status;
            }

            if (delivery !== undefined) {
                localVarQueryParameter['delivery'] = delivery;
            }

            if (exclude_id !== undefined) {
                localVarQueryParameter['exclude_id'] = exclude_id;
            }

            if (tracking_number_isnull !== undefined) {
                localVarQueryParameter['tracking_number_isnull'] = tracking_number_isnull;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [ops_type] ops_type
         * @param {string} [connect] connect
         * @param {string} [serial] serial
         * @param {string} [use_type] use_type
         * @param {string} [handover] handover
         * @param {string} [generic_product__item__id] generic_product__item__id
         * @param {string} [replacement__status] replacement__status
         * @param {string} [replacement__serial] replacement__serial
         * @param {string} [order] order
         * @param {string} [po__po_number] po__po_number
         * @param {string} [meta__CONDITION_OF_ASSET] meta__CONDITION_OF_ASSET
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [asset_status] asset_status
         * @param {string} [current_category] current_category
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsExportOperationsList: async (ops_type?: string, connect?: string, serial?: string, use_type?: string, handover?: string, generic_product__item__id?: string, replacement__status?: string, replacement__serial?: string, order?: string, po__po_number?: string, meta__CONDITION_OF_ASSET?: string, start_date?: string, end_date?: string, asset_status?: string, current_category?: string, search?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/logs/export/operations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (ops_type !== undefined) {
                localVarQueryParameter['ops_type'] = ops_type;
            }

            if (connect !== undefined) {
                localVarQueryParameter['connect'] = connect;
            }

            if (serial !== undefined) {
                localVarQueryParameter['serial'] = serial;
            }

            if (use_type !== undefined) {
                localVarQueryParameter['use_type'] = use_type;
            }

            if (handover !== undefined) {
                localVarQueryParameter['handover'] = handover;
            }

            if (generic_product__item__id !== undefined) {
                localVarQueryParameter['generic_product__item__id'] = generic_product__item__id;
            }

            if (replacement__status !== undefined) {
                localVarQueryParameter['replacement__status'] = replacement__status;
            }

            if (replacement__serial !== undefined) {
                localVarQueryParameter['replacement__serial'] = replacement__serial;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (po__po_number !== undefined) {
                localVarQueryParameter['po__po_number'] = po__po_number;
            }

            if (meta__CONDITION_OF_ASSET !== undefined) {
                localVarQueryParameter['meta__CONDITION_OF_ASSET'] = meta__CONDITION_OF_ASSET;
            }

            if (start_date !== undefined) {
                localVarQueryParameter['start_date'] = start_date;
            }

            if (end_date !== undefined) {
                localVarQueryParameter['end_date'] = end_date;
            }

            if (asset_status !== undefined) {
                localVarQueryParameter['asset_status'] = asset_status;
            }

            if (current_category !== undefined) {
                localVarQueryParameter['current_category'] = current_category;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [status] status
         * @param {string} [from_to_user] from_to_user
         * @param {string} [serial] serial
         * @param {string} [ops_type] ops_type
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [asset_status] asset_status
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsExportQualityCheckList: async (status?: string, from_to_user?: string, serial?: string, ops_type?: string, start_date?: string, end_date?: string, asset_status?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/logs/export/quality_check/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (from_to_user !== undefined) {
                localVarQueryParameter['from_to_user'] = from_to_user;
            }

            if (serial !== undefined) {
                localVarQueryParameter['serial'] = serial;
            }

            if (ops_type !== undefined) {
                localVarQueryParameter['ops_type'] = ops_type;
            }

            if (start_date !== undefined) {
                localVarQueryParameter['start_date'] = start_date;
            }

            if (end_date !== undefined) {
                localVarQueryParameter['end_date'] = end_date;
            }

            if (asset_status !== undefined) {
                localVarQueryParameter['asset_status'] = asset_status;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from_user] from_user
         * @param {string} [serial] serial
         * @param {string} [tag__id] tag__id
         * @param {string} [handover_to] handover_to
         * @param {string} [status] status
         * @param {string} [generic_product__item__id] generic_product__item__id
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [asset_status] asset_status
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsExportServiceReturnList: async (from_user?: string, serial?: string, tag__id?: string, handover_to?: string, status?: string, generic_product__item__id?: string, start_date?: string, end_date?: string, asset_status?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/logs/export/service_return/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (from_user !== undefined) {
                localVarQueryParameter['from_user'] = from_user;
            }

            if (serial !== undefined) {
                localVarQueryParameter['serial'] = serial;
            }

            if (tag__id !== undefined) {
                localVarQueryParameter['tag__id'] = tag__id;
            }

            if (handover_to !== undefined) {
                localVarQueryParameter['handover_to'] = handover_to;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (generic_product__item__id !== undefined) {
                localVarQueryParameter['generic_product__item__id'] = generic_product__item__id;
            }

            if (start_date !== undefined) {
                localVarQueryParameter['start_date'] = start_date;
            }

            if (end_date !== undefined) {
                localVarQueryParameter['end_date'] = end_date;
            }

            if (asset_status !== undefined) {
                localVarQueryParameter['asset_status'] = asset_status;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [serial] serial
         * @param {string} [assigned_to] assigned_to
         * @param {string} [generic_product__item__id] generic_product__item__id
         * @param {string} [fromuser] fromuser
         * @param {string} [tag__id] tag__id
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [asset_status] asset_status
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsExportServiceSentList: async (serial?: string, assigned_to?: string, generic_product__item__id?: string, fromuser?: string, tag__id?: string, start_date?: string, end_date?: string, asset_status?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/logs/export/service_sent/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (serial !== undefined) {
                localVarQueryParameter['serial'] = serial;
            }

            if (assigned_to !== undefined) {
                localVarQueryParameter['assigned_to'] = assigned_to;
            }

            if (generic_product__item__id !== undefined) {
                localVarQueryParameter['generic_product__item__id'] = generic_product__item__id;
            }

            if (fromuser !== undefined) {
                localVarQueryParameter['fromuser'] = fromuser;
            }

            if (tag__id !== undefined) {
                localVarQueryParameter['tag__id'] = tag__id;
            }

            if (start_date !== undefined) {
                localVarQueryParameter['start_date'] = start_date;
            }

            if (end_date !== undefined) {
                localVarQueryParameter['end_date'] = end_date;
            }

            if (asset_status !== undefined) {
                localVarQueryParameter['asset_status'] = asset_status;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {NewLogisticsSerializer} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsLogisticsCreate: async (data: NewLogisticsSerializer, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyLogsLogisticsCreate', 'data', data)
            const localVarPath = `/stocky/logs/logistics/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this logistics.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsLogisticsDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyLogsLogisticsDelete', 'id', id)
            const localVarPath = `/stocky/logs/logistics/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {LogisticDelivered} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsLogisticsDeliveryCreate: async (data: LogisticDelivered, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyLogsLogisticsDeliveryCreate', 'data', data)
            const localVarPath = `/stocky/logs/logistics_delivery/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this logistic delivered.
         * @param {LogisticDelivered} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsLogisticsDeliveryPartialUpdate: async (id: number, data: LogisticDelivered, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyLogsLogisticsDeliveryPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyLogsLogisticsDeliveryPartialUpdate', 'data', data)
            const localVarPath = `/stocky/logs/logistics_delivery/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this logistic delivered.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsLogisticsDeliveryRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyLogsLogisticsDeliveryRead', 'id', id)
            const localVarPath = `/stocky/logs/logistics_delivery/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this logistic delivered.
         * @param {LogisticDelivered} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsLogisticsDeliveryUpdate: async (id: number, data: LogisticDelivered, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyLogsLogisticsDeliveryUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyLogsLogisticsDeliveryUpdate', 'data', data)
            const localVarPath = `/stocky/logs/logistics_delivery/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [ops_type] ops_type
         * @param {string} [serial] serial
         * @param {string} [product__item__id] product__item__id
         * @param {string} [order_type] order_type
         * @param {string} [connect] connect
         * @param {string} [tracking_no] tracking_no
         * @param {string} [replacement_asset__status] replacement_asset__status
         * @param {string} [replacement_asset__serial] replacement_asset__serial
         * @param {string} [order] order
         * @param {string} [cost_to] cost_to
         * @param {string} [logistics_partner] logistics_partner
         * @param {string} [pagination] Pagination
         * @param {string} [meta__CONDITION_OF_ASSET] meta__CONDITION_OF_ASSET
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [asset_status] asset_status
         * @param {string} [delivery] delivery
         * @param {string} [exclude_id] exclude_id
         * @param {string} [tracking_number_isnull] tracking_number_isnull
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsLogisticsList: async (ops_type?: string, serial?: string, product__item__id?: string, order_type?: string, connect?: string, tracking_no?: string, replacement_asset__status?: string, replacement_asset__serial?: string, order?: string, cost_to?: string, logistics_partner?: string, pagination?: string, meta__CONDITION_OF_ASSET?: string, start_date?: string, end_date?: string, asset_status?: string, delivery?: string, exclude_id?: string, tracking_number_isnull?: string, search?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/logs/logistics/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (ops_type !== undefined) {
                localVarQueryParameter['ops_type'] = ops_type;
            }

            if (serial !== undefined) {
                localVarQueryParameter['serial'] = serial;
            }

            if (product__item__id !== undefined) {
                localVarQueryParameter['product__item__id'] = product__item__id;
            }

            if (order_type !== undefined) {
                localVarQueryParameter['order_type'] = order_type;
            }

            if (connect !== undefined) {
                localVarQueryParameter['connect'] = connect;
            }

            if (tracking_no !== undefined) {
                localVarQueryParameter['tracking_no'] = tracking_no;
            }

            if (replacement_asset__status !== undefined) {
                localVarQueryParameter['replacement_asset__status'] = replacement_asset__status;
            }

            if (replacement_asset__serial !== undefined) {
                localVarQueryParameter['replacement_asset__serial'] = replacement_asset__serial;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (cost_to !== undefined) {
                localVarQueryParameter['cost_to'] = cost_to;
            }

            if (logistics_partner !== undefined) {
                localVarQueryParameter['logistics_partner'] = logistics_partner;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }

            if (meta__CONDITION_OF_ASSET !== undefined) {
                localVarQueryParameter['meta__CONDITION_OF_ASSET'] = meta__CONDITION_OF_ASSET;
            }

            if (start_date !== undefined) {
                localVarQueryParameter['start_date'] = start_date;
            }

            if (end_date !== undefined) {
                localVarQueryParameter['end_date'] = end_date;
            }

            if (asset_status !== undefined) {
                localVarQueryParameter['asset_status'] = asset_status;
            }

            if (delivery !== undefined) {
                localVarQueryParameter['delivery'] = delivery;
            }

            if (exclude_id !== undefined) {
                localVarQueryParameter['exclude_id'] = exclude_id;
            }

            if (tracking_number_isnull !== undefined) {
                localVarQueryParameter['tracking_number_isnull'] = tracking_number_isnull;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this logistics.
         * @param {NewLogisticsSerializer} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsLogisticsPartialUpdate: async (id: number, data: NewLogisticsSerializer, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyLogsLogisticsPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyLogsLogisticsPartialUpdate', 'data', data)
            const localVarPath = `/stocky/logs/logistics/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PickupAttempt} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsLogisticsPickupAttemptCreate: async (data: PickupAttempt, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyLogsLogisticsPickupAttemptCreate', 'data', data)
            const localVarPath = `/stocky/logs/logistics/pickup_attempt/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this pickup attempt.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsLogisticsPickupAttemptDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyLogsLogisticsPickupAttemptDelete', 'id', id)
            const localVarPath = `/stocky/logs/logistics/pickup_attempt/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [city] city
         * @param {string} [logistics_partner] logistics_partner
         * @param {string} [cost_to] cost_to
         * @param {string} [slug] slug
         * @param {string} [state] state
         * @param {string} [tracking_no] tracking_no
         * @param {string} [amount] amount
         * @param {string} [pincode] pincode
         * @param {string} [logistics] logistics
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsLogisticsPickupAttemptList: async (city?: string, logistics_partner?: string, cost_to?: string, slug?: string, state?: string, tracking_no?: string, amount?: string, pincode?: string, logistics?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/logs/logistics/pickup_attempt/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (city !== undefined) {
                localVarQueryParameter['city'] = city;
            }

            if (logistics_partner !== undefined) {
                localVarQueryParameter['logistics_partner'] = logistics_partner;
            }

            if (cost_to !== undefined) {
                localVarQueryParameter['cost_to'] = cost_to;
            }

            if (slug !== undefined) {
                localVarQueryParameter['slug'] = slug;
            }

            if (state !== undefined) {
                localVarQueryParameter['state'] = state;
            }

            if (tracking_no !== undefined) {
                localVarQueryParameter['tracking_no'] = tracking_no;
            }

            if (amount !== undefined) {
                localVarQueryParameter['amount'] = amount;
            }

            if (pincode !== undefined) {
                localVarQueryParameter['pincode'] = pincode;
            }

            if (logistics !== undefined) {
                localVarQueryParameter['logistics'] = logistics;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this pickup attempt.
         * @param {PickupAttempt} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsLogisticsPickupAttemptPartialUpdate: async (id: number, data: PickupAttempt, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyLogsLogisticsPickupAttemptPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyLogsLogisticsPickupAttemptPartialUpdate', 'data', data)
            const localVarPath = `/stocky/logs/logistics/pickup_attempt/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this pickup attempt.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsLogisticsPickupAttemptRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyLogsLogisticsPickupAttemptRead', 'id', id)
            const localVarPath = `/stocky/logs/logistics/pickup_attempt/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this pickup attempt.
         * @param {PickupAttempt} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsLogisticsPickupAttemptUpdate: async (id: number, data: PickupAttempt, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyLogsLogisticsPickupAttemptUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyLogsLogisticsPickupAttemptUpdate', 'data', data)
            const localVarPath = `/stocky/logs/logistics/pickup_attempt/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this logistics.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsLogisticsRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyLogsLogisticsRead', 'id', id)
            const localVarPath = `/stocky/logs/logistics/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this logistics.
         * @param {NewLogisticsSerializer} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsLogisticsUpdate: async (id: number, data: NewLogisticsSerializer, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyLogsLogisticsUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyLogsLogisticsUpdate', 'data', data)
            const localVarPath = `/stocky/logs/logistics/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this logistics.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsMediaLogisticsRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyLogsMediaLogisticsRead', 'id', id)
            const localVarPath = `/stocky/logs/media/logistics/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MigrateFromOperations} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsMigrateOperationsCreate: async (data: MigrateFromOperations, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyLogsMigrateOperationsCreate', 'data', data)
            const localVarPath = `/stocky/logs/migrate/operations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MigrateFromQualityCheck} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsMigrateQualityCheckCreate: async (data: MigrateFromQualityCheck, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyLogsMigrateQualityCheckCreate', 'data', data)
            const localVarPath = `/stocky/logs/migrate/quality_check/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MigrateReturnFromService} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsMigrateReturnFromServiceCreate: async (data: MigrateReturnFromService, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyLogsMigrateReturnFromServiceCreate', 'data', data)
            const localVarPath = `/stocky/logs/migrate/return_from_service/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ServiceReAssign} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsMigrateServiceReAssignCreate: async (data: ServiceReAssign, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyLogsMigrateServiceReAssignCreate', 'data', data)
            const localVarPath = `/stocky/logs/migrate/service_re_assign/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Operations} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsOperationsCreate: async (data: Operations, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyLogsOperationsCreate', 'data', data)
            const localVarPath = `/stocky/logs/operations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this operations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsOperationsDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyLogsOperationsDelete', 'id', id)
            const localVarPath = `/stocky/logs/operations/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [ops_type] ops_type
         * @param {string} [connect] connect
         * @param {string} [serial] serial
         * @param {string} [use_type] use_type
         * @param {string} [handover] handover
         * @param {string} [generic_product__item__id] generic_product__item__id
         * @param {string} [replacement__status] replacement__status
         * @param {string} [replacement__serial] replacement__serial
         * @param {string} [order] order
         * @param {string} [po__po_number] po__po_number
         * @param {string} [meta__CONDITION_OF_ASSET] meta__CONDITION_OF_ASSET
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [asset_status] asset_status
         * @param {string} [current_category] current_category
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsOperationsList: async (ops_type?: string, connect?: string, serial?: string, use_type?: string, handover?: string, generic_product__item__id?: string, replacement__status?: string, replacement__serial?: string, order?: string, po__po_number?: string, meta__CONDITION_OF_ASSET?: string, start_date?: string, end_date?: string, asset_status?: string, current_category?: string, search?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/logs/operations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (ops_type !== undefined) {
                localVarQueryParameter['ops_type'] = ops_type;
            }

            if (connect !== undefined) {
                localVarQueryParameter['connect'] = connect;
            }

            if (serial !== undefined) {
                localVarQueryParameter['serial'] = serial;
            }

            if (use_type !== undefined) {
                localVarQueryParameter['use_type'] = use_type;
            }

            if (handover !== undefined) {
                localVarQueryParameter['handover'] = handover;
            }

            if (generic_product__item__id !== undefined) {
                localVarQueryParameter['generic_product__item__id'] = generic_product__item__id;
            }

            if (replacement__status !== undefined) {
                localVarQueryParameter['replacement__status'] = replacement__status;
            }

            if (replacement__serial !== undefined) {
                localVarQueryParameter['replacement__serial'] = replacement__serial;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (po__po_number !== undefined) {
                localVarQueryParameter['po__po_number'] = po__po_number;
            }

            if (meta__CONDITION_OF_ASSET !== undefined) {
                localVarQueryParameter['meta__CONDITION_OF_ASSET'] = meta__CONDITION_OF_ASSET;
            }

            if (start_date !== undefined) {
                localVarQueryParameter['start_date'] = start_date;
            }

            if (end_date !== undefined) {
                localVarQueryParameter['end_date'] = end_date;
            }

            if (asset_status !== undefined) {
                localVarQueryParameter['asset_status'] = asset_status;
            }

            if (current_category !== undefined) {
                localVarQueryParameter['current_category'] = current_category;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this operations.
         * @param {Operations} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsOperationsPartialUpdate: async (id: number, data: Operations, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyLogsOperationsPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyLogsOperationsPartialUpdate', 'data', data)
            const localVarPath = `/stocky/logs/operations/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this operations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsOperationsRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyLogsOperationsRead', 'id', id)
            const localVarPath = `/stocky/logs/operations/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this operations.
         * @param {Operations} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsOperationsUpdate: async (id: number, data: Operations, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyLogsOperationsUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyLogsOperationsUpdate', 'data', data)
            const localVarPath = `/stocky/logs/operations/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {QualityCheck} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsQualityCheckCreate: async (data: QualityCheck, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyLogsQualityCheckCreate', 'data', data)
            const localVarPath = `/stocky/logs/quality_check/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this quality check.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsQualityCheckDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyLogsQualityCheckDelete', 'id', id)
            const localVarPath = `/stocky/logs/quality_check/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [status] status
         * @param {string} [from_to_user] from_to_user
         * @param {string} [serial] serial
         * @param {string} [ops_type] ops_type
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [asset_status] asset_status
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsQualityCheckList: async (status?: string, from_to_user?: string, serial?: string, ops_type?: string, start_date?: string, end_date?: string, asset_status?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/logs/quality_check/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (from_to_user !== undefined) {
                localVarQueryParameter['from_to_user'] = from_to_user;
            }

            if (serial !== undefined) {
                localVarQueryParameter['serial'] = serial;
            }

            if (ops_type !== undefined) {
                localVarQueryParameter['ops_type'] = ops_type;
            }

            if (start_date !== undefined) {
                localVarQueryParameter['start_date'] = start_date;
            }

            if (end_date !== undefined) {
                localVarQueryParameter['end_date'] = end_date;
            }

            if (asset_status !== undefined) {
                localVarQueryParameter['asset_status'] = asset_status;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this quality check.
         * @param {QualityCheck} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsQualityCheckPartialUpdate: async (id: number, data: QualityCheck, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyLogsQualityCheckPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyLogsQualityCheckPartialUpdate', 'data', data)
            const localVarPath = `/stocky/logs/quality_check/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this quality check.
         * @param {QualityCheck} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsQualityCheckUpdate: async (id: number, data: QualityCheck, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyLogsQualityCheckUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyLogsQualityCheckUpdate', 'data', data)
            const localVarPath = `/stocky/logs/quality_check/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this replacement asset.
         * @param {ReplacementAsset | null} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsReplacementAssetPartialUpdate: async (id: number, data: ReplacementAsset | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyLogsReplacementAssetPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyLogsReplacementAssetPartialUpdate', 'data', data)
            const localVarPath = `/stocky/logs/replacement_asset/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this replacement asset.
         * @param {ReplacementAsset | null} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsReplacementAssetUpdate: async (id: number, data: ReplacementAsset | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyLogsReplacementAssetUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyLogsReplacementAssetUpdate', 'data', data)
            const localVarPath = `/stocky/logs/replacement_asset/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SentService} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsSentServiceCreate: async (data: SentService, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyLogsSentServiceCreate', 'data', data)
            const localVarPath = `/stocky/logs/sent_service/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this sent to service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsSentServiceDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyLogsSentServiceDelete', 'id', id)
            const localVarPath = `/stocky/logs/sent_service/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [serial] serial
         * @param {string} [assigned_to] assigned_to
         * @param {string} [generic_product__item__id] generic_product__item__id
         * @param {string} [fromuser] fromuser
         * @param {string} [tag__id] tag__id
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [asset_status] asset_status
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsSentServiceList: async (serial?: string, assigned_to?: string, generic_product__item__id?: string, fromuser?: string, tag__id?: string, start_date?: string, end_date?: string, asset_status?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/logs/sent_service/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (serial !== undefined) {
                localVarQueryParameter['serial'] = serial;
            }

            if (assigned_to !== undefined) {
                localVarQueryParameter['assigned_to'] = assigned_to;
            }

            if (generic_product__item__id !== undefined) {
                localVarQueryParameter['generic_product__item__id'] = generic_product__item__id;
            }

            if (fromuser !== undefined) {
                localVarQueryParameter['fromuser'] = fromuser;
            }

            if (tag__id !== undefined) {
                localVarQueryParameter['tag__id'] = tag__id;
            }

            if (start_date !== undefined) {
                localVarQueryParameter['start_date'] = start_date;
            }

            if (end_date !== undefined) {
                localVarQueryParameter['end_date'] = end_date;
            }

            if (asset_status !== undefined) {
                localVarQueryParameter['asset_status'] = asset_status;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReturnFromService} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsServiceReturnCreate: async (data: ReturnFromService, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyLogsServiceReturnCreate', 'data', data)
            const localVarPath = `/stocky/logs/service_return/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this return from service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsServiceReturnDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyLogsServiceReturnDelete', 'id', id)
            const localVarPath = `/stocky/logs/service_return/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from_user] from_user
         * @param {string} [serial] serial
         * @param {string} [tag__id] tag__id
         * @param {string} [handover_to] handover_to
         * @param {string} [status] status
         * @param {string} [generic_product__item__id] generic_product__item__id
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [asset_status] asset_status
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsServiceReturnList: async (from_user?: string, serial?: string, tag__id?: string, handover_to?: string, status?: string, generic_product__item__id?: string, start_date?: string, end_date?: string, asset_status?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/logs/service_return/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (from_user !== undefined) {
                localVarQueryParameter['from_user'] = from_user;
            }

            if (serial !== undefined) {
                localVarQueryParameter['serial'] = serial;
            }

            if (tag__id !== undefined) {
                localVarQueryParameter['tag__id'] = tag__id;
            }

            if (handover_to !== undefined) {
                localVarQueryParameter['handover_to'] = handover_to;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (generic_product__item__id !== undefined) {
                localVarQueryParameter['generic_product__item__id'] = generic_product__item__id;
            }

            if (start_date !== undefined) {
                localVarQueryParameter['start_date'] = start_date;
            }

            if (end_date !== undefined) {
                localVarQueryParameter['end_date'] = end_date;
            }

            if (asset_status !== undefined) {
                localVarQueryParameter['asset_status'] = asset_status;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this generic product.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyProductsBiosPasswordRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyProductsBiosPasswordRead', 'id', id)
            const localVarPath = `/stocky/products/bios/password/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GenericProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyProductsCreate: async (data: GenericProduct, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyProductsCreate', 'data', data)
            const localVarPath = `/stocky/products/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [brand__id] brand__id
         * @param {string} [item__id] item__id
         * @param {string} [serial] serial
         * @param {string} [tag__id] tag__id
         * @param {string} [ids] ids
         * @param {string} [processor] processor
         * @param {string} [generation] generation
         * @param {string} [stock_filter] stock_filter
         * @param {string} [current_category] current_category
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [asset_status] asset_status
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyProductsList: async (brand__id?: string, item__id?: string, serial?: string, tag__id?: string, ids?: string, processor?: string, generation?: string, stock_filter?: string, current_category?: string, start_date?: string, end_date?: string, asset_status?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/products/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (brand__id !== undefined) {
                localVarQueryParameter['brand__id'] = brand__id;
            }

            if (item__id !== undefined) {
                localVarQueryParameter['item__id'] = item__id;
            }

            if (serial !== undefined) {
                localVarQueryParameter['serial'] = serial;
            }

            if (tag__id !== undefined) {
                localVarQueryParameter['tag__id'] = tag__id;
            }

            if (ids !== undefined) {
                localVarQueryParameter['ids'] = ids;
            }

            if (processor !== undefined) {
                localVarQueryParameter['processor'] = processor;
            }

            if (generation !== undefined) {
                localVarQueryParameter['generation'] = generation;
            }

            if (stock_filter !== undefined) {
                localVarQueryParameter['stock_filter'] = stock_filter;
            }

            if (current_category !== undefined) {
                localVarQueryParameter['current_category'] = current_category;
            }

            if (start_date !== undefined) {
                localVarQueryParameter['start_date'] = start_date;
            }

            if (end_date !== undefined) {
                localVarQueryParameter['end_date'] = end_date;
            }

            if (asset_status !== undefined) {
                localVarQueryParameter['asset_status'] = asset_status;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this generic product.
         * @param {GenericProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyProductsPartialUpdate: async (id: number, data: GenericProduct, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyProductsPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyProductsPartialUpdate', 'data', data)
            const localVarPath = `/stocky/products/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this generic product.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyProductsRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyProductsRead', 'id', id)
            const localVarPath = `/stocky/products/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this generic product.
         * @param {GenericProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyProductsUpdate: async (id: number, data: GenericProduct, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyProductsUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyProductsUpdate', 'data', data)
            const localVarPath = `/stocky/products/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [brand__id] brand__id
         * @param {string} [item__id] item__id
         * @param {string} [serial] serial
         * @param {string} [tag__id] tag__id
         * @param {string} [ids] ids
         * @param {string} [processor] processor
         * @param {string} [generation] generation
         * @param {string} [stock_filter] stock_filter
         * @param {string} [current_category] current_category
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [asset_status] asset_status
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyPublicProductList: async (brand__id?: string, item__id?: string, serial?: string, tag__id?: string, ids?: string, processor?: string, generation?: string, stock_filter?: string, current_category?: string, start_date?: string, end_date?: string, asset_status?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/public/product`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (brand__id !== undefined) {
                localVarQueryParameter['brand__id'] = brand__id;
            }

            if (item__id !== undefined) {
                localVarQueryParameter['item__id'] = item__id;
            }

            if (serial !== undefined) {
                localVarQueryParameter['serial'] = serial;
            }

            if (tag__id !== undefined) {
                localVarQueryParameter['tag__id'] = tag__id;
            }

            if (ids !== undefined) {
                localVarQueryParameter['ids'] = ids;
            }

            if (processor !== undefined) {
                localVarQueryParameter['processor'] = processor;
            }

            if (generation !== undefined) {
                localVarQueryParameter['generation'] = generation;
            }

            if (stock_filter !== undefined) {
                localVarQueryParameter['stock_filter'] = stock_filter;
            }

            if (current_category !== undefined) {
                localVarQueryParameter['current_category'] = current_category;
            }

            if (start_date !== undefined) {
                localVarQueryParameter['start_date'] = start_date;
            }

            if (end_date !== undefined) {
                localVarQueryParameter['end_date'] = end_date;
            }

            if (asset_status !== undefined) {
                localVarQueryParameter['asset_status'] = asset_status;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyPublicProductRead: async (key: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'key' is not null or undefined
            assertParamExists('stockyPublicProductRead', 'key', key)
            const localVarPath = `/stocky/public/product/{key}`
                .replace(`{${"key"}}`, encodeURIComponent(String(key)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} serial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyPublicProductSerialRead: async (serial: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serial' is not null or undefined
            assertParamExists('stockyPublicProductSerialRead', 'serial', serial)
            const localVarPath = `/stocky/public/product/serial/{serial}`
                .replace(`{${"serial"}}`, encodeURIComponent(String(serial)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this connect.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyPublicTicketConnectRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyPublicTicketConnectRead', 'id', id)
            const localVarPath = `/stocky/public/ticket/connect/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Quote} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyQuoteCreate: async (data: Quote, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyQuoteCreate', 'data', data)
            const localVarPath = `/stocky/quote/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [user] user
         * @param {string} [qr] qr
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyQuoteList: async (user?: string, qr?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/quote/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (user !== undefined) {
                localVarQueryParameter['user'] = user;
            }

            if (qr !== undefined) {
                localVarQueryParameter['qr'] = qr;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {QuoteRequest} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyQuoteRequestCreate: async (data: QuoteRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyQuoteRequestCreate', 'data', data)
            const localVarPath = `/stocky/quote/request/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [request_type] request_type
         * @param {string} [lead] lead
         * @param {string} [connect] connect
         * @param {string} [stage] stage
         * @param {string} [user] user
         * @param {string} [lead_user] lead_user
         * @param {string} [is_group] is_group
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyQuoteRequestList: async (request_type?: string, lead?: string, connect?: string, stage?: string, user?: string, lead_user?: string, is_group?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/quote/request/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (request_type !== undefined) {
                localVarQueryParameter['request_type'] = request_type;
            }

            if (lead !== undefined) {
                localVarQueryParameter['lead'] = lead;
            }

            if (connect !== undefined) {
                localVarQueryParameter['connect'] = connect;
            }

            if (stage !== undefined) {
                localVarQueryParameter['stage'] = stage;
            }

            if (user !== undefined) {
                localVarQueryParameter['user'] = user;
            }

            if (lead_user !== undefined) {
                localVarQueryParameter['lead_user'] = lead_user;
            }

            if (is_group !== undefined) {
                localVarQueryParameter['is_group'] = is_group;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this quote request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyQuoteRequestRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyQuoteRequestRead', 'id', id)
            const localVarPath = `/stocky/quote/request/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {QuoteRequestStage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyQuoteRequestStageCreate: async (data: QuoteRequestStage, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyQuoteRequestStageCreate', 'data', data)
            const localVarPath = `/stocky/quote/request/stage/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [qr] qr
         * @param {string} [stage] stage
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyQuoteRequestStageList: async (qr?: string, stage?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/quote/request/stage/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (qr !== undefined) {
                localVarQueryParameter['qr'] = qr;
            }

            if (stage !== undefined) {
                localVarQueryParameter['stage'] = stage;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyReportsMasterList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/reports/master/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [current_category] current_category
         * @param {string} [fields] fields
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyReportsOperationsModelWiseQtyList: async (current_category?: string, fields?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/reports/operations/model_wise_qty/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (current_category !== undefined) {
                localVarQueryParameter['current_category'] = current_category;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fields] fields
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyReportsQualityCheckModelWiseQtyList: async (fields?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/reports/quality_check/model_wise_qty/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [current_category] current_category
         * @param {string} [fields] fields
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyReportsServiceModelWiseQtyList: async (current_category?: string, fields?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/reports/service/model_wise_qty/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (current_category !== undefined) {
                localVarQueryParameter['current_category'] = current_category;
            }

            if (fields !== undefined) {
                localVarQueryParameter['fields'] = fields;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SavedFilter} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockySavedFilterCreate: async (data: SavedFilter, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockySavedFilterCreate', 'data', data)
            const localVarPath = `/stocky/saved_filter/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this saved filter model.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockySavedFilterDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockySavedFilterDelete', 'id', id)
            const localVarPath = `/stocky/saved_filter/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [category] category
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockySavedFilterList: async (category?: string, search?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/saved_filter/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this saved filter model.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockySavedFilterRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockySavedFilterRead', 'id', id)
            const localVarPath = `/stocky/saved_filter/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockySearchCategoryList: async (search?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/search/category/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockySearchConnectList: async (search?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/search/connect/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this connect.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockySearchConnectRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockySearchConnectRead', 'id', id)
            const localVarPath = `/stocky/search/connect/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockySearchItemList: async (search?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/search/item/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [category] category
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockySearchTagList: async (category?: string, search?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/search/tag/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {string} [email] email
         * @param {string} [role] role
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockySearchUserList: async (search?: string, email?: string, role?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/search/user/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (role !== undefined) {
                localVarQueryParameter['role'] = role;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} task_id 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockySubtasksListList: async (task_id: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'task_id' is not null or undefined
            assertParamExists('stockySubtasksListList', 'task_id', task_id)
            const localVarPath = `/stocky/subtasks/list/{task_id}`
                .replace(`{${"task_id"}}`, encodeURIComponent(String(task_id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Tag} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyTagsCreate: async (data: Tag, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyTagsCreate', 'data', data)
            const localVarPath = `/stocky/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {TaskThread} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyTaskCommentsCreate: async (data: TaskThread, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyTaskCommentsCreate', 'data', data)
            const localVarPath = `/stocky/task/comments/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this thread.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyTaskCommentsDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyTaskCommentsDelete', 'id', id)
            const localVarPath = `/stocky/task/comments/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyTaskCommentsList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/task/comments/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this thread.
         * @param {TaskThread} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyTaskCommentsPartialUpdate: async (id: number, data: TaskThread, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyTaskCommentsPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyTaskCommentsPartialUpdate', 'data', data)
            const localVarPath = `/stocky/task/comments/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this thread.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyTaskCommentsRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyTaskCommentsRead', 'id', id)
            const localVarPath = `/stocky/task/comments/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this thread.
         * @param {TaskThread} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyTaskCommentsUpdate: async (id: number, data: TaskThread, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyTaskCommentsUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyTaskCommentsUpdate', 'data', data)
            const localVarPath = `/stocky/task/comments/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Task} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyTasksCreate: async (data: Task, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyTasksCreate', 'data', data)
            const localVarPath = `/stocky/tasks/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this task.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyTasksDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyTasksDelete', 'id', id)
            const localVarPath = `/stocky/tasks/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [client_request] client_request
         * @param {string} [status] status
         * @param {string} [assigned] assigned
         * @param {string} [client] client
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [status_exclude] status_exclude
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyTasksList: async (client_request?: string, status?: string, assigned?: string, client?: string, start_date?: string, end_date?: string, status_exclude?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/tasks/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (client_request !== undefined) {
                localVarQueryParameter['client_request'] = client_request;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (assigned !== undefined) {
                localVarQueryParameter['assigned'] = assigned;
            }

            if (client !== undefined) {
                localVarQueryParameter['client'] = client;
            }

            if (start_date !== undefined) {
                localVarQueryParameter['start_date'] = start_date;
            }

            if (end_date !== undefined) {
                localVarQueryParameter['end_date'] = end_date;
            }

            if (status_exclude !== undefined) {
                localVarQueryParameter['status_exclude'] = status_exclude;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this task.
         * @param {Task} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyTasksPartialUpdate: async (id: number, data: Task, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyTasksPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyTasksPartialUpdate', 'data', data)
            const localVarPath = `/stocky/tasks/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this task.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyTasksRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyTasksRead', 'id', id)
            const localVarPath = `/stocky/tasks/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this task.
         * @param {Task} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyTasksUpdate: async (id: number, data: Task, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stockyTasksUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyTasksUpdate', 'data', data)
            const localVarPath = `/stocky/tasks/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyTemplateContentRead: async (slug: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('stockyTemplateContentRead', 'slug', slug)
            const localVarPath = `/stocky/template/content/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [slug] slug
         * @param {string} [target__in] target__in
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyTemplatesList: async (slug?: string, target__in?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/templates/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (slug !== undefined) {
                localVarQueryParameter['slug'] = slug;
            }

            if (target__in !== undefined) {
                localVarQueryParameter['target__in'] = target__in;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GroupFileUpload} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyUploadGroupCreate: async (data: GroupFileUpload, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('stockyUploadGroupCreate', 'data', data)
            const localVarPath = `/stocky/upload/group/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyUuidRead: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/stocky/uuid/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StockyApi - functional programming interface
 * @export
 */
export const StockyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StockyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} date 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyAttendanceCheckInDayReportRead(date: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyAttendanceCheckInDayReportRead(date, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyAttendanceCheckInDayReportRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Attendance} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyAttendanceCreate(data: Attendance, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Attendance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyAttendanceCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyAttendanceCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [user] user
         * @param {string} [created_at__month] created_at__month
         * @param {string} [created_at__year] created_at__year
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyAttendanceList(user?: string, created_at__month?: string, created_at__year?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyAttendanceList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyAttendanceList(user, created_at__month, created_at__year, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyAttendanceList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this attendance.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyAttendanceRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Attendance>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyAttendanceRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyAttendanceRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [user] user
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyAttendanceReportUserMonthList(user?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyAttendanceReportUserMonthList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyAttendanceReportUserMonthList(user, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyAttendanceReportUserMonthList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyAttendanceStatusRead(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AttendanceCheckIn>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyAttendanceStatusRead(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyAttendanceStatusRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Brand | null} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyBrandCreate(data: Brand | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Brand>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyBrandCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyBrandCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyBrandList(search?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyBrandList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyBrandList(search, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyBrandList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this brand.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyBrandRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Brand>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyBrandRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyBrandRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Category | null} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyCategoryCreate(data: Category | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyCategoryCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyCategoryCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ClientThread} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyClientRequestCommentsCreate(data: ClientThread, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientThread>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyClientRequestCommentsCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyClientRequestCommentsCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this client thread.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyClientRequestCommentsDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyClientRequestCommentsDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyClientRequestCommentsDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyClientRequestCommentsList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyClientRequestCommentsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyClientRequestCommentsList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyClientRequestCommentsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this client thread.
         * @param {ClientThread} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyClientRequestCommentsPartialUpdate(id: number, data: ClientThread, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientThread>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyClientRequestCommentsPartialUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyClientRequestCommentsPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this client thread.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyClientRequestCommentsRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientThread>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyClientRequestCommentsRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyClientRequestCommentsRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this client thread.
         * @param {ClientThread} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyClientRequestCommentsUpdate(id: number, data: ClientThread, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientThread>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyClientRequestCommentsUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyClientRequestCommentsUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SupportRequest} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyClientRequestsCreate(data: SupportRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupportRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyClientRequestsCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyClientRequestsCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this support request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyClientRequestsDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyClientRequestsDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyClientRequestsDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [serial_number] serial_number
         * @param {string} [connect] connect
         * @param {string} [category] category
         * @param {string} [slug] slug
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [stages] stages
         * @param {string} [exclude_stages] exclude_stages
         * @param {string} [consignee__auto_id] consignee__auto_id
         * @param {string} [consignee__city] consignee__city
         * @param {string} [consignee__state] consignee__state
         * @param {string} [consignee__pincode] consignee__pincode
         * @param {string} [task_id] task_id
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyClientRequestsExportList(serial_number?: string, connect?: string, category?: string, slug?: string, start_date?: string, end_date?: string, stages?: string, exclude_stages?: string, consignee__auto_id?: string, consignee__city?: string, consignee__state?: string, consignee__pincode?: string, task_id?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyClientRequestsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyClientRequestsExportList(serial_number, connect, category, slug, start_date, end_date, stages, exclude_stages, consignee__auto_id, consignee__city, consignee__state, consignee__pincode, task_id, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyClientRequestsExportList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {string} [serial_number] serial_number
         * @param {string} [connect] connect
         * @param {string} [category] category
         * @param {string} [slug] slug
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [stages] stages
         * @param {string} [exclude_stages] exclude_stages
         * @param {string} [consignee__auto_id] consignee__auto_id
         * @param {string} [consignee__city] consignee__city
         * @param {string} [consignee__state] consignee__state
         * @param {string} [consignee__pincode] consignee__pincode
         * @param {string} [task_id] task_id
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyClientRequestsList(search?: string, serial_number?: string, connect?: string, category?: string, slug?: string, start_date?: string, end_date?: string, stages?: string, exclude_stages?: string, consignee__auto_id?: string, consignee__city?: string, consignee__state?: string, consignee__pincode?: string, task_id?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyClientRequestsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyClientRequestsList(search, serial_number, connect, category, slug, start_date, end_date, stages, exclude_stages, consignee__auto_id, consignee__city, consignee__state, consignee__pincode, task_id, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyClientRequestsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this support request.
         * @param {SupportRequest} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyClientRequestsPartialUpdate(id: number, data: SupportRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupportRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyClientRequestsPartialUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyClientRequestsPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this support request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyClientRequestsRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupportRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyClientRequestsRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyClientRequestsRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SupportRequestStage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyClientRequestsStageCreate(data: SupportRequestStage, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupportRequestStage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyClientRequestsStageCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyClientRequestsStageCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [stage] stage
         * @param {string} [client_request] client_request
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyClientRequestsStageList(stage?: string, client_request?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyClientRequestsStageList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyClientRequestsStageList(stage, client_request, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyClientRequestsStageList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this cr stage.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyClientRequestsStageRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupportRequestStage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyClientRequestsStageRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyClientRequestsStageRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this support request.
         * @param {SupportRequest} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyClientRequestsUpdate(id: number, data: SupportRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SupportRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyClientRequestsUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyClientRequestsUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Connect} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyConnectCreate(data: Connect, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Connect>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyConnectCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyConnectCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [is_rental_client] is_rental_client
         * @param {string} [is_individual] is_individual
         * @param {string} [is_supplier] is_supplier
         * @param {string} [company] company
         * @param {string} [gst_number] gst_number
         * @param {string} [is_sales_client] is_sales_client
         * @param {string} [connect] connect
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyConnectExportList(is_rental_client?: string, is_individual?: string, is_supplier?: string, company?: string, gst_number?: string, is_sales_client?: string, connect?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyConnectExportList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyConnectExportList(is_rental_client, is_individual, is_supplier, company, gst_number, is_sales_client, connect, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyConnectExportList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {string} [is_rental_client] is_rental_client
         * @param {string} [is_individual] is_individual
         * @param {string} [is_supplier] is_supplier
         * @param {string} [company] company
         * @param {string} [gst_number] gst_number
         * @param {string} [is_sales_client] is_sales_client
         * @param {string} [connect] connect
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyConnectList(search?: string, is_rental_client?: string, is_individual?: string, is_supplier?: string, company?: string, gst_number?: string, is_sales_client?: string, connect?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyConnectList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyConnectList(search, is_rental_client, is_individual, is_supplier, company, gst_number, is_sales_client, connect, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyConnectList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this connect.
         * @param {Connect} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyConnectPartialUpdate(id: number, data: Connect, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Connect>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyConnectPartialUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyConnectPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this connect.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyConnectPocEmailsRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectPOCEmails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyConnectPocEmailsRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyConnectPocEmailsRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this connect.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyConnectRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Connect>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyConnectRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyConnectRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this connect.
         * @param {Connect} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyConnectUpdate(id: number, data: Connect, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Connect>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyConnectUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyConnectUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DeliveryChalan} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyDeliveryChalanCreate(data: DeliveryChalan, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryChalan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyDeliveryChalanCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyDeliveryChalanCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this delivery chalan.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyDeliveryChalanDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyDeliveryChalanDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyDeliveryChalanDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {DCItems} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyDeliveryChalanItemsCreate(data: DCItems, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DCItems>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyDeliveryChalanItemsCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyDeliveryChalanItemsCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this dc items.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyDeliveryChalanItemsDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyDeliveryChalanItemsDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyDeliveryChalanItemsDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [dc] dc
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyDeliveryChalanItemsList(dc?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyDeliveryChalanItemsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyDeliveryChalanItemsList(dc, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyDeliveryChalanItemsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this dc items.
         * @param {DCItems} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyDeliveryChalanItemsPartialUpdate(id: number, data: DCItems, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DCItems>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyDeliveryChalanItemsPartialUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyDeliveryChalanItemsPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this dc items.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyDeliveryChalanItemsRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DCItems>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyDeliveryChalanItemsRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyDeliveryChalanItemsRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this dc items.
         * @param {DCItems} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyDeliveryChalanItemsUpdate(id: number, data: DCItems, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DCItems>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyDeliveryChalanItemsUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyDeliveryChalanItemsUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {StockyDeliveryChalanListStatus} [status] status
         * @param {string} [ids] ids
         * @param {string} [eway_bill_required] eway_bill_required
         * @param {string} [connect] connect
         * @param {string} [company] company
         * @param {string} [order] order
         * @param {string} [serial] serial
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyDeliveryChalanList(status?: StockyDeliveryChalanListStatus, ids?: string, eway_bill_required?: string, connect?: string, company?: string, order?: string, serial?: string, start_date?: string, end_date?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyDeliveryChalanList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyDeliveryChalanList(status, ids, eway_bill_required, connect, company, order, serial, start_date, end_date, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyDeliveryChalanList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this delivery chalan.
         * @param {DeliveryChalan} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyDeliveryChalanPartialUpdate(id: number, data: DeliveryChalan, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryChalan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyDeliveryChalanPartialUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyDeliveryChalanPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this delivery chalan.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyDeliveryChalanRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryChalan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyDeliveryChalanRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyDeliveryChalanRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this delivery chalan.
         * @param {DeliveryChalan} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyDeliveryChalanUpdate(id: number, data: DeliveryChalan, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeliveryChalan>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyDeliveryChalanUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyDeliveryChalanUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ComputerExport} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyExportComputerCreate(data: ComputerExport, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ComputerExport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyExportComputerCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyExportComputerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {StockyExportDcListStatus} [status] status
         * @param {string} [ids] ids
         * @param {string} [eway_bill_required] eway_bill_required
         * @param {string} [connect] connect
         * @param {string} [company] company
         * @param {string} [order] order
         * @param {string} [serial] serial
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyExportDcList(status?: StockyExportDcListStatus, ids?: string, eway_bill_required?: string, connect?: string, company?: string, order?: string, serial?: string, start_date?: string, end_date?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyExportDcList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyExportDcList(status, ids, eway_bill_required, connect, company, order, serial, start_date, end_date, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyExportDcList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ProductExport} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyExportProductsCreate(data: ProductExport, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductExport>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyExportProductsCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyExportProductsCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [brand__id] brand__id
         * @param {string} [item__id] item__id
         * @param {string} [serial] serial
         * @param {string} [tag__id] tag__id
         * @param {string} [ids] ids
         * @param {string} [processor] processor
         * @param {string} [generation] generation
         * @param {string} [stock_filter] stock_filter
         * @param {string} [current_category] current_category
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [asset_status] asset_status
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyExportProductsList(brand__id?: string, item__id?: string, serial?: string, tag__id?: string, ids?: string, processor?: string, generation?: string, stock_filter?: string, current_category?: string, start_date?: string, end_date?: string, asset_status?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyExportProductsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyExportProductsList(brand__id, item__id, serial, tag__id, ids, processor, generation, stock_filter, current_category, start_date, end_date, asset_status, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyExportProductsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [serial] serial
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyFilterProductSerialList(serial?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyFilterProductSerialList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyFilterProductSerialList(serial, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyFilterProductSerialList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyGreetingsRead(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyGreetingsRead(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyGreetingsRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this group product.
         * @param {GroupItemSave} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyGroupItemsAddCreate(id: number, data: GroupItemSave, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupItemSave>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyGroupItemsAddCreate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyGroupItemsAddCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this group product.
         * @param {string} item_id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyGroupItemsDelete(id: number, item_id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyGroupItemsDelete(id, item_id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyGroupItemsDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this group product.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyGroupItemsList(id: number, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyGroupItemsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyGroupItemsList(id, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyGroupItemsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GroupProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyGroupsCreate(data: GroupProduct, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyGroupsCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyGroupsCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this group product.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyGroupsDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyGroupsDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyGroupsDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [connect] connect
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyGroupsList(connect?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyGroupsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyGroupsList(connect, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyGroupsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this group product.
         * @param {GroupProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyGroupsPartialUpdate(id: number, data: GroupProduct, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyGroupsPartialUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyGroupsPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this group product.
         * @param {GroupProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyGroupsUpdate(id: number, data: GroupProduct, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyGroupsUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyGroupsUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyHsnCodesList(search?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyHsnCodesList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyHsnCodesList(search, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyHsnCodesList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this hsn code.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyHsnCodesRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HSNCode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyHsnCodesRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyHsnCodesRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Inventory} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyInventoryComputerCreate(data: Inventory, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Inventory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyInventoryComputerCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyInventoryComputerCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ItemModel | null} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyItemCreate(data: ItemModel | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ItemModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyItemCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyItemCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {LogisticsDC} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogisticsDcCreate(data: LogisticsDC, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogisticsDC>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogisticsDcCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogisticsDcCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogisticsDcList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyLogisticsDcList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogisticsDcList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogisticsDcList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} serial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogisticsRelatedRead(serial: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Operations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogisticsRelatedRead(serial, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogisticsRelatedRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AssetCurrentCategory} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsAssetCurrentCategoryCreate(data: AssetCurrentCategory, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetCurrentCategory>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsAssetCurrentCategoryCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsAssetCurrentCategoryCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this asset trail.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsAssetTrailDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsAssetTrailDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsAssetTrailDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [serial] serial
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsAssetTrailList(serial?: string, start_date?: string, end_date?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyLogsAssetTrailList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsAssetTrailList(serial, start_date, end_date, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsAssetTrailList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [ops_type] ops_type
         * @param {string} [serial] serial
         * @param {string} [product__item__id] product__item__id
         * @param {string} [order_type] order_type
         * @param {string} [connect] connect
         * @param {string} [tracking_no] tracking_no
         * @param {string} [replacement_asset__status] replacement_asset__status
         * @param {string} [replacement_asset__serial] replacement_asset__serial
         * @param {string} [order] order
         * @param {string} [cost_to] cost_to
         * @param {string} [logistics_partner] logistics_partner
         * @param {string} [pagination] Pagination
         * @param {string} [meta__CONDITION_OF_ASSET] meta__CONDITION_OF_ASSET
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [asset_status] asset_status
         * @param {string} [delivery] delivery
         * @param {string} [exclude_id] exclude_id
         * @param {string} [tracking_number_isnull] tracking_number_isnull
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsExportLogisticsList(ops_type?: string, serial?: string, product__item__id?: string, order_type?: string, connect?: string, tracking_no?: string, replacement_asset__status?: string, replacement_asset__serial?: string, order?: string, cost_to?: string, logistics_partner?: string, pagination?: string, meta__CONDITION_OF_ASSET?: string, start_date?: string, end_date?: string, asset_status?: string, delivery?: string, exclude_id?: string, tracking_number_isnull?: string, search?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyLogsExportLogisticsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsExportLogisticsList(ops_type, serial, product__item__id, order_type, connect, tracking_no, replacement_asset__status, replacement_asset__serial, order, cost_to, logistics_partner, pagination, meta__CONDITION_OF_ASSET, start_date, end_date, asset_status, delivery, exclude_id, tracking_number_isnull, search, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsExportLogisticsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [ops_type] ops_type
         * @param {string} [connect] connect
         * @param {string} [serial] serial
         * @param {string} [use_type] use_type
         * @param {string} [handover] handover
         * @param {string} [generic_product__item__id] generic_product__item__id
         * @param {string} [replacement__status] replacement__status
         * @param {string} [replacement__serial] replacement__serial
         * @param {string} [order] order
         * @param {string} [po__po_number] po__po_number
         * @param {string} [meta__CONDITION_OF_ASSET] meta__CONDITION_OF_ASSET
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [asset_status] asset_status
         * @param {string} [current_category] current_category
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsExportOperationsList(ops_type?: string, connect?: string, serial?: string, use_type?: string, handover?: string, generic_product__item__id?: string, replacement__status?: string, replacement__serial?: string, order?: string, po__po_number?: string, meta__CONDITION_OF_ASSET?: string, start_date?: string, end_date?: string, asset_status?: string, current_category?: string, search?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyLogsExportOperationsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsExportOperationsList(ops_type, connect, serial, use_type, handover, generic_product__item__id, replacement__status, replacement__serial, order, po__po_number, meta__CONDITION_OF_ASSET, start_date, end_date, asset_status, current_category, search, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsExportOperationsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [status] status
         * @param {string} [from_to_user] from_to_user
         * @param {string} [serial] serial
         * @param {string} [ops_type] ops_type
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [asset_status] asset_status
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsExportQualityCheckList(status?: string, from_to_user?: string, serial?: string, ops_type?: string, start_date?: string, end_date?: string, asset_status?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyLogsExportQualityCheckList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsExportQualityCheckList(status, from_to_user, serial, ops_type, start_date, end_date, asset_status, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsExportQualityCheckList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [from_user] from_user
         * @param {string} [serial] serial
         * @param {string} [tag__id] tag__id
         * @param {string} [handover_to] handover_to
         * @param {string} [status] status
         * @param {string} [generic_product__item__id] generic_product__item__id
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [asset_status] asset_status
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsExportServiceReturnList(from_user?: string, serial?: string, tag__id?: string, handover_to?: string, status?: string, generic_product__item__id?: string, start_date?: string, end_date?: string, asset_status?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyLogsExportServiceReturnList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsExportServiceReturnList(from_user, serial, tag__id, handover_to, status, generic_product__item__id, start_date, end_date, asset_status, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsExportServiceReturnList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [serial] serial
         * @param {string} [assigned_to] assigned_to
         * @param {string} [generic_product__item__id] generic_product__item__id
         * @param {string} [fromuser] fromuser
         * @param {string} [tag__id] tag__id
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [asset_status] asset_status
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsExportServiceSentList(serial?: string, assigned_to?: string, generic_product__item__id?: string, fromuser?: string, tag__id?: string, start_date?: string, end_date?: string, asset_status?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyLogsExportServiceSentList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsExportServiceSentList(serial, assigned_to, generic_product__item__id, fromuser, tag__id, start_date, end_date, asset_status, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsExportServiceSentList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {NewLogisticsSerializer} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsLogisticsCreate(data: NewLogisticsSerializer, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewLogisticsSerializer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsLogisticsCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsLogisticsCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this logistics.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsLogisticsDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsLogisticsDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsLogisticsDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {LogisticDelivered} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsLogisticsDeliveryCreate(data: LogisticDelivered, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogisticDelivered>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsLogisticsDeliveryCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsLogisticsDeliveryCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this logistic delivered.
         * @param {LogisticDelivered} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsLogisticsDeliveryPartialUpdate(id: number, data: LogisticDelivered, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogisticDelivered>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsLogisticsDeliveryPartialUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsLogisticsDeliveryPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this logistic delivered.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsLogisticsDeliveryRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogisticDelivered>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsLogisticsDeliveryRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsLogisticsDeliveryRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this logistic delivered.
         * @param {LogisticDelivered} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsLogisticsDeliveryUpdate(id: number, data: LogisticDelivered, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogisticDelivered>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsLogisticsDeliveryUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsLogisticsDeliveryUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [ops_type] ops_type
         * @param {string} [serial] serial
         * @param {string} [product__item__id] product__item__id
         * @param {string} [order_type] order_type
         * @param {string} [connect] connect
         * @param {string} [tracking_no] tracking_no
         * @param {string} [replacement_asset__status] replacement_asset__status
         * @param {string} [replacement_asset__serial] replacement_asset__serial
         * @param {string} [order] order
         * @param {string} [cost_to] cost_to
         * @param {string} [logistics_partner] logistics_partner
         * @param {string} [pagination] Pagination
         * @param {string} [meta__CONDITION_OF_ASSET] meta__CONDITION_OF_ASSET
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [asset_status] asset_status
         * @param {string} [delivery] delivery
         * @param {string} [exclude_id] exclude_id
         * @param {string} [tracking_number_isnull] tracking_number_isnull
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsLogisticsList(ops_type?: string, serial?: string, product__item__id?: string, order_type?: string, connect?: string, tracking_no?: string, replacement_asset__status?: string, replacement_asset__serial?: string, order?: string, cost_to?: string, logistics_partner?: string, pagination?: string, meta__CONDITION_OF_ASSET?: string, start_date?: string, end_date?: string, asset_status?: string, delivery?: string, exclude_id?: string, tracking_number_isnull?: string, search?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyLogsLogisticsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsLogisticsList(ops_type, serial, product__item__id, order_type, connect, tracking_no, replacement_asset__status, replacement_asset__serial, order, cost_to, logistics_partner, pagination, meta__CONDITION_OF_ASSET, start_date, end_date, asset_status, delivery, exclude_id, tracking_number_isnull, search, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsLogisticsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this logistics.
         * @param {NewLogisticsSerializer} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsLogisticsPartialUpdate(id: number, data: NewLogisticsSerializer, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewLogisticsSerializer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsLogisticsPartialUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsLogisticsPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {PickupAttempt} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsLogisticsPickupAttemptCreate(data: PickupAttempt, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PickupAttempt>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsLogisticsPickupAttemptCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsLogisticsPickupAttemptCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this pickup attempt.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsLogisticsPickupAttemptDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsLogisticsPickupAttemptDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsLogisticsPickupAttemptDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [city] city
         * @param {string} [logistics_partner] logistics_partner
         * @param {string} [cost_to] cost_to
         * @param {string} [slug] slug
         * @param {string} [state] state
         * @param {string} [tracking_no] tracking_no
         * @param {string} [amount] amount
         * @param {string} [pincode] pincode
         * @param {string} [logistics] logistics
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsLogisticsPickupAttemptList(city?: string, logistics_partner?: string, cost_to?: string, slug?: string, state?: string, tracking_no?: string, amount?: string, pincode?: string, logistics?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyLogsLogisticsPickupAttemptList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsLogisticsPickupAttemptList(city, logistics_partner, cost_to, slug, state, tracking_no, amount, pincode, logistics, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsLogisticsPickupAttemptList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this pickup attempt.
         * @param {PickupAttempt} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsLogisticsPickupAttemptPartialUpdate(id: number, data: PickupAttempt, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PickupAttempt>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsLogisticsPickupAttemptPartialUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsLogisticsPickupAttemptPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this pickup attempt.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsLogisticsPickupAttemptRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PickupAttempt>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsLogisticsPickupAttemptRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsLogisticsPickupAttemptRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this pickup attempt.
         * @param {PickupAttempt} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsLogisticsPickupAttemptUpdate(id: number, data: PickupAttempt, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PickupAttempt>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsLogisticsPickupAttemptUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsLogisticsPickupAttemptUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this logistics.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsLogisticsRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewLogisticsSerializer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsLogisticsRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsLogisticsRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this logistics.
         * @param {NewLogisticsSerializer} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsLogisticsUpdate(id: number, data: NewLogisticsSerializer, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NewLogisticsSerializer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsLogisticsUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsLogisticsUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this logistics.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsMediaLogisticsRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LogisticsMedia>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsMediaLogisticsRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsMediaLogisticsRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {MigrateFromOperations} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsMigrateOperationsCreate(data: MigrateFromOperations, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MigrateFromOperations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsMigrateOperationsCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsMigrateOperationsCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {MigrateFromQualityCheck} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsMigrateQualityCheckCreate(data: MigrateFromQualityCheck, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MigrateFromQualityCheck>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsMigrateQualityCheckCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsMigrateQualityCheckCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {MigrateReturnFromService} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsMigrateReturnFromServiceCreate(data: MigrateReturnFromService, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MigrateReturnFromService>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsMigrateReturnFromServiceCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsMigrateReturnFromServiceCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ServiceReAssign} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsMigrateServiceReAssignCreate(data: ServiceReAssign, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceReAssign>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsMigrateServiceReAssignCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsMigrateServiceReAssignCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Operations} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsOperationsCreate(data: Operations, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Operations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsOperationsCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsOperationsCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this operations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsOperationsDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsOperationsDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsOperationsDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [ops_type] ops_type
         * @param {string} [connect] connect
         * @param {string} [serial] serial
         * @param {string} [use_type] use_type
         * @param {string} [handover] handover
         * @param {string} [generic_product__item__id] generic_product__item__id
         * @param {string} [replacement__status] replacement__status
         * @param {string} [replacement__serial] replacement__serial
         * @param {string} [order] order
         * @param {string} [po__po_number] po__po_number
         * @param {string} [meta__CONDITION_OF_ASSET] meta__CONDITION_OF_ASSET
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [asset_status] asset_status
         * @param {string} [current_category] current_category
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsOperationsList(ops_type?: string, connect?: string, serial?: string, use_type?: string, handover?: string, generic_product__item__id?: string, replacement__status?: string, replacement__serial?: string, order?: string, po__po_number?: string, meta__CONDITION_OF_ASSET?: string, start_date?: string, end_date?: string, asset_status?: string, current_category?: string, search?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyLogsOperationsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsOperationsList(ops_type, connect, serial, use_type, handover, generic_product__item__id, replacement__status, replacement__serial, order, po__po_number, meta__CONDITION_OF_ASSET, start_date, end_date, asset_status, current_category, search, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsOperationsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this operations.
         * @param {Operations} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsOperationsPartialUpdate(id: number, data: Operations, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Operations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsOperationsPartialUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsOperationsPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this operations.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsOperationsRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Operations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsOperationsRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsOperationsRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this operations.
         * @param {Operations} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsOperationsUpdate(id: number, data: Operations, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Operations>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsOperationsUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsOperationsUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {QualityCheck} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsQualityCheckCreate(data: QualityCheck, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QualityCheck>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsQualityCheckCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsQualityCheckCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this quality check.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsQualityCheckDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsQualityCheckDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsQualityCheckDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [status] status
         * @param {string} [from_to_user] from_to_user
         * @param {string} [serial] serial
         * @param {string} [ops_type] ops_type
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [asset_status] asset_status
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsQualityCheckList(status?: string, from_to_user?: string, serial?: string, ops_type?: string, start_date?: string, end_date?: string, asset_status?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyLogsQualityCheckList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsQualityCheckList(status, from_to_user, serial, ops_type, start_date, end_date, asset_status, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsQualityCheckList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this quality check.
         * @param {QualityCheck} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsQualityCheckPartialUpdate(id: number, data: QualityCheck, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QualityCheck>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsQualityCheckPartialUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsQualityCheckPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this quality check.
         * @param {QualityCheck} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsQualityCheckUpdate(id: number, data: QualityCheck, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QualityCheck>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsQualityCheckUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsQualityCheckUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this replacement asset.
         * @param {ReplacementAsset | null} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsReplacementAssetPartialUpdate(id: number, data: ReplacementAsset | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReplacementAsset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsReplacementAssetPartialUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsReplacementAssetPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this replacement asset.
         * @param {ReplacementAsset | null} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsReplacementAssetUpdate(id: number, data: ReplacementAsset | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReplacementAsset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsReplacementAssetUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsReplacementAssetUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SentService} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsSentServiceCreate(data: SentService, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SentService>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsSentServiceCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsSentServiceCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this sent to service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsSentServiceDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsSentServiceDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsSentServiceDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [serial] serial
         * @param {string} [assigned_to] assigned_to
         * @param {string} [generic_product__item__id] generic_product__item__id
         * @param {string} [fromuser] fromuser
         * @param {string} [tag__id] tag__id
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [asset_status] asset_status
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsSentServiceList(serial?: string, assigned_to?: string, generic_product__item__id?: string, fromuser?: string, tag__id?: string, start_date?: string, end_date?: string, asset_status?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyLogsSentServiceList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsSentServiceList(serial, assigned_to, generic_product__item__id, fromuser, tag__id, start_date, end_date, asset_status, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsSentServiceList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ReturnFromService} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsServiceReturnCreate(data: ReturnFromService, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReturnFromService>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsServiceReturnCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsServiceReturnCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this return from service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsServiceReturnDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsServiceReturnDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsServiceReturnDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [from_user] from_user
         * @param {string} [serial] serial
         * @param {string} [tag__id] tag__id
         * @param {string} [handover_to] handover_to
         * @param {string} [status] status
         * @param {string} [generic_product__item__id] generic_product__item__id
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [asset_status] asset_status
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyLogsServiceReturnList(from_user?: string, serial?: string, tag__id?: string, handover_to?: string, status?: string, generic_product__item__id?: string, start_date?: string, end_date?: string, asset_status?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyLogsServiceReturnList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyLogsServiceReturnList(from_user, serial, tag__id, handover_to, status, generic_product__item__id, start_date, end_date, asset_status, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyLogsServiceReturnList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this generic product.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyProductsBiosPasswordRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BiosPassword>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyProductsBiosPasswordRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyProductsBiosPasswordRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GenericProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyProductsCreate(data: GenericProduct, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyProductsCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyProductsCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [brand__id] brand__id
         * @param {string} [item__id] item__id
         * @param {string} [serial] serial
         * @param {string} [tag__id] tag__id
         * @param {string} [ids] ids
         * @param {string} [processor] processor
         * @param {string} [generation] generation
         * @param {string} [stock_filter] stock_filter
         * @param {string} [current_category] current_category
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [asset_status] asset_status
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyProductsList(brand__id?: string, item__id?: string, serial?: string, tag__id?: string, ids?: string, processor?: string, generation?: string, stock_filter?: string, current_category?: string, start_date?: string, end_date?: string, asset_status?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyFilterProductSerialList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyProductsList(brand__id, item__id, serial, tag__id, ids, processor, generation, stock_filter, current_category, start_date, end_date, asset_status, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyProductsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this generic product.
         * @param {GenericProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyProductsPartialUpdate(id: number, data: GenericProduct, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyProductsPartialUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyProductsPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this generic product.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyProductsRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyProductsRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyProductsRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this generic product.
         * @param {GenericProduct} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyProductsUpdate(id: number, data: GenericProduct, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyProductsUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyProductsUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [brand__id] brand__id
         * @param {string} [item__id] item__id
         * @param {string} [serial] serial
         * @param {string} [tag__id] tag__id
         * @param {string} [ids] ids
         * @param {string} [processor] processor
         * @param {string} [generation] generation
         * @param {string} [stock_filter] stock_filter
         * @param {string} [current_category] current_category
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [asset_status] asset_status
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyPublicProductList(brand__id?: string, item__id?: string, serial?: string, tag__id?: string, ids?: string, processor?: string, generation?: string, stock_filter?: string, current_category?: string, start_date?: string, end_date?: string, asset_status?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyPublicProductList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyPublicProductList(brand__id, item__id, serial, tag__id, ids, processor, generation, stock_filter, current_category, start_date, end_date, asset_status, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyPublicProductList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} key 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyPublicProductRead(key: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyPublicProductRead(key, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyPublicProductRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} serial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyPublicProductSerialRead(serial: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SlimProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyPublicProductSerialRead(serial, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyPublicProductSerialRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this connect.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyPublicTicketConnectRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SlimConnect>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyPublicTicketConnectRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyPublicTicketConnectRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Quote} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyQuoteCreate(data: Quote, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Quote>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyQuoteCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyQuoteCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [user] user
         * @param {string} [qr] qr
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyQuoteList(user?: string, qr?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyQuoteList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyQuoteList(user, qr, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyQuoteList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {QuoteRequest} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyQuoteRequestCreate(data: QuoteRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuoteRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyQuoteRequestCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyQuoteRequestCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [request_type] request_type
         * @param {string} [lead] lead
         * @param {string} [connect] connect
         * @param {string} [stage] stage
         * @param {string} [user] user
         * @param {string} [lead_user] lead_user
         * @param {string} [is_group] is_group
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyQuoteRequestList(request_type?: string, lead?: string, connect?: string, stage?: string, user?: string, lead_user?: string, is_group?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyQuoteRequestList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyQuoteRequestList(request_type, lead, connect, stage, user, lead_user, is_group, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyQuoteRequestList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this quote request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyQuoteRequestRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuoteRequest>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyQuoteRequestRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyQuoteRequestRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {QuoteRequestStage} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyQuoteRequestStageCreate(data: QuoteRequestStage, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuoteRequestStage>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyQuoteRequestStageCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyQuoteRequestStageCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [qr] qr
         * @param {string} [stage] stage
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyQuoteRequestStageList(qr?: string, stage?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyQuoteRequestStageList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyQuoteRequestStageList(qr, stage, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyQuoteRequestStageList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyReportsMasterList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyReportsMasterList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyReportsMasterList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyReportsMasterList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [current_category] current_category
         * @param {string} [fields] fields
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyReportsOperationsModelWiseQtyList(current_category?: string, fields?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyReportsOperationsModelWiseQtyList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyReportsOperationsModelWiseQtyList(current_category, fields, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyReportsOperationsModelWiseQtyList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [fields] fields
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyReportsQualityCheckModelWiseQtyList(fields?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyReportsOperationsModelWiseQtyList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyReportsQualityCheckModelWiseQtyList(fields, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyReportsQualityCheckModelWiseQtyList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [current_category] current_category
         * @param {string} [fields] fields
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyReportsServiceModelWiseQtyList(current_category?: string, fields?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyReportsServiceModelWiseQtyList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyReportsServiceModelWiseQtyList(current_category, fields, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyReportsServiceModelWiseQtyList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SavedFilter} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockySavedFilterCreate(data: SavedFilter, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SavedFilter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockySavedFilterCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockySavedFilterCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this saved filter model.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockySavedFilterDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockySavedFilterDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockySavedFilterDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [category] category
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockySavedFilterList(category?: string, search?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockySavedFilterList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockySavedFilterList(category, search, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockySavedFilterList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this saved filter model.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockySavedFilterRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SavedFilter>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockySavedFilterRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockySavedFilterRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockySearchCategoryList(search?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockySearchCategoryList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockySearchCategoryList(search, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockySearchCategoryList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockySearchConnectList(search?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyConnectList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockySearchConnectList(search, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockySearchConnectList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this connect.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockySearchConnectRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Connect>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockySearchConnectRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockySearchConnectRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockySearchItemList(search?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockySearchItemList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockySearchItemList(search, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockySearchItemList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [category] category
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockySearchTagList(category?: string, search?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockySearchTagList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockySearchTagList(category, search, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockySearchTagList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {string} [email] email
         * @param {string} [role] role
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockySearchUserList(search?: string, email?: string, role?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockySearchUserList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockySearchUserList(search, email, role, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockySearchUserList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} task_id 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockySubtasksListList(task_id: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockySubtasksListList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockySubtasksListList(task_id, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockySubtasksListList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Tag} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyTagsCreate(data: Tag, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tag>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyTagsCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyTagsCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {TaskThread} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyTaskCommentsCreate(data: TaskThread, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskThread>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyTaskCommentsCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyTaskCommentsCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this thread.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyTaskCommentsDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyTaskCommentsDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyTaskCommentsDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyTaskCommentsList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyTaskCommentsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyTaskCommentsList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyTaskCommentsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this thread.
         * @param {TaskThread} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyTaskCommentsPartialUpdate(id: number, data: TaskThread, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskThread>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyTaskCommentsPartialUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyTaskCommentsPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this thread.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyTaskCommentsRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskThread>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyTaskCommentsRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyTaskCommentsRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this thread.
         * @param {TaskThread} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyTaskCommentsUpdate(id: number, data: TaskThread, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TaskThread>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyTaskCommentsUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyTaskCommentsUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Task} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyTasksCreate(data: Task, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Task>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyTasksCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyTasksCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this task.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyTasksDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyTasksDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyTasksDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [client_request] client_request
         * @param {string} [status] status
         * @param {string} [assigned] assigned
         * @param {string} [client] client
         * @param {string} [start_date] start_date
         * @param {string} [end_date] end_date
         * @param {string} [status_exclude] status_exclude
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyTasksList(client_request?: string, status?: string, assigned?: string, client?: string, start_date?: string, end_date?: string, status_exclude?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockySubtasksListList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyTasksList(client_request, status, assigned, client, start_date, end_date, status_exclude, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyTasksList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this task.
         * @param {Task} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyTasksPartialUpdate(id: number, data: Task, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Task>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyTasksPartialUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyTasksPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this task.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyTasksRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Task>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyTasksRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyTasksRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this task.
         * @param {Task} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyTasksUpdate(id: number, data: Task, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Task>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyTasksUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyTasksUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyTemplateContentRead(slug: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Template>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyTemplateContentRead(slug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyTemplateContentRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [slug] slug
         * @param {string} [target__in] target__in
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyTemplatesList(slug?: string, target__in?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockyTemplatesList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyTemplatesList(slug, target__in, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyTemplatesList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GroupFileUpload} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyUploadGroupCreate(data: GroupFileUpload, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GroupFileUpload>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyUploadGroupCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyUploadGroupCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stockyUuidRead(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenerateUUID>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stockyUuidRead(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['StockyApi.stockyUuidRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * StockyApi - factory interface
 * @export
 */
export const StockyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StockyApiFp(configuration)
    return {
        /**
         * 
         * @param {StockyApiStockyAttendanceCheckInDayReportReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyAttendanceCheckInDayReportRead(requestParameters: StockyApiStockyAttendanceCheckInDayReportReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.stockyAttendanceCheckInDayReportRead(requestParameters.date, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyAttendanceCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyAttendanceCreate(requestParameters: StockyApiStockyAttendanceCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Attendance> {
            return localVarFp.stockyAttendanceCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyAttendanceListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyAttendanceList(requestParameters: StockyApiStockyAttendanceListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockyAttendanceList200Response> {
            return localVarFp.stockyAttendanceList(requestParameters.user, requestParameters.created_at__month, requestParameters.created_at__year, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyAttendanceReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyAttendanceRead(requestParameters: StockyApiStockyAttendanceReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<Attendance> {
            return localVarFp.stockyAttendanceRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyAttendanceReportUserMonthListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyAttendanceReportUserMonthList(requestParameters: StockyApiStockyAttendanceReportUserMonthListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockyAttendanceReportUserMonthList200Response> {
            return localVarFp.stockyAttendanceReportUserMonthList(requestParameters.user, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyAttendanceStatusRead(options?: RawAxiosRequestConfig): AxiosPromise<AttendanceCheckIn> {
            return localVarFp.stockyAttendanceStatusRead(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyBrandCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyBrandCreate(requestParameters: StockyApiStockyBrandCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Brand> {
            return localVarFp.stockyBrandCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyBrandListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyBrandList(requestParameters: StockyApiStockyBrandListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockyBrandList200Response> {
            return localVarFp.stockyBrandList(requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyBrandReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyBrandRead(requestParameters: StockyApiStockyBrandReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<Brand> {
            return localVarFp.stockyBrandRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyCategoryCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyCategoryCreate(requestParameters: StockyApiStockyCategoryCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Category> {
            return localVarFp.stockyCategoryCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyClientRequestCommentsCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyClientRequestCommentsCreate(requestParameters: StockyApiStockyClientRequestCommentsCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ClientThread> {
            return localVarFp.stockyClientRequestCommentsCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyClientRequestCommentsDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyClientRequestCommentsDelete(requestParameters: StockyApiStockyClientRequestCommentsDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.stockyClientRequestCommentsDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyClientRequestCommentsListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyClientRequestCommentsList(requestParameters: StockyApiStockyClientRequestCommentsListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockyClientRequestCommentsList200Response> {
            return localVarFp.stockyClientRequestCommentsList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyClientRequestCommentsPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyClientRequestCommentsPartialUpdate(requestParameters: StockyApiStockyClientRequestCommentsPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ClientThread> {
            return localVarFp.stockyClientRequestCommentsPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyClientRequestCommentsReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyClientRequestCommentsRead(requestParameters: StockyApiStockyClientRequestCommentsReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<ClientThread> {
            return localVarFp.stockyClientRequestCommentsRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyClientRequestCommentsUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyClientRequestCommentsUpdate(requestParameters: StockyApiStockyClientRequestCommentsUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ClientThread> {
            return localVarFp.stockyClientRequestCommentsUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyClientRequestsCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyClientRequestsCreate(requestParameters: StockyApiStockyClientRequestsCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<SupportRequest> {
            return localVarFp.stockyClientRequestsCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyClientRequestsDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyClientRequestsDelete(requestParameters: StockyApiStockyClientRequestsDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.stockyClientRequestsDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyClientRequestsExportListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyClientRequestsExportList(requestParameters: StockyApiStockyClientRequestsExportListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockyClientRequestsList200Response> {
            return localVarFp.stockyClientRequestsExportList(requestParameters.serial_number, requestParameters.connect, requestParameters.category, requestParameters.slug, requestParameters.start_date, requestParameters.end_date, requestParameters.stages, requestParameters.exclude_stages, requestParameters.consignee__auto_id, requestParameters.consignee__city, requestParameters.consignee__state, requestParameters.consignee__pincode, requestParameters.task_id, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyClientRequestsListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyClientRequestsList(requestParameters: StockyApiStockyClientRequestsListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockyClientRequestsList200Response> {
            return localVarFp.stockyClientRequestsList(requestParameters.search, requestParameters.serial_number, requestParameters.connect, requestParameters.category, requestParameters.slug, requestParameters.start_date, requestParameters.end_date, requestParameters.stages, requestParameters.exclude_stages, requestParameters.consignee__auto_id, requestParameters.consignee__city, requestParameters.consignee__state, requestParameters.consignee__pincode, requestParameters.task_id, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyClientRequestsPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyClientRequestsPartialUpdate(requestParameters: StockyApiStockyClientRequestsPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<SupportRequest> {
            return localVarFp.stockyClientRequestsPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyClientRequestsReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyClientRequestsRead(requestParameters: StockyApiStockyClientRequestsReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<SupportRequest> {
            return localVarFp.stockyClientRequestsRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyClientRequestsStageCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyClientRequestsStageCreate(requestParameters: StockyApiStockyClientRequestsStageCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<SupportRequestStage> {
            return localVarFp.stockyClientRequestsStageCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyClientRequestsStageListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyClientRequestsStageList(requestParameters: StockyApiStockyClientRequestsStageListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockyClientRequestsStageList200Response> {
            return localVarFp.stockyClientRequestsStageList(requestParameters.stage, requestParameters.client_request, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyClientRequestsStageReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyClientRequestsStageRead(requestParameters: StockyApiStockyClientRequestsStageReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<SupportRequestStage> {
            return localVarFp.stockyClientRequestsStageRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyClientRequestsUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyClientRequestsUpdate(requestParameters: StockyApiStockyClientRequestsUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<SupportRequest> {
            return localVarFp.stockyClientRequestsUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyConnectCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyConnectCreate(requestParameters: StockyApiStockyConnectCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Connect> {
            return localVarFp.stockyConnectCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyConnectExportListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyConnectExportList(requestParameters: StockyApiStockyConnectExportListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockyConnectExportList200Response> {
            return localVarFp.stockyConnectExportList(requestParameters.is_rental_client, requestParameters.is_individual, requestParameters.is_supplier, requestParameters.company, requestParameters.gst_number, requestParameters.is_sales_client, requestParameters.connect, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyConnectListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyConnectList(requestParameters: StockyApiStockyConnectListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockyConnectList200Response> {
            return localVarFp.stockyConnectList(requestParameters.search, requestParameters.is_rental_client, requestParameters.is_individual, requestParameters.is_supplier, requestParameters.company, requestParameters.gst_number, requestParameters.is_sales_client, requestParameters.connect, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyConnectPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyConnectPartialUpdate(requestParameters: StockyApiStockyConnectPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Connect> {
            return localVarFp.stockyConnectPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyConnectPocEmailsReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyConnectPocEmailsRead(requestParameters: StockyApiStockyConnectPocEmailsReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<ConnectPOCEmails> {
            return localVarFp.stockyConnectPocEmailsRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyConnectReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyConnectRead(requestParameters: StockyApiStockyConnectReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<Connect> {
            return localVarFp.stockyConnectRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyConnectUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyConnectUpdate(requestParameters: StockyApiStockyConnectUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Connect> {
            return localVarFp.stockyConnectUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyDeliveryChalanCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyDeliveryChalanCreate(requestParameters: StockyApiStockyDeliveryChalanCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeliveryChalan> {
            return localVarFp.stockyDeliveryChalanCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyDeliveryChalanDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyDeliveryChalanDelete(requestParameters: StockyApiStockyDeliveryChalanDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.stockyDeliveryChalanDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyDeliveryChalanItemsCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyDeliveryChalanItemsCreate(requestParameters: StockyApiStockyDeliveryChalanItemsCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<DCItems> {
            return localVarFp.stockyDeliveryChalanItemsCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyDeliveryChalanItemsDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyDeliveryChalanItemsDelete(requestParameters: StockyApiStockyDeliveryChalanItemsDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.stockyDeliveryChalanItemsDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyDeliveryChalanItemsListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyDeliveryChalanItemsList(requestParameters: StockyApiStockyDeliveryChalanItemsListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockyDeliveryChalanItemsList200Response> {
            return localVarFp.stockyDeliveryChalanItemsList(requestParameters.dc, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyDeliveryChalanItemsPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyDeliveryChalanItemsPartialUpdate(requestParameters: StockyApiStockyDeliveryChalanItemsPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<DCItems> {
            return localVarFp.stockyDeliveryChalanItemsPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyDeliveryChalanItemsReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyDeliveryChalanItemsRead(requestParameters: StockyApiStockyDeliveryChalanItemsReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<DCItems> {
            return localVarFp.stockyDeliveryChalanItemsRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyDeliveryChalanItemsUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyDeliveryChalanItemsUpdate(requestParameters: StockyApiStockyDeliveryChalanItemsUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<DCItems> {
            return localVarFp.stockyDeliveryChalanItemsUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyDeliveryChalanListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyDeliveryChalanList(requestParameters: StockyApiStockyDeliveryChalanListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockyDeliveryChalanList200Response> {
            return localVarFp.stockyDeliveryChalanList(requestParameters.status, requestParameters.ids, requestParameters.eway_bill_required, requestParameters.connect, requestParameters.company, requestParameters.order, requestParameters.serial, requestParameters.start_date, requestParameters.end_date, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyDeliveryChalanPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyDeliveryChalanPartialUpdate(requestParameters: StockyApiStockyDeliveryChalanPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeliveryChalan> {
            return localVarFp.stockyDeliveryChalanPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyDeliveryChalanReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyDeliveryChalanRead(requestParameters: StockyApiStockyDeliveryChalanReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeliveryChalan> {
            return localVarFp.stockyDeliveryChalanRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyDeliveryChalanUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyDeliveryChalanUpdate(requestParameters: StockyApiStockyDeliveryChalanUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<DeliveryChalan> {
            return localVarFp.stockyDeliveryChalanUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyExportComputerCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyExportComputerCreate(requestParameters: StockyApiStockyExportComputerCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ComputerExport> {
            return localVarFp.stockyExportComputerCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyExportDcListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyExportDcList(requestParameters: StockyApiStockyExportDcListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockyExportDcList200Response> {
            return localVarFp.stockyExportDcList(requestParameters.status, requestParameters.ids, requestParameters.eway_bill_required, requestParameters.connect, requestParameters.company, requestParameters.order, requestParameters.serial, requestParameters.start_date, requestParameters.end_date, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyExportProductsCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyExportProductsCreate(requestParameters: StockyApiStockyExportProductsCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ProductExport> {
            return localVarFp.stockyExportProductsCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyExportProductsListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyExportProductsList(requestParameters: StockyApiStockyExportProductsListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockyExportProductsList200Response> {
            return localVarFp.stockyExportProductsList(requestParameters.brand__id, requestParameters.item__id, requestParameters.serial, requestParameters.tag__id, requestParameters.ids, requestParameters.processor, requestParameters.generation, requestParameters.stock_filter, requestParameters.current_category, requestParameters.start_date, requestParameters.end_date, requestParameters.asset_status, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyFilterProductSerialListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyFilterProductSerialList(requestParameters: StockyApiStockyFilterProductSerialListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockyFilterProductSerialList200Response> {
            return localVarFp.stockyFilterProductSerialList(requestParameters.serial, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyGreetingsRead(options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.stockyGreetingsRead(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyGroupItemsAddCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyGroupItemsAddCreate(requestParameters: StockyApiStockyGroupItemsAddCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<GroupItemSave> {
            return localVarFp.stockyGroupItemsAddCreate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyGroupItemsDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyGroupItemsDelete(requestParameters: StockyApiStockyGroupItemsDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.stockyGroupItemsDelete(requestParameters.id, requestParameters.item_id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyGroupItemsListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyGroupItemsList(requestParameters: StockyApiStockyGroupItemsListRequest, options?: RawAxiosRequestConfig): AxiosPromise<StockyGroupItemsList200Response> {
            return localVarFp.stockyGroupItemsList(requestParameters.id, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyGroupsCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyGroupsCreate(requestParameters: StockyApiStockyGroupsCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<GroupProduct> {
            return localVarFp.stockyGroupsCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyGroupsDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyGroupsDelete(requestParameters: StockyApiStockyGroupsDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.stockyGroupsDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyGroupsListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyGroupsList(requestParameters: StockyApiStockyGroupsListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockyGroupsList200Response> {
            return localVarFp.stockyGroupsList(requestParameters.connect, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyGroupsPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyGroupsPartialUpdate(requestParameters: StockyApiStockyGroupsPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<GroupProduct> {
            return localVarFp.stockyGroupsPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyGroupsUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyGroupsUpdate(requestParameters: StockyApiStockyGroupsUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<GroupProduct> {
            return localVarFp.stockyGroupsUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyHsnCodesListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyHsnCodesList(requestParameters: StockyApiStockyHsnCodesListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockyHsnCodesList200Response> {
            return localVarFp.stockyHsnCodesList(requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyHsnCodesReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyHsnCodesRead(requestParameters: StockyApiStockyHsnCodesReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<HSNCode> {
            return localVarFp.stockyHsnCodesRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyInventoryComputerCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyInventoryComputerCreate(requestParameters: StockyApiStockyInventoryComputerCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Inventory> {
            return localVarFp.stockyInventoryComputerCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyItemCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyItemCreate(requestParameters: StockyApiStockyItemCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ItemModel> {
            return localVarFp.stockyItemCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogisticsDcCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogisticsDcCreate(requestParameters: StockyApiStockyLogisticsDcCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<LogisticsDC> {
            return localVarFp.stockyLogisticsDcCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogisticsDcListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogisticsDcList(requestParameters: StockyApiStockyLogisticsDcListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockyLogisticsDcList200Response> {
            return localVarFp.stockyLogisticsDcList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogisticsRelatedReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogisticsRelatedRead(requestParameters: StockyApiStockyLogisticsRelatedReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<Operations> {
            return localVarFp.stockyLogisticsRelatedRead(requestParameters.serial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsAssetCurrentCategoryCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsAssetCurrentCategoryCreate(requestParameters: StockyApiStockyLogsAssetCurrentCategoryCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssetCurrentCategory> {
            return localVarFp.stockyLogsAssetCurrentCategoryCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsAssetTrailDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsAssetTrailDelete(requestParameters: StockyApiStockyLogsAssetTrailDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.stockyLogsAssetTrailDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsAssetTrailListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsAssetTrailList(requestParameters: StockyApiStockyLogsAssetTrailListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockyLogsAssetTrailList200Response> {
            return localVarFp.stockyLogsAssetTrailList(requestParameters.serial, requestParameters.start_date, requestParameters.end_date, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsExportLogisticsListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsExportLogisticsList(requestParameters: StockyApiStockyLogsExportLogisticsListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockyLogsExportLogisticsList200Response> {
            return localVarFp.stockyLogsExportLogisticsList(requestParameters.ops_type, requestParameters.serial, requestParameters.product__item__id, requestParameters.order_type, requestParameters.connect, requestParameters.tracking_no, requestParameters.replacement_asset__status, requestParameters.replacement_asset__serial, requestParameters.order, requestParameters.cost_to, requestParameters.logistics_partner, requestParameters.pagination, requestParameters.meta__CONDITION_OF_ASSET, requestParameters.start_date, requestParameters.end_date, requestParameters.asset_status, requestParameters.delivery, requestParameters.exclude_id, requestParameters.tracking_number_isnull, requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsExportOperationsListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsExportOperationsList(requestParameters: StockyApiStockyLogsExportOperationsListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockyLogsExportOperationsList200Response> {
            return localVarFp.stockyLogsExportOperationsList(requestParameters.ops_type, requestParameters.connect, requestParameters.serial, requestParameters.use_type, requestParameters.handover, requestParameters.generic_product__item__id, requestParameters.replacement__status, requestParameters.replacement__serial, requestParameters.order, requestParameters.po__po_number, requestParameters.meta__CONDITION_OF_ASSET, requestParameters.start_date, requestParameters.end_date, requestParameters.asset_status, requestParameters.current_category, requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsExportQualityCheckListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsExportQualityCheckList(requestParameters: StockyApiStockyLogsExportQualityCheckListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockyLogsExportQualityCheckList200Response> {
            return localVarFp.stockyLogsExportQualityCheckList(requestParameters.status, requestParameters.from_to_user, requestParameters.serial, requestParameters.ops_type, requestParameters.start_date, requestParameters.end_date, requestParameters.asset_status, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsExportServiceReturnListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsExportServiceReturnList(requestParameters: StockyApiStockyLogsExportServiceReturnListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockyLogsExportServiceReturnList200Response> {
            return localVarFp.stockyLogsExportServiceReturnList(requestParameters.from_user, requestParameters.serial, requestParameters.tag__id, requestParameters.handover_to, requestParameters.status, requestParameters.generic_product__item__id, requestParameters.start_date, requestParameters.end_date, requestParameters.asset_status, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsExportServiceSentListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsExportServiceSentList(requestParameters: StockyApiStockyLogsExportServiceSentListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockyLogsExportServiceSentList200Response> {
            return localVarFp.stockyLogsExportServiceSentList(requestParameters.serial, requestParameters.assigned_to, requestParameters.generic_product__item__id, requestParameters.fromuser, requestParameters.tag__id, requestParameters.start_date, requestParameters.end_date, requestParameters.asset_status, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsLogisticsCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsLogisticsCreate(requestParameters: StockyApiStockyLogsLogisticsCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<NewLogisticsSerializer> {
            return localVarFp.stockyLogsLogisticsCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsLogisticsDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsLogisticsDelete(requestParameters: StockyApiStockyLogsLogisticsDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.stockyLogsLogisticsDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsLogisticsDeliveryCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsLogisticsDeliveryCreate(requestParameters: StockyApiStockyLogsLogisticsDeliveryCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<LogisticDelivered> {
            return localVarFp.stockyLogsLogisticsDeliveryCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsLogisticsDeliveryPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsLogisticsDeliveryPartialUpdate(requestParameters: StockyApiStockyLogsLogisticsDeliveryPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<LogisticDelivered> {
            return localVarFp.stockyLogsLogisticsDeliveryPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsLogisticsDeliveryReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsLogisticsDeliveryRead(requestParameters: StockyApiStockyLogsLogisticsDeliveryReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<LogisticDelivered> {
            return localVarFp.stockyLogsLogisticsDeliveryRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsLogisticsDeliveryUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsLogisticsDeliveryUpdate(requestParameters: StockyApiStockyLogsLogisticsDeliveryUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<LogisticDelivered> {
            return localVarFp.stockyLogsLogisticsDeliveryUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsLogisticsListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsLogisticsList(requestParameters: StockyApiStockyLogsLogisticsListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockyLogsLogisticsList200Response> {
            return localVarFp.stockyLogsLogisticsList(requestParameters.ops_type, requestParameters.serial, requestParameters.product__item__id, requestParameters.order_type, requestParameters.connect, requestParameters.tracking_no, requestParameters.replacement_asset__status, requestParameters.replacement_asset__serial, requestParameters.order, requestParameters.cost_to, requestParameters.logistics_partner, requestParameters.pagination, requestParameters.meta__CONDITION_OF_ASSET, requestParameters.start_date, requestParameters.end_date, requestParameters.asset_status, requestParameters.delivery, requestParameters.exclude_id, requestParameters.tracking_number_isnull, requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsLogisticsPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsLogisticsPartialUpdate(requestParameters: StockyApiStockyLogsLogisticsPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<NewLogisticsSerializer> {
            return localVarFp.stockyLogsLogisticsPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsLogisticsPickupAttemptCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsLogisticsPickupAttemptCreate(requestParameters: StockyApiStockyLogsLogisticsPickupAttemptCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<PickupAttempt> {
            return localVarFp.stockyLogsLogisticsPickupAttemptCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsLogisticsPickupAttemptDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsLogisticsPickupAttemptDelete(requestParameters: StockyApiStockyLogsLogisticsPickupAttemptDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.stockyLogsLogisticsPickupAttemptDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsLogisticsPickupAttemptListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsLogisticsPickupAttemptList(requestParameters: StockyApiStockyLogsLogisticsPickupAttemptListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockyLogsLogisticsPickupAttemptList200Response> {
            return localVarFp.stockyLogsLogisticsPickupAttemptList(requestParameters.city, requestParameters.logistics_partner, requestParameters.cost_to, requestParameters.slug, requestParameters.state, requestParameters.tracking_no, requestParameters.amount, requestParameters.pincode, requestParameters.logistics, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsLogisticsPickupAttemptPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsLogisticsPickupAttemptPartialUpdate(requestParameters: StockyApiStockyLogsLogisticsPickupAttemptPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<PickupAttempt> {
            return localVarFp.stockyLogsLogisticsPickupAttemptPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsLogisticsPickupAttemptReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsLogisticsPickupAttemptRead(requestParameters: StockyApiStockyLogsLogisticsPickupAttemptReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<PickupAttempt> {
            return localVarFp.stockyLogsLogisticsPickupAttemptRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsLogisticsPickupAttemptUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsLogisticsPickupAttemptUpdate(requestParameters: StockyApiStockyLogsLogisticsPickupAttemptUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<PickupAttempt> {
            return localVarFp.stockyLogsLogisticsPickupAttemptUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsLogisticsReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsLogisticsRead(requestParameters: StockyApiStockyLogsLogisticsReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<NewLogisticsSerializer> {
            return localVarFp.stockyLogsLogisticsRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsLogisticsUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsLogisticsUpdate(requestParameters: StockyApiStockyLogsLogisticsUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<NewLogisticsSerializer> {
            return localVarFp.stockyLogsLogisticsUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsMediaLogisticsReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsMediaLogisticsRead(requestParameters: StockyApiStockyLogsMediaLogisticsReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<LogisticsMedia> {
            return localVarFp.stockyLogsMediaLogisticsRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsMigrateOperationsCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsMigrateOperationsCreate(requestParameters: StockyApiStockyLogsMigrateOperationsCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<MigrateFromOperations> {
            return localVarFp.stockyLogsMigrateOperationsCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsMigrateQualityCheckCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsMigrateQualityCheckCreate(requestParameters: StockyApiStockyLogsMigrateQualityCheckCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<MigrateFromQualityCheck> {
            return localVarFp.stockyLogsMigrateQualityCheckCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsMigrateReturnFromServiceCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsMigrateReturnFromServiceCreate(requestParameters: StockyApiStockyLogsMigrateReturnFromServiceCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<MigrateReturnFromService> {
            return localVarFp.stockyLogsMigrateReturnFromServiceCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsMigrateServiceReAssignCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsMigrateServiceReAssignCreate(requestParameters: StockyApiStockyLogsMigrateServiceReAssignCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ServiceReAssign> {
            return localVarFp.stockyLogsMigrateServiceReAssignCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsOperationsCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsOperationsCreate(requestParameters: StockyApiStockyLogsOperationsCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Operations> {
            return localVarFp.stockyLogsOperationsCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsOperationsDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsOperationsDelete(requestParameters: StockyApiStockyLogsOperationsDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.stockyLogsOperationsDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsOperationsListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsOperationsList(requestParameters: StockyApiStockyLogsOperationsListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockyLogsOperationsList200Response> {
            return localVarFp.stockyLogsOperationsList(requestParameters.ops_type, requestParameters.connect, requestParameters.serial, requestParameters.use_type, requestParameters.handover, requestParameters.generic_product__item__id, requestParameters.replacement__status, requestParameters.replacement__serial, requestParameters.order, requestParameters.po__po_number, requestParameters.meta__CONDITION_OF_ASSET, requestParameters.start_date, requestParameters.end_date, requestParameters.asset_status, requestParameters.current_category, requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsOperationsPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsOperationsPartialUpdate(requestParameters: StockyApiStockyLogsOperationsPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Operations> {
            return localVarFp.stockyLogsOperationsPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsOperationsReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsOperationsRead(requestParameters: StockyApiStockyLogsOperationsReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<Operations> {
            return localVarFp.stockyLogsOperationsRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsOperationsUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsOperationsUpdate(requestParameters: StockyApiStockyLogsOperationsUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Operations> {
            return localVarFp.stockyLogsOperationsUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsQualityCheckCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsQualityCheckCreate(requestParameters: StockyApiStockyLogsQualityCheckCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<QualityCheck> {
            return localVarFp.stockyLogsQualityCheckCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsQualityCheckDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsQualityCheckDelete(requestParameters: StockyApiStockyLogsQualityCheckDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.stockyLogsQualityCheckDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsQualityCheckListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsQualityCheckList(requestParameters: StockyApiStockyLogsQualityCheckListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockyLogsQualityCheckList200Response> {
            return localVarFp.stockyLogsQualityCheckList(requestParameters.status, requestParameters.from_to_user, requestParameters.serial, requestParameters.ops_type, requestParameters.start_date, requestParameters.end_date, requestParameters.asset_status, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsQualityCheckPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsQualityCheckPartialUpdate(requestParameters: StockyApiStockyLogsQualityCheckPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<QualityCheck> {
            return localVarFp.stockyLogsQualityCheckPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsQualityCheckUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsQualityCheckUpdate(requestParameters: StockyApiStockyLogsQualityCheckUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<QualityCheck> {
            return localVarFp.stockyLogsQualityCheckUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsReplacementAssetPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsReplacementAssetPartialUpdate(requestParameters: StockyApiStockyLogsReplacementAssetPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ReplacementAsset> {
            return localVarFp.stockyLogsReplacementAssetPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsReplacementAssetUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsReplacementAssetUpdate(requestParameters: StockyApiStockyLogsReplacementAssetUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ReplacementAsset> {
            return localVarFp.stockyLogsReplacementAssetUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsSentServiceCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsSentServiceCreate(requestParameters: StockyApiStockyLogsSentServiceCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<SentService> {
            return localVarFp.stockyLogsSentServiceCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsSentServiceDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsSentServiceDelete(requestParameters: StockyApiStockyLogsSentServiceDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.stockyLogsSentServiceDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsSentServiceListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsSentServiceList(requestParameters: StockyApiStockyLogsSentServiceListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockyLogsSentServiceList200Response> {
            return localVarFp.stockyLogsSentServiceList(requestParameters.serial, requestParameters.assigned_to, requestParameters.generic_product__item__id, requestParameters.fromuser, requestParameters.tag__id, requestParameters.start_date, requestParameters.end_date, requestParameters.asset_status, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsServiceReturnCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsServiceReturnCreate(requestParameters: StockyApiStockyLogsServiceReturnCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ReturnFromService> {
            return localVarFp.stockyLogsServiceReturnCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsServiceReturnDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsServiceReturnDelete(requestParameters: StockyApiStockyLogsServiceReturnDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.stockyLogsServiceReturnDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyLogsServiceReturnListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyLogsServiceReturnList(requestParameters: StockyApiStockyLogsServiceReturnListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockyLogsServiceReturnList200Response> {
            return localVarFp.stockyLogsServiceReturnList(requestParameters.from_user, requestParameters.serial, requestParameters.tag__id, requestParameters.handover_to, requestParameters.status, requestParameters.generic_product__item__id, requestParameters.start_date, requestParameters.end_date, requestParameters.asset_status, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyProductsBiosPasswordReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyProductsBiosPasswordRead(requestParameters: StockyApiStockyProductsBiosPasswordReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<BiosPassword> {
            return localVarFp.stockyProductsBiosPasswordRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyProductsCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyProductsCreate(requestParameters: StockyApiStockyProductsCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<GenericProduct> {
            return localVarFp.stockyProductsCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyProductsListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyProductsList(requestParameters: StockyApiStockyProductsListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockyFilterProductSerialList200Response> {
            return localVarFp.stockyProductsList(requestParameters.brand__id, requestParameters.item__id, requestParameters.serial, requestParameters.tag__id, requestParameters.ids, requestParameters.processor, requestParameters.generation, requestParameters.stock_filter, requestParameters.current_category, requestParameters.start_date, requestParameters.end_date, requestParameters.asset_status, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyProductsPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyProductsPartialUpdate(requestParameters: StockyApiStockyProductsPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<GenericProduct> {
            return localVarFp.stockyProductsPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyProductsReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyProductsRead(requestParameters: StockyApiStockyProductsReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<GenericProduct> {
            return localVarFp.stockyProductsRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyProductsUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyProductsUpdate(requestParameters: StockyApiStockyProductsUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<GenericProduct> {
            return localVarFp.stockyProductsUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyPublicProductListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyPublicProductList(requestParameters: StockyApiStockyPublicProductListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockyPublicProductList200Response> {
            return localVarFp.stockyPublicProductList(requestParameters.brand__id, requestParameters.item__id, requestParameters.serial, requestParameters.tag__id, requestParameters.ids, requestParameters.processor, requestParameters.generation, requestParameters.stock_filter, requestParameters.current_category, requestParameters.start_date, requestParameters.end_date, requestParameters.asset_status, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyPublicProductReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyPublicProductRead(requestParameters: StockyApiStockyPublicProductReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<PublicProduct> {
            return localVarFp.stockyPublicProductRead(requestParameters.key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyPublicProductSerialReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyPublicProductSerialRead(requestParameters: StockyApiStockyPublicProductSerialReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<SlimProduct> {
            return localVarFp.stockyPublicProductSerialRead(requestParameters.serial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyPublicTicketConnectReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyPublicTicketConnectRead(requestParameters: StockyApiStockyPublicTicketConnectReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<SlimConnect> {
            return localVarFp.stockyPublicTicketConnectRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyQuoteCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyQuoteCreate(requestParameters: StockyApiStockyQuoteCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Quote> {
            return localVarFp.stockyQuoteCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyQuoteListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyQuoteList(requestParameters: StockyApiStockyQuoteListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockyQuoteList200Response> {
            return localVarFp.stockyQuoteList(requestParameters.user, requestParameters.qr, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyQuoteRequestCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyQuoteRequestCreate(requestParameters: StockyApiStockyQuoteRequestCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<QuoteRequest> {
            return localVarFp.stockyQuoteRequestCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyQuoteRequestListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyQuoteRequestList(requestParameters: StockyApiStockyQuoteRequestListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockyQuoteRequestList200Response> {
            return localVarFp.stockyQuoteRequestList(requestParameters.request_type, requestParameters.lead, requestParameters.connect, requestParameters.stage, requestParameters.user, requestParameters.lead_user, requestParameters.is_group, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyQuoteRequestReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyQuoteRequestRead(requestParameters: StockyApiStockyQuoteRequestReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<QuoteRequest> {
            return localVarFp.stockyQuoteRequestRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyQuoteRequestStageCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyQuoteRequestStageCreate(requestParameters: StockyApiStockyQuoteRequestStageCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<QuoteRequestStage> {
            return localVarFp.stockyQuoteRequestStageCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyQuoteRequestStageListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyQuoteRequestStageList(requestParameters: StockyApiStockyQuoteRequestStageListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockyQuoteRequestStageList200Response> {
            return localVarFp.stockyQuoteRequestStageList(requestParameters.qr, requestParameters.stage, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyReportsMasterListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyReportsMasterList(requestParameters: StockyApiStockyReportsMasterListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockyReportsMasterList200Response> {
            return localVarFp.stockyReportsMasterList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyReportsOperationsModelWiseQtyListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyReportsOperationsModelWiseQtyList(requestParameters: StockyApiStockyReportsOperationsModelWiseQtyListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockyReportsOperationsModelWiseQtyList200Response> {
            return localVarFp.stockyReportsOperationsModelWiseQtyList(requestParameters.current_category, requestParameters.fields, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyReportsQualityCheckModelWiseQtyListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyReportsQualityCheckModelWiseQtyList(requestParameters: StockyApiStockyReportsQualityCheckModelWiseQtyListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockyReportsOperationsModelWiseQtyList200Response> {
            return localVarFp.stockyReportsQualityCheckModelWiseQtyList(requestParameters.fields, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyReportsServiceModelWiseQtyListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyReportsServiceModelWiseQtyList(requestParameters: StockyApiStockyReportsServiceModelWiseQtyListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockyReportsServiceModelWiseQtyList200Response> {
            return localVarFp.stockyReportsServiceModelWiseQtyList(requestParameters.current_category, requestParameters.fields, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockySavedFilterCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockySavedFilterCreate(requestParameters: StockyApiStockySavedFilterCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<SavedFilter> {
            return localVarFp.stockySavedFilterCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockySavedFilterDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockySavedFilterDelete(requestParameters: StockyApiStockySavedFilterDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.stockySavedFilterDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockySavedFilterListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockySavedFilterList(requestParameters: StockyApiStockySavedFilterListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockySavedFilterList200Response> {
            return localVarFp.stockySavedFilterList(requestParameters.category, requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockySavedFilterReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockySavedFilterRead(requestParameters: StockyApiStockySavedFilterReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<SavedFilter> {
            return localVarFp.stockySavedFilterRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockySearchCategoryListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockySearchCategoryList(requestParameters: StockyApiStockySearchCategoryListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockySearchCategoryList200Response> {
            return localVarFp.stockySearchCategoryList(requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockySearchConnectListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockySearchConnectList(requestParameters: StockyApiStockySearchConnectListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockyConnectList200Response> {
            return localVarFp.stockySearchConnectList(requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockySearchConnectReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockySearchConnectRead(requestParameters: StockyApiStockySearchConnectReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<Connect> {
            return localVarFp.stockySearchConnectRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockySearchItemListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockySearchItemList(requestParameters: StockyApiStockySearchItemListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockySearchItemList200Response> {
            return localVarFp.stockySearchItemList(requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockySearchTagListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockySearchTagList(requestParameters: StockyApiStockySearchTagListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockySearchTagList200Response> {
            return localVarFp.stockySearchTagList(requestParameters.category, requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockySearchUserListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockySearchUserList(requestParameters: StockyApiStockySearchUserListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockySearchUserList200Response> {
            return localVarFp.stockySearchUserList(requestParameters.search, requestParameters.email, requestParameters.role, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockySubtasksListListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockySubtasksListList(requestParameters: StockyApiStockySubtasksListListRequest, options?: RawAxiosRequestConfig): AxiosPromise<StockySubtasksListList200Response> {
            return localVarFp.stockySubtasksListList(requestParameters.task_id, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyTagsCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyTagsCreate(requestParameters: StockyApiStockyTagsCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Tag> {
            return localVarFp.stockyTagsCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyTaskCommentsCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyTaskCommentsCreate(requestParameters: StockyApiStockyTaskCommentsCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<TaskThread> {
            return localVarFp.stockyTaskCommentsCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyTaskCommentsDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyTaskCommentsDelete(requestParameters: StockyApiStockyTaskCommentsDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.stockyTaskCommentsDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyTaskCommentsListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyTaskCommentsList(requestParameters: StockyApiStockyTaskCommentsListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockyTaskCommentsList200Response> {
            return localVarFp.stockyTaskCommentsList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyTaskCommentsPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyTaskCommentsPartialUpdate(requestParameters: StockyApiStockyTaskCommentsPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<TaskThread> {
            return localVarFp.stockyTaskCommentsPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyTaskCommentsReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyTaskCommentsRead(requestParameters: StockyApiStockyTaskCommentsReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<TaskThread> {
            return localVarFp.stockyTaskCommentsRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyTaskCommentsUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyTaskCommentsUpdate(requestParameters: StockyApiStockyTaskCommentsUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<TaskThread> {
            return localVarFp.stockyTaskCommentsUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyTasksCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyTasksCreate(requestParameters: StockyApiStockyTasksCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Task> {
            return localVarFp.stockyTasksCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyTasksDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyTasksDelete(requestParameters: StockyApiStockyTasksDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.stockyTasksDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyTasksListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyTasksList(requestParameters: StockyApiStockyTasksListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockySubtasksListList200Response> {
            return localVarFp.stockyTasksList(requestParameters.client_request, requestParameters.status, requestParameters.assigned, requestParameters.client, requestParameters.start_date, requestParameters.end_date, requestParameters.status_exclude, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyTasksPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyTasksPartialUpdate(requestParameters: StockyApiStockyTasksPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Task> {
            return localVarFp.stockyTasksPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyTasksReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyTasksRead(requestParameters: StockyApiStockyTasksReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<Task> {
            return localVarFp.stockyTasksRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyTasksUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyTasksUpdate(requestParameters: StockyApiStockyTasksUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Task> {
            return localVarFp.stockyTasksUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyTemplateContentReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyTemplateContentRead(requestParameters: StockyApiStockyTemplateContentReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<Template> {
            return localVarFp.stockyTemplateContentRead(requestParameters.slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyTemplatesListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyTemplatesList(requestParameters: StockyApiStockyTemplatesListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<StockyTemplatesList200Response> {
            return localVarFp.stockyTemplatesList(requestParameters.slug, requestParameters.target__in, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StockyApiStockyUploadGroupCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyUploadGroupCreate(requestParameters: StockyApiStockyUploadGroupCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<GroupFileUpload> {
            return localVarFp.stockyUploadGroupCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stockyUuidRead(options?: RawAxiosRequestConfig): AxiosPromise<GenerateUUID> {
            return localVarFp.stockyUuidRead(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for stockyAttendanceCheckInDayReportRead operation in StockyApi.
 * @export
 * @interface StockyApiStockyAttendanceCheckInDayReportReadRequest
 */
export interface StockyApiStockyAttendanceCheckInDayReportReadRequest {
    /**
     * 
     * @type {string}
     * @memberof StockyApiStockyAttendanceCheckInDayReportRead
     */
    readonly date: string
}

/**
 * Request parameters for stockyAttendanceCreate operation in StockyApi.
 * @export
 * @interface StockyApiStockyAttendanceCreateRequest
 */
export interface StockyApiStockyAttendanceCreateRequest {
    /**
     * 
     * @type {Attendance}
     * @memberof StockyApiStockyAttendanceCreate
     */
    readonly data: Attendance
}

/**
 * Request parameters for stockyAttendanceList operation in StockyApi.
 * @export
 * @interface StockyApiStockyAttendanceListRequest
 */
export interface StockyApiStockyAttendanceListRequest {
    /**
     * user
     * @type {string}
     * @memberof StockyApiStockyAttendanceList
     */
    readonly user?: string

    /**
     * created_at__month
     * @type {string}
     * @memberof StockyApiStockyAttendanceList
     */
    readonly created_at__month?: string

    /**
     * created_at__year
     * @type {string}
     * @memberof StockyApiStockyAttendanceList
     */
    readonly created_at__year?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockyAttendanceList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockyAttendanceList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockyAttendanceRead operation in StockyApi.
 * @export
 * @interface StockyApiStockyAttendanceReadRequest
 */
export interface StockyApiStockyAttendanceReadRequest {
    /**
     * A unique integer value identifying this attendance.
     * @type {number}
     * @memberof StockyApiStockyAttendanceRead
     */
    readonly id: number
}

/**
 * Request parameters for stockyAttendanceReportUserMonthList operation in StockyApi.
 * @export
 * @interface StockyApiStockyAttendanceReportUserMonthListRequest
 */
export interface StockyApiStockyAttendanceReportUserMonthListRequest {
    /**
     * user
     * @type {string}
     * @memberof StockyApiStockyAttendanceReportUserMonthList
     */
    readonly user?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockyAttendanceReportUserMonthList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockyAttendanceReportUserMonthList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockyBrandCreate operation in StockyApi.
 * @export
 * @interface StockyApiStockyBrandCreateRequest
 */
export interface StockyApiStockyBrandCreateRequest {
    /**
     * 
     * @type {Brand}
     * @memberof StockyApiStockyBrandCreate
     */
    readonly data: Brand | null
}

/**
 * Request parameters for stockyBrandList operation in StockyApi.
 * @export
 * @interface StockyApiStockyBrandListRequest
 */
export interface StockyApiStockyBrandListRequest {
    /**
     * A search term.
     * @type {string}
     * @memberof StockyApiStockyBrandList
     */
    readonly search?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockyBrandList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockyBrandList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockyBrandRead operation in StockyApi.
 * @export
 * @interface StockyApiStockyBrandReadRequest
 */
export interface StockyApiStockyBrandReadRequest {
    /**
     * A unique integer value identifying this brand.
     * @type {number}
     * @memberof StockyApiStockyBrandRead
     */
    readonly id: number
}

/**
 * Request parameters for stockyCategoryCreate operation in StockyApi.
 * @export
 * @interface StockyApiStockyCategoryCreateRequest
 */
export interface StockyApiStockyCategoryCreateRequest {
    /**
     * 
     * @type {Category}
     * @memberof StockyApiStockyCategoryCreate
     */
    readonly data: Category | null
}

/**
 * Request parameters for stockyClientRequestCommentsCreate operation in StockyApi.
 * @export
 * @interface StockyApiStockyClientRequestCommentsCreateRequest
 */
export interface StockyApiStockyClientRequestCommentsCreateRequest {
    /**
     * 
     * @type {ClientThread}
     * @memberof StockyApiStockyClientRequestCommentsCreate
     */
    readonly data: ClientThread
}

/**
 * Request parameters for stockyClientRequestCommentsDelete operation in StockyApi.
 * @export
 * @interface StockyApiStockyClientRequestCommentsDeleteRequest
 */
export interface StockyApiStockyClientRequestCommentsDeleteRequest {
    /**
     * A unique integer value identifying this client thread.
     * @type {number}
     * @memberof StockyApiStockyClientRequestCommentsDelete
     */
    readonly id: number
}

/**
 * Request parameters for stockyClientRequestCommentsList operation in StockyApi.
 * @export
 * @interface StockyApiStockyClientRequestCommentsListRequest
 */
export interface StockyApiStockyClientRequestCommentsListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockyClientRequestCommentsList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockyClientRequestCommentsList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockyClientRequestCommentsPartialUpdate operation in StockyApi.
 * @export
 * @interface StockyApiStockyClientRequestCommentsPartialUpdateRequest
 */
export interface StockyApiStockyClientRequestCommentsPartialUpdateRequest {
    /**
     * A unique integer value identifying this client thread.
     * @type {number}
     * @memberof StockyApiStockyClientRequestCommentsPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {ClientThread}
     * @memberof StockyApiStockyClientRequestCommentsPartialUpdate
     */
    readonly data: ClientThread
}

/**
 * Request parameters for stockyClientRequestCommentsRead operation in StockyApi.
 * @export
 * @interface StockyApiStockyClientRequestCommentsReadRequest
 */
export interface StockyApiStockyClientRequestCommentsReadRequest {
    /**
     * A unique integer value identifying this client thread.
     * @type {number}
     * @memberof StockyApiStockyClientRequestCommentsRead
     */
    readonly id: number
}

/**
 * Request parameters for stockyClientRequestCommentsUpdate operation in StockyApi.
 * @export
 * @interface StockyApiStockyClientRequestCommentsUpdateRequest
 */
export interface StockyApiStockyClientRequestCommentsUpdateRequest {
    /**
     * A unique integer value identifying this client thread.
     * @type {number}
     * @memberof StockyApiStockyClientRequestCommentsUpdate
     */
    readonly id: number

    /**
     * 
     * @type {ClientThread}
     * @memberof StockyApiStockyClientRequestCommentsUpdate
     */
    readonly data: ClientThread
}

/**
 * Request parameters for stockyClientRequestsCreate operation in StockyApi.
 * @export
 * @interface StockyApiStockyClientRequestsCreateRequest
 */
export interface StockyApiStockyClientRequestsCreateRequest {
    /**
     * 
     * @type {SupportRequest}
     * @memberof StockyApiStockyClientRequestsCreate
     */
    readonly data: SupportRequest
}

/**
 * Request parameters for stockyClientRequestsDelete operation in StockyApi.
 * @export
 * @interface StockyApiStockyClientRequestsDeleteRequest
 */
export interface StockyApiStockyClientRequestsDeleteRequest {
    /**
     * A unique integer value identifying this support request.
     * @type {number}
     * @memberof StockyApiStockyClientRequestsDelete
     */
    readonly id: number
}

/**
 * Request parameters for stockyClientRequestsExportList operation in StockyApi.
 * @export
 * @interface StockyApiStockyClientRequestsExportListRequest
 */
export interface StockyApiStockyClientRequestsExportListRequest {
    /**
     * serial_number
     * @type {string}
     * @memberof StockyApiStockyClientRequestsExportList
     */
    readonly serial_number?: string

    /**
     * connect
     * @type {string}
     * @memberof StockyApiStockyClientRequestsExportList
     */
    readonly connect?: string

    /**
     * category
     * @type {string}
     * @memberof StockyApiStockyClientRequestsExportList
     */
    readonly category?: string

    /**
     * slug
     * @type {string}
     * @memberof StockyApiStockyClientRequestsExportList
     */
    readonly slug?: string

    /**
     * start_date
     * @type {string}
     * @memberof StockyApiStockyClientRequestsExportList
     */
    readonly start_date?: string

    /**
     * end_date
     * @type {string}
     * @memberof StockyApiStockyClientRequestsExportList
     */
    readonly end_date?: string

    /**
     * stages
     * @type {string}
     * @memberof StockyApiStockyClientRequestsExportList
     */
    readonly stages?: string

    /**
     * exclude_stages
     * @type {string}
     * @memberof StockyApiStockyClientRequestsExportList
     */
    readonly exclude_stages?: string

    /**
     * consignee__auto_id
     * @type {string}
     * @memberof StockyApiStockyClientRequestsExportList
     */
    readonly consignee__auto_id?: string

    /**
     * consignee__city
     * @type {string}
     * @memberof StockyApiStockyClientRequestsExportList
     */
    readonly consignee__city?: string

    /**
     * consignee__state
     * @type {string}
     * @memberof StockyApiStockyClientRequestsExportList
     */
    readonly consignee__state?: string

    /**
     * consignee__pincode
     * @type {string}
     * @memberof StockyApiStockyClientRequestsExportList
     */
    readonly consignee__pincode?: string

    /**
     * task_id
     * @type {string}
     * @memberof StockyApiStockyClientRequestsExportList
     */
    readonly task_id?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockyClientRequestsExportList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockyClientRequestsExportList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockyClientRequestsList operation in StockyApi.
 * @export
 * @interface StockyApiStockyClientRequestsListRequest
 */
export interface StockyApiStockyClientRequestsListRequest {
    /**
     * A search term.
     * @type {string}
     * @memberof StockyApiStockyClientRequestsList
     */
    readonly search?: string

    /**
     * serial_number
     * @type {string}
     * @memberof StockyApiStockyClientRequestsList
     */
    readonly serial_number?: string

    /**
     * connect
     * @type {string}
     * @memberof StockyApiStockyClientRequestsList
     */
    readonly connect?: string

    /**
     * category
     * @type {string}
     * @memberof StockyApiStockyClientRequestsList
     */
    readonly category?: string

    /**
     * slug
     * @type {string}
     * @memberof StockyApiStockyClientRequestsList
     */
    readonly slug?: string

    /**
     * start_date
     * @type {string}
     * @memberof StockyApiStockyClientRequestsList
     */
    readonly start_date?: string

    /**
     * end_date
     * @type {string}
     * @memberof StockyApiStockyClientRequestsList
     */
    readonly end_date?: string

    /**
     * stages
     * @type {string}
     * @memberof StockyApiStockyClientRequestsList
     */
    readonly stages?: string

    /**
     * exclude_stages
     * @type {string}
     * @memberof StockyApiStockyClientRequestsList
     */
    readonly exclude_stages?: string

    /**
     * consignee__auto_id
     * @type {string}
     * @memberof StockyApiStockyClientRequestsList
     */
    readonly consignee__auto_id?: string

    /**
     * consignee__city
     * @type {string}
     * @memberof StockyApiStockyClientRequestsList
     */
    readonly consignee__city?: string

    /**
     * consignee__state
     * @type {string}
     * @memberof StockyApiStockyClientRequestsList
     */
    readonly consignee__state?: string

    /**
     * consignee__pincode
     * @type {string}
     * @memberof StockyApiStockyClientRequestsList
     */
    readonly consignee__pincode?: string

    /**
     * task_id
     * @type {string}
     * @memberof StockyApiStockyClientRequestsList
     */
    readonly task_id?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockyClientRequestsList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockyClientRequestsList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockyClientRequestsPartialUpdate operation in StockyApi.
 * @export
 * @interface StockyApiStockyClientRequestsPartialUpdateRequest
 */
export interface StockyApiStockyClientRequestsPartialUpdateRequest {
    /**
     * A unique integer value identifying this support request.
     * @type {number}
     * @memberof StockyApiStockyClientRequestsPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {SupportRequest}
     * @memberof StockyApiStockyClientRequestsPartialUpdate
     */
    readonly data: SupportRequest
}

/**
 * Request parameters for stockyClientRequestsRead operation in StockyApi.
 * @export
 * @interface StockyApiStockyClientRequestsReadRequest
 */
export interface StockyApiStockyClientRequestsReadRequest {
    /**
     * A unique integer value identifying this support request.
     * @type {number}
     * @memberof StockyApiStockyClientRequestsRead
     */
    readonly id: number
}

/**
 * Request parameters for stockyClientRequestsStageCreate operation in StockyApi.
 * @export
 * @interface StockyApiStockyClientRequestsStageCreateRequest
 */
export interface StockyApiStockyClientRequestsStageCreateRequest {
    /**
     * 
     * @type {SupportRequestStage}
     * @memberof StockyApiStockyClientRequestsStageCreate
     */
    readonly data: SupportRequestStage
}

/**
 * Request parameters for stockyClientRequestsStageList operation in StockyApi.
 * @export
 * @interface StockyApiStockyClientRequestsStageListRequest
 */
export interface StockyApiStockyClientRequestsStageListRequest {
    /**
     * stage
     * @type {string}
     * @memberof StockyApiStockyClientRequestsStageList
     */
    readonly stage?: string

    /**
     * client_request
     * @type {string}
     * @memberof StockyApiStockyClientRequestsStageList
     */
    readonly client_request?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockyClientRequestsStageList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockyClientRequestsStageList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockyClientRequestsStageRead operation in StockyApi.
 * @export
 * @interface StockyApiStockyClientRequestsStageReadRequest
 */
export interface StockyApiStockyClientRequestsStageReadRequest {
    /**
     * A unique integer value identifying this cr stage.
     * @type {number}
     * @memberof StockyApiStockyClientRequestsStageRead
     */
    readonly id: number
}

/**
 * Request parameters for stockyClientRequestsUpdate operation in StockyApi.
 * @export
 * @interface StockyApiStockyClientRequestsUpdateRequest
 */
export interface StockyApiStockyClientRequestsUpdateRequest {
    /**
     * A unique integer value identifying this support request.
     * @type {number}
     * @memberof StockyApiStockyClientRequestsUpdate
     */
    readonly id: number

    /**
     * 
     * @type {SupportRequest}
     * @memberof StockyApiStockyClientRequestsUpdate
     */
    readonly data: SupportRequest
}

/**
 * Request parameters for stockyConnectCreate operation in StockyApi.
 * @export
 * @interface StockyApiStockyConnectCreateRequest
 */
export interface StockyApiStockyConnectCreateRequest {
    /**
     * 
     * @type {Connect}
     * @memberof StockyApiStockyConnectCreate
     */
    readonly data: Connect
}

/**
 * Request parameters for stockyConnectExportList operation in StockyApi.
 * @export
 * @interface StockyApiStockyConnectExportListRequest
 */
export interface StockyApiStockyConnectExportListRequest {
    /**
     * is_rental_client
     * @type {string}
     * @memberof StockyApiStockyConnectExportList
     */
    readonly is_rental_client?: string

    /**
     * is_individual
     * @type {string}
     * @memberof StockyApiStockyConnectExportList
     */
    readonly is_individual?: string

    /**
     * is_supplier
     * @type {string}
     * @memberof StockyApiStockyConnectExportList
     */
    readonly is_supplier?: string

    /**
     * company
     * @type {string}
     * @memberof StockyApiStockyConnectExportList
     */
    readonly company?: string

    /**
     * gst_number
     * @type {string}
     * @memberof StockyApiStockyConnectExportList
     */
    readonly gst_number?: string

    /**
     * is_sales_client
     * @type {string}
     * @memberof StockyApiStockyConnectExportList
     */
    readonly is_sales_client?: string

    /**
     * connect
     * @type {string}
     * @memberof StockyApiStockyConnectExportList
     */
    readonly connect?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockyConnectExportList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockyConnectExportList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockyConnectList operation in StockyApi.
 * @export
 * @interface StockyApiStockyConnectListRequest
 */
export interface StockyApiStockyConnectListRequest {
    /**
     * A search term.
     * @type {string}
     * @memberof StockyApiStockyConnectList
     */
    readonly search?: string

    /**
     * is_rental_client
     * @type {string}
     * @memberof StockyApiStockyConnectList
     */
    readonly is_rental_client?: string

    /**
     * is_individual
     * @type {string}
     * @memberof StockyApiStockyConnectList
     */
    readonly is_individual?: string

    /**
     * is_supplier
     * @type {string}
     * @memberof StockyApiStockyConnectList
     */
    readonly is_supplier?: string

    /**
     * company
     * @type {string}
     * @memberof StockyApiStockyConnectList
     */
    readonly company?: string

    /**
     * gst_number
     * @type {string}
     * @memberof StockyApiStockyConnectList
     */
    readonly gst_number?: string

    /**
     * is_sales_client
     * @type {string}
     * @memberof StockyApiStockyConnectList
     */
    readonly is_sales_client?: string

    /**
     * connect
     * @type {string}
     * @memberof StockyApiStockyConnectList
     */
    readonly connect?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockyConnectList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockyConnectList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockyConnectPartialUpdate operation in StockyApi.
 * @export
 * @interface StockyApiStockyConnectPartialUpdateRequest
 */
export interface StockyApiStockyConnectPartialUpdateRequest {
    /**
     * A unique integer value identifying this connect.
     * @type {number}
     * @memberof StockyApiStockyConnectPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Connect}
     * @memberof StockyApiStockyConnectPartialUpdate
     */
    readonly data: Connect
}

/**
 * Request parameters for stockyConnectPocEmailsRead operation in StockyApi.
 * @export
 * @interface StockyApiStockyConnectPocEmailsReadRequest
 */
export interface StockyApiStockyConnectPocEmailsReadRequest {
    /**
     * A unique integer value identifying this connect.
     * @type {number}
     * @memberof StockyApiStockyConnectPocEmailsRead
     */
    readonly id: number
}

/**
 * Request parameters for stockyConnectRead operation in StockyApi.
 * @export
 * @interface StockyApiStockyConnectReadRequest
 */
export interface StockyApiStockyConnectReadRequest {
    /**
     * A unique integer value identifying this connect.
     * @type {number}
     * @memberof StockyApiStockyConnectRead
     */
    readonly id: number
}

/**
 * Request parameters for stockyConnectUpdate operation in StockyApi.
 * @export
 * @interface StockyApiStockyConnectUpdateRequest
 */
export interface StockyApiStockyConnectUpdateRequest {
    /**
     * A unique integer value identifying this connect.
     * @type {number}
     * @memberof StockyApiStockyConnectUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Connect}
     * @memberof StockyApiStockyConnectUpdate
     */
    readonly data: Connect
}

/**
 * Request parameters for stockyDeliveryChalanCreate operation in StockyApi.
 * @export
 * @interface StockyApiStockyDeliveryChalanCreateRequest
 */
export interface StockyApiStockyDeliveryChalanCreateRequest {
    /**
     * 
     * @type {DeliveryChalan}
     * @memberof StockyApiStockyDeliveryChalanCreate
     */
    readonly data: DeliveryChalan
}

/**
 * Request parameters for stockyDeliveryChalanDelete operation in StockyApi.
 * @export
 * @interface StockyApiStockyDeliveryChalanDeleteRequest
 */
export interface StockyApiStockyDeliveryChalanDeleteRequest {
    /**
     * A unique integer value identifying this delivery chalan.
     * @type {number}
     * @memberof StockyApiStockyDeliveryChalanDelete
     */
    readonly id: number
}

/**
 * Request parameters for stockyDeliveryChalanItemsCreate operation in StockyApi.
 * @export
 * @interface StockyApiStockyDeliveryChalanItemsCreateRequest
 */
export interface StockyApiStockyDeliveryChalanItemsCreateRequest {
    /**
     * 
     * @type {DCItems}
     * @memberof StockyApiStockyDeliveryChalanItemsCreate
     */
    readonly data: DCItems
}

/**
 * Request parameters for stockyDeliveryChalanItemsDelete operation in StockyApi.
 * @export
 * @interface StockyApiStockyDeliveryChalanItemsDeleteRequest
 */
export interface StockyApiStockyDeliveryChalanItemsDeleteRequest {
    /**
     * A unique integer value identifying this dc items.
     * @type {number}
     * @memberof StockyApiStockyDeliveryChalanItemsDelete
     */
    readonly id: number
}

/**
 * Request parameters for stockyDeliveryChalanItemsList operation in StockyApi.
 * @export
 * @interface StockyApiStockyDeliveryChalanItemsListRequest
 */
export interface StockyApiStockyDeliveryChalanItemsListRequest {
    /**
     * dc
     * @type {string}
     * @memberof StockyApiStockyDeliveryChalanItemsList
     */
    readonly dc?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockyDeliveryChalanItemsList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockyDeliveryChalanItemsList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockyDeliveryChalanItemsPartialUpdate operation in StockyApi.
 * @export
 * @interface StockyApiStockyDeliveryChalanItemsPartialUpdateRequest
 */
export interface StockyApiStockyDeliveryChalanItemsPartialUpdateRequest {
    /**
     * A unique integer value identifying this dc items.
     * @type {number}
     * @memberof StockyApiStockyDeliveryChalanItemsPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {DCItems}
     * @memberof StockyApiStockyDeliveryChalanItemsPartialUpdate
     */
    readonly data: DCItems
}

/**
 * Request parameters for stockyDeliveryChalanItemsRead operation in StockyApi.
 * @export
 * @interface StockyApiStockyDeliveryChalanItemsReadRequest
 */
export interface StockyApiStockyDeliveryChalanItemsReadRequest {
    /**
     * A unique integer value identifying this dc items.
     * @type {number}
     * @memberof StockyApiStockyDeliveryChalanItemsRead
     */
    readonly id: number
}

/**
 * Request parameters for stockyDeliveryChalanItemsUpdate operation in StockyApi.
 * @export
 * @interface StockyApiStockyDeliveryChalanItemsUpdateRequest
 */
export interface StockyApiStockyDeliveryChalanItemsUpdateRequest {
    /**
     * A unique integer value identifying this dc items.
     * @type {number}
     * @memberof StockyApiStockyDeliveryChalanItemsUpdate
     */
    readonly id: number

    /**
     * 
     * @type {DCItems}
     * @memberof StockyApiStockyDeliveryChalanItemsUpdate
     */
    readonly data: DCItems
}

/**
 * Request parameters for stockyDeliveryChalanList operation in StockyApi.
 * @export
 * @interface StockyApiStockyDeliveryChalanListRequest
 */
export interface StockyApiStockyDeliveryChalanListRequest {
    /**
     * status
     * @type {'0' | '1' | '2'}
     * @memberof StockyApiStockyDeliveryChalanList
     */
    readonly status?: StockyDeliveryChalanListStatus

    /**
     * ids
     * @type {string}
     * @memberof StockyApiStockyDeliveryChalanList
     */
    readonly ids?: string

    /**
     * eway_bill_required
     * @type {string}
     * @memberof StockyApiStockyDeliveryChalanList
     */
    readonly eway_bill_required?: string

    /**
     * connect
     * @type {string}
     * @memberof StockyApiStockyDeliveryChalanList
     */
    readonly connect?: string

    /**
     * company
     * @type {string}
     * @memberof StockyApiStockyDeliveryChalanList
     */
    readonly company?: string

    /**
     * order
     * @type {string}
     * @memberof StockyApiStockyDeliveryChalanList
     */
    readonly order?: string

    /**
     * serial
     * @type {string}
     * @memberof StockyApiStockyDeliveryChalanList
     */
    readonly serial?: string

    /**
     * start_date
     * @type {string}
     * @memberof StockyApiStockyDeliveryChalanList
     */
    readonly start_date?: string

    /**
     * end_date
     * @type {string}
     * @memberof StockyApiStockyDeliveryChalanList
     */
    readonly end_date?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockyDeliveryChalanList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockyDeliveryChalanList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockyDeliveryChalanPartialUpdate operation in StockyApi.
 * @export
 * @interface StockyApiStockyDeliveryChalanPartialUpdateRequest
 */
export interface StockyApiStockyDeliveryChalanPartialUpdateRequest {
    /**
     * A unique integer value identifying this delivery chalan.
     * @type {number}
     * @memberof StockyApiStockyDeliveryChalanPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {DeliveryChalan}
     * @memberof StockyApiStockyDeliveryChalanPartialUpdate
     */
    readonly data: DeliveryChalan
}

/**
 * Request parameters for stockyDeliveryChalanRead operation in StockyApi.
 * @export
 * @interface StockyApiStockyDeliveryChalanReadRequest
 */
export interface StockyApiStockyDeliveryChalanReadRequest {
    /**
     * A unique integer value identifying this delivery chalan.
     * @type {number}
     * @memberof StockyApiStockyDeliveryChalanRead
     */
    readonly id: number
}

/**
 * Request parameters for stockyDeliveryChalanUpdate operation in StockyApi.
 * @export
 * @interface StockyApiStockyDeliveryChalanUpdateRequest
 */
export interface StockyApiStockyDeliveryChalanUpdateRequest {
    /**
     * A unique integer value identifying this delivery chalan.
     * @type {number}
     * @memberof StockyApiStockyDeliveryChalanUpdate
     */
    readonly id: number

    /**
     * 
     * @type {DeliveryChalan}
     * @memberof StockyApiStockyDeliveryChalanUpdate
     */
    readonly data: DeliveryChalan
}

/**
 * Request parameters for stockyExportComputerCreate operation in StockyApi.
 * @export
 * @interface StockyApiStockyExportComputerCreateRequest
 */
export interface StockyApiStockyExportComputerCreateRequest {
    /**
     * 
     * @type {ComputerExport}
     * @memberof StockyApiStockyExportComputerCreate
     */
    readonly data: ComputerExport
}

/**
 * Request parameters for stockyExportDcList operation in StockyApi.
 * @export
 * @interface StockyApiStockyExportDcListRequest
 */
export interface StockyApiStockyExportDcListRequest {
    /**
     * status
     * @type {'0' | '1' | '2'}
     * @memberof StockyApiStockyExportDcList
     */
    readonly status?: StockyExportDcListStatus

    /**
     * ids
     * @type {string}
     * @memberof StockyApiStockyExportDcList
     */
    readonly ids?: string

    /**
     * eway_bill_required
     * @type {string}
     * @memberof StockyApiStockyExportDcList
     */
    readonly eway_bill_required?: string

    /**
     * connect
     * @type {string}
     * @memberof StockyApiStockyExportDcList
     */
    readonly connect?: string

    /**
     * company
     * @type {string}
     * @memberof StockyApiStockyExportDcList
     */
    readonly company?: string

    /**
     * order
     * @type {string}
     * @memberof StockyApiStockyExportDcList
     */
    readonly order?: string

    /**
     * serial
     * @type {string}
     * @memberof StockyApiStockyExportDcList
     */
    readonly serial?: string

    /**
     * start_date
     * @type {string}
     * @memberof StockyApiStockyExportDcList
     */
    readonly start_date?: string

    /**
     * end_date
     * @type {string}
     * @memberof StockyApiStockyExportDcList
     */
    readonly end_date?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockyExportDcList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockyExportDcList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockyExportProductsCreate operation in StockyApi.
 * @export
 * @interface StockyApiStockyExportProductsCreateRequest
 */
export interface StockyApiStockyExportProductsCreateRequest {
    /**
     * 
     * @type {ProductExport}
     * @memberof StockyApiStockyExportProductsCreate
     */
    readonly data: ProductExport
}

/**
 * Request parameters for stockyExportProductsList operation in StockyApi.
 * @export
 * @interface StockyApiStockyExportProductsListRequest
 */
export interface StockyApiStockyExportProductsListRequest {
    /**
     * brand__id
     * @type {string}
     * @memberof StockyApiStockyExportProductsList
     */
    readonly brand__id?: string

    /**
     * item__id
     * @type {string}
     * @memberof StockyApiStockyExportProductsList
     */
    readonly item__id?: string

    /**
     * serial
     * @type {string}
     * @memberof StockyApiStockyExportProductsList
     */
    readonly serial?: string

    /**
     * tag__id
     * @type {string}
     * @memberof StockyApiStockyExportProductsList
     */
    readonly tag__id?: string

    /**
     * ids
     * @type {string}
     * @memberof StockyApiStockyExportProductsList
     */
    readonly ids?: string

    /**
     * processor
     * @type {string}
     * @memberof StockyApiStockyExportProductsList
     */
    readonly processor?: string

    /**
     * generation
     * @type {string}
     * @memberof StockyApiStockyExportProductsList
     */
    readonly generation?: string

    /**
     * stock_filter
     * @type {string}
     * @memberof StockyApiStockyExportProductsList
     */
    readonly stock_filter?: string

    /**
     * current_category
     * @type {string}
     * @memberof StockyApiStockyExportProductsList
     */
    readonly current_category?: string

    /**
     * start_date
     * @type {string}
     * @memberof StockyApiStockyExportProductsList
     */
    readonly start_date?: string

    /**
     * end_date
     * @type {string}
     * @memberof StockyApiStockyExportProductsList
     */
    readonly end_date?: string

    /**
     * asset_status
     * @type {string}
     * @memberof StockyApiStockyExportProductsList
     */
    readonly asset_status?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockyExportProductsList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockyExportProductsList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockyFilterProductSerialList operation in StockyApi.
 * @export
 * @interface StockyApiStockyFilterProductSerialListRequest
 */
export interface StockyApiStockyFilterProductSerialListRequest {
    /**
     * serial
     * @type {string}
     * @memberof StockyApiStockyFilterProductSerialList
     */
    readonly serial?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockyFilterProductSerialList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockyFilterProductSerialList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockyGroupItemsAddCreate operation in StockyApi.
 * @export
 * @interface StockyApiStockyGroupItemsAddCreateRequest
 */
export interface StockyApiStockyGroupItemsAddCreateRequest {
    /**
     * A unique integer value identifying this group product.
     * @type {number}
     * @memberof StockyApiStockyGroupItemsAddCreate
     */
    readonly id: number

    /**
     * 
     * @type {GroupItemSave}
     * @memberof StockyApiStockyGroupItemsAddCreate
     */
    readonly data: GroupItemSave
}

/**
 * Request parameters for stockyGroupItemsDelete operation in StockyApi.
 * @export
 * @interface StockyApiStockyGroupItemsDeleteRequest
 */
export interface StockyApiStockyGroupItemsDeleteRequest {
    /**
     * A unique integer value identifying this group product.
     * @type {number}
     * @memberof StockyApiStockyGroupItemsDelete
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof StockyApiStockyGroupItemsDelete
     */
    readonly item_id: string
}

/**
 * Request parameters for stockyGroupItemsList operation in StockyApi.
 * @export
 * @interface StockyApiStockyGroupItemsListRequest
 */
export interface StockyApiStockyGroupItemsListRequest {
    /**
     * A unique integer value identifying this group product.
     * @type {number}
     * @memberof StockyApiStockyGroupItemsList
     */
    readonly id: number

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockyGroupItemsList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockyGroupItemsList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockyGroupsCreate operation in StockyApi.
 * @export
 * @interface StockyApiStockyGroupsCreateRequest
 */
export interface StockyApiStockyGroupsCreateRequest {
    /**
     * 
     * @type {GroupProduct}
     * @memberof StockyApiStockyGroupsCreate
     */
    readonly data: GroupProduct
}

/**
 * Request parameters for stockyGroupsDelete operation in StockyApi.
 * @export
 * @interface StockyApiStockyGroupsDeleteRequest
 */
export interface StockyApiStockyGroupsDeleteRequest {
    /**
     * A unique integer value identifying this group product.
     * @type {number}
     * @memberof StockyApiStockyGroupsDelete
     */
    readonly id: number
}

/**
 * Request parameters for stockyGroupsList operation in StockyApi.
 * @export
 * @interface StockyApiStockyGroupsListRequest
 */
export interface StockyApiStockyGroupsListRequest {
    /**
     * connect
     * @type {string}
     * @memberof StockyApiStockyGroupsList
     */
    readonly connect?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockyGroupsList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockyGroupsList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockyGroupsPartialUpdate operation in StockyApi.
 * @export
 * @interface StockyApiStockyGroupsPartialUpdateRequest
 */
export interface StockyApiStockyGroupsPartialUpdateRequest {
    /**
     * A unique integer value identifying this group product.
     * @type {number}
     * @memberof StockyApiStockyGroupsPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {GroupProduct}
     * @memberof StockyApiStockyGroupsPartialUpdate
     */
    readonly data: GroupProduct
}

/**
 * Request parameters for stockyGroupsUpdate operation in StockyApi.
 * @export
 * @interface StockyApiStockyGroupsUpdateRequest
 */
export interface StockyApiStockyGroupsUpdateRequest {
    /**
     * A unique integer value identifying this group product.
     * @type {number}
     * @memberof StockyApiStockyGroupsUpdate
     */
    readonly id: number

    /**
     * 
     * @type {GroupProduct}
     * @memberof StockyApiStockyGroupsUpdate
     */
    readonly data: GroupProduct
}

/**
 * Request parameters for stockyHsnCodesList operation in StockyApi.
 * @export
 * @interface StockyApiStockyHsnCodesListRequest
 */
export interface StockyApiStockyHsnCodesListRequest {
    /**
     * A search term.
     * @type {string}
     * @memberof StockyApiStockyHsnCodesList
     */
    readonly search?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockyHsnCodesList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockyHsnCodesList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockyHsnCodesRead operation in StockyApi.
 * @export
 * @interface StockyApiStockyHsnCodesReadRequest
 */
export interface StockyApiStockyHsnCodesReadRequest {
    /**
     * A unique integer value identifying this hsn code.
     * @type {number}
     * @memberof StockyApiStockyHsnCodesRead
     */
    readonly id: number
}

/**
 * Request parameters for stockyInventoryComputerCreate operation in StockyApi.
 * @export
 * @interface StockyApiStockyInventoryComputerCreateRequest
 */
export interface StockyApiStockyInventoryComputerCreateRequest {
    /**
     * 
     * @type {Inventory}
     * @memberof StockyApiStockyInventoryComputerCreate
     */
    readonly data: Inventory
}

/**
 * Request parameters for stockyItemCreate operation in StockyApi.
 * @export
 * @interface StockyApiStockyItemCreateRequest
 */
export interface StockyApiStockyItemCreateRequest {
    /**
     * 
     * @type {ItemModel}
     * @memberof StockyApiStockyItemCreate
     */
    readonly data: ItemModel | null
}

/**
 * Request parameters for stockyLogisticsDcCreate operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogisticsDcCreateRequest
 */
export interface StockyApiStockyLogisticsDcCreateRequest {
    /**
     * 
     * @type {LogisticsDC}
     * @memberof StockyApiStockyLogisticsDcCreate
     */
    readonly data: LogisticsDC
}

/**
 * Request parameters for stockyLogisticsDcList operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogisticsDcListRequest
 */
export interface StockyApiStockyLogisticsDcListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockyLogisticsDcList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockyLogisticsDcList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockyLogisticsRelatedRead operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogisticsRelatedReadRequest
 */
export interface StockyApiStockyLogisticsRelatedReadRequest {
    /**
     * 
     * @type {string}
     * @memberof StockyApiStockyLogisticsRelatedRead
     */
    readonly serial: string
}

/**
 * Request parameters for stockyLogsAssetCurrentCategoryCreate operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsAssetCurrentCategoryCreateRequest
 */
export interface StockyApiStockyLogsAssetCurrentCategoryCreateRequest {
    /**
     * 
     * @type {AssetCurrentCategory}
     * @memberof StockyApiStockyLogsAssetCurrentCategoryCreate
     */
    readonly data: AssetCurrentCategory
}

/**
 * Request parameters for stockyLogsAssetTrailDelete operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsAssetTrailDeleteRequest
 */
export interface StockyApiStockyLogsAssetTrailDeleteRequest {
    /**
     * A unique integer value identifying this asset trail.
     * @type {number}
     * @memberof StockyApiStockyLogsAssetTrailDelete
     */
    readonly id: number
}

/**
 * Request parameters for stockyLogsAssetTrailList operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsAssetTrailListRequest
 */
export interface StockyApiStockyLogsAssetTrailListRequest {
    /**
     * serial
     * @type {string}
     * @memberof StockyApiStockyLogsAssetTrailList
     */
    readonly serial?: string

    /**
     * start_date
     * @type {string}
     * @memberof StockyApiStockyLogsAssetTrailList
     */
    readonly start_date?: string

    /**
     * end_date
     * @type {string}
     * @memberof StockyApiStockyLogsAssetTrailList
     */
    readonly end_date?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockyLogsAssetTrailList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockyLogsAssetTrailList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockyLogsExportLogisticsList operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsExportLogisticsListRequest
 */
export interface StockyApiStockyLogsExportLogisticsListRequest {
    /**
     * ops_type
     * @type {string}
     * @memberof StockyApiStockyLogsExportLogisticsList
     */
    readonly ops_type?: string

    /**
     * serial
     * @type {string}
     * @memberof StockyApiStockyLogsExportLogisticsList
     */
    readonly serial?: string

    /**
     * product__item__id
     * @type {string}
     * @memberof StockyApiStockyLogsExportLogisticsList
     */
    readonly product__item__id?: string

    /**
     * order_type
     * @type {string}
     * @memberof StockyApiStockyLogsExportLogisticsList
     */
    readonly order_type?: string

    /**
     * connect
     * @type {string}
     * @memberof StockyApiStockyLogsExportLogisticsList
     */
    readonly connect?: string

    /**
     * tracking_no
     * @type {string}
     * @memberof StockyApiStockyLogsExportLogisticsList
     */
    readonly tracking_no?: string

    /**
     * replacement_asset__status
     * @type {string}
     * @memberof StockyApiStockyLogsExportLogisticsList
     */
    readonly replacement_asset__status?: string

    /**
     * replacement_asset__serial
     * @type {string}
     * @memberof StockyApiStockyLogsExportLogisticsList
     */
    readonly replacement_asset__serial?: string

    /**
     * order
     * @type {string}
     * @memberof StockyApiStockyLogsExportLogisticsList
     */
    readonly order?: string

    /**
     * cost_to
     * @type {string}
     * @memberof StockyApiStockyLogsExportLogisticsList
     */
    readonly cost_to?: string

    /**
     * logistics_partner
     * @type {string}
     * @memberof StockyApiStockyLogsExportLogisticsList
     */
    readonly logistics_partner?: string

    /**
     * Pagination
     * @type {string}
     * @memberof StockyApiStockyLogsExportLogisticsList
     */
    readonly pagination?: string

    /**
     * meta__CONDITION_OF_ASSET
     * @type {string}
     * @memberof StockyApiStockyLogsExportLogisticsList
     */
    readonly meta__CONDITION_OF_ASSET?: string

    /**
     * start_date
     * @type {string}
     * @memberof StockyApiStockyLogsExportLogisticsList
     */
    readonly start_date?: string

    /**
     * end_date
     * @type {string}
     * @memberof StockyApiStockyLogsExportLogisticsList
     */
    readonly end_date?: string

    /**
     * asset_status
     * @type {string}
     * @memberof StockyApiStockyLogsExportLogisticsList
     */
    readonly asset_status?: string

    /**
     * delivery
     * @type {string}
     * @memberof StockyApiStockyLogsExportLogisticsList
     */
    readonly delivery?: string

    /**
     * exclude_id
     * @type {string}
     * @memberof StockyApiStockyLogsExportLogisticsList
     */
    readonly exclude_id?: string

    /**
     * tracking_number_isnull
     * @type {string}
     * @memberof StockyApiStockyLogsExportLogisticsList
     */
    readonly tracking_number_isnull?: string

    /**
     * A search term.
     * @type {string}
     * @memberof StockyApiStockyLogsExportLogisticsList
     */
    readonly search?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockyLogsExportLogisticsList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockyLogsExportLogisticsList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockyLogsExportOperationsList operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsExportOperationsListRequest
 */
export interface StockyApiStockyLogsExportOperationsListRequest {
    /**
     * ops_type
     * @type {string}
     * @memberof StockyApiStockyLogsExportOperationsList
     */
    readonly ops_type?: string

    /**
     * connect
     * @type {string}
     * @memberof StockyApiStockyLogsExportOperationsList
     */
    readonly connect?: string

    /**
     * serial
     * @type {string}
     * @memberof StockyApiStockyLogsExportOperationsList
     */
    readonly serial?: string

    /**
     * use_type
     * @type {string}
     * @memberof StockyApiStockyLogsExportOperationsList
     */
    readonly use_type?: string

    /**
     * handover
     * @type {string}
     * @memberof StockyApiStockyLogsExportOperationsList
     */
    readonly handover?: string

    /**
     * generic_product__item__id
     * @type {string}
     * @memberof StockyApiStockyLogsExportOperationsList
     */
    readonly generic_product__item__id?: string

    /**
     * replacement__status
     * @type {string}
     * @memberof StockyApiStockyLogsExportOperationsList
     */
    readonly replacement__status?: string

    /**
     * replacement__serial
     * @type {string}
     * @memberof StockyApiStockyLogsExportOperationsList
     */
    readonly replacement__serial?: string

    /**
     * order
     * @type {string}
     * @memberof StockyApiStockyLogsExportOperationsList
     */
    readonly order?: string

    /**
     * po__po_number
     * @type {string}
     * @memberof StockyApiStockyLogsExportOperationsList
     */
    readonly po__po_number?: string

    /**
     * meta__CONDITION_OF_ASSET
     * @type {string}
     * @memberof StockyApiStockyLogsExportOperationsList
     */
    readonly meta__CONDITION_OF_ASSET?: string

    /**
     * start_date
     * @type {string}
     * @memberof StockyApiStockyLogsExportOperationsList
     */
    readonly start_date?: string

    /**
     * end_date
     * @type {string}
     * @memberof StockyApiStockyLogsExportOperationsList
     */
    readonly end_date?: string

    /**
     * asset_status
     * @type {string}
     * @memberof StockyApiStockyLogsExportOperationsList
     */
    readonly asset_status?: string

    /**
     * current_category
     * @type {string}
     * @memberof StockyApiStockyLogsExportOperationsList
     */
    readonly current_category?: string

    /**
     * A search term.
     * @type {string}
     * @memberof StockyApiStockyLogsExportOperationsList
     */
    readonly search?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockyLogsExportOperationsList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockyLogsExportOperationsList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockyLogsExportQualityCheckList operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsExportQualityCheckListRequest
 */
export interface StockyApiStockyLogsExportQualityCheckListRequest {
    /**
     * status
     * @type {string}
     * @memberof StockyApiStockyLogsExportQualityCheckList
     */
    readonly status?: string

    /**
     * from_to_user
     * @type {string}
     * @memberof StockyApiStockyLogsExportQualityCheckList
     */
    readonly from_to_user?: string

    /**
     * serial
     * @type {string}
     * @memberof StockyApiStockyLogsExportQualityCheckList
     */
    readonly serial?: string

    /**
     * ops_type
     * @type {string}
     * @memberof StockyApiStockyLogsExportQualityCheckList
     */
    readonly ops_type?: string

    /**
     * start_date
     * @type {string}
     * @memberof StockyApiStockyLogsExportQualityCheckList
     */
    readonly start_date?: string

    /**
     * end_date
     * @type {string}
     * @memberof StockyApiStockyLogsExportQualityCheckList
     */
    readonly end_date?: string

    /**
     * asset_status
     * @type {string}
     * @memberof StockyApiStockyLogsExportQualityCheckList
     */
    readonly asset_status?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockyLogsExportQualityCheckList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockyLogsExportQualityCheckList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockyLogsExportServiceReturnList operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsExportServiceReturnListRequest
 */
export interface StockyApiStockyLogsExportServiceReturnListRequest {
    /**
     * from_user
     * @type {string}
     * @memberof StockyApiStockyLogsExportServiceReturnList
     */
    readonly from_user?: string

    /**
     * serial
     * @type {string}
     * @memberof StockyApiStockyLogsExportServiceReturnList
     */
    readonly serial?: string

    /**
     * tag__id
     * @type {string}
     * @memberof StockyApiStockyLogsExportServiceReturnList
     */
    readonly tag__id?: string

    /**
     * handover_to
     * @type {string}
     * @memberof StockyApiStockyLogsExportServiceReturnList
     */
    readonly handover_to?: string

    /**
     * status
     * @type {string}
     * @memberof StockyApiStockyLogsExportServiceReturnList
     */
    readonly status?: string

    /**
     * generic_product__item__id
     * @type {string}
     * @memberof StockyApiStockyLogsExportServiceReturnList
     */
    readonly generic_product__item__id?: string

    /**
     * start_date
     * @type {string}
     * @memberof StockyApiStockyLogsExportServiceReturnList
     */
    readonly start_date?: string

    /**
     * end_date
     * @type {string}
     * @memberof StockyApiStockyLogsExportServiceReturnList
     */
    readonly end_date?: string

    /**
     * asset_status
     * @type {string}
     * @memberof StockyApiStockyLogsExportServiceReturnList
     */
    readonly asset_status?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockyLogsExportServiceReturnList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockyLogsExportServiceReturnList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockyLogsExportServiceSentList operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsExportServiceSentListRequest
 */
export interface StockyApiStockyLogsExportServiceSentListRequest {
    /**
     * serial
     * @type {string}
     * @memberof StockyApiStockyLogsExportServiceSentList
     */
    readonly serial?: string

    /**
     * assigned_to
     * @type {string}
     * @memberof StockyApiStockyLogsExportServiceSentList
     */
    readonly assigned_to?: string

    /**
     * generic_product__item__id
     * @type {string}
     * @memberof StockyApiStockyLogsExportServiceSentList
     */
    readonly generic_product__item__id?: string

    /**
     * fromuser
     * @type {string}
     * @memberof StockyApiStockyLogsExportServiceSentList
     */
    readonly fromuser?: string

    /**
     * tag__id
     * @type {string}
     * @memberof StockyApiStockyLogsExportServiceSentList
     */
    readonly tag__id?: string

    /**
     * start_date
     * @type {string}
     * @memberof StockyApiStockyLogsExportServiceSentList
     */
    readonly start_date?: string

    /**
     * end_date
     * @type {string}
     * @memberof StockyApiStockyLogsExportServiceSentList
     */
    readonly end_date?: string

    /**
     * asset_status
     * @type {string}
     * @memberof StockyApiStockyLogsExportServiceSentList
     */
    readonly asset_status?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockyLogsExportServiceSentList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockyLogsExportServiceSentList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockyLogsLogisticsCreate operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsLogisticsCreateRequest
 */
export interface StockyApiStockyLogsLogisticsCreateRequest {
    /**
     * 
     * @type {NewLogisticsSerializer}
     * @memberof StockyApiStockyLogsLogisticsCreate
     */
    readonly data: NewLogisticsSerializer
}

/**
 * Request parameters for stockyLogsLogisticsDelete operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsLogisticsDeleteRequest
 */
export interface StockyApiStockyLogsLogisticsDeleteRequest {
    /**
     * A unique integer value identifying this logistics.
     * @type {number}
     * @memberof StockyApiStockyLogsLogisticsDelete
     */
    readonly id: number
}

/**
 * Request parameters for stockyLogsLogisticsDeliveryCreate operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsLogisticsDeliveryCreateRequest
 */
export interface StockyApiStockyLogsLogisticsDeliveryCreateRequest {
    /**
     * 
     * @type {LogisticDelivered}
     * @memberof StockyApiStockyLogsLogisticsDeliveryCreate
     */
    readonly data: LogisticDelivered
}

/**
 * Request parameters for stockyLogsLogisticsDeliveryPartialUpdate operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsLogisticsDeliveryPartialUpdateRequest
 */
export interface StockyApiStockyLogsLogisticsDeliveryPartialUpdateRequest {
    /**
     * A unique integer value identifying this logistic delivered.
     * @type {number}
     * @memberof StockyApiStockyLogsLogisticsDeliveryPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {LogisticDelivered}
     * @memberof StockyApiStockyLogsLogisticsDeliveryPartialUpdate
     */
    readonly data: LogisticDelivered
}

/**
 * Request parameters for stockyLogsLogisticsDeliveryRead operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsLogisticsDeliveryReadRequest
 */
export interface StockyApiStockyLogsLogisticsDeliveryReadRequest {
    /**
     * A unique integer value identifying this logistic delivered.
     * @type {number}
     * @memberof StockyApiStockyLogsLogisticsDeliveryRead
     */
    readonly id: number
}

/**
 * Request parameters for stockyLogsLogisticsDeliveryUpdate operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsLogisticsDeliveryUpdateRequest
 */
export interface StockyApiStockyLogsLogisticsDeliveryUpdateRequest {
    /**
     * A unique integer value identifying this logistic delivered.
     * @type {number}
     * @memberof StockyApiStockyLogsLogisticsDeliveryUpdate
     */
    readonly id: number

    /**
     * 
     * @type {LogisticDelivered}
     * @memberof StockyApiStockyLogsLogisticsDeliveryUpdate
     */
    readonly data: LogisticDelivered
}

/**
 * Request parameters for stockyLogsLogisticsList operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsLogisticsListRequest
 */
export interface StockyApiStockyLogsLogisticsListRequest {
    /**
     * ops_type
     * @type {string}
     * @memberof StockyApiStockyLogsLogisticsList
     */
    readonly ops_type?: string

    /**
     * serial
     * @type {string}
     * @memberof StockyApiStockyLogsLogisticsList
     */
    readonly serial?: string

    /**
     * product__item__id
     * @type {string}
     * @memberof StockyApiStockyLogsLogisticsList
     */
    readonly product__item__id?: string

    /**
     * order_type
     * @type {string}
     * @memberof StockyApiStockyLogsLogisticsList
     */
    readonly order_type?: string

    /**
     * connect
     * @type {string}
     * @memberof StockyApiStockyLogsLogisticsList
     */
    readonly connect?: string

    /**
     * tracking_no
     * @type {string}
     * @memberof StockyApiStockyLogsLogisticsList
     */
    readonly tracking_no?: string

    /**
     * replacement_asset__status
     * @type {string}
     * @memberof StockyApiStockyLogsLogisticsList
     */
    readonly replacement_asset__status?: string

    /**
     * replacement_asset__serial
     * @type {string}
     * @memberof StockyApiStockyLogsLogisticsList
     */
    readonly replacement_asset__serial?: string

    /**
     * order
     * @type {string}
     * @memberof StockyApiStockyLogsLogisticsList
     */
    readonly order?: string

    /**
     * cost_to
     * @type {string}
     * @memberof StockyApiStockyLogsLogisticsList
     */
    readonly cost_to?: string

    /**
     * logistics_partner
     * @type {string}
     * @memberof StockyApiStockyLogsLogisticsList
     */
    readonly logistics_partner?: string

    /**
     * Pagination
     * @type {string}
     * @memberof StockyApiStockyLogsLogisticsList
     */
    readonly pagination?: string

    /**
     * meta__CONDITION_OF_ASSET
     * @type {string}
     * @memberof StockyApiStockyLogsLogisticsList
     */
    readonly meta__CONDITION_OF_ASSET?: string

    /**
     * start_date
     * @type {string}
     * @memberof StockyApiStockyLogsLogisticsList
     */
    readonly start_date?: string

    /**
     * end_date
     * @type {string}
     * @memberof StockyApiStockyLogsLogisticsList
     */
    readonly end_date?: string

    /**
     * asset_status
     * @type {string}
     * @memberof StockyApiStockyLogsLogisticsList
     */
    readonly asset_status?: string

    /**
     * delivery
     * @type {string}
     * @memberof StockyApiStockyLogsLogisticsList
     */
    readonly delivery?: string

    /**
     * exclude_id
     * @type {string}
     * @memberof StockyApiStockyLogsLogisticsList
     */
    readonly exclude_id?: string

    /**
     * tracking_number_isnull
     * @type {string}
     * @memberof StockyApiStockyLogsLogisticsList
     */
    readonly tracking_number_isnull?: string

    /**
     * A search term.
     * @type {string}
     * @memberof StockyApiStockyLogsLogisticsList
     */
    readonly search?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockyLogsLogisticsList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockyLogsLogisticsList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockyLogsLogisticsPartialUpdate operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsLogisticsPartialUpdateRequest
 */
export interface StockyApiStockyLogsLogisticsPartialUpdateRequest {
    /**
     * A unique integer value identifying this logistics.
     * @type {number}
     * @memberof StockyApiStockyLogsLogisticsPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {NewLogisticsSerializer}
     * @memberof StockyApiStockyLogsLogisticsPartialUpdate
     */
    readonly data: NewLogisticsSerializer
}

/**
 * Request parameters for stockyLogsLogisticsPickupAttemptCreate operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsLogisticsPickupAttemptCreateRequest
 */
export interface StockyApiStockyLogsLogisticsPickupAttemptCreateRequest {
    /**
     * 
     * @type {PickupAttempt}
     * @memberof StockyApiStockyLogsLogisticsPickupAttemptCreate
     */
    readonly data: PickupAttempt
}

/**
 * Request parameters for stockyLogsLogisticsPickupAttemptDelete operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsLogisticsPickupAttemptDeleteRequest
 */
export interface StockyApiStockyLogsLogisticsPickupAttemptDeleteRequest {
    /**
     * A unique integer value identifying this pickup attempt.
     * @type {number}
     * @memberof StockyApiStockyLogsLogisticsPickupAttemptDelete
     */
    readonly id: number
}

/**
 * Request parameters for stockyLogsLogisticsPickupAttemptList operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsLogisticsPickupAttemptListRequest
 */
export interface StockyApiStockyLogsLogisticsPickupAttemptListRequest {
    /**
     * city
     * @type {string}
     * @memberof StockyApiStockyLogsLogisticsPickupAttemptList
     */
    readonly city?: string

    /**
     * logistics_partner
     * @type {string}
     * @memberof StockyApiStockyLogsLogisticsPickupAttemptList
     */
    readonly logistics_partner?: string

    /**
     * cost_to
     * @type {string}
     * @memberof StockyApiStockyLogsLogisticsPickupAttemptList
     */
    readonly cost_to?: string

    /**
     * slug
     * @type {string}
     * @memberof StockyApiStockyLogsLogisticsPickupAttemptList
     */
    readonly slug?: string

    /**
     * state
     * @type {string}
     * @memberof StockyApiStockyLogsLogisticsPickupAttemptList
     */
    readonly state?: string

    /**
     * tracking_no
     * @type {string}
     * @memberof StockyApiStockyLogsLogisticsPickupAttemptList
     */
    readonly tracking_no?: string

    /**
     * amount
     * @type {string}
     * @memberof StockyApiStockyLogsLogisticsPickupAttemptList
     */
    readonly amount?: string

    /**
     * pincode
     * @type {string}
     * @memberof StockyApiStockyLogsLogisticsPickupAttemptList
     */
    readonly pincode?: string

    /**
     * logistics
     * @type {string}
     * @memberof StockyApiStockyLogsLogisticsPickupAttemptList
     */
    readonly logistics?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockyLogsLogisticsPickupAttemptList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockyLogsLogisticsPickupAttemptList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockyLogsLogisticsPickupAttemptPartialUpdate operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsLogisticsPickupAttemptPartialUpdateRequest
 */
export interface StockyApiStockyLogsLogisticsPickupAttemptPartialUpdateRequest {
    /**
     * A unique integer value identifying this pickup attempt.
     * @type {number}
     * @memberof StockyApiStockyLogsLogisticsPickupAttemptPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {PickupAttempt}
     * @memberof StockyApiStockyLogsLogisticsPickupAttemptPartialUpdate
     */
    readonly data: PickupAttempt
}

/**
 * Request parameters for stockyLogsLogisticsPickupAttemptRead operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsLogisticsPickupAttemptReadRequest
 */
export interface StockyApiStockyLogsLogisticsPickupAttemptReadRequest {
    /**
     * A unique integer value identifying this pickup attempt.
     * @type {number}
     * @memberof StockyApiStockyLogsLogisticsPickupAttemptRead
     */
    readonly id: number
}

/**
 * Request parameters for stockyLogsLogisticsPickupAttemptUpdate operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsLogisticsPickupAttemptUpdateRequest
 */
export interface StockyApiStockyLogsLogisticsPickupAttemptUpdateRequest {
    /**
     * A unique integer value identifying this pickup attempt.
     * @type {number}
     * @memberof StockyApiStockyLogsLogisticsPickupAttemptUpdate
     */
    readonly id: number

    /**
     * 
     * @type {PickupAttempt}
     * @memberof StockyApiStockyLogsLogisticsPickupAttemptUpdate
     */
    readonly data: PickupAttempt
}

/**
 * Request parameters for stockyLogsLogisticsRead operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsLogisticsReadRequest
 */
export interface StockyApiStockyLogsLogisticsReadRequest {
    /**
     * A unique integer value identifying this logistics.
     * @type {number}
     * @memberof StockyApiStockyLogsLogisticsRead
     */
    readonly id: number
}

/**
 * Request parameters for stockyLogsLogisticsUpdate operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsLogisticsUpdateRequest
 */
export interface StockyApiStockyLogsLogisticsUpdateRequest {
    /**
     * A unique integer value identifying this logistics.
     * @type {number}
     * @memberof StockyApiStockyLogsLogisticsUpdate
     */
    readonly id: number

    /**
     * 
     * @type {NewLogisticsSerializer}
     * @memberof StockyApiStockyLogsLogisticsUpdate
     */
    readonly data: NewLogisticsSerializer
}

/**
 * Request parameters for stockyLogsMediaLogisticsRead operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsMediaLogisticsReadRequest
 */
export interface StockyApiStockyLogsMediaLogisticsReadRequest {
    /**
     * A unique integer value identifying this logistics.
     * @type {number}
     * @memberof StockyApiStockyLogsMediaLogisticsRead
     */
    readonly id: number
}

/**
 * Request parameters for stockyLogsMigrateOperationsCreate operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsMigrateOperationsCreateRequest
 */
export interface StockyApiStockyLogsMigrateOperationsCreateRequest {
    /**
     * 
     * @type {MigrateFromOperations}
     * @memberof StockyApiStockyLogsMigrateOperationsCreate
     */
    readonly data: MigrateFromOperations
}

/**
 * Request parameters for stockyLogsMigrateQualityCheckCreate operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsMigrateQualityCheckCreateRequest
 */
export interface StockyApiStockyLogsMigrateQualityCheckCreateRequest {
    /**
     * 
     * @type {MigrateFromQualityCheck}
     * @memberof StockyApiStockyLogsMigrateQualityCheckCreate
     */
    readonly data: MigrateFromQualityCheck
}

/**
 * Request parameters for stockyLogsMigrateReturnFromServiceCreate operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsMigrateReturnFromServiceCreateRequest
 */
export interface StockyApiStockyLogsMigrateReturnFromServiceCreateRequest {
    /**
     * 
     * @type {MigrateReturnFromService}
     * @memberof StockyApiStockyLogsMigrateReturnFromServiceCreate
     */
    readonly data: MigrateReturnFromService
}

/**
 * Request parameters for stockyLogsMigrateServiceReAssignCreate operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsMigrateServiceReAssignCreateRequest
 */
export interface StockyApiStockyLogsMigrateServiceReAssignCreateRequest {
    /**
     * 
     * @type {ServiceReAssign}
     * @memberof StockyApiStockyLogsMigrateServiceReAssignCreate
     */
    readonly data: ServiceReAssign
}

/**
 * Request parameters for stockyLogsOperationsCreate operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsOperationsCreateRequest
 */
export interface StockyApiStockyLogsOperationsCreateRequest {
    /**
     * 
     * @type {Operations}
     * @memberof StockyApiStockyLogsOperationsCreate
     */
    readonly data: Operations
}

/**
 * Request parameters for stockyLogsOperationsDelete operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsOperationsDeleteRequest
 */
export interface StockyApiStockyLogsOperationsDeleteRequest {
    /**
     * A unique integer value identifying this operations.
     * @type {number}
     * @memberof StockyApiStockyLogsOperationsDelete
     */
    readonly id: number
}

/**
 * Request parameters for stockyLogsOperationsList operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsOperationsListRequest
 */
export interface StockyApiStockyLogsOperationsListRequest {
    /**
     * ops_type
     * @type {string}
     * @memberof StockyApiStockyLogsOperationsList
     */
    readonly ops_type?: string

    /**
     * connect
     * @type {string}
     * @memberof StockyApiStockyLogsOperationsList
     */
    readonly connect?: string

    /**
     * serial
     * @type {string}
     * @memberof StockyApiStockyLogsOperationsList
     */
    readonly serial?: string

    /**
     * use_type
     * @type {string}
     * @memberof StockyApiStockyLogsOperationsList
     */
    readonly use_type?: string

    /**
     * handover
     * @type {string}
     * @memberof StockyApiStockyLogsOperationsList
     */
    readonly handover?: string

    /**
     * generic_product__item__id
     * @type {string}
     * @memberof StockyApiStockyLogsOperationsList
     */
    readonly generic_product__item__id?: string

    /**
     * replacement__status
     * @type {string}
     * @memberof StockyApiStockyLogsOperationsList
     */
    readonly replacement__status?: string

    /**
     * replacement__serial
     * @type {string}
     * @memberof StockyApiStockyLogsOperationsList
     */
    readonly replacement__serial?: string

    /**
     * order
     * @type {string}
     * @memberof StockyApiStockyLogsOperationsList
     */
    readonly order?: string

    /**
     * po__po_number
     * @type {string}
     * @memberof StockyApiStockyLogsOperationsList
     */
    readonly po__po_number?: string

    /**
     * meta__CONDITION_OF_ASSET
     * @type {string}
     * @memberof StockyApiStockyLogsOperationsList
     */
    readonly meta__CONDITION_OF_ASSET?: string

    /**
     * start_date
     * @type {string}
     * @memberof StockyApiStockyLogsOperationsList
     */
    readonly start_date?: string

    /**
     * end_date
     * @type {string}
     * @memberof StockyApiStockyLogsOperationsList
     */
    readonly end_date?: string

    /**
     * asset_status
     * @type {string}
     * @memberof StockyApiStockyLogsOperationsList
     */
    readonly asset_status?: string

    /**
     * current_category
     * @type {string}
     * @memberof StockyApiStockyLogsOperationsList
     */
    readonly current_category?: string

    /**
     * A search term.
     * @type {string}
     * @memberof StockyApiStockyLogsOperationsList
     */
    readonly search?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockyLogsOperationsList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockyLogsOperationsList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockyLogsOperationsPartialUpdate operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsOperationsPartialUpdateRequest
 */
export interface StockyApiStockyLogsOperationsPartialUpdateRequest {
    /**
     * A unique integer value identifying this operations.
     * @type {number}
     * @memberof StockyApiStockyLogsOperationsPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Operations}
     * @memberof StockyApiStockyLogsOperationsPartialUpdate
     */
    readonly data: Operations
}

/**
 * Request parameters for stockyLogsOperationsRead operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsOperationsReadRequest
 */
export interface StockyApiStockyLogsOperationsReadRequest {
    /**
     * A unique integer value identifying this operations.
     * @type {number}
     * @memberof StockyApiStockyLogsOperationsRead
     */
    readonly id: number
}

/**
 * Request parameters for stockyLogsOperationsUpdate operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsOperationsUpdateRequest
 */
export interface StockyApiStockyLogsOperationsUpdateRequest {
    /**
     * A unique integer value identifying this operations.
     * @type {number}
     * @memberof StockyApiStockyLogsOperationsUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Operations}
     * @memberof StockyApiStockyLogsOperationsUpdate
     */
    readonly data: Operations
}

/**
 * Request parameters for stockyLogsQualityCheckCreate operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsQualityCheckCreateRequest
 */
export interface StockyApiStockyLogsQualityCheckCreateRequest {
    /**
     * 
     * @type {QualityCheck}
     * @memberof StockyApiStockyLogsQualityCheckCreate
     */
    readonly data: QualityCheck
}

/**
 * Request parameters for stockyLogsQualityCheckDelete operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsQualityCheckDeleteRequest
 */
export interface StockyApiStockyLogsQualityCheckDeleteRequest {
    /**
     * A unique integer value identifying this quality check.
     * @type {number}
     * @memberof StockyApiStockyLogsQualityCheckDelete
     */
    readonly id: number
}

/**
 * Request parameters for stockyLogsQualityCheckList operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsQualityCheckListRequest
 */
export interface StockyApiStockyLogsQualityCheckListRequest {
    /**
     * status
     * @type {string}
     * @memberof StockyApiStockyLogsQualityCheckList
     */
    readonly status?: string

    /**
     * from_to_user
     * @type {string}
     * @memberof StockyApiStockyLogsQualityCheckList
     */
    readonly from_to_user?: string

    /**
     * serial
     * @type {string}
     * @memberof StockyApiStockyLogsQualityCheckList
     */
    readonly serial?: string

    /**
     * ops_type
     * @type {string}
     * @memberof StockyApiStockyLogsQualityCheckList
     */
    readonly ops_type?: string

    /**
     * start_date
     * @type {string}
     * @memberof StockyApiStockyLogsQualityCheckList
     */
    readonly start_date?: string

    /**
     * end_date
     * @type {string}
     * @memberof StockyApiStockyLogsQualityCheckList
     */
    readonly end_date?: string

    /**
     * asset_status
     * @type {string}
     * @memberof StockyApiStockyLogsQualityCheckList
     */
    readonly asset_status?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockyLogsQualityCheckList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockyLogsQualityCheckList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockyLogsQualityCheckPartialUpdate operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsQualityCheckPartialUpdateRequest
 */
export interface StockyApiStockyLogsQualityCheckPartialUpdateRequest {
    /**
     * A unique integer value identifying this quality check.
     * @type {number}
     * @memberof StockyApiStockyLogsQualityCheckPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {QualityCheck}
     * @memberof StockyApiStockyLogsQualityCheckPartialUpdate
     */
    readonly data: QualityCheck
}

/**
 * Request parameters for stockyLogsQualityCheckUpdate operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsQualityCheckUpdateRequest
 */
export interface StockyApiStockyLogsQualityCheckUpdateRequest {
    /**
     * A unique integer value identifying this quality check.
     * @type {number}
     * @memberof StockyApiStockyLogsQualityCheckUpdate
     */
    readonly id: number

    /**
     * 
     * @type {QualityCheck}
     * @memberof StockyApiStockyLogsQualityCheckUpdate
     */
    readonly data: QualityCheck
}

/**
 * Request parameters for stockyLogsReplacementAssetPartialUpdate operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsReplacementAssetPartialUpdateRequest
 */
export interface StockyApiStockyLogsReplacementAssetPartialUpdateRequest {
    /**
     * A unique integer value identifying this replacement asset.
     * @type {number}
     * @memberof StockyApiStockyLogsReplacementAssetPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {ReplacementAsset}
     * @memberof StockyApiStockyLogsReplacementAssetPartialUpdate
     */
    readonly data: ReplacementAsset | null
}

/**
 * Request parameters for stockyLogsReplacementAssetUpdate operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsReplacementAssetUpdateRequest
 */
export interface StockyApiStockyLogsReplacementAssetUpdateRequest {
    /**
     * A unique integer value identifying this replacement asset.
     * @type {number}
     * @memberof StockyApiStockyLogsReplacementAssetUpdate
     */
    readonly id: number

    /**
     * 
     * @type {ReplacementAsset}
     * @memberof StockyApiStockyLogsReplacementAssetUpdate
     */
    readonly data: ReplacementAsset | null
}

/**
 * Request parameters for stockyLogsSentServiceCreate operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsSentServiceCreateRequest
 */
export interface StockyApiStockyLogsSentServiceCreateRequest {
    /**
     * 
     * @type {SentService}
     * @memberof StockyApiStockyLogsSentServiceCreate
     */
    readonly data: SentService
}

/**
 * Request parameters for stockyLogsSentServiceDelete operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsSentServiceDeleteRequest
 */
export interface StockyApiStockyLogsSentServiceDeleteRequest {
    /**
     * A unique integer value identifying this sent to service.
     * @type {number}
     * @memberof StockyApiStockyLogsSentServiceDelete
     */
    readonly id: number
}

/**
 * Request parameters for stockyLogsSentServiceList operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsSentServiceListRequest
 */
export interface StockyApiStockyLogsSentServiceListRequest {
    /**
     * serial
     * @type {string}
     * @memberof StockyApiStockyLogsSentServiceList
     */
    readonly serial?: string

    /**
     * assigned_to
     * @type {string}
     * @memberof StockyApiStockyLogsSentServiceList
     */
    readonly assigned_to?: string

    /**
     * generic_product__item__id
     * @type {string}
     * @memberof StockyApiStockyLogsSentServiceList
     */
    readonly generic_product__item__id?: string

    /**
     * fromuser
     * @type {string}
     * @memberof StockyApiStockyLogsSentServiceList
     */
    readonly fromuser?: string

    /**
     * tag__id
     * @type {string}
     * @memberof StockyApiStockyLogsSentServiceList
     */
    readonly tag__id?: string

    /**
     * start_date
     * @type {string}
     * @memberof StockyApiStockyLogsSentServiceList
     */
    readonly start_date?: string

    /**
     * end_date
     * @type {string}
     * @memberof StockyApiStockyLogsSentServiceList
     */
    readonly end_date?: string

    /**
     * asset_status
     * @type {string}
     * @memberof StockyApiStockyLogsSentServiceList
     */
    readonly asset_status?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockyLogsSentServiceList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockyLogsSentServiceList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockyLogsServiceReturnCreate operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsServiceReturnCreateRequest
 */
export interface StockyApiStockyLogsServiceReturnCreateRequest {
    /**
     * 
     * @type {ReturnFromService}
     * @memberof StockyApiStockyLogsServiceReturnCreate
     */
    readonly data: ReturnFromService
}

/**
 * Request parameters for stockyLogsServiceReturnDelete operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsServiceReturnDeleteRequest
 */
export interface StockyApiStockyLogsServiceReturnDeleteRequest {
    /**
     * A unique integer value identifying this return from service.
     * @type {number}
     * @memberof StockyApiStockyLogsServiceReturnDelete
     */
    readonly id: number
}

/**
 * Request parameters for stockyLogsServiceReturnList operation in StockyApi.
 * @export
 * @interface StockyApiStockyLogsServiceReturnListRequest
 */
export interface StockyApiStockyLogsServiceReturnListRequest {
    /**
     * from_user
     * @type {string}
     * @memberof StockyApiStockyLogsServiceReturnList
     */
    readonly from_user?: string

    /**
     * serial
     * @type {string}
     * @memberof StockyApiStockyLogsServiceReturnList
     */
    readonly serial?: string

    /**
     * tag__id
     * @type {string}
     * @memberof StockyApiStockyLogsServiceReturnList
     */
    readonly tag__id?: string

    /**
     * handover_to
     * @type {string}
     * @memberof StockyApiStockyLogsServiceReturnList
     */
    readonly handover_to?: string

    /**
     * status
     * @type {string}
     * @memberof StockyApiStockyLogsServiceReturnList
     */
    readonly status?: string

    /**
     * generic_product__item__id
     * @type {string}
     * @memberof StockyApiStockyLogsServiceReturnList
     */
    readonly generic_product__item__id?: string

    /**
     * start_date
     * @type {string}
     * @memberof StockyApiStockyLogsServiceReturnList
     */
    readonly start_date?: string

    /**
     * end_date
     * @type {string}
     * @memberof StockyApiStockyLogsServiceReturnList
     */
    readonly end_date?: string

    /**
     * asset_status
     * @type {string}
     * @memberof StockyApiStockyLogsServiceReturnList
     */
    readonly asset_status?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockyLogsServiceReturnList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockyLogsServiceReturnList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockyProductsBiosPasswordRead operation in StockyApi.
 * @export
 * @interface StockyApiStockyProductsBiosPasswordReadRequest
 */
export interface StockyApiStockyProductsBiosPasswordReadRequest {
    /**
     * A unique integer value identifying this generic product.
     * @type {number}
     * @memberof StockyApiStockyProductsBiosPasswordRead
     */
    readonly id: number
}

/**
 * Request parameters for stockyProductsCreate operation in StockyApi.
 * @export
 * @interface StockyApiStockyProductsCreateRequest
 */
export interface StockyApiStockyProductsCreateRequest {
    /**
     * 
     * @type {GenericProduct}
     * @memberof StockyApiStockyProductsCreate
     */
    readonly data: GenericProduct
}

/**
 * Request parameters for stockyProductsList operation in StockyApi.
 * @export
 * @interface StockyApiStockyProductsListRequest
 */
export interface StockyApiStockyProductsListRequest {
    /**
     * brand__id
     * @type {string}
     * @memberof StockyApiStockyProductsList
     */
    readonly brand__id?: string

    /**
     * item__id
     * @type {string}
     * @memberof StockyApiStockyProductsList
     */
    readonly item__id?: string

    /**
     * serial
     * @type {string}
     * @memberof StockyApiStockyProductsList
     */
    readonly serial?: string

    /**
     * tag__id
     * @type {string}
     * @memberof StockyApiStockyProductsList
     */
    readonly tag__id?: string

    /**
     * ids
     * @type {string}
     * @memberof StockyApiStockyProductsList
     */
    readonly ids?: string

    /**
     * processor
     * @type {string}
     * @memberof StockyApiStockyProductsList
     */
    readonly processor?: string

    /**
     * generation
     * @type {string}
     * @memberof StockyApiStockyProductsList
     */
    readonly generation?: string

    /**
     * stock_filter
     * @type {string}
     * @memberof StockyApiStockyProductsList
     */
    readonly stock_filter?: string

    /**
     * current_category
     * @type {string}
     * @memberof StockyApiStockyProductsList
     */
    readonly current_category?: string

    /**
     * start_date
     * @type {string}
     * @memberof StockyApiStockyProductsList
     */
    readonly start_date?: string

    /**
     * end_date
     * @type {string}
     * @memberof StockyApiStockyProductsList
     */
    readonly end_date?: string

    /**
     * asset_status
     * @type {string}
     * @memberof StockyApiStockyProductsList
     */
    readonly asset_status?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockyProductsList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockyProductsList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockyProductsPartialUpdate operation in StockyApi.
 * @export
 * @interface StockyApiStockyProductsPartialUpdateRequest
 */
export interface StockyApiStockyProductsPartialUpdateRequest {
    /**
     * A unique integer value identifying this generic product.
     * @type {number}
     * @memberof StockyApiStockyProductsPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {GenericProduct}
     * @memberof StockyApiStockyProductsPartialUpdate
     */
    readonly data: GenericProduct
}

/**
 * Request parameters for stockyProductsRead operation in StockyApi.
 * @export
 * @interface StockyApiStockyProductsReadRequest
 */
export interface StockyApiStockyProductsReadRequest {
    /**
     * A unique integer value identifying this generic product.
     * @type {number}
     * @memberof StockyApiStockyProductsRead
     */
    readonly id: number
}

/**
 * Request parameters for stockyProductsUpdate operation in StockyApi.
 * @export
 * @interface StockyApiStockyProductsUpdateRequest
 */
export interface StockyApiStockyProductsUpdateRequest {
    /**
     * A unique integer value identifying this generic product.
     * @type {number}
     * @memberof StockyApiStockyProductsUpdate
     */
    readonly id: number

    /**
     * 
     * @type {GenericProduct}
     * @memberof StockyApiStockyProductsUpdate
     */
    readonly data: GenericProduct
}

/**
 * Request parameters for stockyPublicProductList operation in StockyApi.
 * @export
 * @interface StockyApiStockyPublicProductListRequest
 */
export interface StockyApiStockyPublicProductListRequest {
    /**
     * brand__id
     * @type {string}
     * @memberof StockyApiStockyPublicProductList
     */
    readonly brand__id?: string

    /**
     * item__id
     * @type {string}
     * @memberof StockyApiStockyPublicProductList
     */
    readonly item__id?: string

    /**
     * serial
     * @type {string}
     * @memberof StockyApiStockyPublicProductList
     */
    readonly serial?: string

    /**
     * tag__id
     * @type {string}
     * @memberof StockyApiStockyPublicProductList
     */
    readonly tag__id?: string

    /**
     * ids
     * @type {string}
     * @memberof StockyApiStockyPublicProductList
     */
    readonly ids?: string

    /**
     * processor
     * @type {string}
     * @memberof StockyApiStockyPublicProductList
     */
    readonly processor?: string

    /**
     * generation
     * @type {string}
     * @memberof StockyApiStockyPublicProductList
     */
    readonly generation?: string

    /**
     * stock_filter
     * @type {string}
     * @memberof StockyApiStockyPublicProductList
     */
    readonly stock_filter?: string

    /**
     * current_category
     * @type {string}
     * @memberof StockyApiStockyPublicProductList
     */
    readonly current_category?: string

    /**
     * start_date
     * @type {string}
     * @memberof StockyApiStockyPublicProductList
     */
    readonly start_date?: string

    /**
     * end_date
     * @type {string}
     * @memberof StockyApiStockyPublicProductList
     */
    readonly end_date?: string

    /**
     * asset_status
     * @type {string}
     * @memberof StockyApiStockyPublicProductList
     */
    readonly asset_status?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockyPublicProductList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockyPublicProductList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockyPublicProductRead operation in StockyApi.
 * @export
 * @interface StockyApiStockyPublicProductReadRequest
 */
export interface StockyApiStockyPublicProductReadRequest {
    /**
     * 
     * @type {string}
     * @memberof StockyApiStockyPublicProductRead
     */
    readonly key: string
}

/**
 * Request parameters for stockyPublicProductSerialRead operation in StockyApi.
 * @export
 * @interface StockyApiStockyPublicProductSerialReadRequest
 */
export interface StockyApiStockyPublicProductSerialReadRequest {
    /**
     * 
     * @type {string}
     * @memberof StockyApiStockyPublicProductSerialRead
     */
    readonly serial: string
}

/**
 * Request parameters for stockyPublicTicketConnectRead operation in StockyApi.
 * @export
 * @interface StockyApiStockyPublicTicketConnectReadRequest
 */
export interface StockyApiStockyPublicTicketConnectReadRequest {
    /**
     * A unique integer value identifying this connect.
     * @type {number}
     * @memberof StockyApiStockyPublicTicketConnectRead
     */
    readonly id: number
}

/**
 * Request parameters for stockyQuoteCreate operation in StockyApi.
 * @export
 * @interface StockyApiStockyQuoteCreateRequest
 */
export interface StockyApiStockyQuoteCreateRequest {
    /**
     * 
     * @type {Quote}
     * @memberof StockyApiStockyQuoteCreate
     */
    readonly data: Quote
}

/**
 * Request parameters for stockyQuoteList operation in StockyApi.
 * @export
 * @interface StockyApiStockyQuoteListRequest
 */
export interface StockyApiStockyQuoteListRequest {
    /**
     * user
     * @type {string}
     * @memberof StockyApiStockyQuoteList
     */
    readonly user?: string

    /**
     * qr
     * @type {string}
     * @memberof StockyApiStockyQuoteList
     */
    readonly qr?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockyQuoteList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockyQuoteList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockyQuoteRequestCreate operation in StockyApi.
 * @export
 * @interface StockyApiStockyQuoteRequestCreateRequest
 */
export interface StockyApiStockyQuoteRequestCreateRequest {
    /**
     * 
     * @type {QuoteRequest}
     * @memberof StockyApiStockyQuoteRequestCreate
     */
    readonly data: QuoteRequest
}

/**
 * Request parameters for stockyQuoteRequestList operation in StockyApi.
 * @export
 * @interface StockyApiStockyQuoteRequestListRequest
 */
export interface StockyApiStockyQuoteRequestListRequest {
    /**
     * request_type
     * @type {string}
     * @memberof StockyApiStockyQuoteRequestList
     */
    readonly request_type?: string

    /**
     * lead
     * @type {string}
     * @memberof StockyApiStockyQuoteRequestList
     */
    readonly lead?: string

    /**
     * connect
     * @type {string}
     * @memberof StockyApiStockyQuoteRequestList
     */
    readonly connect?: string

    /**
     * stage
     * @type {string}
     * @memberof StockyApiStockyQuoteRequestList
     */
    readonly stage?: string

    /**
     * user
     * @type {string}
     * @memberof StockyApiStockyQuoteRequestList
     */
    readonly user?: string

    /**
     * lead_user
     * @type {string}
     * @memberof StockyApiStockyQuoteRequestList
     */
    readonly lead_user?: string

    /**
     * is_group
     * @type {string}
     * @memberof StockyApiStockyQuoteRequestList
     */
    readonly is_group?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockyQuoteRequestList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockyQuoteRequestList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockyQuoteRequestRead operation in StockyApi.
 * @export
 * @interface StockyApiStockyQuoteRequestReadRequest
 */
export interface StockyApiStockyQuoteRequestReadRequest {
    /**
     * A unique integer value identifying this quote request.
     * @type {number}
     * @memberof StockyApiStockyQuoteRequestRead
     */
    readonly id: number
}

/**
 * Request parameters for stockyQuoteRequestStageCreate operation in StockyApi.
 * @export
 * @interface StockyApiStockyQuoteRequestStageCreateRequest
 */
export interface StockyApiStockyQuoteRequestStageCreateRequest {
    /**
     * 
     * @type {QuoteRequestStage}
     * @memberof StockyApiStockyQuoteRequestStageCreate
     */
    readonly data: QuoteRequestStage
}

/**
 * Request parameters for stockyQuoteRequestStageList operation in StockyApi.
 * @export
 * @interface StockyApiStockyQuoteRequestStageListRequest
 */
export interface StockyApiStockyQuoteRequestStageListRequest {
    /**
     * qr
     * @type {string}
     * @memberof StockyApiStockyQuoteRequestStageList
     */
    readonly qr?: string

    /**
     * stage
     * @type {string}
     * @memberof StockyApiStockyQuoteRequestStageList
     */
    readonly stage?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockyQuoteRequestStageList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockyQuoteRequestStageList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockyReportsMasterList operation in StockyApi.
 * @export
 * @interface StockyApiStockyReportsMasterListRequest
 */
export interface StockyApiStockyReportsMasterListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockyReportsMasterList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockyReportsMasterList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockyReportsOperationsModelWiseQtyList operation in StockyApi.
 * @export
 * @interface StockyApiStockyReportsOperationsModelWiseQtyListRequest
 */
export interface StockyApiStockyReportsOperationsModelWiseQtyListRequest {
    /**
     * current_category
     * @type {string}
     * @memberof StockyApiStockyReportsOperationsModelWiseQtyList
     */
    readonly current_category?: string

    /**
     * fields
     * @type {string}
     * @memberof StockyApiStockyReportsOperationsModelWiseQtyList
     */
    readonly fields?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockyReportsOperationsModelWiseQtyList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockyReportsOperationsModelWiseQtyList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockyReportsQualityCheckModelWiseQtyList operation in StockyApi.
 * @export
 * @interface StockyApiStockyReportsQualityCheckModelWiseQtyListRequest
 */
export interface StockyApiStockyReportsQualityCheckModelWiseQtyListRequest {
    /**
     * fields
     * @type {string}
     * @memberof StockyApiStockyReportsQualityCheckModelWiseQtyList
     */
    readonly fields?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockyReportsQualityCheckModelWiseQtyList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockyReportsQualityCheckModelWiseQtyList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockyReportsServiceModelWiseQtyList operation in StockyApi.
 * @export
 * @interface StockyApiStockyReportsServiceModelWiseQtyListRequest
 */
export interface StockyApiStockyReportsServiceModelWiseQtyListRequest {
    /**
     * current_category
     * @type {string}
     * @memberof StockyApiStockyReportsServiceModelWiseQtyList
     */
    readonly current_category?: string

    /**
     * fields
     * @type {string}
     * @memberof StockyApiStockyReportsServiceModelWiseQtyList
     */
    readonly fields?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockyReportsServiceModelWiseQtyList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockyReportsServiceModelWiseQtyList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockySavedFilterCreate operation in StockyApi.
 * @export
 * @interface StockyApiStockySavedFilterCreateRequest
 */
export interface StockyApiStockySavedFilterCreateRequest {
    /**
     * 
     * @type {SavedFilter}
     * @memberof StockyApiStockySavedFilterCreate
     */
    readonly data: SavedFilter
}

/**
 * Request parameters for stockySavedFilterDelete operation in StockyApi.
 * @export
 * @interface StockyApiStockySavedFilterDeleteRequest
 */
export interface StockyApiStockySavedFilterDeleteRequest {
    /**
     * A unique integer value identifying this saved filter model.
     * @type {number}
     * @memberof StockyApiStockySavedFilterDelete
     */
    readonly id: number
}

/**
 * Request parameters for stockySavedFilterList operation in StockyApi.
 * @export
 * @interface StockyApiStockySavedFilterListRequest
 */
export interface StockyApiStockySavedFilterListRequest {
    /**
     * category
     * @type {string}
     * @memberof StockyApiStockySavedFilterList
     */
    readonly category?: string

    /**
     * A search term.
     * @type {string}
     * @memberof StockyApiStockySavedFilterList
     */
    readonly search?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockySavedFilterList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockySavedFilterList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockySavedFilterRead operation in StockyApi.
 * @export
 * @interface StockyApiStockySavedFilterReadRequest
 */
export interface StockyApiStockySavedFilterReadRequest {
    /**
     * A unique integer value identifying this saved filter model.
     * @type {number}
     * @memberof StockyApiStockySavedFilterRead
     */
    readonly id: number
}

/**
 * Request parameters for stockySearchCategoryList operation in StockyApi.
 * @export
 * @interface StockyApiStockySearchCategoryListRequest
 */
export interface StockyApiStockySearchCategoryListRequest {
    /**
     * A search term.
     * @type {string}
     * @memberof StockyApiStockySearchCategoryList
     */
    readonly search?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockySearchCategoryList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockySearchCategoryList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockySearchConnectList operation in StockyApi.
 * @export
 * @interface StockyApiStockySearchConnectListRequest
 */
export interface StockyApiStockySearchConnectListRequest {
    /**
     * A search term.
     * @type {string}
     * @memberof StockyApiStockySearchConnectList
     */
    readonly search?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockySearchConnectList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockySearchConnectList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockySearchConnectRead operation in StockyApi.
 * @export
 * @interface StockyApiStockySearchConnectReadRequest
 */
export interface StockyApiStockySearchConnectReadRequest {
    /**
     * A unique integer value identifying this connect.
     * @type {number}
     * @memberof StockyApiStockySearchConnectRead
     */
    readonly id: number
}

/**
 * Request parameters for stockySearchItemList operation in StockyApi.
 * @export
 * @interface StockyApiStockySearchItemListRequest
 */
export interface StockyApiStockySearchItemListRequest {
    /**
     * A search term.
     * @type {string}
     * @memberof StockyApiStockySearchItemList
     */
    readonly search?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockySearchItemList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockySearchItemList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockySearchTagList operation in StockyApi.
 * @export
 * @interface StockyApiStockySearchTagListRequest
 */
export interface StockyApiStockySearchTagListRequest {
    /**
     * category
     * @type {string}
     * @memberof StockyApiStockySearchTagList
     */
    readonly category?: string

    /**
     * A search term.
     * @type {string}
     * @memberof StockyApiStockySearchTagList
     */
    readonly search?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockySearchTagList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockySearchTagList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockySearchUserList operation in StockyApi.
 * @export
 * @interface StockyApiStockySearchUserListRequest
 */
export interface StockyApiStockySearchUserListRequest {
    /**
     * A search term.
     * @type {string}
     * @memberof StockyApiStockySearchUserList
     */
    readonly search?: string

    /**
     * email
     * @type {string}
     * @memberof StockyApiStockySearchUserList
     */
    readonly email?: string

    /**
     * role
     * @type {string}
     * @memberof StockyApiStockySearchUserList
     */
    readonly role?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockySearchUserList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockySearchUserList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockySubtasksListList operation in StockyApi.
 * @export
 * @interface StockyApiStockySubtasksListListRequest
 */
export interface StockyApiStockySubtasksListListRequest {
    /**
     * 
     * @type {string}
     * @memberof StockyApiStockySubtasksListList
     */
    readonly task_id: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockySubtasksListList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockySubtasksListList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockyTagsCreate operation in StockyApi.
 * @export
 * @interface StockyApiStockyTagsCreateRequest
 */
export interface StockyApiStockyTagsCreateRequest {
    /**
     * 
     * @type {Tag}
     * @memberof StockyApiStockyTagsCreate
     */
    readonly data: Tag
}

/**
 * Request parameters for stockyTaskCommentsCreate operation in StockyApi.
 * @export
 * @interface StockyApiStockyTaskCommentsCreateRequest
 */
export interface StockyApiStockyTaskCommentsCreateRequest {
    /**
     * 
     * @type {TaskThread}
     * @memberof StockyApiStockyTaskCommentsCreate
     */
    readonly data: TaskThread
}

/**
 * Request parameters for stockyTaskCommentsDelete operation in StockyApi.
 * @export
 * @interface StockyApiStockyTaskCommentsDeleteRequest
 */
export interface StockyApiStockyTaskCommentsDeleteRequest {
    /**
     * A unique integer value identifying this thread.
     * @type {number}
     * @memberof StockyApiStockyTaskCommentsDelete
     */
    readonly id: number
}

/**
 * Request parameters for stockyTaskCommentsList operation in StockyApi.
 * @export
 * @interface StockyApiStockyTaskCommentsListRequest
 */
export interface StockyApiStockyTaskCommentsListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockyTaskCommentsList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockyTaskCommentsList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockyTaskCommentsPartialUpdate operation in StockyApi.
 * @export
 * @interface StockyApiStockyTaskCommentsPartialUpdateRequest
 */
export interface StockyApiStockyTaskCommentsPartialUpdateRequest {
    /**
     * A unique integer value identifying this thread.
     * @type {number}
     * @memberof StockyApiStockyTaskCommentsPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {TaskThread}
     * @memberof StockyApiStockyTaskCommentsPartialUpdate
     */
    readonly data: TaskThread
}

/**
 * Request parameters for stockyTaskCommentsRead operation in StockyApi.
 * @export
 * @interface StockyApiStockyTaskCommentsReadRequest
 */
export interface StockyApiStockyTaskCommentsReadRequest {
    /**
     * A unique integer value identifying this thread.
     * @type {number}
     * @memberof StockyApiStockyTaskCommentsRead
     */
    readonly id: number
}

/**
 * Request parameters for stockyTaskCommentsUpdate operation in StockyApi.
 * @export
 * @interface StockyApiStockyTaskCommentsUpdateRequest
 */
export interface StockyApiStockyTaskCommentsUpdateRequest {
    /**
     * A unique integer value identifying this thread.
     * @type {number}
     * @memberof StockyApiStockyTaskCommentsUpdate
     */
    readonly id: number

    /**
     * 
     * @type {TaskThread}
     * @memberof StockyApiStockyTaskCommentsUpdate
     */
    readonly data: TaskThread
}

/**
 * Request parameters for stockyTasksCreate operation in StockyApi.
 * @export
 * @interface StockyApiStockyTasksCreateRequest
 */
export interface StockyApiStockyTasksCreateRequest {
    /**
     * 
     * @type {Task}
     * @memberof StockyApiStockyTasksCreate
     */
    readonly data: Task
}

/**
 * Request parameters for stockyTasksDelete operation in StockyApi.
 * @export
 * @interface StockyApiStockyTasksDeleteRequest
 */
export interface StockyApiStockyTasksDeleteRequest {
    /**
     * A unique integer value identifying this task.
     * @type {number}
     * @memberof StockyApiStockyTasksDelete
     */
    readonly id: number
}

/**
 * Request parameters for stockyTasksList operation in StockyApi.
 * @export
 * @interface StockyApiStockyTasksListRequest
 */
export interface StockyApiStockyTasksListRequest {
    /**
     * client_request
     * @type {string}
     * @memberof StockyApiStockyTasksList
     */
    readonly client_request?: string

    /**
     * status
     * @type {string}
     * @memberof StockyApiStockyTasksList
     */
    readonly status?: string

    /**
     * assigned
     * @type {string}
     * @memberof StockyApiStockyTasksList
     */
    readonly assigned?: string

    /**
     * client
     * @type {string}
     * @memberof StockyApiStockyTasksList
     */
    readonly client?: string

    /**
     * start_date
     * @type {string}
     * @memberof StockyApiStockyTasksList
     */
    readonly start_date?: string

    /**
     * end_date
     * @type {string}
     * @memberof StockyApiStockyTasksList
     */
    readonly end_date?: string

    /**
     * status_exclude
     * @type {string}
     * @memberof StockyApiStockyTasksList
     */
    readonly status_exclude?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockyTasksList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockyTasksList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockyTasksPartialUpdate operation in StockyApi.
 * @export
 * @interface StockyApiStockyTasksPartialUpdateRequest
 */
export interface StockyApiStockyTasksPartialUpdateRequest {
    /**
     * A unique integer value identifying this task.
     * @type {number}
     * @memberof StockyApiStockyTasksPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Task}
     * @memberof StockyApiStockyTasksPartialUpdate
     */
    readonly data: Task
}

/**
 * Request parameters for stockyTasksRead operation in StockyApi.
 * @export
 * @interface StockyApiStockyTasksReadRequest
 */
export interface StockyApiStockyTasksReadRequest {
    /**
     * A unique integer value identifying this task.
     * @type {number}
     * @memberof StockyApiStockyTasksRead
     */
    readonly id: number
}

/**
 * Request parameters for stockyTasksUpdate operation in StockyApi.
 * @export
 * @interface StockyApiStockyTasksUpdateRequest
 */
export interface StockyApiStockyTasksUpdateRequest {
    /**
     * A unique integer value identifying this task.
     * @type {number}
     * @memberof StockyApiStockyTasksUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Task}
     * @memberof StockyApiStockyTasksUpdate
     */
    readonly data: Task
}

/**
 * Request parameters for stockyTemplateContentRead operation in StockyApi.
 * @export
 * @interface StockyApiStockyTemplateContentReadRequest
 */
export interface StockyApiStockyTemplateContentReadRequest {
    /**
     * 
     * @type {string}
     * @memberof StockyApiStockyTemplateContentRead
     */
    readonly slug: string
}

/**
 * Request parameters for stockyTemplatesList operation in StockyApi.
 * @export
 * @interface StockyApiStockyTemplatesListRequest
 */
export interface StockyApiStockyTemplatesListRequest {
    /**
     * slug
     * @type {string}
     * @memberof StockyApiStockyTemplatesList
     */
    readonly slug?: string

    /**
     * target__in
     * @type {string}
     * @memberof StockyApiStockyTemplatesList
     */
    readonly target__in?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof StockyApiStockyTemplatesList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof StockyApiStockyTemplatesList
     */
    readonly offset?: number
}

/**
 * Request parameters for stockyUploadGroupCreate operation in StockyApi.
 * @export
 * @interface StockyApiStockyUploadGroupCreateRequest
 */
export interface StockyApiStockyUploadGroupCreateRequest {
    /**
     * 
     * @type {GroupFileUpload}
     * @memberof StockyApiStockyUploadGroupCreate
     */
    readonly data: GroupFileUpload
}

/**
 * StockyApi - object-oriented interface
 * @export
 * @class StockyApi
 * @extends {BaseAPI}
 */
export class StockyApi extends BaseAPI {
    /**
     * 
     * @param {StockyApiStockyAttendanceCheckInDayReportReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyAttendanceCheckInDayReportRead(requestParameters: StockyApiStockyAttendanceCheckInDayReportReadRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyAttendanceCheckInDayReportRead(requestParameters.date, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyAttendanceCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyAttendanceCreate(requestParameters: StockyApiStockyAttendanceCreateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyAttendanceCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyAttendanceListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyAttendanceList(requestParameters: StockyApiStockyAttendanceListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyAttendanceList(requestParameters.user, requestParameters.created_at__month, requestParameters.created_at__year, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyAttendanceReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyAttendanceRead(requestParameters: StockyApiStockyAttendanceReadRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyAttendanceRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyAttendanceReportUserMonthListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyAttendanceReportUserMonthList(requestParameters: StockyApiStockyAttendanceReportUserMonthListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyAttendanceReportUserMonthList(requestParameters.user, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyAttendanceStatusRead(options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyAttendanceStatusRead(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyBrandCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyBrandCreate(requestParameters: StockyApiStockyBrandCreateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyBrandCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyBrandListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyBrandList(requestParameters: StockyApiStockyBrandListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyBrandList(requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyBrandReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyBrandRead(requestParameters: StockyApiStockyBrandReadRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyBrandRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyCategoryCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyCategoryCreate(requestParameters: StockyApiStockyCategoryCreateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyCategoryCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyClientRequestCommentsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyClientRequestCommentsCreate(requestParameters: StockyApiStockyClientRequestCommentsCreateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyClientRequestCommentsCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyClientRequestCommentsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyClientRequestCommentsDelete(requestParameters: StockyApiStockyClientRequestCommentsDeleteRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyClientRequestCommentsDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyClientRequestCommentsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyClientRequestCommentsList(requestParameters: StockyApiStockyClientRequestCommentsListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyClientRequestCommentsList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyClientRequestCommentsPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyClientRequestCommentsPartialUpdate(requestParameters: StockyApiStockyClientRequestCommentsPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyClientRequestCommentsPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyClientRequestCommentsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyClientRequestCommentsRead(requestParameters: StockyApiStockyClientRequestCommentsReadRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyClientRequestCommentsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyClientRequestCommentsUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyClientRequestCommentsUpdate(requestParameters: StockyApiStockyClientRequestCommentsUpdateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyClientRequestCommentsUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyClientRequestsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyClientRequestsCreate(requestParameters: StockyApiStockyClientRequestsCreateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyClientRequestsCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyClientRequestsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyClientRequestsDelete(requestParameters: StockyApiStockyClientRequestsDeleteRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyClientRequestsDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyClientRequestsExportListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyClientRequestsExportList(requestParameters: StockyApiStockyClientRequestsExportListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyClientRequestsExportList(requestParameters.serial_number, requestParameters.connect, requestParameters.category, requestParameters.slug, requestParameters.start_date, requestParameters.end_date, requestParameters.stages, requestParameters.exclude_stages, requestParameters.consignee__auto_id, requestParameters.consignee__city, requestParameters.consignee__state, requestParameters.consignee__pincode, requestParameters.task_id, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyClientRequestsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyClientRequestsList(requestParameters: StockyApiStockyClientRequestsListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyClientRequestsList(requestParameters.search, requestParameters.serial_number, requestParameters.connect, requestParameters.category, requestParameters.slug, requestParameters.start_date, requestParameters.end_date, requestParameters.stages, requestParameters.exclude_stages, requestParameters.consignee__auto_id, requestParameters.consignee__city, requestParameters.consignee__state, requestParameters.consignee__pincode, requestParameters.task_id, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyClientRequestsPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyClientRequestsPartialUpdate(requestParameters: StockyApiStockyClientRequestsPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyClientRequestsPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyClientRequestsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyClientRequestsRead(requestParameters: StockyApiStockyClientRequestsReadRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyClientRequestsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyClientRequestsStageCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyClientRequestsStageCreate(requestParameters: StockyApiStockyClientRequestsStageCreateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyClientRequestsStageCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyClientRequestsStageListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyClientRequestsStageList(requestParameters: StockyApiStockyClientRequestsStageListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyClientRequestsStageList(requestParameters.stage, requestParameters.client_request, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyClientRequestsStageReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyClientRequestsStageRead(requestParameters: StockyApiStockyClientRequestsStageReadRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyClientRequestsStageRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyClientRequestsUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyClientRequestsUpdate(requestParameters: StockyApiStockyClientRequestsUpdateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyClientRequestsUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyConnectCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyConnectCreate(requestParameters: StockyApiStockyConnectCreateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyConnectCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyConnectExportListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyConnectExportList(requestParameters: StockyApiStockyConnectExportListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyConnectExportList(requestParameters.is_rental_client, requestParameters.is_individual, requestParameters.is_supplier, requestParameters.company, requestParameters.gst_number, requestParameters.is_sales_client, requestParameters.connect, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyConnectListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyConnectList(requestParameters: StockyApiStockyConnectListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyConnectList(requestParameters.search, requestParameters.is_rental_client, requestParameters.is_individual, requestParameters.is_supplier, requestParameters.company, requestParameters.gst_number, requestParameters.is_sales_client, requestParameters.connect, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyConnectPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyConnectPartialUpdate(requestParameters: StockyApiStockyConnectPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyConnectPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyConnectPocEmailsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyConnectPocEmailsRead(requestParameters: StockyApiStockyConnectPocEmailsReadRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyConnectPocEmailsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyConnectReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyConnectRead(requestParameters: StockyApiStockyConnectReadRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyConnectRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyConnectUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyConnectUpdate(requestParameters: StockyApiStockyConnectUpdateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyConnectUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyDeliveryChalanCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyDeliveryChalanCreate(requestParameters: StockyApiStockyDeliveryChalanCreateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyDeliveryChalanCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyDeliveryChalanDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyDeliveryChalanDelete(requestParameters: StockyApiStockyDeliveryChalanDeleteRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyDeliveryChalanDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyDeliveryChalanItemsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyDeliveryChalanItemsCreate(requestParameters: StockyApiStockyDeliveryChalanItemsCreateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyDeliveryChalanItemsCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyDeliveryChalanItemsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyDeliveryChalanItemsDelete(requestParameters: StockyApiStockyDeliveryChalanItemsDeleteRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyDeliveryChalanItemsDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyDeliveryChalanItemsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyDeliveryChalanItemsList(requestParameters: StockyApiStockyDeliveryChalanItemsListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyDeliveryChalanItemsList(requestParameters.dc, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyDeliveryChalanItemsPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyDeliveryChalanItemsPartialUpdate(requestParameters: StockyApiStockyDeliveryChalanItemsPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyDeliveryChalanItemsPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyDeliveryChalanItemsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyDeliveryChalanItemsRead(requestParameters: StockyApiStockyDeliveryChalanItemsReadRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyDeliveryChalanItemsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyDeliveryChalanItemsUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyDeliveryChalanItemsUpdate(requestParameters: StockyApiStockyDeliveryChalanItemsUpdateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyDeliveryChalanItemsUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyDeliveryChalanListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyDeliveryChalanList(requestParameters: StockyApiStockyDeliveryChalanListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyDeliveryChalanList(requestParameters.status, requestParameters.ids, requestParameters.eway_bill_required, requestParameters.connect, requestParameters.company, requestParameters.order, requestParameters.serial, requestParameters.start_date, requestParameters.end_date, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyDeliveryChalanPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyDeliveryChalanPartialUpdate(requestParameters: StockyApiStockyDeliveryChalanPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyDeliveryChalanPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyDeliveryChalanReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyDeliveryChalanRead(requestParameters: StockyApiStockyDeliveryChalanReadRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyDeliveryChalanRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyDeliveryChalanUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyDeliveryChalanUpdate(requestParameters: StockyApiStockyDeliveryChalanUpdateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyDeliveryChalanUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyExportComputerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyExportComputerCreate(requestParameters: StockyApiStockyExportComputerCreateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyExportComputerCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyExportDcListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyExportDcList(requestParameters: StockyApiStockyExportDcListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyExportDcList(requestParameters.status, requestParameters.ids, requestParameters.eway_bill_required, requestParameters.connect, requestParameters.company, requestParameters.order, requestParameters.serial, requestParameters.start_date, requestParameters.end_date, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyExportProductsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyExportProductsCreate(requestParameters: StockyApiStockyExportProductsCreateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyExportProductsCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyExportProductsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyExportProductsList(requestParameters: StockyApiStockyExportProductsListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyExportProductsList(requestParameters.brand__id, requestParameters.item__id, requestParameters.serial, requestParameters.tag__id, requestParameters.ids, requestParameters.processor, requestParameters.generation, requestParameters.stock_filter, requestParameters.current_category, requestParameters.start_date, requestParameters.end_date, requestParameters.asset_status, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyFilterProductSerialListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyFilterProductSerialList(requestParameters: StockyApiStockyFilterProductSerialListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyFilterProductSerialList(requestParameters.serial, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyGreetingsRead(options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyGreetingsRead(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyGroupItemsAddCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyGroupItemsAddCreate(requestParameters: StockyApiStockyGroupItemsAddCreateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyGroupItemsAddCreate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyGroupItemsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyGroupItemsDelete(requestParameters: StockyApiStockyGroupItemsDeleteRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyGroupItemsDelete(requestParameters.id, requestParameters.item_id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyGroupItemsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyGroupItemsList(requestParameters: StockyApiStockyGroupItemsListRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyGroupItemsList(requestParameters.id, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyGroupsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyGroupsCreate(requestParameters: StockyApiStockyGroupsCreateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyGroupsCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyGroupsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyGroupsDelete(requestParameters: StockyApiStockyGroupsDeleteRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyGroupsDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyGroupsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyGroupsList(requestParameters: StockyApiStockyGroupsListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyGroupsList(requestParameters.connect, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyGroupsPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyGroupsPartialUpdate(requestParameters: StockyApiStockyGroupsPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyGroupsPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyGroupsUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyGroupsUpdate(requestParameters: StockyApiStockyGroupsUpdateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyGroupsUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyHsnCodesListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyHsnCodesList(requestParameters: StockyApiStockyHsnCodesListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyHsnCodesList(requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyHsnCodesReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyHsnCodesRead(requestParameters: StockyApiStockyHsnCodesReadRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyHsnCodesRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyInventoryComputerCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyInventoryComputerCreate(requestParameters: StockyApiStockyInventoryComputerCreateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyInventoryComputerCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyItemCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyItemCreate(requestParameters: StockyApiStockyItemCreateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyItemCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogisticsDcCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogisticsDcCreate(requestParameters: StockyApiStockyLogisticsDcCreateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogisticsDcCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogisticsDcListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogisticsDcList(requestParameters: StockyApiStockyLogisticsDcListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogisticsDcList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogisticsRelatedReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogisticsRelatedRead(requestParameters: StockyApiStockyLogisticsRelatedReadRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogisticsRelatedRead(requestParameters.serial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsAssetCurrentCategoryCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsAssetCurrentCategoryCreate(requestParameters: StockyApiStockyLogsAssetCurrentCategoryCreateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsAssetCurrentCategoryCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsAssetTrailDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsAssetTrailDelete(requestParameters: StockyApiStockyLogsAssetTrailDeleteRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsAssetTrailDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsAssetTrailListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsAssetTrailList(requestParameters: StockyApiStockyLogsAssetTrailListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsAssetTrailList(requestParameters.serial, requestParameters.start_date, requestParameters.end_date, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsExportLogisticsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsExportLogisticsList(requestParameters: StockyApiStockyLogsExportLogisticsListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsExportLogisticsList(requestParameters.ops_type, requestParameters.serial, requestParameters.product__item__id, requestParameters.order_type, requestParameters.connect, requestParameters.tracking_no, requestParameters.replacement_asset__status, requestParameters.replacement_asset__serial, requestParameters.order, requestParameters.cost_to, requestParameters.logistics_partner, requestParameters.pagination, requestParameters.meta__CONDITION_OF_ASSET, requestParameters.start_date, requestParameters.end_date, requestParameters.asset_status, requestParameters.delivery, requestParameters.exclude_id, requestParameters.tracking_number_isnull, requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsExportOperationsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsExportOperationsList(requestParameters: StockyApiStockyLogsExportOperationsListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsExportOperationsList(requestParameters.ops_type, requestParameters.connect, requestParameters.serial, requestParameters.use_type, requestParameters.handover, requestParameters.generic_product__item__id, requestParameters.replacement__status, requestParameters.replacement__serial, requestParameters.order, requestParameters.po__po_number, requestParameters.meta__CONDITION_OF_ASSET, requestParameters.start_date, requestParameters.end_date, requestParameters.asset_status, requestParameters.current_category, requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsExportQualityCheckListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsExportQualityCheckList(requestParameters: StockyApiStockyLogsExportQualityCheckListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsExportQualityCheckList(requestParameters.status, requestParameters.from_to_user, requestParameters.serial, requestParameters.ops_type, requestParameters.start_date, requestParameters.end_date, requestParameters.asset_status, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsExportServiceReturnListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsExportServiceReturnList(requestParameters: StockyApiStockyLogsExportServiceReturnListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsExportServiceReturnList(requestParameters.from_user, requestParameters.serial, requestParameters.tag__id, requestParameters.handover_to, requestParameters.status, requestParameters.generic_product__item__id, requestParameters.start_date, requestParameters.end_date, requestParameters.asset_status, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsExportServiceSentListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsExportServiceSentList(requestParameters: StockyApiStockyLogsExportServiceSentListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsExportServiceSentList(requestParameters.serial, requestParameters.assigned_to, requestParameters.generic_product__item__id, requestParameters.fromuser, requestParameters.tag__id, requestParameters.start_date, requestParameters.end_date, requestParameters.asset_status, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsLogisticsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsLogisticsCreate(requestParameters: StockyApiStockyLogsLogisticsCreateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsLogisticsCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsLogisticsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsLogisticsDelete(requestParameters: StockyApiStockyLogsLogisticsDeleteRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsLogisticsDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsLogisticsDeliveryCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsLogisticsDeliveryCreate(requestParameters: StockyApiStockyLogsLogisticsDeliveryCreateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsLogisticsDeliveryCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsLogisticsDeliveryPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsLogisticsDeliveryPartialUpdate(requestParameters: StockyApiStockyLogsLogisticsDeliveryPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsLogisticsDeliveryPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsLogisticsDeliveryReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsLogisticsDeliveryRead(requestParameters: StockyApiStockyLogsLogisticsDeliveryReadRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsLogisticsDeliveryRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsLogisticsDeliveryUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsLogisticsDeliveryUpdate(requestParameters: StockyApiStockyLogsLogisticsDeliveryUpdateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsLogisticsDeliveryUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsLogisticsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsLogisticsList(requestParameters: StockyApiStockyLogsLogisticsListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsLogisticsList(requestParameters.ops_type, requestParameters.serial, requestParameters.product__item__id, requestParameters.order_type, requestParameters.connect, requestParameters.tracking_no, requestParameters.replacement_asset__status, requestParameters.replacement_asset__serial, requestParameters.order, requestParameters.cost_to, requestParameters.logistics_partner, requestParameters.pagination, requestParameters.meta__CONDITION_OF_ASSET, requestParameters.start_date, requestParameters.end_date, requestParameters.asset_status, requestParameters.delivery, requestParameters.exclude_id, requestParameters.tracking_number_isnull, requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsLogisticsPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsLogisticsPartialUpdate(requestParameters: StockyApiStockyLogsLogisticsPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsLogisticsPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsLogisticsPickupAttemptCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsLogisticsPickupAttemptCreate(requestParameters: StockyApiStockyLogsLogisticsPickupAttemptCreateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsLogisticsPickupAttemptCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsLogisticsPickupAttemptDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsLogisticsPickupAttemptDelete(requestParameters: StockyApiStockyLogsLogisticsPickupAttemptDeleteRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsLogisticsPickupAttemptDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsLogisticsPickupAttemptListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsLogisticsPickupAttemptList(requestParameters: StockyApiStockyLogsLogisticsPickupAttemptListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsLogisticsPickupAttemptList(requestParameters.city, requestParameters.logistics_partner, requestParameters.cost_to, requestParameters.slug, requestParameters.state, requestParameters.tracking_no, requestParameters.amount, requestParameters.pincode, requestParameters.logistics, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsLogisticsPickupAttemptPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsLogisticsPickupAttemptPartialUpdate(requestParameters: StockyApiStockyLogsLogisticsPickupAttemptPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsLogisticsPickupAttemptPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsLogisticsPickupAttemptReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsLogisticsPickupAttemptRead(requestParameters: StockyApiStockyLogsLogisticsPickupAttemptReadRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsLogisticsPickupAttemptRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsLogisticsPickupAttemptUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsLogisticsPickupAttemptUpdate(requestParameters: StockyApiStockyLogsLogisticsPickupAttemptUpdateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsLogisticsPickupAttemptUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsLogisticsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsLogisticsRead(requestParameters: StockyApiStockyLogsLogisticsReadRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsLogisticsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsLogisticsUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsLogisticsUpdate(requestParameters: StockyApiStockyLogsLogisticsUpdateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsLogisticsUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsMediaLogisticsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsMediaLogisticsRead(requestParameters: StockyApiStockyLogsMediaLogisticsReadRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsMediaLogisticsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsMigrateOperationsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsMigrateOperationsCreate(requestParameters: StockyApiStockyLogsMigrateOperationsCreateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsMigrateOperationsCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsMigrateQualityCheckCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsMigrateQualityCheckCreate(requestParameters: StockyApiStockyLogsMigrateQualityCheckCreateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsMigrateQualityCheckCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsMigrateReturnFromServiceCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsMigrateReturnFromServiceCreate(requestParameters: StockyApiStockyLogsMigrateReturnFromServiceCreateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsMigrateReturnFromServiceCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsMigrateServiceReAssignCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsMigrateServiceReAssignCreate(requestParameters: StockyApiStockyLogsMigrateServiceReAssignCreateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsMigrateServiceReAssignCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsOperationsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsOperationsCreate(requestParameters: StockyApiStockyLogsOperationsCreateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsOperationsCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsOperationsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsOperationsDelete(requestParameters: StockyApiStockyLogsOperationsDeleteRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsOperationsDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsOperationsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsOperationsList(requestParameters: StockyApiStockyLogsOperationsListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsOperationsList(requestParameters.ops_type, requestParameters.connect, requestParameters.serial, requestParameters.use_type, requestParameters.handover, requestParameters.generic_product__item__id, requestParameters.replacement__status, requestParameters.replacement__serial, requestParameters.order, requestParameters.po__po_number, requestParameters.meta__CONDITION_OF_ASSET, requestParameters.start_date, requestParameters.end_date, requestParameters.asset_status, requestParameters.current_category, requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsOperationsPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsOperationsPartialUpdate(requestParameters: StockyApiStockyLogsOperationsPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsOperationsPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsOperationsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsOperationsRead(requestParameters: StockyApiStockyLogsOperationsReadRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsOperationsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsOperationsUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsOperationsUpdate(requestParameters: StockyApiStockyLogsOperationsUpdateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsOperationsUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsQualityCheckCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsQualityCheckCreate(requestParameters: StockyApiStockyLogsQualityCheckCreateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsQualityCheckCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsQualityCheckDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsQualityCheckDelete(requestParameters: StockyApiStockyLogsQualityCheckDeleteRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsQualityCheckDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsQualityCheckListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsQualityCheckList(requestParameters: StockyApiStockyLogsQualityCheckListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsQualityCheckList(requestParameters.status, requestParameters.from_to_user, requestParameters.serial, requestParameters.ops_type, requestParameters.start_date, requestParameters.end_date, requestParameters.asset_status, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsQualityCheckPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsQualityCheckPartialUpdate(requestParameters: StockyApiStockyLogsQualityCheckPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsQualityCheckPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsQualityCheckUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsQualityCheckUpdate(requestParameters: StockyApiStockyLogsQualityCheckUpdateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsQualityCheckUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsReplacementAssetPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsReplacementAssetPartialUpdate(requestParameters: StockyApiStockyLogsReplacementAssetPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsReplacementAssetPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsReplacementAssetUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsReplacementAssetUpdate(requestParameters: StockyApiStockyLogsReplacementAssetUpdateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsReplacementAssetUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsSentServiceCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsSentServiceCreate(requestParameters: StockyApiStockyLogsSentServiceCreateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsSentServiceCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsSentServiceDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsSentServiceDelete(requestParameters: StockyApiStockyLogsSentServiceDeleteRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsSentServiceDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsSentServiceListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsSentServiceList(requestParameters: StockyApiStockyLogsSentServiceListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsSentServiceList(requestParameters.serial, requestParameters.assigned_to, requestParameters.generic_product__item__id, requestParameters.fromuser, requestParameters.tag__id, requestParameters.start_date, requestParameters.end_date, requestParameters.asset_status, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsServiceReturnCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsServiceReturnCreate(requestParameters: StockyApiStockyLogsServiceReturnCreateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsServiceReturnCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsServiceReturnDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsServiceReturnDelete(requestParameters: StockyApiStockyLogsServiceReturnDeleteRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsServiceReturnDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyLogsServiceReturnListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyLogsServiceReturnList(requestParameters: StockyApiStockyLogsServiceReturnListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyLogsServiceReturnList(requestParameters.from_user, requestParameters.serial, requestParameters.tag__id, requestParameters.handover_to, requestParameters.status, requestParameters.generic_product__item__id, requestParameters.start_date, requestParameters.end_date, requestParameters.asset_status, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyProductsBiosPasswordReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyProductsBiosPasswordRead(requestParameters: StockyApiStockyProductsBiosPasswordReadRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyProductsBiosPasswordRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyProductsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyProductsCreate(requestParameters: StockyApiStockyProductsCreateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyProductsCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyProductsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyProductsList(requestParameters: StockyApiStockyProductsListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyProductsList(requestParameters.brand__id, requestParameters.item__id, requestParameters.serial, requestParameters.tag__id, requestParameters.ids, requestParameters.processor, requestParameters.generation, requestParameters.stock_filter, requestParameters.current_category, requestParameters.start_date, requestParameters.end_date, requestParameters.asset_status, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyProductsPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyProductsPartialUpdate(requestParameters: StockyApiStockyProductsPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyProductsPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyProductsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyProductsRead(requestParameters: StockyApiStockyProductsReadRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyProductsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyProductsUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyProductsUpdate(requestParameters: StockyApiStockyProductsUpdateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyProductsUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyPublicProductListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyPublicProductList(requestParameters: StockyApiStockyPublicProductListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyPublicProductList(requestParameters.brand__id, requestParameters.item__id, requestParameters.serial, requestParameters.tag__id, requestParameters.ids, requestParameters.processor, requestParameters.generation, requestParameters.stock_filter, requestParameters.current_category, requestParameters.start_date, requestParameters.end_date, requestParameters.asset_status, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyPublicProductReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyPublicProductRead(requestParameters: StockyApiStockyPublicProductReadRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyPublicProductRead(requestParameters.key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyPublicProductSerialReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyPublicProductSerialRead(requestParameters: StockyApiStockyPublicProductSerialReadRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyPublicProductSerialRead(requestParameters.serial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyPublicTicketConnectReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyPublicTicketConnectRead(requestParameters: StockyApiStockyPublicTicketConnectReadRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyPublicTicketConnectRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyQuoteCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyQuoteCreate(requestParameters: StockyApiStockyQuoteCreateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyQuoteCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyQuoteListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyQuoteList(requestParameters: StockyApiStockyQuoteListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyQuoteList(requestParameters.user, requestParameters.qr, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyQuoteRequestCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyQuoteRequestCreate(requestParameters: StockyApiStockyQuoteRequestCreateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyQuoteRequestCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyQuoteRequestListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyQuoteRequestList(requestParameters: StockyApiStockyQuoteRequestListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyQuoteRequestList(requestParameters.request_type, requestParameters.lead, requestParameters.connect, requestParameters.stage, requestParameters.user, requestParameters.lead_user, requestParameters.is_group, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyQuoteRequestReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyQuoteRequestRead(requestParameters: StockyApiStockyQuoteRequestReadRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyQuoteRequestRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyQuoteRequestStageCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyQuoteRequestStageCreate(requestParameters: StockyApiStockyQuoteRequestStageCreateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyQuoteRequestStageCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyQuoteRequestStageListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyQuoteRequestStageList(requestParameters: StockyApiStockyQuoteRequestStageListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyQuoteRequestStageList(requestParameters.qr, requestParameters.stage, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyReportsMasterListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyReportsMasterList(requestParameters: StockyApiStockyReportsMasterListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyReportsMasterList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyReportsOperationsModelWiseQtyListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyReportsOperationsModelWiseQtyList(requestParameters: StockyApiStockyReportsOperationsModelWiseQtyListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyReportsOperationsModelWiseQtyList(requestParameters.current_category, requestParameters.fields, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyReportsQualityCheckModelWiseQtyListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyReportsQualityCheckModelWiseQtyList(requestParameters: StockyApiStockyReportsQualityCheckModelWiseQtyListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyReportsQualityCheckModelWiseQtyList(requestParameters.fields, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyReportsServiceModelWiseQtyListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyReportsServiceModelWiseQtyList(requestParameters: StockyApiStockyReportsServiceModelWiseQtyListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyReportsServiceModelWiseQtyList(requestParameters.current_category, requestParameters.fields, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockySavedFilterCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockySavedFilterCreate(requestParameters: StockyApiStockySavedFilterCreateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockySavedFilterCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockySavedFilterDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockySavedFilterDelete(requestParameters: StockyApiStockySavedFilterDeleteRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockySavedFilterDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockySavedFilterListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockySavedFilterList(requestParameters: StockyApiStockySavedFilterListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockySavedFilterList(requestParameters.category, requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockySavedFilterReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockySavedFilterRead(requestParameters: StockyApiStockySavedFilterReadRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockySavedFilterRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockySearchCategoryListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockySearchCategoryList(requestParameters: StockyApiStockySearchCategoryListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockySearchCategoryList(requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockySearchConnectListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockySearchConnectList(requestParameters: StockyApiStockySearchConnectListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockySearchConnectList(requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockySearchConnectReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockySearchConnectRead(requestParameters: StockyApiStockySearchConnectReadRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockySearchConnectRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockySearchItemListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockySearchItemList(requestParameters: StockyApiStockySearchItemListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockySearchItemList(requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockySearchTagListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockySearchTagList(requestParameters: StockyApiStockySearchTagListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockySearchTagList(requestParameters.category, requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockySearchUserListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockySearchUserList(requestParameters: StockyApiStockySearchUserListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockySearchUserList(requestParameters.search, requestParameters.email, requestParameters.role, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockySubtasksListListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockySubtasksListList(requestParameters: StockyApiStockySubtasksListListRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockySubtasksListList(requestParameters.task_id, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyTagsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyTagsCreate(requestParameters: StockyApiStockyTagsCreateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyTagsCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyTaskCommentsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyTaskCommentsCreate(requestParameters: StockyApiStockyTaskCommentsCreateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyTaskCommentsCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyTaskCommentsDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyTaskCommentsDelete(requestParameters: StockyApiStockyTaskCommentsDeleteRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyTaskCommentsDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyTaskCommentsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyTaskCommentsList(requestParameters: StockyApiStockyTaskCommentsListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyTaskCommentsList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyTaskCommentsPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyTaskCommentsPartialUpdate(requestParameters: StockyApiStockyTaskCommentsPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyTaskCommentsPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyTaskCommentsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyTaskCommentsRead(requestParameters: StockyApiStockyTaskCommentsReadRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyTaskCommentsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyTaskCommentsUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyTaskCommentsUpdate(requestParameters: StockyApiStockyTaskCommentsUpdateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyTaskCommentsUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyTasksCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyTasksCreate(requestParameters: StockyApiStockyTasksCreateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyTasksCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyTasksDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyTasksDelete(requestParameters: StockyApiStockyTasksDeleteRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyTasksDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyTasksListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyTasksList(requestParameters: StockyApiStockyTasksListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyTasksList(requestParameters.client_request, requestParameters.status, requestParameters.assigned, requestParameters.client, requestParameters.start_date, requestParameters.end_date, requestParameters.status_exclude, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyTasksPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyTasksPartialUpdate(requestParameters: StockyApiStockyTasksPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyTasksPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyTasksReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyTasksRead(requestParameters: StockyApiStockyTasksReadRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyTasksRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyTasksUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyTasksUpdate(requestParameters: StockyApiStockyTasksUpdateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyTasksUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyTemplateContentReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyTemplateContentRead(requestParameters: StockyApiStockyTemplateContentReadRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyTemplateContentRead(requestParameters.slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyTemplatesListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyTemplatesList(requestParameters: StockyApiStockyTemplatesListRequest = {}, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyTemplatesList(requestParameters.slug, requestParameters.target__in, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StockyApiStockyUploadGroupCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyUploadGroupCreate(requestParameters: StockyApiStockyUploadGroupCreateRequest, options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyUploadGroupCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StockyApi
     */
    public stockyUuidRead(options?: RawAxiosRequestConfig) {
        return StockyApiFp(this.configuration).stockyUuidRead(options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const StockyDeliveryChalanListStatus = {
    _0: '0',
    _1: '1',
    _2: '2'
} as const;
export type StockyDeliveryChalanListStatus = typeof StockyDeliveryChalanListStatus[keyof typeof StockyDeliveryChalanListStatus];
/**
 * @export
 */
export const StockyExportDcListStatus = {
    _0: '0',
    _1: '1',
    _2: '2'
} as const;
export type StockyExportDcListStatus = typeof StockyExportDcListStatus[keyof typeof StockyExportDcListStatus];
