/* tslint:disable */
/* eslint-disable */
/**
 * Stocky API
 * Playground For Stocky API
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { Account } from '../model';
// @ts-ignore
import type { AccountsAssetVerificationExportList200Response } from '../model';
// @ts-ignore
import type { AccountsAssetVerificationList200Response } from '../model';
// @ts-ignore
import type { AccountsAssetVerificationMasterList200Response } from '../model';
// @ts-ignore
import type { AccountsList200Response } from '../model';
// @ts-ignore
import type { AccountsRentalLogisticsRateList200Response } from '../model';
// @ts-ignore
import type { AccountsRentalMatrixConfigList200Response } from '../model';
// @ts-ignore
import type { AccountsRentalMatrixItemList200Response } from '../model';
// @ts-ignore
import type { AppRefreshToken } from '../model';
// @ts-ignore
import type { AppToken } from '../model';
// @ts-ignore
import type { AssetVerification } from '../model';
// @ts-ignore
import type { AssetVerificationMaster } from '../model';
// @ts-ignore
import type { InstallationToken } from '../model';
// @ts-ignore
import type { PublicAssetVerification } from '../model';
// @ts-ignore
import type { PublicGenericProduct } from '../model';
// @ts-ignore
import type { ReadOnlyAccount } from '../model';
// @ts-ignore
import type { RentalItem } from '../model';
// @ts-ignore
import type { RentalItemConfig } from '../model';
// @ts-ignore
import type { RentalLogisticsRate } from '../model';
// @ts-ignore
import type { Signup } from '../model';
// @ts-ignore
import type { WelcomeClient } from '../model';
/**
 * AccountsApi - axios parameter creator
 * @export
 */
export const AccountsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {AppToken} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAppAccessTokenCreate: async (data: AppToken, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('accountsAppAccessTokenCreate', 'data', data)
            const localVarPath = `/accounts/app/access_token/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InstallationToken} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAppInstallTokenCreate: async (data: InstallationToken, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('accountsAppInstallTokenCreate', 'data', data)
            const localVarPath = `/accounts/app/install_token/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AppRefreshToken} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAppRefreshTokenCreate: async (data: AppRefreshToken, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('accountsAppRefreshTokenCreate', 'data', data)
            const localVarPath = `/accounts/app/refresh_token/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAssetVerificationAccountRead: async (slug: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'slug' is not null or undefined
            assertParamExists('accountsAssetVerificationAccountRead', 'slug', slug)
            const localVarPath = `/accounts/asset/verification/account/{slug}`
                .replace(`{${"slug"}}`, encodeURIComponent(String(slug)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AssetVerification} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAssetVerificationCreate: async (data: AssetVerification, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('accountsAssetVerificationCreate', 'data', data)
            const localVarPath = `/accounts/asset/verification/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this asset verification.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAssetVerificationDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountsAssetVerificationDelete', 'id', id)
            const localVarPath = `/accounts/asset/verification/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {string} [employee_id] employee_id
         * @param {string} [pincode] pincode
         * @param {string} [master__account] master__account
         * @param {string} [serial] serial
         * @param {string} [generic_product] generic_product
         * @param {string} [email] email
         * @param {string} [mobile] mobile
         * @param {string} [master] master
         * @param {AccountsAssetVerificationExportListStatus} [status] status
         * @param {string} [pagination] Pagination
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAssetVerificationExportList: async (search?: string, employee_id?: string, pincode?: string, master__account?: string, serial?: string, generic_product?: string, email?: string, mobile?: string, master?: string, status?: AccountsAssetVerificationExportListStatus, pagination?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts/asset/verification/export/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (employee_id !== undefined) {
                localVarQueryParameter['employee_id'] = employee_id;
            }

            if (pincode !== undefined) {
                localVarQueryParameter['pincode'] = pincode;
            }

            if (master__account !== undefined) {
                localVarQueryParameter['master__account'] = master__account;
            }

            if (serial !== undefined) {
                localVarQueryParameter['serial'] = serial;
            }

            if (generic_product !== undefined) {
                localVarQueryParameter['generic_product'] = generic_product;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (mobile !== undefined) {
                localVarQueryParameter['mobile'] = mobile;
            }

            if (master !== undefined) {
                localVarQueryParameter['master'] = master;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {string} [employee_id] employee_id
         * @param {string} [pincode] pincode
         * @param {string} [master__account] master__account
         * @param {string} [serial] serial
         * @param {string} [generic_product] generic_product
         * @param {string} [email] email
         * @param {string} [mobile] mobile
         * @param {string} [master] master
         * @param {AccountsAssetVerificationListStatus} [status] status
         * @param {string} [pagination] Pagination
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAssetVerificationList: async (search?: string, employee_id?: string, pincode?: string, master__account?: string, serial?: string, generic_product?: string, email?: string, mobile?: string, master?: string, status?: AccountsAssetVerificationListStatus, pagination?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts/asset/verification/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (employee_id !== undefined) {
                localVarQueryParameter['employee_id'] = employee_id;
            }

            if (pincode !== undefined) {
                localVarQueryParameter['pincode'] = pincode;
            }

            if (master__account !== undefined) {
                localVarQueryParameter['master__account'] = master__account;
            }

            if (serial !== undefined) {
                localVarQueryParameter['serial'] = serial;
            }

            if (generic_product !== undefined) {
                localVarQueryParameter['generic_product'] = generic_product;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (mobile !== undefined) {
                localVarQueryParameter['mobile'] = mobile;
            }

            if (master !== undefined) {
                localVarQueryParameter['master'] = master;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (pagination !== undefined) {
                localVarQueryParameter['pagination'] = pagination;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AssetVerificationMaster} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAssetVerificationMasterCreate: async (data: AssetVerificationMaster, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('accountsAssetVerificationMasterCreate', 'data', data)
            const localVarPath = `/accounts/asset/verification/master/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [slug] slug
         * @param {string} [track_location] track_location
         * @param {AccountsAssetVerificationMasterListStatus} [status] status
         * @param {string} [account] account
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAssetVerificationMasterList: async (slug?: string, track_location?: string, status?: AccountsAssetVerificationMasterListStatus, account?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts/asset/verification/master/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (slug !== undefined) {
                localVarQueryParameter['slug'] = slug;
            }

            if (track_location !== undefined) {
                localVarQueryParameter['track_location'] = track_location;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (account !== undefined) {
                localVarQueryParameter['account'] = account;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this asset verification master.
         * @param {AssetVerificationMaster} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAssetVerificationMasterPartialUpdate: async (id: number, data: AssetVerificationMaster, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountsAssetVerificationMasterPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('accountsAssetVerificationMasterPartialUpdate', 'data', data)
            const localVarPath = `/accounts/asset/verification/master/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this asset verification master.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAssetVerificationMasterRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountsAssetVerificationMasterRead', 'id', id)
            const localVarPath = `/accounts/asset/verification/master/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this asset verification master.
         * @param {AssetVerificationMaster} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAssetVerificationMasterUpdate: async (id: number, data: AssetVerificationMaster, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountsAssetVerificationMasterUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('accountsAssetVerificationMasterUpdate', 'data', data)
            const localVarPath = `/accounts/asset/verification/master/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this asset verification.
         * @param {AssetVerification} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAssetVerificationPartialUpdate: async (id: number, data: AssetVerification, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountsAssetVerificationPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('accountsAssetVerificationPartialUpdate', 'data', data)
            const localVarPath = `/accounts/asset/verification/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this asset verification.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAssetVerificationRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountsAssetVerificationRead', 'id', id)
            const localVarPath = `/accounts/asset/verification/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this asset verification.
         * @param {AssetVerification} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAssetVerificationUpdate: async (id: number, data: AssetVerification, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountsAssetVerificationUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('accountsAssetVerificationUpdate', 'data', data)
            const localVarPath = `/accounts/asset/verification/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAuthCheckRead: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts/auth/check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReadOnlyAccount} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsCreate: async (data: ReadOnlyAccount, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('accountsCreate', 'data', data)
            const localVarPath = `/accounts/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [id] id
         * @param {string} [connect__is_rental_client] connect__is_rental_client
         * @param {string} [connect__is_sales_client] connect__is_sales_client
         * @param {string} [connect__is_supplier] connect__is_supplier
         * @param {string} [connect__is_individual] connect__is_individual
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsList: async (id?: string, connect__is_rental_client?: string, connect__is_sales_client?: string, connect__is_supplier?: string, connect__is_individual?: string, search?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (connect__is_rental_client !== undefined) {
                localVarQueryParameter['connect__is_rental_client'] = connect__is_rental_client;
            }

            if (connect__is_sales_client !== undefined) {
                localVarQueryParameter['connect__is_sales_client'] = connect__is_sales_client;
            }

            if (connect__is_supplier !== undefined) {
                localVarQueryParameter['connect__is_supplier'] = connect__is_supplier;
            }

            if (connect__is_individual !== undefined) {
                localVarQueryParameter['connect__is_individual'] = connect__is_individual;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Account} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsManageCreate: async (data: Account, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('accountsManageCreate', 'data', data)
            const localVarPath = `/accounts/manage/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this account.
         * @param {Account} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsManagePartialUpdate: async (id: number, data: Account, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountsManagePartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('accountsManagePartialUpdate', 'data', data)
            const localVarPath = `/accounts/manage/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this account.
         * @param {Account} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsManageUpdate: async (id: number, data: Account, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountsManageUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('accountsManageUpdate', 'data', data)
            const localVarPath = `/accounts/manage/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Signup} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsOnboardingSignupCreate: async (data: Signup, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('accountsOnboardingSignupCreate', 'data', data)
            const localVarPath = `/accounts/onboarding/signup/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} serial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsPublicAssetRead: async (serial: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serial' is not null or undefined
            assertParamExists('accountsPublicAssetRead', 'serial', serial)
            const localVarPath = `/accounts/public/asset/{serial}`
                .replace(`{${"serial"}}`, encodeURIComponent(String(serial)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this account.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountsRead', 'id', id)
            const localVarPath = `/accounts/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RentalLogisticsRate} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsRentalLogisticsRateCreate: async (data: RentalLogisticsRate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('accountsRentalLogisticsRateCreate', 'data', data)
            const localVarPath = `/accounts/rental/logistics/rate/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rental logistics rate.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsRentalLogisticsRateDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountsRentalLogisticsRateDelete', 'id', id)
            const localVarPath = `/accounts/rental/logistics/rate/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [zone] zone
         * @param {string} [account] account
         * @param {string} [rate] rate
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsRentalLogisticsRateList: async (zone?: string, account?: string, rate?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts/rental/logistics/rate/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (zone !== undefined) {
                localVarQueryParameter['zone'] = zone;
            }

            if (account !== undefined) {
                localVarQueryParameter['account'] = account;
            }

            if (rate !== undefined) {
                localVarQueryParameter['rate'] = rate;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rental logistics rate.
         * @param {RentalLogisticsRate} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsRentalLogisticsRatePartialUpdate: async (id: number, data: RentalLogisticsRate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountsRentalLogisticsRatePartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('accountsRentalLogisticsRatePartialUpdate', 'data', data)
            const localVarPath = `/accounts/rental/logistics/rate/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rental logistics rate.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsRentalLogisticsRateRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountsRentalLogisticsRateRead', 'id', id)
            const localVarPath = `/accounts/rental/logistics/rate/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rental logistics rate.
         * @param {RentalLogisticsRate} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsRentalLogisticsRateUpdate: async (id: number, data: RentalLogisticsRate, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountsRentalLogisticsRateUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('accountsRentalLogisticsRateUpdate', 'data', data)
            const localVarPath = `/accounts/rental/logistics/rate/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RentalItemConfig} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsRentalMatrixConfigCreate: async (data: RentalItemConfig, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('accountsRentalMatrixConfigCreate', 'data', data)
            const localVarPath = `/accounts/rental/matrix/config/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rental item config.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsRentalMatrixConfigDelete: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountsRentalMatrixConfigDelete', 'id', id)
            const localVarPath = `/accounts/rental/matrix/config/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsRentalMatrixConfigList: async (limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts/rental/matrix/config/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rental item config.
         * @param {RentalItemConfig} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsRentalMatrixConfigPartialUpdate: async (id: number, data: RentalItemConfig, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountsRentalMatrixConfigPartialUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('accountsRentalMatrixConfigPartialUpdate', 'data', data)
            const localVarPath = `/accounts/rental/matrix/config/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rental item config.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsRentalMatrixConfigRead: async (id: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountsRentalMatrixConfigRead', 'id', id)
            const localVarPath = `/accounts/rental/matrix/config/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rental item config.
         * @param {RentalItemConfig} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsRentalMatrixConfigUpdate: async (id: number, data: RentalItemConfig, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('accountsRentalMatrixConfigUpdate', 'id', id)
            // verify required parameter 'data' is not null or undefined
            assertParamExists('accountsRentalMatrixConfigUpdate', 'data', data)
            const localVarPath = `/accounts/rental/matrix/config/{id}/`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {RentalItem} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsRentalMatrixItemCreate: async (data: RentalItem, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('accountsRentalMatrixItemCreate', 'data', data)
            const localVarPath = `/accounts/rental/matrix/item/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsRentalMatrixItemList: async (search?: string, limit?: number, offset?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts/rental/matrix/item/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsTestRead: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts/test`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {WelcomeClient} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsWelcomeClientCreate: async (data: WelcomeClient, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'data' is not null or undefined
            assertParamExists('accountsWelcomeClientCreate', 'data', data)
            const localVarPath = `/accounts/welcome/client/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(data, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountsApi - functional programming interface
 * @export
 */
export const AccountsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {AppToken} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsAppAccessTokenCreate(data: AppToken, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsAppAccessTokenCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsAppAccessTokenCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InstallationToken} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsAppInstallTokenCreate(data: InstallationToken, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstallationToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsAppInstallTokenCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsAppInstallTokenCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AppRefreshToken} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsAppRefreshTokenCreate(data: AppRefreshToken, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppRefreshToken>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsAppRefreshTokenCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsAppRefreshTokenCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} slug 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsAssetVerificationAccountRead(slug: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicAssetVerification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsAssetVerificationAccountRead(slug, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsAssetVerificationAccountRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AssetVerification} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsAssetVerificationCreate(data: AssetVerification, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetVerification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsAssetVerificationCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsAssetVerificationCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this asset verification.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsAssetVerificationDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsAssetVerificationDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsAssetVerificationDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {string} [employee_id] employee_id
         * @param {string} [pincode] pincode
         * @param {string} [master__account] master__account
         * @param {string} [serial] serial
         * @param {string} [generic_product] generic_product
         * @param {string} [email] email
         * @param {string} [mobile] mobile
         * @param {string} [master] master
         * @param {AccountsAssetVerificationExportListStatus} [status] status
         * @param {string} [pagination] Pagination
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsAssetVerificationExportList(search?: string, employee_id?: string, pincode?: string, master__account?: string, serial?: string, generic_product?: string, email?: string, mobile?: string, master?: string, status?: AccountsAssetVerificationExportListStatus, pagination?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountsAssetVerificationExportList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsAssetVerificationExportList(search, employee_id, pincode, master__account, serial, generic_product, email, mobile, master, status, pagination, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsAssetVerificationExportList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {string} [employee_id] employee_id
         * @param {string} [pincode] pincode
         * @param {string} [master__account] master__account
         * @param {string} [serial] serial
         * @param {string} [generic_product] generic_product
         * @param {string} [email] email
         * @param {string} [mobile] mobile
         * @param {string} [master] master
         * @param {AccountsAssetVerificationListStatus} [status] status
         * @param {string} [pagination] Pagination
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsAssetVerificationList(search?: string, employee_id?: string, pincode?: string, master__account?: string, serial?: string, generic_product?: string, email?: string, mobile?: string, master?: string, status?: AccountsAssetVerificationListStatus, pagination?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountsAssetVerificationList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsAssetVerificationList(search, employee_id, pincode, master__account, serial, generic_product, email, mobile, master, status, pagination, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsAssetVerificationList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AssetVerificationMaster} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsAssetVerificationMasterCreate(data: AssetVerificationMaster, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetVerificationMaster>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsAssetVerificationMasterCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsAssetVerificationMasterCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [slug] slug
         * @param {string} [track_location] track_location
         * @param {AccountsAssetVerificationMasterListStatus} [status] status
         * @param {string} [account] account
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsAssetVerificationMasterList(slug?: string, track_location?: string, status?: AccountsAssetVerificationMasterListStatus, account?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountsAssetVerificationMasterList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsAssetVerificationMasterList(slug, track_location, status, account, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsAssetVerificationMasterList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this asset verification master.
         * @param {AssetVerificationMaster} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsAssetVerificationMasterPartialUpdate(id: number, data: AssetVerificationMaster, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetVerificationMaster>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsAssetVerificationMasterPartialUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsAssetVerificationMasterPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this asset verification master.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsAssetVerificationMasterRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetVerificationMaster>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsAssetVerificationMasterRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsAssetVerificationMasterRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this asset verification master.
         * @param {AssetVerificationMaster} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsAssetVerificationMasterUpdate(id: number, data: AssetVerificationMaster, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetVerificationMaster>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsAssetVerificationMasterUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsAssetVerificationMasterUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this asset verification.
         * @param {AssetVerification} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsAssetVerificationPartialUpdate(id: number, data: AssetVerification, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetVerification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsAssetVerificationPartialUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsAssetVerificationPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this asset verification.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsAssetVerificationRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetVerification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsAssetVerificationRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsAssetVerificationRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this asset verification.
         * @param {AssetVerification} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsAssetVerificationUpdate(id: number, data: AssetVerification, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssetVerification>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsAssetVerificationUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsAssetVerificationUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsAuthCheckRead(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsAuthCheckRead(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsAuthCheckRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {ReadOnlyAccount} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsCreate(data: ReadOnlyAccount, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReadOnlyAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [id] id
         * @param {string} [connect__is_rental_client] connect__is_rental_client
         * @param {string} [connect__is_sales_client] connect__is_sales_client
         * @param {string} [connect__is_supplier] connect__is_supplier
         * @param {string} [connect__is_individual] connect__is_individual
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsList(id?: string, connect__is_rental_client?: string, connect__is_sales_client?: string, connect__is_supplier?: string, connect__is_individual?: string, search?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountsList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsList(id, connect__is_rental_client, connect__is_sales_client, connect__is_supplier, connect__is_individual, search, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Account} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsManageCreate(data: Account, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsManageCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsManageCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this account.
         * @param {Account} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsManagePartialUpdate(id: number, data: Account, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsManagePartialUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsManagePartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this account.
         * @param {Account} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsManageUpdate(id: number, data: Account, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsManageUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsManageUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Signup} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsOnboardingSignupCreate(data: Signup, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Signup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsOnboardingSignupCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsOnboardingSignupCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} serial 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsPublicAssetRead(serial: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicGenericProduct>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsPublicAssetRead(serial, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsPublicAssetRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this account.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReadOnlyAccount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RentalLogisticsRate} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsRentalLogisticsRateCreate(data: RentalLogisticsRate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalLogisticsRate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsRentalLogisticsRateCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsRentalLogisticsRateCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rental logistics rate.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsRentalLogisticsRateDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsRentalLogisticsRateDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsRentalLogisticsRateDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [zone] zone
         * @param {string} [account] account
         * @param {string} [rate] rate
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsRentalLogisticsRateList(zone?: string, account?: string, rate?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountsRentalLogisticsRateList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsRentalLogisticsRateList(zone, account, rate, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsRentalLogisticsRateList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rental logistics rate.
         * @param {RentalLogisticsRate} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsRentalLogisticsRatePartialUpdate(id: number, data: RentalLogisticsRate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalLogisticsRate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsRentalLogisticsRatePartialUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsRentalLogisticsRatePartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rental logistics rate.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsRentalLogisticsRateRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalLogisticsRate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsRentalLogisticsRateRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsRentalLogisticsRateRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rental logistics rate.
         * @param {RentalLogisticsRate} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsRentalLogisticsRateUpdate(id: number, data: RentalLogisticsRate, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalLogisticsRate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsRentalLogisticsRateUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsRentalLogisticsRateUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RentalItemConfig} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsRentalMatrixConfigCreate(data: RentalItemConfig, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalItemConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsRentalMatrixConfigCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsRentalMatrixConfigCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rental item config.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsRentalMatrixConfigDelete(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsRentalMatrixConfigDelete(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsRentalMatrixConfigDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsRentalMatrixConfigList(limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountsRentalMatrixConfigList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsRentalMatrixConfigList(limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsRentalMatrixConfigList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rental item config.
         * @param {RentalItemConfig} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsRentalMatrixConfigPartialUpdate(id: number, data: RentalItemConfig, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalItemConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsRentalMatrixConfigPartialUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsRentalMatrixConfigPartialUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rental item config.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsRentalMatrixConfigRead(id: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalItemConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsRentalMatrixConfigRead(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsRentalMatrixConfigRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} id A unique integer value identifying this rental item config.
         * @param {RentalItemConfig} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsRentalMatrixConfigUpdate(id: number, data: RentalItemConfig, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalItemConfig>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsRentalMatrixConfigUpdate(id, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsRentalMatrixConfigUpdate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {RentalItem} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsRentalMatrixItemCreate(data: RentalItem, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RentalItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsRentalMatrixItemCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsRentalMatrixItemCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [search] A search term.
         * @param {number} [limit] Number of results to return per page.
         * @param {number} [offset] The initial index from which to return the results.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsRentalMatrixItemList(search?: string, limit?: number, offset?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountsRentalMatrixItemList200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsRentalMatrixItemList(search, limit, offset, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsRentalMatrixItemList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsTestRead(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsTestRead(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsTestRead']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {WelcomeClient} data 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async accountsWelcomeClientCreate(data: WelcomeClient, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WelcomeClient>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.accountsWelcomeClientCreate(data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountsApi.accountsWelcomeClientCreate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AccountsApi - factory interface
 * @export
 */
export const AccountsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountsApiFp(configuration)
    return {
        /**
         * 
         * @param {AccountsApiAccountsAppAccessTokenCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAppAccessTokenCreate(requestParameters: AccountsApiAccountsAppAccessTokenCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<AppToken> {
            return localVarFp.accountsAppAccessTokenCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountsApiAccountsAppInstallTokenCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAppInstallTokenCreate(requestParameters: AccountsApiAccountsAppInstallTokenCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<InstallationToken> {
            return localVarFp.accountsAppInstallTokenCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountsApiAccountsAppRefreshTokenCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAppRefreshTokenCreate(requestParameters: AccountsApiAccountsAppRefreshTokenCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<AppRefreshToken> {
            return localVarFp.accountsAppRefreshTokenCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountsApiAccountsAssetVerificationAccountReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAssetVerificationAccountRead(requestParameters: AccountsApiAccountsAssetVerificationAccountReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<PublicAssetVerification> {
            return localVarFp.accountsAssetVerificationAccountRead(requestParameters.slug, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountsApiAccountsAssetVerificationCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAssetVerificationCreate(requestParameters: AccountsApiAccountsAssetVerificationCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssetVerification> {
            return localVarFp.accountsAssetVerificationCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountsApiAccountsAssetVerificationDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAssetVerificationDelete(requestParameters: AccountsApiAccountsAssetVerificationDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.accountsAssetVerificationDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountsApiAccountsAssetVerificationExportListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAssetVerificationExportList(requestParameters: AccountsApiAccountsAssetVerificationExportListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AccountsAssetVerificationExportList200Response> {
            return localVarFp.accountsAssetVerificationExportList(requestParameters.search, requestParameters.employee_id, requestParameters.pincode, requestParameters.master__account, requestParameters.serial, requestParameters.generic_product, requestParameters.email, requestParameters.mobile, requestParameters.master, requestParameters.status, requestParameters.pagination, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountsApiAccountsAssetVerificationListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAssetVerificationList(requestParameters: AccountsApiAccountsAssetVerificationListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AccountsAssetVerificationList200Response> {
            return localVarFp.accountsAssetVerificationList(requestParameters.search, requestParameters.employee_id, requestParameters.pincode, requestParameters.master__account, requestParameters.serial, requestParameters.generic_product, requestParameters.email, requestParameters.mobile, requestParameters.master, requestParameters.status, requestParameters.pagination, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountsApiAccountsAssetVerificationMasterCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAssetVerificationMasterCreate(requestParameters: AccountsApiAccountsAssetVerificationMasterCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssetVerificationMaster> {
            return localVarFp.accountsAssetVerificationMasterCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountsApiAccountsAssetVerificationMasterListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAssetVerificationMasterList(requestParameters: AccountsApiAccountsAssetVerificationMasterListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AccountsAssetVerificationMasterList200Response> {
            return localVarFp.accountsAssetVerificationMasterList(requestParameters.slug, requestParameters.track_location, requestParameters.status, requestParameters.account, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountsApiAccountsAssetVerificationMasterPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAssetVerificationMasterPartialUpdate(requestParameters: AccountsApiAccountsAssetVerificationMasterPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssetVerificationMaster> {
            return localVarFp.accountsAssetVerificationMasterPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountsApiAccountsAssetVerificationMasterReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAssetVerificationMasterRead(requestParameters: AccountsApiAccountsAssetVerificationMasterReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssetVerificationMaster> {
            return localVarFp.accountsAssetVerificationMasterRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountsApiAccountsAssetVerificationMasterUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAssetVerificationMasterUpdate(requestParameters: AccountsApiAccountsAssetVerificationMasterUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssetVerificationMaster> {
            return localVarFp.accountsAssetVerificationMasterUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountsApiAccountsAssetVerificationPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAssetVerificationPartialUpdate(requestParameters: AccountsApiAccountsAssetVerificationPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssetVerification> {
            return localVarFp.accountsAssetVerificationPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountsApiAccountsAssetVerificationReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAssetVerificationRead(requestParameters: AccountsApiAccountsAssetVerificationReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssetVerification> {
            return localVarFp.accountsAssetVerificationRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountsApiAccountsAssetVerificationUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAssetVerificationUpdate(requestParameters: AccountsApiAccountsAssetVerificationUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<AssetVerification> {
            return localVarFp.accountsAssetVerificationUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsAuthCheckRead(options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.accountsAuthCheckRead(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountsApiAccountsCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsCreate(requestParameters: AccountsApiAccountsCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<ReadOnlyAccount> {
            return localVarFp.accountsCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountsApiAccountsListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsList(requestParameters: AccountsApiAccountsListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AccountsList200Response> {
            return localVarFp.accountsList(requestParameters.id, requestParameters.connect__is_rental_client, requestParameters.connect__is_sales_client, requestParameters.connect__is_supplier, requestParameters.connect__is_individual, requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountsApiAccountsManageCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsManageCreate(requestParameters: AccountsApiAccountsManageCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Account> {
            return localVarFp.accountsManageCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountsApiAccountsManagePartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsManagePartialUpdate(requestParameters: AccountsApiAccountsManagePartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Account> {
            return localVarFp.accountsManagePartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountsApiAccountsManageUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsManageUpdate(requestParameters: AccountsApiAccountsManageUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Account> {
            return localVarFp.accountsManageUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountsApiAccountsOnboardingSignupCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsOnboardingSignupCreate(requestParameters: AccountsApiAccountsOnboardingSignupCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<Signup> {
            return localVarFp.accountsOnboardingSignupCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountsApiAccountsPublicAssetReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsPublicAssetRead(requestParameters: AccountsApiAccountsPublicAssetReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<PublicGenericProduct> {
            return localVarFp.accountsPublicAssetRead(requestParameters.serial, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountsApiAccountsReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsRead(requestParameters: AccountsApiAccountsReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<ReadOnlyAccount> {
            return localVarFp.accountsRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountsApiAccountsRentalLogisticsRateCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsRentalLogisticsRateCreate(requestParameters: AccountsApiAccountsRentalLogisticsRateCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<RentalLogisticsRate> {
            return localVarFp.accountsRentalLogisticsRateCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountsApiAccountsRentalLogisticsRateDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsRentalLogisticsRateDelete(requestParameters: AccountsApiAccountsRentalLogisticsRateDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.accountsRentalLogisticsRateDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountsApiAccountsRentalLogisticsRateListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsRentalLogisticsRateList(requestParameters: AccountsApiAccountsRentalLogisticsRateListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AccountsRentalLogisticsRateList200Response> {
            return localVarFp.accountsRentalLogisticsRateList(requestParameters.zone, requestParameters.account, requestParameters.rate, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountsApiAccountsRentalLogisticsRatePartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsRentalLogisticsRatePartialUpdate(requestParameters: AccountsApiAccountsRentalLogisticsRatePartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<RentalLogisticsRate> {
            return localVarFp.accountsRentalLogisticsRatePartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountsApiAccountsRentalLogisticsRateReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsRentalLogisticsRateRead(requestParameters: AccountsApiAccountsRentalLogisticsRateReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<RentalLogisticsRate> {
            return localVarFp.accountsRentalLogisticsRateRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountsApiAccountsRentalLogisticsRateUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsRentalLogisticsRateUpdate(requestParameters: AccountsApiAccountsRentalLogisticsRateUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<RentalLogisticsRate> {
            return localVarFp.accountsRentalLogisticsRateUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountsApiAccountsRentalMatrixConfigCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsRentalMatrixConfigCreate(requestParameters: AccountsApiAccountsRentalMatrixConfigCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<RentalItemConfig> {
            return localVarFp.accountsRentalMatrixConfigCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountsApiAccountsRentalMatrixConfigDeleteRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsRentalMatrixConfigDelete(requestParameters: AccountsApiAccountsRentalMatrixConfigDeleteRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.accountsRentalMatrixConfigDelete(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountsApiAccountsRentalMatrixConfigListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsRentalMatrixConfigList(requestParameters: AccountsApiAccountsRentalMatrixConfigListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AccountsRentalMatrixConfigList200Response> {
            return localVarFp.accountsRentalMatrixConfigList(requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountsApiAccountsRentalMatrixConfigPartialUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsRentalMatrixConfigPartialUpdate(requestParameters: AccountsApiAccountsRentalMatrixConfigPartialUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<RentalItemConfig> {
            return localVarFp.accountsRentalMatrixConfigPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountsApiAccountsRentalMatrixConfigReadRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsRentalMatrixConfigRead(requestParameters: AccountsApiAccountsRentalMatrixConfigReadRequest, options?: RawAxiosRequestConfig): AxiosPromise<RentalItemConfig> {
            return localVarFp.accountsRentalMatrixConfigRead(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountsApiAccountsRentalMatrixConfigUpdateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsRentalMatrixConfigUpdate(requestParameters: AccountsApiAccountsRentalMatrixConfigUpdateRequest, options?: RawAxiosRequestConfig): AxiosPromise<RentalItemConfig> {
            return localVarFp.accountsRentalMatrixConfigUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountsApiAccountsRentalMatrixItemCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsRentalMatrixItemCreate(requestParameters: AccountsApiAccountsRentalMatrixItemCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<RentalItem> {
            return localVarFp.accountsRentalMatrixItemCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountsApiAccountsRentalMatrixItemListRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsRentalMatrixItemList(requestParameters: AccountsApiAccountsRentalMatrixItemListRequest = {}, options?: RawAxiosRequestConfig): AxiosPromise<AccountsRentalMatrixItemList200Response> {
            return localVarFp.accountsRentalMatrixItemList(requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsTestRead(options?: RawAxiosRequestConfig): AxiosPromise<object> {
            return localVarFp.accountsTestRead(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AccountsApiAccountsWelcomeClientCreateRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        accountsWelcomeClientCreate(requestParameters: AccountsApiAccountsWelcomeClientCreateRequest, options?: RawAxiosRequestConfig): AxiosPromise<WelcomeClient> {
            return localVarFp.accountsWelcomeClientCreate(requestParameters.data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for accountsAppAccessTokenCreate operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsAppAccessTokenCreateRequest
 */
export interface AccountsApiAccountsAppAccessTokenCreateRequest {
    /**
     * 
     * @type {AppToken}
     * @memberof AccountsApiAccountsAppAccessTokenCreate
     */
    readonly data: AppToken
}

/**
 * Request parameters for accountsAppInstallTokenCreate operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsAppInstallTokenCreateRequest
 */
export interface AccountsApiAccountsAppInstallTokenCreateRequest {
    /**
     * 
     * @type {InstallationToken}
     * @memberof AccountsApiAccountsAppInstallTokenCreate
     */
    readonly data: InstallationToken
}

/**
 * Request parameters for accountsAppRefreshTokenCreate operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsAppRefreshTokenCreateRequest
 */
export interface AccountsApiAccountsAppRefreshTokenCreateRequest {
    /**
     * 
     * @type {AppRefreshToken}
     * @memberof AccountsApiAccountsAppRefreshTokenCreate
     */
    readonly data: AppRefreshToken
}

/**
 * Request parameters for accountsAssetVerificationAccountRead operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsAssetVerificationAccountReadRequest
 */
export interface AccountsApiAccountsAssetVerificationAccountReadRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiAccountsAssetVerificationAccountRead
     */
    readonly slug: string
}

/**
 * Request parameters for accountsAssetVerificationCreate operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsAssetVerificationCreateRequest
 */
export interface AccountsApiAccountsAssetVerificationCreateRequest {
    /**
     * 
     * @type {AssetVerification}
     * @memberof AccountsApiAccountsAssetVerificationCreate
     */
    readonly data: AssetVerification
}

/**
 * Request parameters for accountsAssetVerificationDelete operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsAssetVerificationDeleteRequest
 */
export interface AccountsApiAccountsAssetVerificationDeleteRequest {
    /**
     * A unique integer value identifying this asset verification.
     * @type {number}
     * @memberof AccountsApiAccountsAssetVerificationDelete
     */
    readonly id: number
}

/**
 * Request parameters for accountsAssetVerificationExportList operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsAssetVerificationExportListRequest
 */
export interface AccountsApiAccountsAssetVerificationExportListRequest {
    /**
     * A search term.
     * @type {string}
     * @memberof AccountsApiAccountsAssetVerificationExportList
     */
    readonly search?: string

    /**
     * employee_id
     * @type {string}
     * @memberof AccountsApiAccountsAssetVerificationExportList
     */
    readonly employee_id?: string

    /**
     * pincode
     * @type {string}
     * @memberof AccountsApiAccountsAssetVerificationExportList
     */
    readonly pincode?: string

    /**
     * master__account
     * @type {string}
     * @memberof AccountsApiAccountsAssetVerificationExportList
     */
    readonly master__account?: string

    /**
     * serial
     * @type {string}
     * @memberof AccountsApiAccountsAssetVerificationExportList
     */
    readonly serial?: string

    /**
     * generic_product
     * @type {string}
     * @memberof AccountsApiAccountsAssetVerificationExportList
     */
    readonly generic_product?: string

    /**
     * email
     * @type {string}
     * @memberof AccountsApiAccountsAssetVerificationExportList
     */
    readonly email?: string

    /**
     * mobile
     * @type {string}
     * @memberof AccountsApiAccountsAssetVerificationExportList
     */
    readonly mobile?: string

    /**
     * master
     * @type {string}
     * @memberof AccountsApiAccountsAssetVerificationExportList
     */
    readonly master?: string

    /**
     * status
     * @type {'PENDING' | 'VERIFIED' | 'MISSMATCH'}
     * @memberof AccountsApiAccountsAssetVerificationExportList
     */
    readonly status?: AccountsAssetVerificationExportListStatus

    /**
     * Pagination
     * @type {string}
     * @memberof AccountsApiAccountsAssetVerificationExportList
     */
    readonly pagination?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof AccountsApiAccountsAssetVerificationExportList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof AccountsApiAccountsAssetVerificationExportList
     */
    readonly offset?: number
}

/**
 * Request parameters for accountsAssetVerificationList operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsAssetVerificationListRequest
 */
export interface AccountsApiAccountsAssetVerificationListRequest {
    /**
     * A search term.
     * @type {string}
     * @memberof AccountsApiAccountsAssetVerificationList
     */
    readonly search?: string

    /**
     * employee_id
     * @type {string}
     * @memberof AccountsApiAccountsAssetVerificationList
     */
    readonly employee_id?: string

    /**
     * pincode
     * @type {string}
     * @memberof AccountsApiAccountsAssetVerificationList
     */
    readonly pincode?: string

    /**
     * master__account
     * @type {string}
     * @memberof AccountsApiAccountsAssetVerificationList
     */
    readonly master__account?: string

    /**
     * serial
     * @type {string}
     * @memberof AccountsApiAccountsAssetVerificationList
     */
    readonly serial?: string

    /**
     * generic_product
     * @type {string}
     * @memberof AccountsApiAccountsAssetVerificationList
     */
    readonly generic_product?: string

    /**
     * email
     * @type {string}
     * @memberof AccountsApiAccountsAssetVerificationList
     */
    readonly email?: string

    /**
     * mobile
     * @type {string}
     * @memberof AccountsApiAccountsAssetVerificationList
     */
    readonly mobile?: string

    /**
     * master
     * @type {string}
     * @memberof AccountsApiAccountsAssetVerificationList
     */
    readonly master?: string

    /**
     * status
     * @type {'PENDING' | 'VERIFIED' | 'MISSMATCH'}
     * @memberof AccountsApiAccountsAssetVerificationList
     */
    readonly status?: AccountsAssetVerificationListStatus

    /**
     * Pagination
     * @type {string}
     * @memberof AccountsApiAccountsAssetVerificationList
     */
    readonly pagination?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof AccountsApiAccountsAssetVerificationList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof AccountsApiAccountsAssetVerificationList
     */
    readonly offset?: number
}

/**
 * Request parameters for accountsAssetVerificationMasterCreate operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsAssetVerificationMasterCreateRequest
 */
export interface AccountsApiAccountsAssetVerificationMasterCreateRequest {
    /**
     * 
     * @type {AssetVerificationMaster}
     * @memberof AccountsApiAccountsAssetVerificationMasterCreate
     */
    readonly data: AssetVerificationMaster
}

/**
 * Request parameters for accountsAssetVerificationMasterList operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsAssetVerificationMasterListRequest
 */
export interface AccountsApiAccountsAssetVerificationMasterListRequest {
    /**
     * slug
     * @type {string}
     * @memberof AccountsApiAccountsAssetVerificationMasterList
     */
    readonly slug?: string

    /**
     * track_location
     * @type {string}
     * @memberof AccountsApiAccountsAssetVerificationMasterList
     */
    readonly track_location?: string

    /**
     * status
     * @type {'ACTIVE' | 'IN_PROGRESS' | 'COMPLETED' | 'VERIFIED_CLOSED'}
     * @memberof AccountsApiAccountsAssetVerificationMasterList
     */
    readonly status?: AccountsAssetVerificationMasterListStatus

    /**
     * account
     * @type {string}
     * @memberof AccountsApiAccountsAssetVerificationMasterList
     */
    readonly account?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof AccountsApiAccountsAssetVerificationMasterList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof AccountsApiAccountsAssetVerificationMasterList
     */
    readonly offset?: number
}

/**
 * Request parameters for accountsAssetVerificationMasterPartialUpdate operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsAssetVerificationMasterPartialUpdateRequest
 */
export interface AccountsApiAccountsAssetVerificationMasterPartialUpdateRequest {
    /**
     * A unique integer value identifying this asset verification master.
     * @type {number}
     * @memberof AccountsApiAccountsAssetVerificationMasterPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {AssetVerificationMaster}
     * @memberof AccountsApiAccountsAssetVerificationMasterPartialUpdate
     */
    readonly data: AssetVerificationMaster
}

/**
 * Request parameters for accountsAssetVerificationMasterRead operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsAssetVerificationMasterReadRequest
 */
export interface AccountsApiAccountsAssetVerificationMasterReadRequest {
    /**
     * A unique integer value identifying this asset verification master.
     * @type {number}
     * @memberof AccountsApiAccountsAssetVerificationMasterRead
     */
    readonly id: number
}

/**
 * Request parameters for accountsAssetVerificationMasterUpdate operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsAssetVerificationMasterUpdateRequest
 */
export interface AccountsApiAccountsAssetVerificationMasterUpdateRequest {
    /**
     * A unique integer value identifying this asset verification master.
     * @type {number}
     * @memberof AccountsApiAccountsAssetVerificationMasterUpdate
     */
    readonly id: number

    /**
     * 
     * @type {AssetVerificationMaster}
     * @memberof AccountsApiAccountsAssetVerificationMasterUpdate
     */
    readonly data: AssetVerificationMaster
}

/**
 * Request parameters for accountsAssetVerificationPartialUpdate operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsAssetVerificationPartialUpdateRequest
 */
export interface AccountsApiAccountsAssetVerificationPartialUpdateRequest {
    /**
     * A unique integer value identifying this asset verification.
     * @type {number}
     * @memberof AccountsApiAccountsAssetVerificationPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {AssetVerification}
     * @memberof AccountsApiAccountsAssetVerificationPartialUpdate
     */
    readonly data: AssetVerification
}

/**
 * Request parameters for accountsAssetVerificationRead operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsAssetVerificationReadRequest
 */
export interface AccountsApiAccountsAssetVerificationReadRequest {
    /**
     * A unique integer value identifying this asset verification.
     * @type {number}
     * @memberof AccountsApiAccountsAssetVerificationRead
     */
    readonly id: number
}

/**
 * Request parameters for accountsAssetVerificationUpdate operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsAssetVerificationUpdateRequest
 */
export interface AccountsApiAccountsAssetVerificationUpdateRequest {
    /**
     * A unique integer value identifying this asset verification.
     * @type {number}
     * @memberof AccountsApiAccountsAssetVerificationUpdate
     */
    readonly id: number

    /**
     * 
     * @type {AssetVerification}
     * @memberof AccountsApiAccountsAssetVerificationUpdate
     */
    readonly data: AssetVerification
}

/**
 * Request parameters for accountsCreate operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsCreateRequest
 */
export interface AccountsApiAccountsCreateRequest {
    /**
     * 
     * @type {ReadOnlyAccount}
     * @memberof AccountsApiAccountsCreate
     */
    readonly data: ReadOnlyAccount
}

/**
 * Request parameters for accountsList operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsListRequest
 */
export interface AccountsApiAccountsListRequest {
    /**
     * id
     * @type {string}
     * @memberof AccountsApiAccountsList
     */
    readonly id?: string

    /**
     * connect__is_rental_client
     * @type {string}
     * @memberof AccountsApiAccountsList
     */
    readonly connect__is_rental_client?: string

    /**
     * connect__is_sales_client
     * @type {string}
     * @memberof AccountsApiAccountsList
     */
    readonly connect__is_sales_client?: string

    /**
     * connect__is_supplier
     * @type {string}
     * @memberof AccountsApiAccountsList
     */
    readonly connect__is_supplier?: string

    /**
     * connect__is_individual
     * @type {string}
     * @memberof AccountsApiAccountsList
     */
    readonly connect__is_individual?: string

    /**
     * A search term.
     * @type {string}
     * @memberof AccountsApiAccountsList
     */
    readonly search?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof AccountsApiAccountsList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof AccountsApiAccountsList
     */
    readonly offset?: number
}

/**
 * Request parameters for accountsManageCreate operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsManageCreateRequest
 */
export interface AccountsApiAccountsManageCreateRequest {
    /**
     * 
     * @type {Account}
     * @memberof AccountsApiAccountsManageCreate
     */
    readonly data: Account
}

/**
 * Request parameters for accountsManagePartialUpdate operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsManagePartialUpdateRequest
 */
export interface AccountsApiAccountsManagePartialUpdateRequest {
    /**
     * A unique integer value identifying this account.
     * @type {number}
     * @memberof AccountsApiAccountsManagePartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Account}
     * @memberof AccountsApiAccountsManagePartialUpdate
     */
    readonly data: Account
}

/**
 * Request parameters for accountsManageUpdate operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsManageUpdateRequest
 */
export interface AccountsApiAccountsManageUpdateRequest {
    /**
     * A unique integer value identifying this account.
     * @type {number}
     * @memberof AccountsApiAccountsManageUpdate
     */
    readonly id: number

    /**
     * 
     * @type {Account}
     * @memberof AccountsApiAccountsManageUpdate
     */
    readonly data: Account
}

/**
 * Request parameters for accountsOnboardingSignupCreate operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsOnboardingSignupCreateRequest
 */
export interface AccountsApiAccountsOnboardingSignupCreateRequest {
    /**
     * 
     * @type {Signup}
     * @memberof AccountsApiAccountsOnboardingSignupCreate
     */
    readonly data: Signup
}

/**
 * Request parameters for accountsPublicAssetRead operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsPublicAssetReadRequest
 */
export interface AccountsApiAccountsPublicAssetReadRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountsApiAccountsPublicAssetRead
     */
    readonly serial: string
}

/**
 * Request parameters for accountsRead operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsReadRequest
 */
export interface AccountsApiAccountsReadRequest {
    /**
     * A unique integer value identifying this account.
     * @type {number}
     * @memberof AccountsApiAccountsRead
     */
    readonly id: number
}

/**
 * Request parameters for accountsRentalLogisticsRateCreate operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsRentalLogisticsRateCreateRequest
 */
export interface AccountsApiAccountsRentalLogisticsRateCreateRequest {
    /**
     * 
     * @type {RentalLogisticsRate}
     * @memberof AccountsApiAccountsRentalLogisticsRateCreate
     */
    readonly data: RentalLogisticsRate
}

/**
 * Request parameters for accountsRentalLogisticsRateDelete operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsRentalLogisticsRateDeleteRequest
 */
export interface AccountsApiAccountsRentalLogisticsRateDeleteRequest {
    /**
     * A unique integer value identifying this rental logistics rate.
     * @type {number}
     * @memberof AccountsApiAccountsRentalLogisticsRateDelete
     */
    readonly id: number
}

/**
 * Request parameters for accountsRentalLogisticsRateList operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsRentalLogisticsRateListRequest
 */
export interface AccountsApiAccountsRentalLogisticsRateListRequest {
    /**
     * zone
     * @type {string}
     * @memberof AccountsApiAccountsRentalLogisticsRateList
     */
    readonly zone?: string

    /**
     * account
     * @type {string}
     * @memberof AccountsApiAccountsRentalLogisticsRateList
     */
    readonly account?: string

    /**
     * rate
     * @type {string}
     * @memberof AccountsApiAccountsRentalLogisticsRateList
     */
    readonly rate?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof AccountsApiAccountsRentalLogisticsRateList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof AccountsApiAccountsRentalLogisticsRateList
     */
    readonly offset?: number
}

/**
 * Request parameters for accountsRentalLogisticsRatePartialUpdate operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsRentalLogisticsRatePartialUpdateRequest
 */
export interface AccountsApiAccountsRentalLogisticsRatePartialUpdateRequest {
    /**
     * A unique integer value identifying this rental logistics rate.
     * @type {number}
     * @memberof AccountsApiAccountsRentalLogisticsRatePartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {RentalLogisticsRate}
     * @memberof AccountsApiAccountsRentalLogisticsRatePartialUpdate
     */
    readonly data: RentalLogisticsRate
}

/**
 * Request parameters for accountsRentalLogisticsRateRead operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsRentalLogisticsRateReadRequest
 */
export interface AccountsApiAccountsRentalLogisticsRateReadRequest {
    /**
     * A unique integer value identifying this rental logistics rate.
     * @type {number}
     * @memberof AccountsApiAccountsRentalLogisticsRateRead
     */
    readonly id: number
}

/**
 * Request parameters for accountsRentalLogisticsRateUpdate operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsRentalLogisticsRateUpdateRequest
 */
export interface AccountsApiAccountsRentalLogisticsRateUpdateRequest {
    /**
     * A unique integer value identifying this rental logistics rate.
     * @type {number}
     * @memberof AccountsApiAccountsRentalLogisticsRateUpdate
     */
    readonly id: number

    /**
     * 
     * @type {RentalLogisticsRate}
     * @memberof AccountsApiAccountsRentalLogisticsRateUpdate
     */
    readonly data: RentalLogisticsRate
}

/**
 * Request parameters for accountsRentalMatrixConfigCreate operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsRentalMatrixConfigCreateRequest
 */
export interface AccountsApiAccountsRentalMatrixConfigCreateRequest {
    /**
     * 
     * @type {RentalItemConfig}
     * @memberof AccountsApiAccountsRentalMatrixConfigCreate
     */
    readonly data: RentalItemConfig
}

/**
 * Request parameters for accountsRentalMatrixConfigDelete operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsRentalMatrixConfigDeleteRequest
 */
export interface AccountsApiAccountsRentalMatrixConfigDeleteRequest {
    /**
     * A unique integer value identifying this rental item config.
     * @type {number}
     * @memberof AccountsApiAccountsRentalMatrixConfigDelete
     */
    readonly id: number
}

/**
 * Request parameters for accountsRentalMatrixConfigList operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsRentalMatrixConfigListRequest
 */
export interface AccountsApiAccountsRentalMatrixConfigListRequest {
    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof AccountsApiAccountsRentalMatrixConfigList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof AccountsApiAccountsRentalMatrixConfigList
     */
    readonly offset?: number
}

/**
 * Request parameters for accountsRentalMatrixConfigPartialUpdate operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsRentalMatrixConfigPartialUpdateRequest
 */
export interface AccountsApiAccountsRentalMatrixConfigPartialUpdateRequest {
    /**
     * A unique integer value identifying this rental item config.
     * @type {number}
     * @memberof AccountsApiAccountsRentalMatrixConfigPartialUpdate
     */
    readonly id: number

    /**
     * 
     * @type {RentalItemConfig}
     * @memberof AccountsApiAccountsRentalMatrixConfigPartialUpdate
     */
    readonly data: RentalItemConfig
}

/**
 * Request parameters for accountsRentalMatrixConfigRead operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsRentalMatrixConfigReadRequest
 */
export interface AccountsApiAccountsRentalMatrixConfigReadRequest {
    /**
     * A unique integer value identifying this rental item config.
     * @type {number}
     * @memberof AccountsApiAccountsRentalMatrixConfigRead
     */
    readonly id: number
}

/**
 * Request parameters for accountsRentalMatrixConfigUpdate operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsRentalMatrixConfigUpdateRequest
 */
export interface AccountsApiAccountsRentalMatrixConfigUpdateRequest {
    /**
     * A unique integer value identifying this rental item config.
     * @type {number}
     * @memberof AccountsApiAccountsRentalMatrixConfigUpdate
     */
    readonly id: number

    /**
     * 
     * @type {RentalItemConfig}
     * @memberof AccountsApiAccountsRentalMatrixConfigUpdate
     */
    readonly data: RentalItemConfig
}

/**
 * Request parameters for accountsRentalMatrixItemCreate operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsRentalMatrixItemCreateRequest
 */
export interface AccountsApiAccountsRentalMatrixItemCreateRequest {
    /**
     * 
     * @type {RentalItem}
     * @memberof AccountsApiAccountsRentalMatrixItemCreate
     */
    readonly data: RentalItem
}

/**
 * Request parameters for accountsRentalMatrixItemList operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsRentalMatrixItemListRequest
 */
export interface AccountsApiAccountsRentalMatrixItemListRequest {
    /**
     * A search term.
     * @type {string}
     * @memberof AccountsApiAccountsRentalMatrixItemList
     */
    readonly search?: string

    /**
     * Number of results to return per page.
     * @type {number}
     * @memberof AccountsApiAccountsRentalMatrixItemList
     */
    readonly limit?: number

    /**
     * The initial index from which to return the results.
     * @type {number}
     * @memberof AccountsApiAccountsRentalMatrixItemList
     */
    readonly offset?: number
}

/**
 * Request parameters for accountsWelcomeClientCreate operation in AccountsApi.
 * @export
 * @interface AccountsApiAccountsWelcomeClientCreateRequest
 */
export interface AccountsApiAccountsWelcomeClientCreateRequest {
    /**
     * 
     * @type {WelcomeClient}
     * @memberof AccountsApiAccountsWelcomeClientCreate
     */
    readonly data: WelcomeClient
}

/**
 * AccountsApi - object-oriented interface
 * @export
 * @class AccountsApi
 * @extends {BaseAPI}
 */
export class AccountsApi extends BaseAPI {
    /**
     * 
     * @param {AccountsApiAccountsAppAccessTokenCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsAppAccessTokenCreate(requestParameters: AccountsApiAccountsAppAccessTokenCreateRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsAppAccessTokenCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAccountsAppInstallTokenCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsAppInstallTokenCreate(requestParameters: AccountsApiAccountsAppInstallTokenCreateRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsAppInstallTokenCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAccountsAppRefreshTokenCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsAppRefreshTokenCreate(requestParameters: AccountsApiAccountsAppRefreshTokenCreateRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsAppRefreshTokenCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAccountsAssetVerificationAccountReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsAssetVerificationAccountRead(requestParameters: AccountsApiAccountsAssetVerificationAccountReadRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsAssetVerificationAccountRead(requestParameters.slug, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAccountsAssetVerificationCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsAssetVerificationCreate(requestParameters: AccountsApiAccountsAssetVerificationCreateRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsAssetVerificationCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAccountsAssetVerificationDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsAssetVerificationDelete(requestParameters: AccountsApiAccountsAssetVerificationDeleteRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsAssetVerificationDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAccountsAssetVerificationExportListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsAssetVerificationExportList(requestParameters: AccountsApiAccountsAssetVerificationExportListRequest = {}, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsAssetVerificationExportList(requestParameters.search, requestParameters.employee_id, requestParameters.pincode, requestParameters.master__account, requestParameters.serial, requestParameters.generic_product, requestParameters.email, requestParameters.mobile, requestParameters.master, requestParameters.status, requestParameters.pagination, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAccountsAssetVerificationListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsAssetVerificationList(requestParameters: AccountsApiAccountsAssetVerificationListRequest = {}, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsAssetVerificationList(requestParameters.search, requestParameters.employee_id, requestParameters.pincode, requestParameters.master__account, requestParameters.serial, requestParameters.generic_product, requestParameters.email, requestParameters.mobile, requestParameters.master, requestParameters.status, requestParameters.pagination, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAccountsAssetVerificationMasterCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsAssetVerificationMasterCreate(requestParameters: AccountsApiAccountsAssetVerificationMasterCreateRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsAssetVerificationMasterCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAccountsAssetVerificationMasterListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsAssetVerificationMasterList(requestParameters: AccountsApiAccountsAssetVerificationMasterListRequest = {}, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsAssetVerificationMasterList(requestParameters.slug, requestParameters.track_location, requestParameters.status, requestParameters.account, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAccountsAssetVerificationMasterPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsAssetVerificationMasterPartialUpdate(requestParameters: AccountsApiAccountsAssetVerificationMasterPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsAssetVerificationMasterPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAccountsAssetVerificationMasterReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsAssetVerificationMasterRead(requestParameters: AccountsApiAccountsAssetVerificationMasterReadRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsAssetVerificationMasterRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAccountsAssetVerificationMasterUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsAssetVerificationMasterUpdate(requestParameters: AccountsApiAccountsAssetVerificationMasterUpdateRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsAssetVerificationMasterUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAccountsAssetVerificationPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsAssetVerificationPartialUpdate(requestParameters: AccountsApiAccountsAssetVerificationPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsAssetVerificationPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAccountsAssetVerificationReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsAssetVerificationRead(requestParameters: AccountsApiAccountsAssetVerificationReadRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsAssetVerificationRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAccountsAssetVerificationUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsAssetVerificationUpdate(requestParameters: AccountsApiAccountsAssetVerificationUpdateRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsAssetVerificationUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsAuthCheckRead(options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsAuthCheckRead(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAccountsCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsCreate(requestParameters: AccountsApiAccountsCreateRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAccountsListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsList(requestParameters: AccountsApiAccountsListRequest = {}, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsList(requestParameters.id, requestParameters.connect__is_rental_client, requestParameters.connect__is_sales_client, requestParameters.connect__is_supplier, requestParameters.connect__is_individual, requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAccountsManageCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsManageCreate(requestParameters: AccountsApiAccountsManageCreateRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsManageCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAccountsManagePartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsManagePartialUpdate(requestParameters: AccountsApiAccountsManagePartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsManagePartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAccountsManageUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsManageUpdate(requestParameters: AccountsApiAccountsManageUpdateRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsManageUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAccountsOnboardingSignupCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsOnboardingSignupCreate(requestParameters: AccountsApiAccountsOnboardingSignupCreateRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsOnboardingSignupCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAccountsPublicAssetReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsPublicAssetRead(requestParameters: AccountsApiAccountsPublicAssetReadRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsPublicAssetRead(requestParameters.serial, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAccountsReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsRead(requestParameters: AccountsApiAccountsReadRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAccountsRentalLogisticsRateCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsRentalLogisticsRateCreate(requestParameters: AccountsApiAccountsRentalLogisticsRateCreateRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsRentalLogisticsRateCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAccountsRentalLogisticsRateDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsRentalLogisticsRateDelete(requestParameters: AccountsApiAccountsRentalLogisticsRateDeleteRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsRentalLogisticsRateDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAccountsRentalLogisticsRateListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsRentalLogisticsRateList(requestParameters: AccountsApiAccountsRentalLogisticsRateListRequest = {}, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsRentalLogisticsRateList(requestParameters.zone, requestParameters.account, requestParameters.rate, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAccountsRentalLogisticsRatePartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsRentalLogisticsRatePartialUpdate(requestParameters: AccountsApiAccountsRentalLogisticsRatePartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsRentalLogisticsRatePartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAccountsRentalLogisticsRateReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsRentalLogisticsRateRead(requestParameters: AccountsApiAccountsRentalLogisticsRateReadRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsRentalLogisticsRateRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAccountsRentalLogisticsRateUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsRentalLogisticsRateUpdate(requestParameters: AccountsApiAccountsRentalLogisticsRateUpdateRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsRentalLogisticsRateUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAccountsRentalMatrixConfigCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsRentalMatrixConfigCreate(requestParameters: AccountsApiAccountsRentalMatrixConfigCreateRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsRentalMatrixConfigCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAccountsRentalMatrixConfigDeleteRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsRentalMatrixConfigDelete(requestParameters: AccountsApiAccountsRentalMatrixConfigDeleteRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsRentalMatrixConfigDelete(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAccountsRentalMatrixConfigListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsRentalMatrixConfigList(requestParameters: AccountsApiAccountsRentalMatrixConfigListRequest = {}, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsRentalMatrixConfigList(requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAccountsRentalMatrixConfigPartialUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsRentalMatrixConfigPartialUpdate(requestParameters: AccountsApiAccountsRentalMatrixConfigPartialUpdateRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsRentalMatrixConfigPartialUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAccountsRentalMatrixConfigReadRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsRentalMatrixConfigRead(requestParameters: AccountsApiAccountsRentalMatrixConfigReadRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsRentalMatrixConfigRead(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAccountsRentalMatrixConfigUpdateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsRentalMatrixConfigUpdate(requestParameters: AccountsApiAccountsRentalMatrixConfigUpdateRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsRentalMatrixConfigUpdate(requestParameters.id, requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAccountsRentalMatrixItemCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsRentalMatrixItemCreate(requestParameters: AccountsApiAccountsRentalMatrixItemCreateRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsRentalMatrixItemCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAccountsRentalMatrixItemListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsRentalMatrixItemList(requestParameters: AccountsApiAccountsRentalMatrixItemListRequest = {}, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsRentalMatrixItemList(requestParameters.search, requestParameters.limit, requestParameters.offset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsTestRead(options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsTestRead(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AccountsApiAccountsWelcomeClientCreateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public accountsWelcomeClientCreate(requestParameters: AccountsApiAccountsWelcomeClientCreateRequest, options?: RawAxiosRequestConfig) {
        return AccountsApiFp(this.configuration).accountsWelcomeClientCreate(requestParameters.data, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const AccountsAssetVerificationExportListStatus = {
    PENDING: 'PENDING',
    VERIFIED: 'VERIFIED',
    MISSMATCH: 'MISSMATCH'
} as const;
export type AccountsAssetVerificationExportListStatus = typeof AccountsAssetVerificationExportListStatus[keyof typeof AccountsAssetVerificationExportListStatus];
/**
 * @export
 */
export const AccountsAssetVerificationListStatus = {
    PENDING: 'PENDING',
    VERIFIED: 'VERIFIED',
    MISSMATCH: 'MISSMATCH'
} as const;
export type AccountsAssetVerificationListStatus = typeof AccountsAssetVerificationListStatus[keyof typeof AccountsAssetVerificationListStatus];
/**
 * @export
 */
export const AccountsAssetVerificationMasterListStatus = {
    ACTIVE: 'ACTIVE',
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETED: 'COMPLETED',
    VERIFIED_CLOSED: 'VERIFIED_CLOSED'
} as const;
export type AccountsAssetVerificationMasterListStatus = typeof AccountsAssetVerificationMasterListStatus[keyof typeof AccountsAssetVerificationMasterListStatus];
